import type * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { UpdateProfile } from '../mutations/updateProfile.mutation';
import { useUserQuery } from './useUserQuery';

export const useUpdateProfileMutation = (
  options?: MutationOptions<Types.UpdateProfileMutation, Types.UpdateProfileMutationVariables>,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(UpdateProfile), {
    ...options,
    onSuccess: async (resp, variables, ctx) => {
      await options?.onSuccess?.(resp, variables, ctx);
      queryClient.invalidateQueries(useUserQuery.getKey({}));
    },
  });
};
