import { uuid } from '@piccolohealth/echo-common';
import React from 'react';
import { useReport } from '../../../../context/ReportContext';
import { useAddReportTemplateVariableMutation } from '../../../../graphql/hooks/useAddReportTemplateVariableMutation';
import { useAppContext } from '../../../../hooks/useAppContext';
import { type FormValues, VariableForm } from './VariableForm';

interface Props {
  site?: string;
  onExit: () => void;
}

export const CreateVariablePage = (props: Props) => {
  const { site, onExit } = props;
  const { organization, successToast, errorToast } = useAppContext();
  const { reportTemplate } = useReport();

  const mutation = useAddReportTemplateVariableMutation();

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      await mutation
        .mutateAsync({
          organizationId: organization.id,
          reportTemplateId: reportTemplate.id,
          request: {
            __typename: 'ReportTemplateChoiceVariable',
            id: values.id,
            label: values.name,
            site: values.site,
            category: values.category,
            controlType: 'SELECT',
            defaultValue: values.choices.find((c) => c.default)?.value,
            choices: values.choices.map((v) => ({
              ...v,
              label: v.value,
            })),
            mappings: [],
            isEditable: true,
          },
        })
        .then(() => {
          successToast('Variable added successfully');
          onExit();
        })
        .catch((err) => {
          errorToast(`Error adding variable: ${err.message}`);
        });
    },
    [mutation, organization, reportTemplate, successToast, errorToast, onExit],
  );

  const initialValues = {
    id: uuid(),
    name: '',
    category: '',
    site: site ?? '',
    choices: [],
  };

  return <VariableForm initialValues={initialValues} onSubmit={onSubmit} />;
};
