import { Tag, type TagProps } from '@chakra-ui/react';
import { DistributionEventType } from '@piccolohealth/echo-common';
import React from 'react';

interface Props extends TagProps {
  type: DistributionEventType;
}

const getTextAndColor = (type: DistributionEventType) => {
  switch (type) {
    case DistributionEventType.Created:
      return { text: 'Created', color: 'yellow' };
    case DistributionEventType.Updated:
      return { text: 'Updated', color: 'orange' };
    case DistributionEventType.Processing:
      return { text: 'Processing', color: 'blue' };
    case DistributionEventType.Sent:
      return { text: 'Sent', color: 'green' };
    case DistributionEventType.Bounced:
    case DistributionEventType.Failed:
      return { text: 'Failed', color: 'red' };
  }
};

export const DistributionEventTypeTag = (props: Props) => {
  const { type, ...rest } = props;

  const { text, color } = getTextAndColor(type);

  return (
    <Tag colorScheme={color} {...rest}>
      {text}
    </Tag>
  );
};
