import { Flex } from '@chakra-ui/react';
import type { Instance, LooseObject } from '@piccolohealth/echo-common';
import { P } from '@piccolohealth/util';
import React, { forwardRef } from 'react';
import { useAppContext } from '../../../hooks/useAppContext';
import type { InstanceFormat } from '../controls/InstanceFormatTag';
import type { Layout } from '../controls/Layout';
import type { Viewport } from '../hooks/useImageViewerActions';
import type { HTMLPlayerElement } from '../hooks/usePlayerRefs';
import { MeasurementTools } from '../measurements/tool/MeasurementTools';
import type { UseMeasurementControlReturn } from '../measurements/tool/hooks/useMeasurementControl';
import { Overlay } from '../overlays/Overlay';
import type { Synchronizer } from '../utils/synchronizer';
import { JpegPlayer } from './JpegPlayer';
import { Mp4Player } from './Mp4Player';

interface Props {
  gridIndex: number;
  viewport: Viewport;
  isPlaying: boolean;
  speed: number;
  synchronizer: Synchronizer;
  brightness: number;
  contrast: number;
  layout: Layout;
  measurementControl: UseMeasurementControlReturn;
  onClickViewportInstance: (instance: Instance) => void;
  onScreenshot?: (data: string, metadata: LooseObject) => void;
}

export const Player = forwardRef<HTMLPlayerElement, Props>((props, ref) => {
  const {
    gridIndex,
    viewport,
    isPlaying,
    speed,
    synchronizer,
    brightness,
    contrast,
    layout,
    measurementControl,
    onScreenshot,
    onClickViewportInstance,
  } = props;

  const { config } = useAppContext();

  const stack = viewport.stack;
  const instance = stack.instances[stack.index ?? 0];
  const format: InstanceFormat = instance.mp4Url ? 'MP4' : 'JPEG';
  const total = viewport.total;

  const content = P.run(() => {
    if (format === 'MP4' && instance.mp4Url) {
      const options = {
        src: instance.mp4Url,
        isPlaying: isPlaying,
        playbackRate: speed,
        duration: instance.dicom.duration as number,
        frameRate: instance.dicom.frameRate as number,
        numberOfFrames: instance.dicom.numberOfFrames as number,
        metadata: instance.dicom,
        synchronizer: synchronizer,
        onScreenshot,
      };

      return <Mp4Player ref={ref as React.LegacyRef<HTMLVideoElement>} {...options} />;
    } else if (format === 'JPEG' && instance.frameUrl) {
      const options = {
        id: instance.id,
        src: `${config.api.url}${instance.frameUrl}/${1}`,
        metadata: instance.dicom,
        onScreenshot,
      };

      return <JpegPlayer ref={ref as React.LegacyRef<HTMLImageElement>} {...options} />;
    }

    return null;
  });

  const instanceInfo = {
    index: stack.index ?? viewport.index,
    total,
    instance,
  };

  const preventMouseInteraction = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => event.preventDefault(),
    [],
  );

  return (
    <Flex
      bg='black'
      onContextMenu={preventMouseInteraction}
      onMouseDown={preventMouseInteraction}
      filter={`
        brightness(${brightness}) contrast(${contrast})
      `}
    >
      <Overlay
        gridIndex={gridIndex}
        format={format}
        instanceInfo={instanceInfo}
        layout={layout}
        viewport={viewport}
        onClickInstance={onClickViewportInstance}
      />
      <MeasurementTools measurementControl={measurementControl} />
      {content}
    </Flex>
  );
});
