import type {
  MeasurementMapping,
  MeasurementMappingAndVariants,
  MeasurementVariant,
} from '@piccolohealth/echo-common';
import { P } from '@piccolohealth/util';
import type { TreeItem, TreeItemIndex } from 'react-complex-tree';

const LIMIT = 10000;

export type RootNode = {
  type: 'root';
  title: string;
};

export type SiteNode = {
  type: 'site';
  title: string;
};

export type NameNode = {
  type: 'name';
  title: string;
};

export type HashNode = {
  type: 'hash';
  title: string;
  variant: MeasurementVariant;
  variantCount: number;
};

export type VariantNode = {
  type: 'variant';
  title: string;
  variant: MeasurementVariant;
};

export type MappingNode = {
  type: 'mapping';
  title: string;
  mapping: MeasurementMapping;
  hashCount: number;
};

export type TreeData = RootNode | SiteNode | NameNode | HashNode | VariantNode | MappingNode;

export const generateMeasurementVariantsTree = (
  variants: MeasurementVariant[],
): Record<TreeItemIndex, TreeItem<TreeData>> => {
  const prefix = 'V';
  const rootIndex = `${prefix}-ROOT`;

  const items: Record<TreeItemIndex, TreeItem<TreeData>> = {
    [rootIndex]: {
      index: rootIndex,
      canMove: true,
      isFolder: true,
      children: [],
      data: { type: 'root', title: 'Root variant node' },
      canRename: true,
    },
  };

  const trimmedVariants = P.take(variants, LIMIT);
  const variantsBySite = P.groupBy(
    P.orderBy(trimmedVariants, (item) => item.site ?? 'No site'),
    (v) => v.site ?? 'No site',
  );

  Object.entries(variantsBySite).forEach(([site, groupedVariants]) => {
    const variants = groupedVariants ?? [];

    const siteIndex = `${prefix}-S-${site}`;

    items[siteIndex] = {
      index: siteIndex,
      canMove: true,
      isFolder: true,
      children: [],
      data: {
        type: 'site',
        title: site,
      },
      canRename: false,
    };

    const variantsByName = P.groupBy(
      P.orderBy(variants, (item) => item.name),
      (v) => v.name,
    );

    Object.entries(variantsByName).forEach(([name, groupedVariants]) => {
      const variants = groupedVariants ?? [];

      const nameIndex = `${prefix}-N-${site}-${name}`;

      items[nameIndex] = {
        index: nameIndex,
        canMove: true,
        isFolder: true,
        children: [],
        data: {
          type: 'name',
          title: name,
        },
        canRename: false,
      };

      const variantsByHash = P.groupBy(
        P.orderBy(variants, (item) => item.hash),
        (v) => v.hash,
      );

      Object.entries(variantsByHash).forEach(([hash, groupedVariants]) => {
        const variants = groupedVariants ?? [];

        const hashIndex = `${prefix}-H-${hash}`;

        items[hashIndex] = {
          index: hashIndex,
          canMove: true,
          isFolder: true,
          children: [],
          data: {
            type: 'hash',
            title: variants[0].name,
            variant: variants[0],
            variantCount: variants.length,
          },
          canRename: false,
        };

        variants.forEach((variant) => {
          const variantIndex = `${prefix}-V-${variant.id}`;
          items[variantIndex] = {
            index: variantIndex,
            canMove: true,
            isFolder: false,
            children: [],
            data: {
              type: 'variant',
              title: variant.name,
              variant: variant,
            },
            canRename: true,
          };

          items[hashIndex].children?.push(variantIndex);
        });

        items[nameIndex].children?.push(hashIndex);
      });

      items[siteIndex].children?.push(nameIndex);
    });

    items[rootIndex].children?.push(siteIndex);
  });

  return items;
};

export const generateMeasurementMappingsTree = (
  mappings: MeasurementMappingAndVariants[],
): Record<TreeItemIndex, TreeItem<TreeData>> => {
  const prefix = 'M';
  const rootIndex = `${prefix}-ROOT`;

  const items: Record<TreeItemIndex, TreeItem<TreeData>> = {
    [rootIndex]: {
      index: rootIndex,
      canMove: true,
      isFolder: true,
      children: [],
      data: {
        type: 'root',
        title: 'Root mapping node',
      },
      canRename: true,
    },
  };

  const trimmedMappings = P.take(mappings, LIMIT);
  const mappingsBySite = P.groupBy(
    P.orderBy(trimmedMappings, (item) => `${item.mapping.name ?? item.mapping.id}`),
    (m) => m.mapping.site ?? 'No site',
  );

  Object.entries(mappingsBySite).forEach(([site, groupedMappings]) => {
    const mappings = groupedMappings ?? [];
    const siteIndex = `${prefix}-S-${site}`;

    items[siteIndex] = {
      index: siteIndex,
      canMove: true,
      isFolder: true,
      children: [],
      data: {
        type: 'site',
        title: site,
      },
      canRename: false,
    };

    const mappingsByName = P.groupBy(
      P.orderBy(mappings, (item) => item.mapping.name),
      (v) => v.mapping.name,
    );

    Object.entries(mappingsByName).forEach(([name, groupedMappings]) => {
      const mappings = groupedMappings ?? [];
      const nameIndex = `${prefix}-N-${site}-${name}`;

      items[nameIndex] = {
        index: nameIndex,
        canMove: true,
        isFolder: true,
        children: [],
        data: {
          type: 'name',
          title: name,
        },
        canRename: false,
      };

      mappings.forEach((mapping) => {
        const mappingIndex = `${prefix}-M-${mapping.mapping.id}`;
        const variantsByHash = P.groupBy(mapping.variants, (v) => v.hash);
        const hashCount = Object.keys(variantsByHash).length;

        items[mappingIndex] = {
          index: mappingIndex,
          canMove: true,
          isFolder: true,
          children: [],
          data: {
            type: 'mapping',
            title: mapping.mapping.alias ?? mapping.mapping.name,
            mapping: mapping.mapping,
            hashCount: hashCount,
          },
          canRename: true,
        };

        Object.entries(variantsByHash).forEach(([hash, groupedVariants]) => {
          const variants = groupedVariants ?? [];
          const hashIndex = `${prefix}-H-${hash}`;

          items[hashIndex] = {
            index: hashIndex,
            canMove: true,
            isFolder: true,
            children: [],
            data: {
              type: 'hash',
              title: variants[0].name,
              variant: variants[0],
              variantCount: variants.length,
            },
            canRename: false,
          };

          variants.forEach((variant) => {
            const variantIndex = `${prefix}-V-${variant.id}`;
            items[variantIndex] = {
              index: variantIndex,
              canMove: true,
              isFolder: false,
              children: [],
              data: {
                type: 'variant',
                title: variant.name,
                variant: variant,
              },
              canRename: true,
            };

            items[hashIndex].children?.push(variantIndex);
          });

          items[mappingIndex].children?.push(hashIndex);
        });

        items[nameIndex].children?.push(mappingIndex);
      });

      items[siteIndex].children?.push(nameIndex);
    });

    items[rootIndex].children?.push(siteIndex);
  });

  return items;
};

export const generateMappingsAndVariantsTree = (
  variants: MeasurementVariant[],
  mappings: MeasurementMappingAndVariants[],
) => {
  const variantsTree = generateMeasurementVariantsTree(variants);
  const mappingsTree = generateMeasurementMappingsTree(mappings);

  return {
    ...variantsTree,
    ...mappingsTree,
  };
};

export const getParentOfItem = <A>(
  tree: Record<string, TreeItem<A>>,
  itemId: TreeItemIndex,
): TreeItem<A> | null => {
  // Find the parent by iterating over the tree
  for (const nodeId in tree) {
    const node = tree[nodeId];
    if (node.children?.includes(itemId)) {
      return node;
    }
  }
  // If no parent is found, return null
  return null;
};
