import { Command } from '@piccolohealth/ui';
import { FloatingPopover } from '@piccolohealth/ui';
import type { Editor, Range } from '@tiptap/core';
import { ReactRenderer } from '@tiptap/react';
import React from 'react';
import { type TiptapContext, useTiptapContext } from '../../utils/TiptapContext';
import type { ContextMenuProps, Renderer } from './ContextMenuPlugin';

export type TiptapCommandMenuState = {
  editor: Editor;
  range: Range;
  context: TiptapContext;
};

export const getContextMenuRenderer = (
  getPages: (state: TiptapCommandMenuState) => Command.Page<TiptapCommandMenuState>[],
  rootPageId?: Command.PageIdWithArgs,
): Renderer => {
  let reactRenderer: ReactRenderer<unknown, ContextMenuProps>;

  return {
    onStart: (props) => {
      reactRenderer = new ReactRenderer(
        () => {
          const tiptapContext = useTiptapContext();

          const state = {
            context: tiptapContext,
            editor: props.editor,
            range: {
              from: props.editor.state.selection.anchor,
              to: props.editor.state.selection.anchor + 1,
            },
          };

          return (
            <FloatingPopover
              open={props.open}
              setOpen={props.setOpen}
              clientRect={props.clientRect}
              shouldInitialFocus
              isPortal
              portalRootId='imageviewer-portal'
              render={({ context }) => (
                <Command.CommandMenu
                  ctx={state}
                  onOpenChange={context.onOpenChange}
                  getPages={getPages}
                  rootPageId={rootPageId}
                />
              )}
            />
          );
        },
        {
          props,
          editor: props.editor,
        },
      );

      if (!props.clientRect) {
        return;
      }
    },

    onUpdate: (props) => {
      reactRenderer.render();
      reactRenderer?.updateProps(props);
    },

    onExit: () => {
      reactRenderer?.destroy();
    },
  };
};
