import type * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { UnshareReport } from '../mutations/unshareReport.mutation';
import { useReportSharesMinimalQuery } from './useReportQuery';

export const useUnshareReportMutation = (
  options?: MutationOptions<Types.UnshareReportMutation, Types.UnshareReportMutationVariables>,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(UnshareReport), {
    ...options,
    onSuccess: async (resp, variables, ctx) => {
      await options?.onSuccess?.(resp, variables, ctx);

      queryClient.invalidateQueries(
        useReportSharesMinimalQuery.getKey({
          organizationId: variables.organizationId,
          reportId: variables.reportId,
        }),
      );
    },
  });
};
