import { Heading, Input } from '@chakra-ui/react';
import React from 'react';
import type { ReportsFilter } from '../../../hooks/useReportsFilter';
import { AutoCompleteReportVariable } from '../../forms/AutoCompleteReportVariable';

interface Props {
  filter: ReportsFilter;
  isAutoComplete: boolean;
}

export const PatientNameFilterControl = ({ filter, isAutoComplete }: Props) => {
  const { reportPatientNameFilter, onReportPatientNameFilter } = filter;

  const control = isAutoComplete ? (
    <AutoCompleteReportVariable
      alias='patientName'
      value={reportPatientNameFilter}
      onChange={(value) => onReportPatientNameFilter(value)}
      data-pw='reportPatientNameFilterAutoCompleteInput'
      placeholder='All names'
    />
  ) : (
    <Input
      bg='white'
      size='sm'
      defaultValue={reportPatientNameFilter}
      placeholder='All names'
      onChange={(event) => onReportPatientNameFilter(event.target.value)}
      data-pw='reportPatientNameFilterAutoCompleteInput'
    />
  );

  return (
    <>
      <Heading as='h5' size='sm' pb='2'>
        Patient name
      </Heading>
      {control}
    </>
  );
};
