import { Center, Stack } from '@chakra-ui/react';
import type { Instance } from '@piccolohealth/echo-common';
import React from 'react';
import { useAppContext } from '../../../hooks/useAppContext';
import { AuthedImage } from '../../generic/AuthedImage';
import type { Viewport } from '../hooks/useImageViewerActions';

const WIDTH = 60;
const HEIGHT = WIDTH * 0.95;

interface Props {
  viewport: Viewport;
  onClickInstance: (instance: Instance) => void;
}

export const StressImageChooserOverlay = (props: Props) => {
  const { viewport, onClickInstance } = props;

  const { config } = useAppContext();

  if (viewport.stack.index === null) {
    return null;
  }

  return (
    <Stack>
      {viewport.stack.instances.map((instance, index) => {
        const thumbUrl = `${config.api.url}${instance.frameUrl}/1`;
        const borderColor = viewport.stack.index === index ? '#9187DA' : 'gray.400';

        return (
          <Center
            key={`thumbnail-${instance.id}`}
            w={`${WIDTH}px`}
            h={`${HEIGHT}px`}
            bg='black'
            borderColor={borderColor}
            borderWidth='2px'
            overflow='hidden'
            rounded='md'
            _hover={{
              filter: 'brightness(1.4)',
              cursor: 'pointer',
            }}
            onClick={() => onClickInstance(instance)}
          >
            <AuthedImage
              cacheKey={`thumbnail-${instance.id}`}
              url={thumbUrl}
              w='full'
              roundedBottom='md'
            />
          </Center>
        );
      })}
    </Stack>
  );
};
