import { Avatar, type AvatarProps } from '@chakra-ui/react';
import React from 'react';
import { PiccoloIcon } from '../generic/PiccoloIcon';

interface Props extends AvatarProps {
  picture?: string | null;
  name?: string;
}

export const UserAvatar = (props: Props) => {
  const { picture, name, ...rest } = props;

  if (picture) {
    return <Avatar src={picture} {...rest} />;
  }

  if (name) {
    return <Avatar name={name} mt='7px' {...rest} />;
  }

  return <Avatar icon={<PiccoloIcon />} mt='7px' {...rest} />;
};
