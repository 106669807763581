import { Heading } from '@chakra-ui/react';
import type { Label } from '@piccolohealth/echo-common';
import { MultiSelect, type MultiSelectOption, type OnChangeRequest } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useLabelsQuery } from '../../../graphql/hooks/useLabelsQuery';
import { useAppContext } from '../../../hooks/useAppContext';
import type { ReportsFilter } from '../../../hooks/useReportsFilter';

const labelToOption = (label: Label): MultiSelectOption<Label> => {
  return {
    value: label.id,
    label: label.name,
    color: label.color,
    raw: label,
  };
};

interface Props {
  filter: ReportsFilter;
}

export const LabelsFilterControl = ({ filter }: Props) => {
  const { onLabelsFilter, labelsFilter } = filter;
  const { organization } = useAppContext();
  const { data } = useLabelsQuery({ organizationId: organization.id });

  const options = React.useMemo(() => {
    const labels = (data?.organization?.labels as Label[]) || [];
    return labels.map(labelToOption);
  }, [data?.organization?.labels]);

  const selectedValues: MultiSelectOption<Label>[] = React.useMemo(() => {
    return P.compact(labelsFilter.map((labelId) => options.find((o) => o.value === labelId)));
  }, [labelsFilter, options]);

  const onChange = React.useCallback(
    (req: OnChangeRequest<Label>) => {
      onLabelsFilter(req.values.map(({ value }) => value));
    },
    [onLabelsFilter],
  );

  return (
    <>
      <Heading as='h5' size='sm' pb='2'>
        Labels
      </Heading>
      <MultiSelect
        options={options}
        selectedValues={selectedValues}
        onChange={onChange}
        placeholder='All labels'
        data-pw='filterByLabelsMultiselect'
        optionVariant='tag'
      />
    </>
  );
};
