import { Spin } from '@piccolohealth/ui';
import React from 'react';
import type { RouteObject } from 'react-router-dom';
import { AdminProtectedRoute } from '../../components/generic/AdminProtectedRoute';
import { PageError } from '../../components/generic/PageError';
import { Dicom } from '../dicom/components/Dicom';
import { AdminLayout } from './components/AdminLayout';
import { Offboard } from './components/Offboard';
import { Onboard } from './components/Onboard';

const GraphQLIDE = React.lazy(() => import('./components/GraphQLIDE'));

export const adminRoutes: RouteObject[] = [
  {
    path: '*',
    element: (
      <AdminProtectedRoute>
        <AdminLayout />
      </AdminProtectedRoute>
    ),
    children: [
      {
        path: 'onboard',
        element: <Onboard />,
      },
      {
        path: 'offboard',
        element: <Offboard />,
      },
      {
        path: 'dicom/*',
        element: <Dicom />,
      },
      {
        path: 'graphiql',
        element: (
          <React.Suspense fallback={<Spin />}>
            <GraphQLIDE />
          </React.Suspense>
        ),
      },
      {
        path: '*',
        element: <PageError type='NotFound' />,
      },
    ],
  },
];
