import {
  Button,
  ButtonGroup,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import type { ReportTemplateVariable } from '@piccolohealth/echo-common';
import React from 'react';
import { useDeleteReportTemplateVariableMutation } from '../../../graphql/hooks/useDeleteReportTemplateVariableMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { createModal } from '../../generic/Modal';

interface Props {
  organizationId: string;
  reportTemplateId: string;
  variable: ReportTemplateVariable;
}

export const ReportTemplateVariableDeleteModal = createModal<Props>((props) => {
  const { organizationId, reportTemplateId, variable, modal } = props;
  const { successToast, errorToast } = useAppContext();

  const mutation = useDeleteReportTemplateVariableMutation();

  const onClick = async () => {
    await mutation
      .mutateAsync({
        organizationId,
        reportTemplateId,
        reportTemplateVariableId: variable.id,
      })
      .then(() => {
        successToast('Variable template deleted successfully');
        modal.hide();
      })
      .catch((err) => {
        errorToast(`Error deleting report template variable: ${err.message}`);
      });
  };

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Do you want to delete this report template variable?</ModalHeader>
        <ModalCloseButton />
        <ModalFooter>
          <ButtonGroup size='sm'>
            <Button key='close' onClick={modal.hide}>
              Close
            </Button>
            <Button
              key='submit'
              type='submit'
              colorScheme='red'
              isLoading={mutation.isLoading}
              loadingText='Deleting...'
              onClick={onClick}
            >
              Delete
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
