import type { Editor } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';
import type { EditorView } from 'prosemirror-view';

export interface ContextMenuProps {
  editor: Editor;
  open: boolean;
  setOpen: (value: boolean) => void;
  clientRect?: () => DOMRect;
}

export interface ContextMenuPluginProps {
  editor: Editor;
  renderer?: () => Renderer;
}

export type ContextMenuViewProps = ContextMenuPluginProps & {
  view: EditorView;
};

export interface Renderer {
  onStart?: (props: ContextMenuProps) => void;
  onUpdate?: (props: ContextMenuProps) => void;
  onExit?: (props: ContextMenuProps) => void;
}

export class ContextMenuView {
  editor: Editor;
  view: EditorView;
  renderer?: Renderer;
  props: ContextMenuProps;

  constructor(options: ContextMenuViewProps) {
    this.editor = options.editor;
    this.view = options.view;
    this.renderer = options.renderer?.();

    this.onContextMenu = this.onContextMenu.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.setOpen = this.setOpen.bind(this);

    this.view.dom.addEventListener('contextmenu', this.onContextMenu);
    this.view.dom.addEventListener('keydown', this.onKeyDown);

    this.props = {
      editor: options.editor,
      clientRect: undefined,
      open: false,
      setOpen: this.setOpen,
    };
    this.renderer?.onStart?.(this.props);
  }

  setOpen(value: boolean) {
    if (!this.editor.isEditable) {
      return;
    }

    if (value) {
      this.editor.commands.blur();
    } else {
      this.editor.chain().focus().run();
    }

    this.props.open = value;
    this.renderer?.onUpdate?.(this.props);
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.target !== this.view.dom) {
      return;
    }

    if (event.key !== '/') {
      return;
    }

    this.editor.commands.insertContent('/');
    event.preventDefault();
    const coords = this.view.coordsAtPos(this.view.state.selection.anchor);

    this.props.clientRect = () => ({
      bottom: coords.bottom,
      left: coords.left,
      right: coords.right,
      // Add a bit of padding to top to account for the line height
      top: coords.top + 20,
      width: 0,
      height: 0,
      x: 0,
      y: 0,
      toJSON: () => ({}),
    });
    this.setOpen(true);
  }

  onContextMenu(event: Event) {
    event.preventDefault();
    const ev = event as MouseEvent;

    this.props.clientRect = () => ({
      bottom: ev.clientY,
      left: ev.clientX,
      right: ev.clientX,
      top: ev.clientY,
      width: 0,
      height: 0,
      x: 0,
      y: 0,
      toJSON: () => ({}),
    });

    this.setOpen(true);
  }

  update() {
    this.renderer?.onUpdate?.(this.props);
  }

  destroy() {
    this.view.dom.removeEventListener('contextmenu', this.onContextMenu);
    this.view.dom.removeEventListener('keydown', this.onKeyDown);
    this.renderer?.onExit?.(this.props);
  }
}

export function ContextMenuPlugin(options: ContextMenuPluginProps) {
  return new Plugin({
    key: new PluginKey('contextMenu'),
    view: (view) => new ContextMenuView({ view, ...options }),
  });
}
