import { Heading } from '@chakra-ui/react';
import {
  type ReportTemplate,
  ReportTemplateStatus,
  type ReportTypeFilter,
} from '@piccolohealth/echo-common';
import { MultiSelect, type MultiSelectOption, type OnChangeRequest } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useReportTemplatesQuery } from '../../../graphql/hooks/useReportTemplatesQuery';
import { useAppContext } from '../../../hooks/useAppContext';
import type { ReportsFilter } from '../../../hooks/useReportsFilter';

interface Props {
  filter: ReportsFilter;
}

export const ReportTypeFilterControl = ({ filter }: Props) => {
  const { reportTypesFilter, onReportTypesFilter } = filter;

  const { organization } = useAppContext();

  const { data } = useReportTemplatesQuery({
    organizationId: organization.id,
    getReportTemplatesRequest: {
      statuses: [ReportTemplateStatus.Archived, ReportTemplateStatus.Published],
      master: true,
      pagination: {
        limit: 100,
      },
    },
  });

  const templates =
    (data?.organization?.reportTemplates?.reportTemplates as ReportTemplate[]) || [];

  const options: MultiSelectOption<ReportTemplate>[] = templates.map((reportTemplate) => ({
    label: reportTemplate.name,
    value: reportTemplate.id,
    color: reportTemplate.labelColor,
    raw: reportTemplate,
  }));

  const onChange = React.useCallback(
    (req: OnChangeRequest<ReportTemplate>) => {
      const reportTypes: ReportTypeFilter[] = req.values.map((item) => ({
        reportTemplateId: item.value,
      }));
      onReportTypesFilter(reportTypes);
    },
    [onReportTypesFilter],
  );

  const selectedValues = React.useMemo(() => {
    return P.compact(
      reportTypesFilter.map((reportTypeFilter) =>
        options.find((o) => o.value === reportTypeFilter.reportTemplateId),
      ),
    );
  }, [reportTypesFilter, options]);

  return (
    <>
      <Heading as='h5' size='sm' pb='2'>
        Report type
      </Heading>
      <MultiSelect
        options={options}
        selectedValues={selectedValues}
        onChange={onChange}
        placeholder='All report types'
        data-pw='filterByReportTypeMultiselect'
        optionVariant='tag'
      />
    </>
  );
};
