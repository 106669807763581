import {
  type Instance,
  type SequenceOfUltrasoundRegion,
  convertMeasurementValue,
  getAllUCUMUnitByLoincProperty,
  roundMeasurementValue,
} from '@piccolohealth/echo-common';
import { P } from '@piccolohealth/util';
import type Konva from 'konva';

export interface MeasurementInfo {
  units: string;
  precision: number;
}

export interface MeasurementValue {
  value: number;
  units: string;
}

export interface MeasurementTool {
  reset: () => void;
  handleMouseDown: (event: Konva.KonvaEventObject<MouseEvent>) => void;
  handleMouseMove: (event: Konva.KonvaEventObject<MouseEvent>) => void;
  handleMouseUp: () => void;
  measurement: MeasurementValue | null;
  text: Text | null;
}

export interface InstanceInfo {
  index: number;
  total: number;
  instance: Instance;
}

export interface Vector2D {
  x: number;
  y: number;
}

export interface Line {
  start: Vector2D;
  end: Vector2D;
  length?: {
    value: number;
    units: string;
  };
}

export interface Polygon {
  points: Vector2D[];
  area?: {
    value: number;
    units: string;
  };
}

export interface Text {
  value: string;
  position: Vector2D;
}

export type MeasurementType = 'linear' | 'area' | 'volume';
// This is based on the DICOM standard for the specification of the sequence of ultrasund regions
// http://dicom.nema.org/medical/dicom/current/output/chtml/part03/sect_C.8.5.5.html#table_C.8-17

export class UltrasoundRegion {
  regionFlags: number;
  x0: number;
  x1: number;
  y0: number;
  y1: number;
  physicalUnitsX: number;
  physicalUnitsY: number;
  physicalDeltaX: number;
  physicalDeltaY: number;

  constructor(region: SequenceOfUltrasoundRegion) {
    this.regionFlags = region.regionFlags;
    this.x0 = region.regionLocationMinX0;
    this.y0 = region.regionLocationMinY0;
    this.x1 = region.regionLocationMaxX1;
    this.y1 = region.regionLocationMaxY1;
    this.physicalUnitsX = region.physicalUnitsXDirection;
    this.physicalUnitsY = region.physicalUnitsYDirection;
    this.physicalDeltaX = region.physicalDeltaX;
    this.physicalDeltaY = region.physicalDeltaY;
    this.contains.bind(this);
    this.extractBit.bind(this);
    this.area.bind(this);
    this.units.bind(this);
  }

  contains(pt: { x: number; y: number }) {
    const { x0, x1, y0, y1 } = this;
    const { x, y } = pt;
    return x >= x0 && x <= x1 && y >= y0 && y <= y1;
  }

  extractBit(bit: number) {
    const { regionFlags } = this;
    return (regionFlags & (1 << bit)) >> bit;
  }

  area() {
    const { x0, x1, y0, y1 } = this;
    return Math.abs(x1 - x0) * Math.abs(y1 - y0);
  }

  units() {
    const { physicalUnitsX, physicalUnitsY } = this;

    if (
      physicalUnitsX === undefined ||
      physicalUnitsX === null ||
      physicalUnitsY === undefined ||
      physicalUnitsY === null ||
      physicalUnitsX !== physicalUnitsY
    ) {
      return 'pixels';
    }

    switch (physicalUnitsX) {
      case 0:
        return '';
      case 1:
        return '%';
      case 2:
        return 'dB';
      case 3:
        return 'cm';
      case 4:
        return 'sec';
      case 5:
        return 'Hz';
      case 6:
        return 'dB/sec';
      case 7:
        return 'cm/sec';
      case 8:
        return `cm${String.fromCharCode(178)}`;
      case 9:
        return `cm${String.fromCharCode(178)}/sec`;
      case 10:
        return `cm${String.fromCharCode(179)}`;
      case 11:
        return `cm${String.fromCharCode(179)}/sec`;
      case 12:
        return '\u00B0';
    }

    return 'pixels';
  }
}

export const clampToRegion = (point: Vector2D, region: UltrasoundRegion): Vector2D => {
  const clampedX = Math.max(region.x0, Math.min(point.x, region.x1));
  const clampedY = Math.max(region.y0, Math.min(point.y, region.y1));
  return { x: clampedX, y: clampedY };
};

export const getMeasurementMetrics = (
  ultrasoundRegions: UltrasoundRegion[],
  points: {
    x: number;
    y: number;
  }[],
) => {
  // // This is based on the DICOM standard for the specification of the sequence of ultrasund regions
  // // http://dicom.nema.org/medical/dicom/current/output/chtml/part03/sect_C.8.5.5.html#table_C.8-17
  if (ultrasoundRegions.length > 0 && points.length === 2) {
    // Find all regions that contain both points and then sort by total area
    const contained = ultrasoundRegions
      .filter((region) => region.contains(points[0]) && region.contains(points[1]))
      .sort((a, b) => a.area() - b.area());

    // If no region contains both of the points, find all regions that contain either the start point or contain the end point
    // and where both regions have the same scaling
    if (contained.length === 0) {
      const containsStart = ultrasoundRegions
        .filter((region) => region.contains(points[0]))
        .sort((a, b) => a.area() - b.area());
      const containsEnd = ultrasoundRegions
        .filter((region) => region.contains(points[1]))
        .sort((a, b) => a.area() - b.area());

      containsStart.forEach((r1) => {
        if (contained.length > 0) {
          return;
        }

        const match = containsEnd.find((r2) => r2.units() === r1.units());

        if (match) {
          contained.push(match);
        }
      });
    }

    // Assume the first discovered region is the most acceptable
    // We do not fall back to regular pixel spacing tags as we assume that if
    // the regions were specified, then points that exist outside of the regions
    // have an unknown physical spacing
    const physicalDeltaX = contained.length > 0 ? contained[0].physicalDeltaX : 1;
    const physicalDeltaY = contained.length > 0 ? contained[0].physicalDeltaY : 1;

    return {
      rowPixelSpacing: physicalDeltaY,
      colPixelSpacing: physicalDeltaX,
      suffix: contained.length > 0 ? contained[0].units() : 'pixels',
    };
  }

  return { rowPixelSpacing: undefined, colPixelSpacing: undefined, suffix: 'pixels' };
};

export const getMidpoint = (pointA: Vector2D, pointB: Vector2D): Vector2D => {
  return {
    x: (pointA.x + pointB.x) / 2,
    y: (pointA.y + pointB.y) / 2,
  };
};

export const getScaledPoint = (point: Vector2D, scale: Vector2D): Vector2D => {
  return {
    x: point.x / scale.x,
    y: point.y / scale.y,
  };
};

export const getExtendedLine = (line: Line, length: number): Line => {
  const { start, end } = line;

  // Calculate the direction vector from start to end
  const direction = {
    x: end.x - start.x,
    y: end.y - start.y,
  };

  // Normalize the direction vector to get a unit vector
  const magnitude = Math.sqrt(direction.x * direction.x + direction.y * direction.y);
  const unitVector = {
    x: direction.x / magnitude,
    y: direction.y / magnitude,
  };

  // Extend the end point along the direction of the line by the given length
  const extendedEnd = {
    x: end.x + unitVector.x * length,
    y: end.y + unitVector.y * length,
  };

  return {
    start,
    end: extendedEnd,
  };
};

export const getIntersectionWithPolygonEdge = (line: Line, polygon: Polygon): Vector2D | null => {
  const { start, end } = line;

  // Calculate direction vector from start to end
  let direction = {
    x: end.x - start.x,
    y: end.y - start.y,
  };

  // Normalize the direction vector to get consistent results
  const magnitude = Math.sqrt(direction.x * direction.x + direction.y * direction.y);
  direction = {
    x: direction.x / magnitude,
    y: direction.y / magnitude,
  };

  // Iterate over each edge of the polygon and find the first intersection
  for (let i = 0; i < polygon.points.length; i++) {
    const edgeStart = polygon.points[i];
    const edgeEnd = polygon.points[(i + 1) % polygon.points.length];

    const intersection = findIntersection(start, direction, edgeStart, edgeEnd);

    if (intersection) {
      return intersection;
    }
  }

  // If no intersection is found, return null
  return null;
};

export const isValidPolygon = (points: Vector2D[]): boolean => {
  if (points.length < 3) {
    return false;
  }

  return true;
};

export const calculateLength = (
  start: Vector2D,
  end: Vector2D,
  rowPixelSpacing: number,
  colPixelSpacing: number,
): number => {
  const dx = (end.x - start.x) * colPixelSpacing;
  const dy = (end.y - start.y) * rowPixelSpacing;
  return Math.sqrt(dx * dx + dy * dy);
};

export const calculatePolygonArea = (
  vertices: Vector2D[],
  rowPixelSpacing: number,
  colPixelSpacing: number,
): number => {
  let area = 0;

  // Apply scaling to each vertex and use the Shoelace formula
  for (let i = 0, j = vertices.length - 1; i < vertices.length; j = i++) {
    const xi = vertices[i].x * colPixelSpacing;
    const yi = vertices[i].y * rowPixelSpacing;
    const xj = vertices[j].x * colPixelSpacing;
    const yj = vertices[j].y * rowPixelSpacing;

    area += (xj + xi) * (yj - yi);
  }

  return Math.abs(area / 2);
};

export const calculateAreaLengthVolume = (polygon: Polygon, line: Line): number | null => {
  if (!polygon.area || !line.length) {
    return null;
  }

  return P.round((8 / (3 * Math.PI)) * (polygon.area.value ** 2 / line.length.value), 2);
};

/**
 * This function calculates the position for a label to be displayed
 * near a graphical element, such as a polygon or line.
 * It determines the direction from a centroid to the furthest point
 * and offsets the label along that direction to ensure good visibility.
 * The offset is dynamically adjusted based on the angle, providing a natural
 * and readable label placement.
 */

export const calculateLabelPosition = (
  centroid: Vector2D,
  furthestPoint: Vector2D,
  baseOffsetDistance: number,
): Vector2D => {
  // Calculate direction vector from centroid to furthest point
  const direction = {
    x: furthestPoint.x - centroid.x,
    y: furthestPoint.y - centroid.y,
  };
  const length = Math.sqrt(direction.x ** 2 + direction.y ** 2);

  // Calculate the angle of the direction vector
  const angle = Math.atan2(direction.y, direction.x);

  // Calculate dynamic offset distance based on the angle with smoothing
  const horizontalFactor = Math.abs(Math.cos(angle)) ** 1.5;
  const offsetDistance = baseOffsetDistance + horizontalFactor * 30;

  // Normalize direction
  const normalizedDirection = {
    x: direction.x / length,
    y: direction.y / length,
  };

  // Calculate the offset position
  const position = {
    x: furthestPoint.x + normalizedDirection.x * offsetDistance,
    y: furthestPoint.y + normalizedDirection.y * offsetDistance,
  };

  return position;
};

export const calculateAreaLabelPosition = (polygon: Polygon): Vector2D | null => {
  const isPolygonValid = isValidPolygon(polygon.points);

  if (!isPolygonValid) {
    return null;
  }

  const { x, y } = polygon.points.reduce(
    (acc, point) => ({ x: acc.x + point.x, y: acc.y + point.y }),
    { x: 0, y: 0 },
  );

  const centroid = { x: x / polygon.points.length, y: y / polygon.points.length };

  const furthestPoint = polygon.points.reduce(
    (furthest, point) => {
      const distance = Math.sqrt((point.x - centroid.x) ** 2 + (point.y - centroid.y) ** 2);
      return distance > furthest.distance ? { point, distance } : furthest;
    },
    { point: polygon.points[0], distance: 0 },
  ).point;

  return calculateLabelPosition(centroid, furthestPoint, 15);
};

export const doLinesIntersect = (a: Vector2D, b: Vector2D, c: Vector2D, d: Vector2D): boolean => {
  // Helper function to calculate the orientation of an ordered triplet
  const orientation = (p: Vector2D, q: Vector2D, r: Vector2D) => {
    const val = (q.y - p.y) * (r.x - q.x) - (q.x - p.x) * (r.y - q.y);
    if (val === 0) return 0; // collinear
    return val > 0 ? 1 : 2; // clock or counterclock wise
  };

  const onSegment = (p: Vector2D, q: Vector2D, r: Vector2D) => {
    return (
      q.x <= Math.max(p.x, r.x) &&
      q.x >= Math.min(p.x, r.x) &&
      q.y <= Math.max(p.y, r.y) &&
      q.y >= Math.min(p.y, r.y)
    );
  };

  const o1 = orientation(a, b, c);
  const o2 = orientation(a, b, d);
  const o3 = orientation(c, d, a);
  const o4 = orientation(c, d, b);

  // General case
  if (o1 !== o2 && o3 !== o4) {
    return true;
  }

  // Special cases
  if (
    (o1 === 0 && onSegment(a, c, b)) ||
    (o2 === 0 && onSegment(a, d, b)) ||
    (o3 === 0 && onSegment(c, a, d)) ||
    (o4 === 0 && onSegment(c, b, d))
  ) {
    return true;
  }

  return false;
};

export const findIntersection = (
  start1: Vector2D,
  direction1: Vector2D,
  start2: Vector2D,
  end2: Vector2D,
): Vector2D | null => {
  const x1 = start1.x;
  const y1 = start1.y;
  const x2 = start1.x + direction1.x;
  const y2 = start1.y + direction1.y;
  const x3 = start2.x;
  const y3 = start2.y;
  const x4 = end2.x;
  const y4 = end2.y;

  const denom = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);

  if (denom === 0) {
    return null; // Lines are parallel and will never intersect
  }

  const t = ((x1 - x3) * (y3 - y4) - (y1 - y3) * (x3 - x4)) / denom;
  const u = -((x1 - x2) * (y1 - y3) - (y1 - y2) * (x1 - x3)) / denom;

  if (t >= 0 && u >= 0 && u <= 1) {
    // Calculate the intersection point
    const intersection = {
      x: x1 + t * (x2 - x1),
      y: y1 + t * (y2 - y1),
    };
    return intersection;
  }

  return null; // No valid intersection found
};

export const isUnitValidMeasurementType = (units: string, type: MeasurementType): boolean => {
  const loincProperty = P.run(() => {
    switch (type) {
      case 'area':
        return 'Area';
      case 'linear':
        return 'Len';
      case 'volume':
        return 'Vol';
      default:
        return null;
    }
  });

  if (!loincProperty) {
    return false;
  }

  const allUnitsWithType = getAllUCUMUnitByLoincProperty(loincProperty).find(
    (unit) =>
      unit.csCode_.toLowerCase().normalize('NFKD') === units.toLowerCase().normalize('NFKD'),
  );

  if (allUnitsWithType) {
    return true;
  }

  return false;
};

export const calculateRoundedMeasurement = (options: {
  fromUnit: string;
  toUnit: string;
  value: number;
  precision: number;
}): MeasurementValue => {
  const convertedValue = convertMeasurementValue(options.value, options.fromUnit, options.toUnit);
  const roundedValue = roundMeasurementValue(convertedValue, options.precision);

  return {
    value: roundedValue,
    units: options.toUnit,
  };
};
