import { Checkbox, CheckboxGroup, Heading, Stack, Text } from '@chakra-ui/react';
import { ReportStatusFilter } from '@piccolohealth/echo-common';
import React from 'react';
import type { ReportsFilter } from '../../../hooks/useReportsFilter';

interface Props {
  filter: ReportsFilter;
}

export const ReportStatusFilterControl = ({ filter }: Props) => {
  const { reportStatusesFilter, onReportStatusesFilter } = filter;
  return (
    <>
      <Heading as='h5' size='sm' pb='2'>
        Report status
      </Heading>

      <CheckboxGroup
        colorScheme='purple'
        size='md'
        value={reportStatusesFilter}
        onChange={(event) => onReportStatusesFilter(event as ReportStatusFilter[])}
      >
        <Stack data-pw='reportStatusFilterCheckboxGroup'>
          <Checkbox value={ReportStatusFilter.Unreported}>
            <Text fontSize='sm'>Unreported</Text>
          </Checkbox>
          <Checkbox value={ReportStatusFilter.AwaitingReview}>
            <Text fontSize='sm'>Awaiting review</Text>
          </Checkbox>
          <Checkbox value={ReportStatusFilter.Finalized}>
            <Text fontSize='sm'>Finalized</Text>
          </Checkbox>
        </Stack>
      </CheckboxGroup>
    </>
  );
};
