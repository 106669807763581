import type * as Types from '@piccolohealth/echo-common';
import { useMutation } from 'react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { GenerateDraftReportPdf } from '../mutations/generateDraftReportPdf.mutation';

export const useGenerateDraftReportPdf = (
  options?: MutationOptions<
    Types.GenerateDraftReportPdfMutation,
    Types.GenerateDraftReportPdfMutationVariables
  >,
) => {
  return useMutation(gqlFetcher(GenerateDraftReportPdf), options);
};
