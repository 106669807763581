import { Button, Icon, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import type { MeasurementRangeGroup } from '@piccolohealth/echo-common';
import React from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { MeasurementRangeGroupDeleteMenuItem } from './MeasurementRangeGroupDeleteMenuItem';
import { MeasurementRangeGroupEditMenuItem } from './MeasurementRangeGroupEditMenuItem';

interface Props {
  rangeGroup: MeasurementRangeGroup;
}

export const MeasurementRangeGroupActionsMenu = (props: Props) => {
  const { rangeGroup } = props;

  return (
    <Menu isLazy lazyBehavior='keepMounted'>
      <MenuList>
        <MeasurementRangeGroupEditMenuItem rangeGroup={rangeGroup} key='rangeGroupEdit' />
        <MeasurementRangeGroupDeleteMenuItem rangeGroup={rangeGroup} key='rangeGroupDelete' />
      </MenuList>
      <MenuButton
        as={Button}
        variant='outline'
        size='xs'
        rightIcon={<Icon as={FaAngleDown} />}
        data-pw={`rangeGroupActionsButton-${rangeGroup.id}`}
      >
        Actions
      </MenuButton>
    </Menu>
  );
};
