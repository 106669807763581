import { Input } from '@chakra-ui/react';
import React from 'react';
import type { VariablesTableFilter } from '../../../hooks/useVariablesTableFilter';
import { FormItem } from '../../forms/FormItem';

interface Props {
  filter: VariablesTableFilter;
}

export const ReportTemplateVariablesSearchFilterControl = ({ filter }: Props) => {
  const { searchFilter, onSearchFilter } = filter;

  return (
    <FormItem id='label' label='Label'>
      <Input
        size='sm'
        defaultValue={searchFilter}
        placeholder='Search by id or label'
        onChange={(event) => onSearchFilter(event.target.value)}
      />
    </FormItem>
  );
};
