import { Box, Button, ButtonGroup, Divider, Icon, Tag, Text, VStack } from '@chakra-ui/react';
import type {
  ReportTemplateChoiceVariable,
  ReportTemplateVariable,
} from '@piccolohealth/echo-common';
import type { Command } from '@piccolohealth/ui';
import { inflection } from '@piccolohealth/util';
import React from 'react';
import { FaListUl, FaPlus } from 'react-icons/fa';
import { CreateVariablePage } from '../CreateVariablePage';
import { DeleteVariablePage } from '../DeleteVariablePage';
import { EditVariablePage } from '../EditVariablePage';
import type { TiptapCommandMenuState } from '../contextMenuRenderer';
import { SCREENSHOTS } from '../../../../../utils/images';

const ChoiceVariableDropdownTooltip = (props: {
  reportTemplateVariable: ReportTemplateChoiceVariable;
  context: Command.CommandMenuContext<TiptapCommandMenuState>;
}) => {
  const { reportTemplateVariable, context } = props;

  return (
    <VStack align='start'>
      <Box>
        <Text fontWeight='semibold'>{reportTemplateVariable.site}</Text>
        <Text fontWeight='semibold'>{reportTemplateVariable.label}</Text>
        <Text>
          {reportTemplateVariable.choices.length}{' '}
          {inflection.inflect('choices', reportTemplateVariable.choices.length)}
        </Text>
      </Box>

      <Divider />
      {reportTemplateVariable.choices.map((choice) => (
        <Tag colorScheme={choice.default ? 'green' : 'gray'} key={choice.id}>
          {choice.value}
        </Tag>
      ))}
      <Divider />
      <ButtonGroup size='xs'>
        <Button
          bg='gray.400'
          onClick={(e) => {
            e.stopPropagation();
            context.pushPage('edit-dropdown', { reportTemplateVariable });
          }}
        >
          Edit choices
        </Button>
        <Button
          colorScheme='red'
          onClick={(e) => {
            e.stopPropagation();
            context.pushPage('delete-dropdown', { reportTemplateVariable });
          }}
        >
          Delete
        </Button>
      </ButtonGroup>
    </VStack>
  );
};

const dropdownActions = (
  site: string,
  state: TiptapCommandMenuState,
): Command.Page<TiptapCommandMenuState> => ({
  kind: 'actions',
  id: 'dropdowns',
  title: 'Dropdowns',
  searchable: true,
  actions: [
    {
      kind: 'goto',
      id: 'create-dropdown',
      args: { site },
      title: 'Create a new dropdown',
      raw: 'Create a new dropdown',
      leftIcon: () => <Icon as={FaPlus} />,
      tooltip: () => ({
        kind: 'image',
        src: SCREENSHOTS.dropdowns,
        description: 'Create a new dropdown with a list of choices',
      }),
    },
    ...state.context.variablePairs
      .filter(
        ({ template }) =>
          template.__typename === 'ReportTemplateChoiceVariable' ||
          template.__typename === 'ReportTemplateWallMotionVariable',
      )
      .map((item) => dropDownAction(item.template)),
  ],
});

const dropdownCreatePage: Command.Page<TiptapCommandMenuState> = {
  kind: 'free',
  id: 'create-dropdown',
  title: 'Create a new dropdown',
  body: (context, args) => {
    return <CreateVariablePage onExit={context.onExit} site={args?.site} />;
  },
};

export const dropdownEditPage: Command.Page<TiptapCommandMenuState> = {
  kind: 'free',
  id: 'edit-dropdown',
  title: 'Edit dropdown',
  body: (context, args) => {
    return (
      <EditVariablePage
        reportTemplateVariable={args?.reportTemplateVariable as ReportTemplateChoiceVariable}
        onExit={context.onExit}
      />
    );
  },
};

const dropdownDeletePage: Command.Page<TiptapCommandMenuState> = {
  kind: 'free',
  id: 'delete-dropdown',
  title: 'Delete dropdown',
  body: (context, args) => {
    return (
      <DeleteVariablePage
        onDelete={context.onExit}
        reportTemplateVariable={args?.reportTemplateVariable as ReportTemplateChoiceVariable}
      />
    );
  },
};

const dropDownAction = (
  template: ReportTemplateVariable,
): Command.VoidAction<TiptapCommandMenuState> => {
  return {
    kind: 'void',
    id: template.id,
    leftIcon: () => <Icon as={FaListUl} />,
    title: template.label,
    raw: template.label,
    category: template.category,
    action: (props: TiptapCommandMenuState) => {
      props.editor.chain().focus().insertVariableAt(props.range, template.id).run();
    },
    tooltip: (context) => {
      switch (template.__typename) {
        case 'ReportTemplateChoiceVariable':
          return {
            kind: 'content',
            content: (
              <ChoiceVariableDropdownTooltip
                reportTemplateVariable={template as ReportTemplateChoiceVariable}
                context={context}
              />
            ),
          };
        default:
          return {
            kind: 'content',
            content: null,
          };
      }
    },
  };
};

export const dropdownsPages =
  (site: string) =>
  (state: TiptapCommandMenuState): Command.Page<TiptapCommandMenuState>[] => {
    return [dropdownActions(site, state), dropdownCreatePage, dropdownEditPage, dropdownDeletePage];
  };
