import { Box, Input, Text } from '@chakra-ui/react';
import { useNode } from '@craftjs/core';
import {
  getReportTemplateVariableById,
  isReportTemplateChoiceVariable,
} from '@piccolohealth/echo-common';
import { getSelectedOptions, RadioGroup } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { useReport } from '../../../../context/ReportContext';
import type { BaseNodeProps } from '../../../../utils/craftjs';
import { FormItem } from '../../../forms/FormItem';
import { HookedMultiSelect } from '../../../forms/hookform/HookedMultiSelect';
import { HookedSelect } from '../../../forms/hookform/HookedSelect';
import { ReportVariableRenderingError } from '../../common/ReportVariableRenderingError';
import { SettingsAccordion } from '../../common/settings/SettingsAccordion';
import { SettingsItem } from '../../common/settings/SettingsItem';
import { VariableChooser } from '../../common/settings/VariableChooser';
import { useSSRNode } from '../../hooks/useSSRNode';
import { ReportTemplateVariableManageChoicesFooter } from '../../variables/ReportTemplateVariableManageChoicesFooter';

export interface Props extends BaseNodeProps {
  variableId: string;
  mode: 'single' | 'multiple';
  size: 'xs' | 'sm' | 'md' | 'lg';
}

export const InlineSelect = (props: Props) => {
  const { variableId, mode, ssr, size } = props;
  const {
    connectors: { connect, drag },
  } = useSSRNode(props);
  const { reportTemplate, isDisabled } = useReport();
  const name = `variables.${variableId}.value`;
  const value = useWatch({ name });

  const reportTemplateVariable = getReportTemplateVariableById(
    reportTemplate.variables,
    variableId,
  );

  if (!reportTemplateVariable) {
    return (
      <ReportVariableRenderingError
        variableId={variableId}
        message={`Unable to find variable ${variableId} in select component`}
      />
    );
  }

  if (!isReportTemplateChoiceVariable(reportTemplateVariable)) {
    return (
      <ReportVariableRenderingError
        variableId={variableId}
        message={`Variable ${variableId} is not supported by select componentt`}
      />
    );
  }

  const isMulti = mode === 'multiple';

  const options = reportTemplateVariable.choices.map((choice) => ({
    label: P.upperFirst(choice.label),
    value: choice.value,
    raw: choice,
  }));

  if (ssr) {
    const selectedValues = getSelectedOptions(options, value);
    const joinedValue = selectedValues.map((o) => P.upperFirst(o.label)).join(', ');

    return (
      <Input size={size} isDisabled={isDisabled} variant='unstyled' value={joinedValue} readOnly />
    );
  }

  const content = P.run(() => {
    if (isMulti) {
      return (
        <HookedMultiSelect
          name={name}
          options={options}
          isDisabled={isDisabled}
          variant='selectGhost'
          optionVariant='text'
          data-pw={`multiSelectVariableControl-${variableId}`}
          size={size}
          components={{
            Placeholder: () => null,
            Footer: () => (
              <ReportTemplateVariableManageChoicesFooter
                reportTemplateVariable={reportTemplateVariable}
              />
            ),
            Value: (props) => {
              return (
                <Text mr={1}>
                  {P.upperFirst(props.option.label)}
                  {props.index + 1 !== props.selectedValues.length && ', '}
                </Text>
              );
            },
          }}
        />
      );
    } else {
      return (
        <HookedSelect
          name={name}
          options={options}
          isDisabled={isDisabled}
          variant='selectGhost'
          optionVariant='text'
          data-pw={`selectVariableControl-${variableId}`}
          size={size}
          components={{
            Placeholder: () => null,
            Footer: () => (
              <ReportTemplateVariableManageChoicesFooter
                reportTemplateVariable={reportTemplateVariable}
              />
            ),
          }}
        />
      );
    }
  });

  return (
    <Box ref={(ref) => connect(drag(ref))} display='inline' w='full'>
      {content}
    </Box>
  );
};

const InlineSelectSettings = () => {
  const {
    actions: { setProp },
    variableId,
    mode,
    size,
  } = useNode((node) => ({
    variableId: node.data.props.variableId,
    mode: node.data.props.mode,
    size: node.data.props.size,
  }));

  return (
    <SettingsAccordion>
      <SettingsItem title='Inline select'>
        <FormItem id='variableId' label='Variable'>
          <VariableChooser
            variableId={variableId}
            type={['ReportTemplateChoiceVariable']}
            onChange={(value) => setProp((props: Props) => (props.variableId = value as string))}
          />
        </FormItem>
        <FormItem id='mode' label='Mode'>
          <RadioGroup
            value={mode}
            onChange={(e) => setProp((props: Props) => (props.mode = e as Props['mode']), 1000)}
            options={[
              { label: 'Single', value: 'single' },
              { label: 'Multiple', value: 'multiple' },
            ]}
            size='sm'
            variant='outline'
          />
        </FormItem>
        <FormItem id='size' label='Size'>
          <Input
            size='sm'
            defaultValue={size}
            value={size}
            onChange={(e) =>
              setProp((props: Props) => (props.size = e.target.value as Props['size']))
            }
          />
        </FormItem>
      </SettingsItem>
    </SettingsAccordion>
  );
};

InlineSelect.defaultProps = {
  variableId: 'sex',
  mode: 'single' as const,
  size: 'sm' as const,
};

InlineSelect.craft = {
  name: 'InlineSelect',
  props: InlineSelect.defaultProps,
  related: {
    settings: InlineSelectSettings,
  },
};
