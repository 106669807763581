import type { NodeId, Resolver, SerializedNode, SerializedNodes } from '@craftjs/core';
import { getRandomId } from '@craftjs/utils';
import { P } from '@piccolohealth/util';
import React from 'react';
export { getRandomId };

export interface BaseNodeProps {
  id?: NodeId;
  ssr?: boolean;
  nodes?: SerializedNodeWithId[];
  linked?: Record<string, string>;
}

export type SerializedNodeWithId = SerializedNode & { id: string };

export const cleanProps = (props: Record<string, any>): Record<string, any> => {
  return P.pickBy(props, (value, key) => P.identity(value) && !['nodes', 'linked'].includes(key));
};

export const deserializeNodes = (
  nodes: SerializedNodes,
  id: NodeId = 'ROOT',
  sorted: SerializedNodeWithId[] = [],
): SerializedNodeWithId[] => {
  const node = nodes[id];
  if (!node) {
    throw new Error(`Could not find node ${id}`);
  }

  sorted.push({ id, ...node });

  node.nodes.forEach((n) => {
    sorted.push(...deserializeNodes(nodes, n));
  });

  Object.values(node.linkedNodes).forEach((n) => {
    sorted.push(...deserializeNodes(nodes, n));
  });

  return sorted;
};

export const serializeNodes = (nodes: SerializedNodeWithId[]): SerializedNodes => {
  return P.keyBy(nodes, (node) => node.id);
};

export const getNodeById = (
  nodes: SerializedNodeWithId[],
  id: NodeId,
): SerializedNodeWithId | undefined => {
  return nodes.find((node) => node.id === id);
};

export const getNodeByDisplayName = (
  nodes: SerializedNodeWithId[],
  name: string,
): SerializedNodeWithId | undefined => {
  return nodes.find((node) => node.displayName === name);
};

export const getNodeBy = (
  nodes: SerializedNodeWithId[],
  pred: (node: SerializedNodeWithId) => boolean,
): SerializedNodeWithId | undefined => {
  return nodes.find(pred);
};

export const getDescendants = (
  nodes: SerializedNodeWithId[],
  id: NodeId,
  deep = false,
  includeOnly?: 'linkedNodes' | 'childNodes',
): SerializedNodeWithId[] => {
  function appendChildNode(id: NodeId, descendants: NodeId[] = [], depth = 0) {
    if (deep || (!deep && depth === 0)) {
      const node = getNodeById(nodes, id);
      let updatedDescendants = descendants;

      if (!node) {
        return updatedDescendants;
      }

      if (includeOnly !== 'childNodes') {
        // Include linkedNodes if any
        const linkedNodes = node.linkedNodes;

        Object.keys(linkedNodes).forEach((nodeId) => {
          updatedDescendants.push(nodeId);
          updatedDescendants = appendChildNode(nodeId, updatedDescendants, depth + 1);
        });
      }

      if (includeOnly !== 'linkedNodes') {
        const childNodes = node.nodes;

        childNodes.forEach((nodeId) => {
          updatedDescendants.push(nodeId);
          updatedDescendants = appendChildNode(nodeId, updatedDescendants, depth + 1);
        });
      }

      return updatedDescendants;
    }
    return descendants;
  }
  return P.compact(appendChildNode(id).map((nid) => getNodeById(nodes, nid)));
};

export const getAncestors = (
  nodes: SerializedNodeWithId[],
  id: NodeId,
  deep = false,
): SerializedNodeWithId[] => {
  function appendParentNode(id: NodeId, ancestors: NodeId[] = [], depth = 0) {
    const node = getNodeById(nodes, id);
    let updatedAncestors = ancestors;

    if (!node) {
      return updatedAncestors;
    }

    updatedAncestors.push(id);

    if (!node.parent) {
      return updatedAncestors;
    }

    if (deep || (!deep && depth === 0)) {
      updatedAncestors = appendParentNode(node.parent, updatedAncestors, depth + 1);
    }
    return updatedAncestors;
  }

  const ancestorIds = appendParentNode(id).filter((ancestorId) => ancestorId !== id);
  return P.compact(ancestorIds.map((ancestorId) => getNodeById(nodes, ancestorId)));
};

export const getStatementSiteNodes = (nodes: SerializedNodeWithId[]) => {
  return nodes.filter((node) => {
    return node.displayName === 'StatementSite';
  });
};

export const renderNode = (
  nodes: SerializedNodeWithId[],
  resolver: Resolver,
  nodeId: NodeId,
  ssr: boolean,
): JSX.Element => {
  const node = getNodeById(nodes, nodeId);

  if (!node) {
    throw new Error(`Could not find node with id ${nodeId}`);
  }

  const linked = node.linkedNodes;
  const resolvedComponent = P.get(resolver, (node.type as any).resolvedName);

  if (!resolvedComponent) {
    throw new Error(`Could not find component for node ${nodeId}`);
  }

  const descendants = getDescendants(nodes, nodeId);

  const children = P.isEmpty(descendants)
    ? undefined
    : descendants.map((descendant) => renderNode(nodes, resolver, descendant.id, ssr));

  const props = {
    ...node.props,
    ssr,
    id: nodeId,
    key: nodeId,
    nodes,
    linked,
  };

  return React.createElement(resolvedComponent, props, children);
};

export const renderNodesToJSX = (
  nodes: SerializedNodeWithId[],
  resolver: Resolver,
  nodeId: NodeId,
  ssr: boolean,
): JSX.Element => {
  return renderNode(nodes, resolver, nodeId, ssr);
};
