import React from 'react';
import type { ReportsFilter } from '../../hooks/useReportsFilter';
import { Divider } from '../generic/Divider';
import { AssignedToFilterControl } from './controls/AssignedToFilterControl';
import { LabelsFilterControl } from './controls/LabelsFilterControl';
import { PatientDobFilterControl } from './controls/PatientDobFilterControl';
import { PatientNameFilterControl } from './controls/PatientNameFilterControl';
import { ReportDateFilterControl } from './controls/ReportDateFilterControl';
import { ReportStatusFilterControl } from './controls/ReportStatusFilterControl';
import { ReportTypeFilterControl } from './controls/ReportTypeFilterControl';

interface Props {
  filter: ReportsFilter;
}

export const ReportsFilterSidebar = ({ filter }: Props) => {
  return (
    <>
      <PatientNameFilterControl filter={filter} isAutoComplete />
      <Divider />

      <PatientDobFilterControl filter={filter} />
      <Divider />

      <AssignedToFilterControl filter={filter} />
      <Divider />

      <ReportDateFilterControl filter={filter} />
      <Divider />

      <ReportStatusFilterControl filter={filter} />
      <Divider />

      <ReportTypeFilterControl filter={filter} />
      <Divider />

      <LabelsFilterControl filter={filter} />
      <Divider />
    </>
  );
};
