import 'handlebars';
import { P } from '@piccolohealth/util';
import Handlebars from 'handlebars/dist/cjs/handlebars';
import { DateTime } from 'luxon';
import type {
  ReportStaticVariable,
  ReportTemplate,
  ReportTemplateStaticVariable,
  ReportTemplateVariable,
  ReportVariable,
} from '../graphql/types';
import { renderFormattedValue } from './format';
import { getReportTemplateVariableById } from './reporting';
import { getWallMotionModule, renderWallMotion } from './wallmotion';

interface HandlebarsContext {
  timezone: string;
  template: string;
  reportTemplate: ReportTemplate;
  values: { [key: string]: ReportVariable | ReportTemplateVariable };
}

interface HandlebarsVariable {
  isHelper: boolean;
  variables: string[];
}

const DATE_FORMATS = {
  short: 'dd/MM/yyyy',
  long: 'dddd DD.MM.YYYY HH:mm',
};

type DateFormat = keyof typeof DATE_FORMATS;

const HANDLEBARS_OPTIONS = {
  noEscape: true,
};

const isTemplateVariable = (template?: string): boolean => {
  if (!template) return false;

  return template.startsWith('{{') && template.endsWith('}}');
};

// Values passed to handlebars function may be a variable or report template variable. We
// need to normalize these values into a common data structure to work with for
// compilation
const sanitizeValues = (
  reportTemplate: ReportTemplate,
  values: {
    [key: string]: ReportVariable | ReportTemplateVariable;
  },
): Record<string, any> => {
  return P.mapValues(values, (v) => {
    const finalValue = P.run(() => {
      // If there is a 'value' field on the incoming object, it is a 'ReportVariable',
      // otherwise it must be a 'ReportTemplateVariable'
      if ((v as ReportVariable).value) {
        const variable = v as ReportVariable;
        // Wall motion has an inner value inside the value, so check for this
        const value = variable?.value?.value || variable?.value;

        if (!P.isNil(value)) {
          const staticVariable = v as ReportStaticVariable;
          const template = getReportTemplateVariableById(reportTemplate.variables, variable.id) as
            | ReportTemplateStaticVariable
            | undefined;

          const units = staticVariable.units ?? template?.units ?? undefined;
          const precision = template?.precision ?? undefined;

          return renderFormattedValue(value, units, precision);
        }
      } else {
        const reportTemplateVariable = v as ReportTemplateStaticVariable;
        const value = reportTemplateVariable.defaultValue;

        if (!P.isNil(value)) {
          const units = reportTemplateVariable.units ?? undefined;
          const precision = reportTemplateVariable.precision ?? undefined;

          return renderFormattedValue(value, units, precision);
        }
      }
    });

    if (P.isArray(finalValue)) {
      return finalValue.join(', ');
    }

    return P.isNil(finalValue) || finalValue === '' ? null : finalValue;
  });
};

// If a value is missing for a given report template variable, try to
// return the original template string
Handlebars.registerHelper('helperMissing', (ctx: any, options: any) => {
  if (ctx.name) {
    return `{{${ctx.name}}}`;
  } else if (options.name && !P.isEmptyObject(options.data.root)) {
    return `{{${options.name} ${Object.keys(options.data.root)[0]}}}`;
  } else {
    return null;
  }
});

Handlebars.registerHelper('withPlaceholder', (...args: any[]) => {
  const [value, placeholder] = args.slice(0, -1);

  if (!P.isNil(value) && !isTemplateVariable(value)) {
    return value;
  } else if (!P.isNil(placeholder)) {
    return placeholder;
  } else {
    return Handlebars.helpers.helperMissing();
  }
});

Handlebars.registerHelper('formatDate', (...args: any[]) => {
  const options = P.last(args);
  const timezone = options.data.root.timezone;

  const [dateTime, format, placeholder] = args.slice(0, -1);

  if (!P.isNil(dateTime) && !P.isNil(format)) {
    try {
      const dateFormat = DATE_FORMATS[format as DateFormat];
      const dateInTimezone = DateTime.fromISO(dateTime, { zone: timezone });

      return dateInTimezone.toFormat(dateFormat);
    } catch (err) {
      if (placeholder) {
        return Handlebars.helpers.withPlaceholder(dateTime, placeholder, options);
      } else {
        return Handlebars.helpers.helperMissing();
      }
    }
  } else if (!P.isNil(placeholder)) {
    return Handlebars.helpers.withPlaceholder(dateTime, placeholder, options);
  } else {
    return Handlebars.helpers.helperMissing();
  }
});

Handlebars.registerHelper('formatWallMotion', (...args: any[]) => {
  const options = P.last(args);
  const [wallMotion, placeholder] = args.slice(0, -1);

  if (!P.isNil(wallMotion)) {
    return renderWallMotion(
      wallMotion.wmPresent,
      wallMotion.wmComplex,
      getWallMotionModule(wallMotion.variant),
    );
  } else if (!P.isNil(placeholder)) {
    return Handlebars.helpers.withPlaceholder(wallMotion, placeholder, options);
  } else {
    return Handlebars.helpers.helperMissing();
  }
});

Handlebars.registerHelper('upperFirst', (...args: any[]) => {
  const options = P.last(args);
  const [value, placeholder] = args.slice(0, -1);

  if (!P.isNil(value)) {
    return P.upperFirst(value);
  } else if (!P.isNil(placeholder)) {
    return Handlebars.helpers.withPlaceholder(value, placeholder, options);
  } else {
    return Handlebars.helpers.helperMissing();
  }
});

export const convertHandlebarsTemplateToTiptap = (value: string): string => {
  const variables = getHandlebarsVariables(value).flatMap((v) => v.variables);
  const context = P.zipObject(
    variables,
    variables.map((v) => `<variable id="${v}"></variable>`),
  );
  const withoutSpan = value.replace('<span>', '').replace('</span>', '');
  return Handlebars.compile(withoutSpan, { noEscape: true })(context);
};

export const compileHandlebarsTemplate = (context: HandlebarsContext): string => {
  const { timezone, template, reportTemplate, values } = context;

  const sanitizedValues = sanitizeValues(reportTemplate, values);

  try {
    return Handlebars.compile(template, HANDLEBARS_OPTIONS)({ ...sanitizedValues, timezone });
  } catch (error) {
    return template;
  }
};

// Returns a list of all handlebars variables found in a given template string
export const getHandlebarsVariables = (input: string): HandlebarsVariable[] => {
  try {
    const ast: hbs.AST.Program = Handlebars.parseWithoutProcessing(input);
    return ast.body
      .filter(({ type }: hbs.AST.Statement) => type === 'MustacheStatement')
      .map((statement: hbs.AST.Statement) => {
        const moustacheStatement: hbs.AST.MustacheStatement =
          statement as hbs.AST.MustacheStatement;
        const paramsExpressionList = moustacheStatement.params as hbs.AST.PathExpression[];
        const pathExpression = moustacheStatement.path as hbs.AST.PathExpression;
        const isHelper = paramsExpressionList.length > 0;

        if (isHelper) {
          const paramExpressions = paramsExpressionList
            .filter((p) => p.type === 'PathExpression')
            .map((p) => p.original);

          return {
            isHelper,
            variables: paramExpressions,
          };
        } else {
          return {
            isHelper,
            variables: [pathExpression.original],
          };
        }
      });
  } catch (error) {
    return [];
  }
};

// Returns only the associated variables or reportTemplateVariables found in a
// given template string
export const getUsedHandlebarsVariablesAndTemplates = (
  template: string,
  getValue: (variableId: string) => ReportVariable | ReportTemplateVariable | undefined,
) => {
  const handlebarsVariables = getHandlebarsVariables(template);

  const usedVariables: { [key: string]: ReportVariable | ReportTemplateVariable } = {};
  for (const handlebarsVariable of handlebarsVariables) {
    const { variables } = handlebarsVariable;

    for (const variableId of variables) {
      const value = getValue(variableId);
      if (value) {
        usedVariables[variableId] = value;
      }
    }
  }

  return usedVariables;
};
