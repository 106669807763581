import type * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { DeleteWorklistEntry } from '../mutations/deleteWorklistEntry.mutation';

export const useDeleteWorklistEntryMutation = (
  options?: MutationOptions<
    Types.MutationDeleteWorklistEntryArgs,
    Types.MutationDeleteWorklistEntryArgs
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(DeleteWorklistEntry), {
    ...options,
    onSuccess: async (resp, variables, ctx) => {
      await options?.onSuccess?.(resp, variables, ctx);
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('worklistEntries'),
      });
    },
  });
};
