export type DebounceFunction = (...args: any[]) => void;

export const debounce = (fn: DebounceFunction, wait: number) => {
  let timer: ReturnType<typeof setTimeout>;

  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => fn(...args), wait);
  };
};

export const debounceByKey = (fn: DebounceFunction, wait: number) => {
  const timers: { [key: string]: ReturnType<typeof setTimeout> } = {};

  return (...args: any[]) => {
    const key = args[0];

    if (timers[key]) {
      clearTimeout(timers[key]);
    }

    timers[key] = setTimeout(() => {
      fn(...args);
      delete timers[key];
    }, wait);
  };
};
