import { COMPANY_NAME } from '@piccolohealth/echo-common';
import { ChakraV3 } from '@piccolohealth/ui';
import React from 'react';
import { Link } from 'react-router-dom';
import { PiccoloIcon } from '../../../components/generic/PiccoloIcon';

export const AdminNavbar = () => {
  return (
    <ChakraV3.chakra.nav>
      <ChakraV3.Flex px={4} py={3} w='full' bg='bg.navbar'>
        <ChakraV3.Flex gridColumnGap={4}>
          <Link to={'/admin'}>
            <ChakraV3.Button size='sm' variant='plain' color='white'>
              <PiccoloIcon boxSize='6' />
              <ChakraV3.Text fontWeight='bold' fontSize='lg'>
                {COMPANY_NAME}
              </ChakraV3.Text>
            </ChakraV3.Button>
          </Link>
          <Link to={'/admin/onboard'}>
            <ChakraV3.Button size='sm'>Onboard</ChakraV3.Button>
          </Link>
          <Link to={'/admin/offboard'}>
            <ChakraV3.Button size='sm'>Offboard</ChakraV3.Button>
          </Link>
          <Link to={'/admin/dicom'}>
            <ChakraV3.Button size='sm'>DICOM</ChakraV3.Button>
          </Link>
          <Link to={'/admin/graphiql'}>
            <ChakraV3.Button size='sm'>GraphiQL</ChakraV3.Button>
          </Link>
          <Link to={'/admin/debug'}>
            <ChakraV3.Button size='sm'>Debug</ChakraV3.Button>
          </Link>
        </ChakraV3.Flex>
      </ChakraV3.Flex>
    </ChakraV3.chakra.nav>
  );
};
