import { Icon, MenuItem } from '@chakra-ui/react';
import type { MeasurementRangeGroup } from '@piccolohealth/echo-common';
import React from 'react';
import { FaCog } from 'react-icons/fa';
import { showModal } from '../../../../components/generic/Modal';
import { MeasurementRangeGroupEditModal } from './MeasurementRangeGroupEditModal';

interface Props {
  rangeGroup: MeasurementRangeGroup;
}

export const MeasurementRangeGroupEditMenuItem = (props: Props) => {
  const { rangeGroup } = props;

  return (
    <MenuItem
      icon={<Icon as={FaCog} />}
      onClick={() =>
        showModal(MeasurementRangeGroupEditModal, {
          rangeGroup,
        })
      }
    >
      Edit
    </MenuItem>
  );
};
