import type * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { DeleteDistribution } from '../mutations/deleteDistribution.mutation';

export const useDeleteDistributionMutation = (
  options?: MutationOptions<
    Types.MutationDeleteDistributionArgs,
    Types.MutationDeleteDistributionArgs
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(DeleteDistribution), {
    ...options,
    onSuccess: async (resp, variables, ctx) => {
      await options?.onSuccess?.(resp, variables, ctx);
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.includes('distributions') || query.queryKey.includes('reports'),
      });
    },
  });
};
