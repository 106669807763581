import { Box } from '@chakra-ui/react';
import type { Report } from '@piccolohealth/echo-common';
import React from 'react';
import { useReportRelatedReportsQuery } from '../../../graphql/hooks/useReportQuery';
import { useAppContext } from '../../../hooks/useAppContext';
import { ReportsTable } from '../tables/ReportsTable';

interface Props {
  reportId: string;
}

export const RelatedReports = (props: Props) => {
  const { reportId } = props;
  const { organization } = useAppContext();

  const { isLoading, data, error, refetch } = useReportRelatedReportsQuery({
    organizationId: organization.id,
    reportId,
  });

  const reports = (data?.organization?.report?.related as Report[]) || [];

  const refetchPrime = async () => {
    await refetch();
  };

  return (
    <Box p={4} h='100%'>
      <ReportsTable isLoading={isLoading} error={error} reports={reports} refetch={refetchPrime} />
    </Box>
  );
};
