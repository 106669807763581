export const ILLUSTRATIONS = {
  imageUpload: '/illustrations/image-upload.png',
  lineChart: '/illustrations/line-chart.png',
  report: '/illustrations/report.png',
  studyImage: '/illustrations/study-image.png',
  upload: '/illustrations/upload.png',
} as const;

export const LOGOS = {
  email: '/logos/email.logo.png',
  fax: '/logos/fax.logo.png',
  ge: '/logos/ge.logo.png',
  healthlink: '/logos/healthlink.logo.png',
  mindray: '/logos/mindray.logo.png',
  medicalObjects: '/logos/mo.logo.png',
  philips: '/logos/philips.logo.png',
  piccolo: '/logos/piccolo.logo.png',
} as const;

export const SCREENSHOTS = {
  dropdowns: '/screenshots/dropdowns.png',
  measurements: '/screenshots/measurements.png',
  statements: '/screenshots/statements.png',
} as const;
