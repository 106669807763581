import {
  Box,
  Button,
  HStack,
  Heading,
  Image,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Skeleton,
  Spacer,
  Text,
  Wrap,
} from '@chakra-ui/react';
import React from 'react';
import { BulletSteps } from '../../../../../generic/BulletSteps';
import { ActionCard } from './ActionCard';
import { ILLUSTRATIONS } from '../../../../../../utils/images';

interface Props {
  onChartAttachmentAction: () => void;
  onStudyImageAttachmentAction: () => void;
  onImageUploadAttachmentAction: () => void;
  onClose: () => void;
}

export const ChooseActionStep = (props: Props) => {
  const {
    onChartAttachmentAction,
    onStudyImageAttachmentAction,
    onImageUploadAttachmentAction,
    onClose,
  } = props;

  return (
    <>
      <ModalHeader>
        <HStack align='start'>
          <Box>
            <Heading size='md'>What would you like to do?</Heading>
            <Text fontSize='md' fontWeight='normal' color='secondary' mt={2}>
              Please select one of the following to proceed
            </Text>
          </Box>
          <Spacer />
          <HStack spacing={3}>
            <BulletSteps currentStep={1} totalSteps={2} />
            <ModalCloseButton position='unset' top='unset' right='unset' />
          </HStack>
        </HStack>
      </ModalHeader>
      <ModalBody>
        <Wrap
          h='full'
          w='full'
          align='center'
          justify='center'
          spacing={8}
          shouldWrapChildren
          p={8}
        >
          <ActionCard
            image={
              <Box w={40}>
                <Image
                  w={24}
                  h={24}
                  ml={10}
                  src={ILLUSTRATIONS.lineChart}
                  alt='Attach measurement chart'
                  fallback={
                    <Skeleton ml={10} h={24} w={24} startColor='gray.100' endColor='gray.50' />
                  }
                />
              </Box>
            }
            title='Attach a chart'
            description='Choose a historical measurement chart for the patient'
            onClick={onChartAttachmentAction}
            data-pw='chartAttachmentCard'
          />
          <ActionCard
            image={
              <Box w={40}>
                <Image
                  w={24}
                  h={24}
                  ml={10}
                  src={ILLUSTRATIONS.studyImage}
                  alt='Attach study image'
                  fallback={
                    <Skeleton ml={10} h={24} w={24} startColor='gray.100' endColor='gray.50' />
                  }
                />
              </Box>
            }
            title='Attach a study image'
            description='Choose an image from the performed study'
            onClick={onStudyImageAttachmentAction}
            data-pw='studyImageAttachmentCard'
          />
          <ActionCard
            image={
              <Box w={40}>
                <Image
                  w={24}
                  h={24}
                  ml={10}
                  src={ILLUSTRATIONS.imageUpload}
                  alt='Attach custom image or PDF'
                  fallback={
                    <Skeleton ml={10} h={24} w={24} startColor='gray.100' endColor='gray.50' />
                  }
                />
              </Box>
            }
            title='Attach a custom image or PDF'
            description='Choose a custom image or PDF and attach it to the report'
            onClick={onImageUploadAttachmentAction}
            data-pw='imageUploadAttachmentCard'
          />
        </Wrap>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Close</Button>
      </ModalFooter>
    </>
  );
};
