import type * as Types from '@piccolohealth/echo-common';
import { createGqlQuery } from '../fetcher';
import { ReportTemplates } from '../queries/reportTemplates.query';

export const useReportTemplatesQuery = createGqlQuery<
  Types.ReportTemplatesQueryVariables,
  Types.ReportTemplatesQuery
>(
  (variables: Types.ReportTemplatesQueryVariables) => ['reportTemplates', variables],
  ReportTemplates,
);
