import type { TagProps } from '@chakra-ui/react';
import { PERMISSIONS, type Report, ReportStatus, andPermission } from '@piccolohealth/echo-common';
import React from 'react';
import { usePermission } from '../../hooks/usePermission';
import { ReportTypeChooser } from '../forms/ReportTypeChooser';
import { showModal } from '../generic/Modal';
import { ReportChangeTypeModal } from '../modals/ReportChangeTypeModal';

interface Props extends Omit<TagProps, 'onClick'> {
  report: Report;
}

export const ReportChangeTypeControl = (props: Props) => {
  const { report, ...rest } = props;
  const hasPermission = usePermission(
    andPermission(PERMISSIONS.reportsReport, PERMISSIONS.reportTemplateRead),
  ).value;
  const isDisabled = report.status === ReportStatus.Finalized || !hasPermission;

  return (
    <ReportTypeChooser
      reportTemplate={report.reportTemplate}
      isDisabled={isDisabled}
      onClick={(reportTemplate) => {
        showModal(ReportChangeTypeModal, { report, reportTemplate });
      }}
      {...rest}
    />
  );
};
