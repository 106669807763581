import React from 'react';
import { useController } from 'react-hook-form';
import {
  MedicalObjectsProviderFromChooser,
  type MedicalObjectsProviderFromChooserProps,
} from '../MedicalObjectsProviderFromChooser';

interface Props
  extends Omit<MedicalObjectsProviderFromChooserProps, 'onChange' | 'value' | 'selectedValues'> {
  name: string;
}

export const HookedMedicalObjectsProviderFromChooser = (props: Props) => {
  const { name, ...rest } = props;
  const { field } = useController({ name });
  const { onChange, value } = field;

  return <MedicalObjectsProviderFromChooser {...rest} onChange={onChange} value={value} />;
};
