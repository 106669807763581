import type * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { RestoreReportTemplate } from '../mutations/restoreReportTemplate.mutation';
import {
  useReportTemplateMinimalQuery,
  useReportTemplateQuery,
  useReportTemplateVersionsQuery,
} from './useReportTemplateQuery';
import { useReportTemplatesQuery } from './useReportTemplatesQuery';

export const useRestoreReportTemplateMutation = (
  options?: MutationOptions<
    Types.RestoreReportTemplateMutation,
    Types.RestoreReportTemplateMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(RestoreReportTemplate), {
    ...options,
    onSuccess: async (resp, variables, ctx) => {
      await options?.onSuccess?.(resp, variables, ctx);

      queryClient.invalidateQueries(
        useReportTemplateMinimalQuery.getKey({
          organizationId: variables.organizationId,
          reportTemplateId: resp.restoreReportTemplate?.id as string,
        }),
      );
      queryClient.invalidateQueries(
        useReportTemplateQuery.getKey({
          organizationId: variables.organizationId,
          reportTemplateId: resp.restoreReportTemplate?.id as string,
        }),
      );
      queryClient.invalidateQueries(
        useReportTemplatesQuery.getKey({
          organizationId: variables.organizationId,
        }),
      );
      queryClient.invalidateQueries(
        useReportTemplateVersionsQuery.getKey({
          organizationId: variables.organizationId,
          reportTemplateId: resp.restoreReportTemplate?.id as string,
        }),
      );
    },
  });
};
