import { useTheme } from '@chakra-ui/react';
import type { ReportUploadedOverTimeInsights } from '@piccolohealth/echo-common';
import { DateTime, Duration, P } from '@piccolohealth/util';
import React from 'react';
import { BarChart, type BarChartData } from '../../../components/charts/BarChart';
import { type ChakraColorMap, getChakraColor } from '../../../utils/colors';

const generateDateRange = (
  start: DateTime,
  end: DateTime,
  granularity: 'day' | 'week' | 'month',
): DateTime[] => {
  const units = { day: { days: 1 }, week: { weeks: 1 }, month: { months: 1 } };
  if (!units[granularity]) {
    throw new Error(`Unsupported granularity: ${granularity}`);
  }

  const increment = Duration.fromObject(units[granularity]);
  const result: DateTime[] = [];

  let current = start.toUTC().startOf(granularity);
  while (current <= end) {
    result.push(current);
    current = current.plus(increment);
  }

  return result;
};

const calculateGranularity = (start: DateTime, end: DateTime): 'day' | 'week' | 'month' => {
  const duration = end.diff(start, ['months', 'days']);

  if (duration.months >= 12) {
    return 'month';
  } else if (duration.months >= 3) {
    return 'week';
  } else if (duration.months >= 1) {
    return 'day';
  } else if (duration.days >= 60) {
    return 'day';
  } else {
    return 'day';
  }
};

interface Props {
  data: ReportUploadedOverTimeInsights[];
  startDate: DateTime;
  endDate: DateTime;
}

const dateFormats = {
  hour: 'p EEEEEE',
  day: 'dd/MM',
  week: 'dd/MM',
  month: 'MM/yy',
};

const getChartData = (
  data: ReportUploadedOverTimeInsights[],
  startDate: DateTime,
  endDate: DateTime,
  granularity: 'day' | 'week' | 'month',
  colors: ChakraColorMap,
): BarChartData[] => {
  const dates = generateDateRange(startDate, endDate, granularity);

  const seriesNames = P.uniq(data.map((item) => item.reportTemplateName));
  const seriesColors = data.reduce<Record<string, string>>((acc, curr) => {
    if (acc[curr.reportTemplateName]) {
      return acc;
    }

    return {
      ...acc,
      [curr.reportTemplateName]: curr.reportTemplateLabelColor,
    };
  }, {});

  return dates.map((date) => {
    const filteredDates = data
      .filter((item) =>
        DateTime.fromISO(item.reportCreatedAtDay.toString())
          .toUTC()
          .startOf(granularity)
          .equals(date),
      )
      .reduce<Record<string, number>>((acc, item) => {
        if (!acc[item.reportTemplateName]) {
          acc[item.reportTemplateName] = 0;
        }
        acc[item.reportTemplateName] += item.reportCount;
        return acc;
      }, {});

    const y = seriesNames.reduce((acc, curr) => {
      const value = filteredDates[curr] ?? 0;
      return {
        ...acc,
        [curr]: {
          value,
          color: getChakraColor(colors, seriesColors[curr]),
        },
      };
    }, {});

    return {
      x: date.toFormat(dateFormats[granularity]),
      y: y,
    };
  });
};

export const InsightsBarTimeChart = (props: Props) => {
  const { data, startDate, endDate } = props;

  const theme = useTheme();
  const granularity = calculateGranularity(startDate, endDate);
  const chartData = getChartData(data, startDate, endDate, granularity, theme.colors);

  return <BarChart chartData={chartData} />;
};
