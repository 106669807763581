import type * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { DeleteMeasurementRangeGroup } from '../mutations/deleteMeasurementRangeGroup.mutation';
import { getMeasurementRangeGroupsBaseKey } from './useMeasurementRangeGroupsQuery';

export const useDeleteMeasurementRangeGroupMutation = (
  options?: MutationOptions<
    Types.DeleteMeasurementRangeGroupMutation,
    Types.DeleteMeasurementRangeGroupMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(DeleteMeasurementRangeGroup), {
    ...options,
    onSuccess: async (resp, variables, ctx) => {
      await options?.onSuccess?.(resp, variables, ctx);
      queryClient.invalidateQueries(getMeasurementRangeGroupsBaseKey({}));
    },
  });
};
