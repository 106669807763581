import { Box, Center, Checkbox, HStack, Input, Text } from '@chakra-ui/react';
import { useNode } from '@craftjs/core';
import {
  getReportTemplateVariableById,
  isReportTemplateChoiceVariable,
} from '@piccolohealth/echo-common';
import React from 'react';
import { useReport } from '../../../../context/ReportContext';
import type { BaseNodeProps } from '../../../../utils/craftjs';
import { FormItem } from '../../../forms/FormItem';
import { HookedCheckboxGroup } from '../../../forms/hookform/HookedCheckboxGroup';
import { useSSRNode } from '../../hooks/useSSRNode';
import { ReportVariableRenderingError } from '../ReportVariableRenderingError';
import { VariableChooser } from '../settings/VariableChooser';

export interface Props extends BaseNodeProps {
  variableId: string;
  label: string;
}

export const CheckboxGroup = (props: Props) => {
  const { label, variableId } = props;

  const {
    connectors: { connect, drag },
  } = useSSRNode(props);
  const { reportTemplate, isDisabled } = useReport();

  const name = `variables.${variableId}.value`;
  const reportTemplateVariable = variableId
    ? getReportTemplateVariableById(reportTemplate.variables, variableId)
    : null;

  if (!reportTemplateVariable) {
    return (
      <Center>
        <ReportVariableRenderingError
          variableId={variableId}
          message={`Unable to find variable ${variableId} in checkbox group component`}
        />
      </Center>
    );
  }

  if (!isReportTemplateChoiceVariable(reportTemplateVariable)) {
    return (
      <ReportVariableRenderingError
        variableId={variableId}
        message={`Variable ${variableId} is not supported by checkbox group component`}
      />
    );
  }

  return (
    <Box ref={(ref) => connect(drag(ref))}>
      <FormItem id={name} label={label} data-pw={`template-${name}`}>
        <HookedCheckboxGroup name={name} isDisabled={isDisabled} variant='inline'>
          <HStack spacing={4}>
            {reportTemplateVariable.choices.map((option) => (
              <Checkbox key={option.value} value={option.value}>
                <Text fontSize='sm'>{option.label}</Text>
              </Checkbox>
            ))}
          </HStack>
        </HookedCheckboxGroup>
      </FormItem>
    </Box>
  );
};

const CheckboxGroupSettings = () => {
  const {
    actions: { setProp },
    label,
    variableId,
  } = useNode((node) => ({
    label: node.data.props.label,
    variableId: node.data.props.variableId,
  }));

  return (
    <>
      <FormItem id='label' label='Label'>
        <Input
          size='sm'
          defaultValue={label}
          onChange={(e) => setProp((props: Props) => (props.label = e.target.value))}
        />
      </FormItem>
      <FormItem id='variableId' label='Variable'>
        <VariableChooser
          variableId={variableId}
          type={['ReportTemplateChoiceVariable']}
          onChange={(value) => setProp((props: Props) => (props.variableId = value as string))}
        />
      </FormItem>
    </>
  );
};

CheckboxGroup.defaultProps = {
  variableId: 'patientAssessment',
  label: 'Patient Assessment',
};

CheckboxGroup.craft = {
  name: 'CheckboxGroup',
  props: CheckboxGroup.defaultProps,
  related: {
    settings: CheckboxGroupSettings,
  },
};
