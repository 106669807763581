import {
  type DistributionPayload,
  type DistributionPayloadRequest,
  DistributionType,
  IntegrationType,
} from '@piccolohealth/echo-common';
import { useOrganization } from '../context/OrganizationContext';
import { LOGOS } from '../utils/images';

const payloadTypenameToType = (
  __typename: DistributionPayload['__typename'] | DistributionPayloadRequest['__typename'],
) => {
  switch (__typename) {
    case 'DistributionEmailPayloadRequest':
    case 'DistributionEmailPayload':
      return DistributionType.Email;

    case 'DistributionHealthLinkPayloadRequest':
    case 'DistributionHealthLinkPayload':
      return DistributionType.HealthLink;

    case 'DistributionMedicalObjectsPayloadRequest':
    case 'DistributionMedicalObjectsPayload':
      return DistributionType.MedicalObjects;

    case 'DistributionFaxPayloadRequest':
    case 'DistributionFaxPayload':
      return DistributionType.Fax;

    default:
      return DistributionType.Email;
  }
};

export const getDistributionTypes = (
  type:
    | DistributionType
    | DistributionPayload['__typename']
    | DistributionPayloadRequest['__typename'],
) => {
  const isDistributionType = (value?: string): value is DistributionType => {
    return Object.values(DistributionType).includes(value as DistributionType);
  };

  const distributionType = isDistributionType(type)
    ? (type as DistributionType)
    : payloadTypenameToType(
        type as DistributionPayload['__typename'] | DistributionPayloadRequest['__typename'],
      );

  switch (distributionType) {
    case DistributionType.Email:
      return {
        type: DistributionType.Email,
        payloadTypename: 'DistributionEmailPayload' as const,
        payloadRequestTypename: 'DistributionEmailPayloadRequest' as const,
      };
    case DistributionType.MedicalObjects:
      return {
        type: DistributionType.MedicalObjects,
        payloadTypename: 'DistributionMedicalObjectsPayload' as const,
        payloadRequestTypename: 'DistributionMedicalObjectsPayloadRequest' as const,
      };
    case DistributionType.HealthLink:
      return {
        type: DistributionType.HealthLink,
        payloadTypename: 'DistributionHealthLinkPayload' as const,
        payloadRequestTypename: 'DistributionHealthLinkPayloadRequest' as const,
      };
    case DistributionType.Fax:
      return {
        type: DistributionType.Fax,
        payloadTypename: 'DistributionFaxPayload' as const,
        payloadRequestTypename: 'DistributionFaxPayloadRequest' as const,
      };
  }
};

export const useDistributionTypeInfo = (
  type:
    | DistributionType
    | DistributionPayload['__typename']
    | DistributionPayloadRequest['__typename'],
) => {
  const { hasIntegrationEnabled } = useOrganization();
  const types = getDistributionTypes(type);

  switch (types.type) {
    case DistributionType.Email:
      return {
        ...types,
        title: 'Email',
        logo: LOGOS.email,
        available: true,
        enabled: true,
      };
    case DistributionType.MedicalObjects:
      return {
        ...types,
        title: 'Medical-Objects',
        logo: LOGOS.medicalObjects,
        available: true,
        enabled: hasIntegrationEnabled(IntegrationType.MedicalObjects),
      };
    case DistributionType.HealthLink:
      return {
        ...types,
        title: 'HealthLink',
        logo: LOGOS.healthlink,
        available: true,
        enabled: hasIntegrationEnabled(IntegrationType.HealthLink),
      };
    case DistributionType.Fax:
      return {
        ...types,
        title: 'Fax',
        logo: LOGOS.fax,
        available: true,
        enabled: hasIntegrationEnabled(IntegrationType.Fax),
      };
  }
};
