import {
  Button,
  ButtonGroup,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import type { MeasurementRangeGroup } from '@piccolohealth/echo-common';
import React from 'react';
import { createModal } from '../../../../components/generic/Modal';
import { useDeleteMeasurementRangeGroupMutation } from '../../../../graphql/hooks/useDeleteMeasurementRangeGroupMutation';
import { useAppContext } from '../../../../hooks/useAppContext';

interface Props {
  rangeGroup: MeasurementRangeGroup;
}

export const MeasurementRangeGroupDeleteModal = createModal<Props>((props) => {
  const { rangeGroup, modal } = props;
  const { successToast, errorToast } = useAppContext();
  const mutation = useDeleteMeasurementRangeGroupMutation({});
  const { hide, visible } = modal;

  const onClick = React.useCallback(async () => {
    await mutation
      .mutateAsync({
        rangeGroupId: rangeGroup.id,
      })
      .then(async () => {
        hide();
        successToast('Measurement range group deleted successfully');
      })
      .catch((err) => {
        errorToast(`Error deleting measurement range group: ${err.message}`);
      });
  }, [mutation, rangeGroup.id, hide, successToast, errorToast]);

  return (
    <Modal isOpen={visible} onClose={hide}>
      <ModalOverlay />
      <ModalContent data-pw='rangeGroupDeleteModalContent'>
        <ModalHeader>Do you want to delete this measurement range group?</ModalHeader>
        <ModalCloseButton />
        <ModalFooter>
          <ButtonGroup size='sm'>
            <Button key='close' onClick={hide}>
              Close
            </Button>
            <Button
              key='submit'
              colorScheme='red'
              isLoading={mutation.isLoading}
              loadingText='Deleting...'
              type='submit'
              onClick={onClick}
              data-pw='rangeGroupDeleteModalSubmitButton'
            >
              Delete
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
