import React from 'react';
import { useController } from 'react-hook-form';
import { TextArea, type TextAreaProps } from '../Textarea';

interface Props extends TextAreaProps {
  name: string;
}

export const HookedTextArea = (props: Props) => {
  const { name, ...rest } = props;

  const {
    field: { ref, ...inputProps },
  } = useController({ name });

  return <TextArea {...rest} {...inputProps} value={inputProps.value ?? ''} />;
};
