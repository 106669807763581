import type { DependencyList } from 'react';
import { useKey } from 'react-use';

export declare type KeyFilter = null | undefined | string | ((event: KeyboardEvent) => boolean);

export interface UseKeyOptions {
  event?: 'keydown' | 'keypress' | 'keyup';
  options?: any;
  filterForms?: boolean;
}

type AvailableTags = 'INPUT' | 'TEXTAREA' | 'SELECT';

const tagFilter = ({ target }: KeyboardEvent, enableOnTags?: AvailableTags[]) => {
  const targetTagName = target && (target as HTMLElement).tagName;

  return Boolean(
    targetTagName && enableOnTags && enableOnTags.includes(targetTagName as AvailableTags),
  );
};

const isKeyboardEventTriggeredByInput = (ev: KeyboardEvent) => {
  return tagFilter(ev, ['INPUT', 'TEXTAREA', 'SELECT']);
};

export const useHotkey = (
  key: KeyFilter,
  fn?: (event: KeyboardEvent) => void,
  opts?: UseKeyOptions,
  deps?: DependencyList,
) => {
  const fnPrime = (event: KeyboardEvent) => {
    if (opts?.filterForms && isKeyboardEventTriggeredByInput(event)) {
      return;
    }
    fn?.(event);
  };

  return useKey(key, fnPrime, opts, deps);
};
