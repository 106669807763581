import { Tab, TabList, TabPanel, TabPanels } from '@chakra-ui/react';
import type { ReportTemplate, ReportTemplateDryRun } from '@piccolohealth/echo-common';
import React from 'react';
import { TabsRouter } from '../generic/TabsRouter';
import { ReportTemplateMeasurementsEditor } from './measurements/ReportTemplateMeasurementsEditor';
import { ReportEditor } from './report/ReportEditor';
import { ReportTemplateVariablesEditor } from './variables/ReportTemplateVariablesEditor';
import { VersioningEditor } from './versioning/VersioningEditor';

interface Props {
  reportTemplate: ReportTemplate;
  dryRun?: ReportTemplateDryRun;
}

export const TemplateContent = (props: Props) => {
  const { reportTemplate, dryRun } = props;

  return (
    <TabsRouter
      defaultIndex={0}
      h='100%'
      display='flex'
      flexDir='column'
      colorScheme='purple'
      isLazy
      routes={['report', 'variables', 'measurements', 'versions']}
      keepQueryParams
    >
      <TabList>
        <Tab key='report'>Report</Tab>
        <Tab key='variables'>Variables</Tab>
        <Tab key='measurements'>Measurements</Tab>
        <Tab key='versions' data-pw='reportTemplateVersionHistoryTab'>
          Version history
        </Tab>
      </TabList>
      <TabPanels h='100%' overflowY='auto'>
        <TabPanel key='report-tabpanel' h='100%' p={0}>
          <ReportEditor reportTemplate={reportTemplate} dryRun={dryRun} />
        </TabPanel>
        <TabPanel key='variables-tabpanel' h='100%'>
          <ReportTemplateVariablesEditor reportTemplate={reportTemplate} />
        </TabPanel>
        <TabPanel key='measurements-tabpanel' h='100%'>
          <ReportTemplateMeasurementsEditor reportTemplate={reportTemplate} dryRun={dryRun} />
        </TabPanel>
        <TabPanel key='versions-tabpanel' h='100%'>
          <VersioningEditor reportTemplate={reportTemplate} />
        </TabPanel>
      </TabPanels>
    </TabsRouter>
  );
};
