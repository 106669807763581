import type { ReportTemplateVariable, ReportVariable } from '@piccolohealth/echo-common';
import { P } from '@piccolohealth/util';
import type { VariablePair } from './TiptapContext';

export const getVariablePairs = (
  variables: ReportVariable[],
  templateVariables: ReportTemplateVariable[],
): VariablePair[] => {
  const pairs = templateVariables.map((template) => {
    const variable = variables.find((variable) => variable.id === template.id);

    if (!variable) {
      return null;
    }

    return { template, variable };
  });

  return P.compact(pairs);
};
