import { MenuItem } from '@chakra-ui/react';
import { PERMISSIONS, type ReportTemplate } from '@piccolohealth/echo-common';
import React from 'react';
import { FaHistory } from 'react-icons/fa';
import { usePermission } from '../../../hooks/usePermission';
import { showModal } from '../../generic/Modal';
import { TemplateRestoreVersionModal } from './TemplateRestoreVersionModal';

interface Props {
  version: ReportTemplate;
}

export const TemplateRestoreVersionMenuItem = (props: Props) => {
  const { version } = props;
  const hasPermission = usePermission(PERMISSIONS.reportTemplateUpdate).value;

  return (
    <MenuItem
      isDisabled={!hasPermission}
      onClick={() => showModal(TemplateRestoreVersionModal, { version })}
      icon={<FaHistory data-pw={`reportTemplateVersionRestoreButton-${version.versionId}`} />}
    >
      Restore version
    </MenuItem>
  );
};
