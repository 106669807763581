import type * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { InviteUser } from '../mutations/inviteUser.mutation';
import { useUsersQuery } from './useUsersQuery';

export const useInviteUserMutation = (
  options?: MutationOptions<Types.InviteUserMutation, Types.InviteUserMutationVariables>,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(InviteUser), {
    ...options,
    onSuccess: async (resp, variables, ctx) => {
      await options?.onSuccess?.(resp, variables, ctx);
      queryClient.invalidateQueries(
        useUsersQuery.getKey({
          organizationId: variables.organizationId,
        }),
      );
    },
  });
};
