import type * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { CreateMeasurementMapping } from '../mutations/createMeasurementMapping.mutation';
import { getMeasurementMappingsAndVariantsBaseKey } from './useMeasurementMappingsAndVariantsQuery';

export const useCreateMeasurementMappingMutation = (
  options?: MutationOptions<
    Types.CreateMeasurementMappingRequest,
    Types.CreateMeasurementMappingMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(CreateMeasurementMapping), {
    ...options,
    onSuccess: async (resp, variables, ctx) => {
      await options?.onSuccess?.(resp, variables, ctx);
      queryClient.invalidateQueries(getMeasurementMappingsAndVariantsBaseKey({}));
    },
  });
};
