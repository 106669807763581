import { Box, Button, ButtonGroup, Flex, Text } from '@chakra-ui/react';
import type { ReportTemplateChoiceVariable } from '@piccolohealth/echo-common';
import React from 'react';
import { useReport } from '../../../../context/ReportContext';
import { useDeleteReportTemplateVariableMutation } from '../../../../graphql/hooks/useDeleteReportTemplateVariableMutation';
import { useAppContext } from '../../../../hooks/useAppContext';

interface Props {
  reportTemplateVariable: ReportTemplateChoiceVariable;
  onDelete: () => void;
}

export const DeleteVariablePage = (props: Props) => {
  const { reportTemplateVariable, onDelete } = props;
  const { organization, errorToast, successToast } = useAppContext();
  const { reportTemplate } = useReport();
  const mutation = useDeleteReportTemplateVariableMutation();

  const onClick = async () => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        reportTemplateId: reportTemplate.id,
        reportTemplateVariableId: reportTemplateVariable.id,
      })
      .then(() => {
        successToast('Dropdown deleted successfully');
        props.onDelete();
      })
      .catch((err) => {
        errorToast(`Error deleting dropdown: ${err.message}`);
      });
  };

  return (
    <Box p={4} h='auto'>
      <Text>
        Are you sure you want to delete the {props.reportTemplateVariable.label} dropdown?
      </Text>
      <Flex w='full' justify='end' mt={2}>
        <ButtonGroup size='sm'>
          <Button variant='outline' onClick={onDelete}>
            Cancel
          </Button>
          <Button isLoading={mutation.isLoading} colorScheme='red' onClick={onClick}>
            Delete
          </Button>
        </ButtonGroup>
      </Flex>
    </Box>
  );
};
