import {
  List as ChakraList,
  ListItem as ChakraListItem,
  type ListItemProps as ChakraListItemProps,
  type ListProps,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';

export const List = (props: React.PropsWithChildren<ListProps>) => {
  const { children, ...rest } = props;

  return <ChakraList {...rest}>{children}</ChakraList>;
};

interface ListItemProps extends ChakraListItemProps {
  isDisabled?: boolean;
}

export const ListItem = forwardRef<React.PropsWithChildren<ListItemProps>, 'li'>((props, ref) => {
  const { children, isDisabled, ...rest } = props;

  return (
    <ChakraListItem
      ref={ref}
      px={4}
      py={2}
      my={1}
      display='flex'
      flexDir='row'
      alignItems='center'
      rounded='lg'
      _hover={{ bg: 'gray.100' }}
      cursor={isDisabled ? 'not-allowed' : undefined}
      {...rest}
    >
      {children}
    </ChakraListItem>
  );
});
