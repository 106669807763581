import { Box, Button, Text } from '@chakra-ui/react';
import type { ReportTemplateChoiceVariable } from '@piccolohealth/echo-common';
import { FloatingPopover, SelectComponents } from '@piccolohealth/ui';
import React from 'react';
import { EditVariablePage } from '../../tiptap/extensions/contextmenu/EditVariablePage';

interface Props {
  reportTemplateVariable: ReportTemplateChoiceVariable;
}

export const ReportTemplateVariableManageChoicesFooter = (props: Props) => {
  const { reportTemplateVariable } = props;

  const isDisabled = !reportTemplateVariable.isEditable;

  return (
    <SelectComponents.Footer>
      <FloatingPopover
        render={({ context }) => {
          return (
            <Box bg='white' rounded='lg' shadow='popover' color='black' w='xl'>
              <Box py={2} px={3} borderBottomColor='gray.200' borderBottomWidth='1px'>
                <Text fontSize='sm'>Edit choices</Text>
              </Box>
              <EditVariablePage
                reportTemplateVariable={reportTemplateVariable}
                onExit={() => context.onOpenChange(false)}
              />
            </Box>
          );
        }}
      >
        <Button variant='link' size='xs' tabIndex={-1} isDisabled={isDisabled}>
          Manage choices
        </Button>
      </FloatingPopover>
    </SelectComponents.Footer>
  );
};
