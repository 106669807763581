import { Box, Divider, Icon, Text, VStack } from '@chakra-ui/react';
import type { ReportTemplateStaticVariable } from '@piccolohealth/echo-common';
import type { Command } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FaFlask } from 'react-icons/fa';
import type { TiptapCommandMenuState } from '../contextMenuRenderer';

const MeasurementTooltip = (props: {
  reportTemplateVariable: ReportTemplateStaticVariable;
  description: string;
  context: Command.CommandMenuContext<TiptapCommandMenuState>;
}) => {
  const { reportTemplateVariable, description } = props;

  return (
    <VStack align='start'>
      <Box>
        <Text fontWeight='bold'>{reportTemplateVariable.label}</Text>
      </Box>
      <Divider />
      <Text fontWeight='bold'>{description}</Text>
    </VStack>
  );
};

export const measurementsPages = (
  state: TiptapCommandMenuState,
): Command.Page<TiptapCommandMenuState>[] => {
  const staticVariableTemplates = state.context.variablePairs.filter(
    ({ variable, template }) =>
      template.__typename === 'ReportTemplateStaticVariable' && !P.isNil(variable),
  );

  return [
    {
      kind: 'actions',
      id: 'measurements',
      title: 'Measurements',
      searchable: true,
      actions: staticVariableTemplates.map(({ variable, template }) => {
        const reportTemplateVariable = template as ReportTemplateStaticVariable;
        const value = variable?.value ?? '-';
        const units = reportTemplateVariable.units ?? '';
        const description = P.isNil(variable?.value) ? 'No value measured' : `${value} ${units}`;

        return {
          kind: 'void',
          id: variable.id,
          showTooltip: true,
          title: reportTemplateVariable.label,
          raw: reportTemplateVariable.label,
          category: reportTemplateVariable.site,
          leftIcon: () => <Icon as={FaFlask} />,
          tooltip: (context) => ({
            kind: 'content',
            content: (
              <MeasurementTooltip
                reportTemplateVariable={reportTemplateVariable}
                description={description}
                context={context}
              />
            ),
          }),
          description,
          action: (props: TiptapCommandMenuState) => {
            props.editor.chain().focus().insertVariableAt(props.range, template.id).run();
          },
        } as Command.VoidAction<TiptapCommandMenuState>;
      }),
    },
  ];
};
