import { Button, Icon } from '@chakra-ui/react';
import {
  PERMISSIONS,
  type ReportTemplateVariable,
  ReportTemplateVariableSource,
} from '@piccolohealth/echo-common';
import React from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { usePermission } from '../../../hooks/usePermission';
import { showModal } from '../../generic/Modal';
import { ReportTemplateVariableDeleteModal } from './ReportTemplateVariableDeleteModal';

interface Props {
  organizationId: string;
  reportTemplateId: string;
  variable: ReportTemplateVariable;
}

export const ReportTemplateVariableDeleteButton = (props: Props) => {
  const { organizationId, reportTemplateId, variable } = props;

  const hasPermission = usePermission(PERMISSIONS.reportTemplateUpdate).value;
  const isDisabled = variable.source === ReportTemplateVariableSource.Dynamic || !hasPermission;

  return (
    <Button
      variant='outline'
      colorScheme='red'
      leftIcon={<Icon as={FaRegTrashAlt} />}
      isDisabled={isDisabled}
      onClick={() =>
        showModal(ReportTemplateVariableDeleteModal, {
          organizationId,
          reportTemplateId,
          variable,
        })
      }
    >
      Delete
    </Button>
  );
};
