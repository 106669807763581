import { MenuItem, Text } from '@chakra-ui/react';
import { PERMISSIONS, type User } from '@piccolohealth/echo-common';
import React from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { usePermission } from '../../hooks/usePermission';
import { showModal } from '../generic/Modal';
import { UserRemoveModal } from '../modals/UserRemoveModal';

interface Props {
  user: User;
}

export const UserRemoveMenuItem = (props: Props) => {
  const { user } = props;
  const hasPermission = usePermission(PERMISSIONS.usersUpdate).value;

  return (
    <MenuItem
      icon={<FaRegTrashAlt />}
      isDisabled={!hasPermission}
      onClick={() => showModal(UserRemoveModal, { user })}
      data-pw={`userRemoveMenuItem-${user.id}`}
    >
      <Text color='danger'>Remove</Text>
    </MenuItem>
  );
};
