import type { StyleFunctionProps } from '@chakra-ui/react';

const defaultProps = {
  colorScheme: 'purple',
};

const softRounded = (props: StyleFunctionProps) => {
  return {
    tab: {
      flex: props.isFitted ? 1 : 0,
      rounded: 'md',
      fontSize: 'sm',
      py: 2,
      px: 4,
      bg: 'transparent',
      justifyContent: props.isFitted ? 'center' : 'flex-start',
      _selected: {
        color: 'gray.600',
        bg: 'gray.100',
      },
    },
  };
};

const baseStyle = {
  tabpanel: {
    p: 0,
  },
  tablist: {
    whiteSpace: 'nowrap',
  },
};

const variants = {
  'soft-rounded': softRounded,
};

export const Tabs = {
  baseStyle,
  variants,
  defaultProps,
};
