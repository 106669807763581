import type { User } from '@piccolohealth/echo-common';
import { MultiSelect, type MultiSelectOption, type OnChangeRequest } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useUsersQuery } from '../../../graphql/hooks/useUsersQuery';
import { useAppContext } from '../../../hooks/useAppContext';
import type { AuditTrailFilter } from '../../../hooks/useAuditTrailFilter';
import { FormItem } from '../../forms/FormItem';

const userToOption = (user: User): MultiSelectOption<User> => {
  return {
    label: user.name,
    value: user.id,
    raw: user,
  };
};

interface Props {
  filter: AuditTrailFilter;
}

export const AuditTrailUserFilterControl = ({ filter }: Props) => {
  const { usersFilter, onUsersFilter } = filter;
  const { organization } = useAppContext();

  const { data } = useUsersQuery({ organizationId: organization.id });

  const users = (data?.organization?.users as User[]) || [];
  const options = users.map(userToOption);

  const onChange = React.useCallback(
    (req: OnChangeRequest<User>) => {
      onUsersFilter(req.values.map((item) => item.value));
    },
    [onUsersFilter],
  );

  const selectedValues = React.useMemo(() => {
    return P.compact(usersFilter.map((v) => options.find((o) => P.isEqual(o.value, v))));
  }, [usersFilter, options]);

  return (
    <FormItem id='user' label='User'>
      <MultiSelect
        options={options}
        selectedValues={selectedValues}
        onChange={onChange}
        placeholder='All users'
      />
    </FormItem>
  );
};
