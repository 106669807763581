import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import type { Report, ReportTemplate } from '@piccolohealth/echo-common';
import React from 'react';
import { useUpdateReportTypeMutation } from '../../graphql/hooks/useUpdateReportTypeMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { createModal } from '../generic/Modal';

interface Props {
  report: Report;
  reportTemplate: ReportTemplate;
}

export const ReportChangeTypeModal = createModal<Props>((props) => {
  const { report, reportTemplate, modal } = props;
  const { successToast, errorToast, organization } = useAppContext();

  const mutation = useUpdateReportTypeMutation();

  const onClick = async () => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        reportId: report.id,
        updateReportTypeRequest: {
          reportTemplateId: reportTemplate.id,
        },
      })
      .then(async () => {
        successToast('Report type changed');
        modal.hide();
      })
      .catch((err) => {
        errorToast(`Error changing report type: ${err.message}`);
      });
  };

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove}>
      <ModalOverlay />
      <ModalContent data-pw='reportChangeTypeModalContent'>
        <ModalHeader>Do you want to change the report type?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>Your changes for the previous report type will not be kept</ModalBody>
        <ModalFooter>
          <ButtonGroup size='sm'>
            <Button key='close' onClick={modal.hide}>
              Close
            </Button>
            <Button
              key='submit'
              colorScheme='purple'
              isLoading={mutation.isLoading}
              loadingText='Updating...'
              type='submit'
              onClick={onClick}
              data-pw='reportChangeTypeModalSubmitButton'
            >
              Change
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
