import type * as Types from '@piccolohealth/echo-common';
import type { ReportShare } from '@piccolohealth/echo-common';
import type { RowSelectionOptions } from '@piccolohealth/ui';
import React from 'react';
import { useAppContext } from '../../hooks/useAppContext';
import type { ReportsFilter } from '../../hooks/useReportsFilter';
import { createPaginatedGqlQuery } from '../fetcher';
import { ReportShares } from '../queries/reportShares.query';

export const useReportSharesFilterQuery = (filter: ReportsFilter) => {
  const { organization } = useAppContext();
  const [selectedRows, onRowsSelect] = React.useState<ReportShare[]>([]);

  const query = createPaginatedGqlQuery<Types.ReportSharesQueryVariables, Types.ReportSharesQuery>(
    (variables) => ['reportShares', variables],
    ReportShares,
    {
      filter,
      getPaginationResponse: (response) => {
        return response?.organization?.reportShares?.pagination;
      },
    },
  );

  const rowSelection: RowSelectionOptions<ReportShare> = {
    selectedRows,
    onRowsSelect,
  };

  const resp = query({
    organizationId: organization.id,
    getReportSharesRequest: {
      pagination: {
        offset: filter.currentPageNumber * filter.pageSize,
        limit: filter.pageSize,
      },
      statuses: filter.reportStatusesFilter,
      types: filter.reportTypesFilter,
      name: filter.reportPatientNameFilter,
      dob: filter.reportPatientDobFilter ? filter.reportPatientDobFilter : null,
      assignedTo: filter.assignedToFilter,
      start: filter.reportStartDateFilter ? filter.reportStartDateFilter : null,
      end: filter.reportEndDateFilter ? filter.reportEndDateFilter : null,
      isExpired: false,
    },
  });

  const shares = (resp.data?.organization?.reportShares?.shares as ReportShare[]) ?? [];

  return {
    ...resp,
    shares,
    rowSelection,
  };
};
