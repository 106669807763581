import React from 'react';
import { Line } from 'react-konva';
import type { Vector2D } from '../utils';

interface Props {
  position: Vector2D;
  size: number;
  gap: number;
  color: string;
}

export const MeasurementCrosshair = (props: Props) => {
  const { position, size, gap, color } = props;
  const halfGap = gap / 2;

  return (
    <>
      {/* Vertical line of the crosshair (top segment) */}
      <Line
        points={[position.x, position.y - size, position.x, position.y - halfGap]}
        stroke={color}
        strokeWidth={2}
      />
      {/* Vertical line of the crosshair (bottom segment) */}
      <Line
        points={[position.x, position.y + halfGap, position.x, position.y + size]}
        stroke={color}
        strokeWidth={2}
      />
      {/* Horizontal line of the crosshair (left segment) */}
      <Line
        points={[position.x - size, position.y, position.x - halfGap, position.y]}
        stroke={color}
        strokeWidth={2}
      />
      {/* Horizontal line of the crosshair (right segment) */}
      <Line
        points={[position.x + halfGap, position.y, position.x + size, position.y]}
        stroke={color}
        strokeWidth={2}
      />
    </>
  );
};
