import { HStack, Tag } from '@chakra-ui/react';
import { type User, getUserAndRoles } from '@piccolohealth/echo-common';
import { P } from '@piccolohealth/util';
import React from 'react';

interface Props {
  organizationId: string;
  user: User;
}

export const UserRoles = (props: Props) => {
  const { organizationId, user } = props;
  const userAndRoles = getUserAndRoles(organizationId, user);

  return (
    <HStack data-pw='userRoles'>
      {P.orderBy(userAndRoles.roles, (role) => role.name).map((role) => (
        <Tag key={role.id}>{role.name}</Tag>
      ))}
    </HStack>
  );
};
