import { Button, Icon, Stack } from '@chakra-ui/react';
import { PERMISSIONS, type ReportTemplateStatementSite, uuid } from '@piccolohealth/echo-common';
import { DndKitCore, DndKitSortable } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FaPlus } from 'react-icons/fa';
import { usePermission } from '../../../../hooks/usePermission';
import { SortableReportTemplateStatementSite } from './SortableReportTemplateStatementSite';

export const SortableReportTemplateStatementSites = () => {
  const { setValue, watch } = useFormContext();

  const isSupport = usePermission(PERMISSIONS.organizationSupport).value;
  const currentReportTemplateStatementSiteId = watch('currentReportTemplateStatementSiteId');

  const { fields, append, remove, move } = useFieldArray({
    name: 'statementSites',
    keyName: 'sortableId',
  });

  const reportTemplateStatementSites = fields as unknown as ReportTemplateStatementSite[];

  const sensors = DndKitCore.useSensors(
    DndKitCore.useSensor(DndKitCore.PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  const onClick = React.useCallback(
    (id: string) => {
      setValue('currentReportTemplateStatementSiteId', id);
    },
    [setValue],
  );

  const onDragEnd = React.useCallback(
    (event: DndKitCore.DragEndEvent) => {
      const { active, over } = event;
      if (over && active.id !== over.id) {
        const oldIndex = reportTemplateStatementSites.findIndex((item) => item.id === active.id);
        const newIndex = reportTemplateStatementSites.findIndex((item) => item.id === over.id);
        move(oldIndex, newIndex);
      }
    },
    [move, reportTemplateStatementSites],
  );

  const onAdd = React.useCallback(() => {
    const newReportTemplateStatementSite: ReportTemplateStatementSite = {
      id: uuid(),
      name: 'New site',
      statements: [],
    };
    append(newReportTemplateStatementSite);
  }, [append]);

  const onRemove = React.useCallback(
    (id: string) => {
      const index = reportTemplateStatementSites.findIndex((item) => item.id === id);
      remove(index);

      const firstReportTemplateStatementSite = P.first(reportTemplateStatementSites);

      if (id === currentReportTemplateStatementSiteId && firstReportTemplateStatementSite) {
        setValue('currentReportTemplateStatementSiteId', firstReportTemplateStatementSite.id);
      }
    },
    [remove, reportTemplateStatementSites, currentReportTemplateStatementSiteId, setValue],
  );

  return (
    <DndKitCore.DndContext
      sensors={sensors}
      collisionDetection={DndKitCore.closestCenter}
      onDragEnd={onDragEnd}
    >
      <DndKitSortable.SortableContext
        strategy={DndKitSortable.verticalListSortingStrategy}
        items={reportTemplateStatementSites.map(({ id }) => id)}
      >
        <Stack spacing={2}>
          {reportTemplateStatementSites.map((reportTemplateStatementSite, index) => (
            <SortableReportTemplateStatementSite
              key={reportTemplateStatementSite.id}
              name={`statementSites[${index}]`}
              isActive={reportTemplateStatementSite.id === currentReportTemplateStatementSiteId}
              onClick={() => onClick(reportTemplateStatementSite.id)}
              onRemove={() => onRemove(reportTemplateStatementSite.id)}
            />
          ))}
          {isSupport && (
            <Button rightIcon={<Icon as={FaPlus} />} onClick={onAdd}>
              Add new site
            </Button>
          )}
        </Stack>
      </DndKitSortable.SortableContext>
    </DndKitCore.DndContext>
  );
};
