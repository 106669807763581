import type { Permission } from '@piccolohealth/echo-common';
import { Spin } from '@piccolohealth/ui';
import React from 'react';
import { useAppContext } from '../../hooks/useAppContext';
import { usePermission } from '../../hooks/usePermission';
import { PageError } from './PageError';

interface Props {
  permission: Permission;
}

export const PermissionRoute = (props: React.PropsWithChildren<Props>) => {
  const { children, permission } = props;
  const { organization } = useAppContext();

  const permissionResponse = usePermission(permission);

  if (organization.isLoading || permissionResponse.isLoading) {
    return <Spin />;
  }

  if (!organization || !permissionResponse.value) {
    return <PageError type='Unauthorized' />;
  }

  return <>{children}</>;
};
