import type * as Types from '@piccolohealth/echo-common';
import { createGqlQuery } from '../fetcher';
import {
  ReportTemplate,
  ReportTemplateDryRun,
  ReportTemplateMinimal,
  ReportTemplateVersions,
} from '../queries/reportTemplate.query';

export const useReportTemplateQuery = createGqlQuery<
  Types.ReportTemplateQueryVariables,
  Types.ReportTemplateQuery
>((variables) => ['reportTemplate', variables], ReportTemplate);

export const useReportTemplateMinimalQuery = createGqlQuery<
  Types.ReportTemplateMinimalQueryVariables,
  Types.ReportTemplateMinimalQuery
>((variables) => ['reportTemplate', 'minimal', variables], ReportTemplateMinimal);

export const useReportTemplateVersionsQuery = createGqlQuery<
  Types.ReportTemplateVersionsQueryVariables,
  Types.ReportTemplateVersionsQuery
>((variables) => ['reportTemplate', 'versions', variables], ReportTemplateVersions);

export const useReportTemplateDryRunQuery = createGqlQuery<
  Types.ReportTemplateDryRunQueryVariables,
  Types.ReportTemplateDryRunQuery
>((variables) => ['reportTemplate', 'dryRun', variables], ReportTemplateDryRun);
