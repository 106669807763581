import React from 'react';
import { useController } from 'react-hook-form';
import {
  AutoCompleteReportVariable,
  type AutoCompleteReportVariableProps,
} from '../AutoCompleteReportVariable';

interface Props extends Omit<AutoCompleteReportVariableProps, 'value' | 'onChange'> {
  name: string;
}

export const HookedAutoCompleteReportVariable = (props: Props) => {
  const { name, ...rest } = props;
  const { field } = useController({ name });

  return (
    <AutoCompleteReportVariable
      {...rest}
      onChange={field.onChange}
      value={field.value ?? undefined}
    />
  );
};
