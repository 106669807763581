import { Divider, HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import { typedMemo } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { MeasurementValue } from './MeasurementValue';
import type { VariablesBySite } from './MeasurementsSidebar';

interface Props {
  variables: VariablesBySite;
}

export const MeasurementsList = typedMemo((props: Props) => {
  const { variables } = props;

  if (P.isEmpty(variables)) {
    return (
      <Text fontWeight='semibold' fontSize='sm' color='gray.300'>
        No measurements found
      </Text>
    );
  }

  return (
    <Stack divider={<Divider />}>
      {variables.map(({ site, measurements }) => (
        <Stack key={site} pr={4} spacing={1}>
          <Text color='gray.500' fontSize='sm' fontWeight='bold'>
            {site}
          </Text>
          <Stack spacing={1}>
            {measurements.map(({ reportTemplateVariable, variable }) => (
              <HStack
                key={reportTemplateVariable?.id}
                color='gray.300'
                fontSize='xs'
                lineHeight='18px'
                align='start'
              >
                <Text>{reportTemplateVariable?.label}</Text>
                <Spacer />
                <MeasurementValue variable={variable} />
              </HStack>
            ))}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
});
