import React from 'react';
import {
  Box,
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  type DialogRootProps,
  DialogTitle,
} from './index';

export interface ModalProps extends DialogRootProps {
  title: string;
  description?: string | React.ReactNode;
  footer?: React.ReactNode;
}

export const Modal = (props: ModalProps) => {
  const { title, description, footer, children, ...rest } = props;

  return (
    <DialogRoot {...rest}>
      <DialogBackdrop />
      <DialogContent>
        <DialogCloseTrigger />
        <DialogHeader>
          {title && <DialogTitle>{title}</DialogTitle>}
          {description && <Box>{description}</Box>}
        </DialogHeader>
        <DialogBody>{children}</DialogBody>
        {footer && <DialogFooter>{footer}</DialogFooter>}
      </DialogContent>
    </DialogRoot>
  );
};
