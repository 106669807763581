import { P } from '@piccolohealth/util';
import React from 'react';

const getContext = () => {
  const fragment: DocumentFragment = document.createDocumentFragment();
  const canvas: HTMLCanvasElement = document.createElement('canvas');
  fragment.appendChild(canvas);
  return canvas.getContext('2d') as CanvasRenderingContext2D;
};

const getCssStyle = (element: HTMLElement, prop: string) => {
  return window.getComputedStyle(element, null).getPropertyValue(prop);
};

const getCanvasFontSize = (el: HTMLElement) => {
  const fontWeight = getCssStyle(el, 'font-weight') || 'normal';
  const fontSize = getCssStyle(el, 'font-size') || '16px';
  const fontFamily = getCssStyle(el, 'font-family') || 'Times New Roman';
  return `${fontWeight} ${fontSize} ${fontFamily}`;
};

export const getTextWidth = (
  canvas: HTMLCanvasElement,
  text: string,
  element: HTMLInputElement,
): number => {
  const font = getCanvasFontSize(element);
  const context = canvas.getContext('2d');

  if (!context) {
    throw new Error('Could not get context');
  }

  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
};

interface UseTextWidthOptions {
  ref: React.RefObject<HTMLInputElement>;
}

export const useTextWidth = (options: UseTextWidthOptions) => {
  const { ref } = options;
  const [width, setWidth] = React.useState('0px');

  const canvas = React.useMemo(() => {
    return getContext().canvas;
  }, []);

  const recalculateWidth = React.useCallback(() => {
    if (ref.current && !P.isNil(ref.current.value)) {
      const width = getTextWidth(canvas, ref.current.value, ref.current);
      setWidth(`${width}px`);
    }
  }, [canvas, ref]);

  React.useLayoutEffect(() => {
    recalculateWidth();
  }, [recalculateWidth]);

  return {
    recalculateWidth,
    width,
  };
};
