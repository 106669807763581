import { Button, Stack } from '@chakra-ui/react';
import React from 'react';
import { FaDatabase, FaDrawPolygon, FaRuler } from 'react-icons/fa';
import { StepPage, type StepProps } from './StepPage';

export const MeasurementTypeSelectionStep = (props: StepProps) => {
  const { measurementControl } = props;

  return (
    <StepPage
      title='Add measurement'
      description='Select the type of measurement you want to add.'
      onClose={measurementControl.onClose}
    >
      <Stack spacing={2}>
        <Button
          leftIcon={<FaRuler />}
          size='xs'
          variant='solid'
          justifyContent='start'
          w='fit-content'
          pr={3}
          onClick={() => measurementControl.onSelectStep('linear')}
          _hover={{
            bg: 'gray.300',
          }}
        >
          Linear
        </Button>
        <Button
          leftIcon={<FaDrawPolygon />}
          size='xs'
          variant='solid'
          justifyContent='start'
          w='fit-content'
          pr={3}
          onClick={() => measurementControl.onSelectStep('area')}
          _hover={{
            bg: 'gray.300',
          }}
        >
          Area
        </Button>
        <Button
          leftIcon={<FaDatabase />}
          size='xs'
          pr={3}
          variant='solid'
          justifyContent='start'
          w='fit-content'
          onClick={() => measurementControl.onSelectStep('volume')}
          _hover={{
            bg: 'gray.300',
          }}
        >
          Volume
        </Button>
      </Stack>
    </StepPage>
  );
};
