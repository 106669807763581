import type { FeatureFlag as Flag } from '@piccolohealth/echo-common';
import React from 'react';
import { useAppContext } from '../../hooks/useAppContext';

interface Props {
  featureFlag: Flag;
}

export const VisibleWithFeatureFlag = (props: React.PropsWithChildren<Props>) => {
  const { featureFlag, children } = props;
  const { organization } = useAppContext();

  if (organization.hasFeature(featureFlag)) {
    return <>{children}</>;
  }

  return null;
};
