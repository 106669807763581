import type * as Types from '@piccolohealth/echo-common';
import { useMutation } from 'react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { DeleteReport } from '../mutations/deleteReport.mutation';

export const useDeleteReportMutation = (
  options?: MutationOptions<Types.DeleteReportMutation, Types.DeleteReportMutationVariables>,
) => {
  return useMutation(gqlFetcher(DeleteReport), {
    ...options,
    onSuccess: async (resp, variables, ctx) => {
      await options?.onSuccess?.(resp, variables, ctx);
    },
  });
};
