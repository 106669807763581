import type * as Types from '../';

import type { DateTime } from '@piccolohealth/util';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: DateTime;
  JSON: any;
};

export type AddAuditTrailEntryRequest = {
  __typename?: 'AddAuditTrailEntryRequest';
  event: AuditTrailEvent;
  message: Scalars['String'];
  organizationId: Scalars['ID'];
  payload?: Maybe<Scalars['JSON']>;
  user: User;
};

export type AddReportTemplateRequest = {
  baseTemplateData?: InputMaybe<Scalars['JSON']>;
  labelColor: Scalars['String'];
  name: Scalars['String'];
};

export type AuditTrailEntry = {
  __typename?: 'AuditTrailEntry';
  createdAt: Scalars['DateTime'];
  event: AuditTrailEvent;
  id: Scalars['ID'];
  message: Scalars['String'];
  organizationId: Scalars['ID'];
  payload: Scalars['JSON'];
  updatedAt: Scalars['DateTime'];
  user: User;
};

export enum AuditTrailEvent {
  BilledAction = 'BILLED_ACTION',
  DistributionCreated = 'DISTRIBUTION_CREATED',
  DistributionDeleted = 'DISTRIBUTION_DELETED',
  DistributionFailed = 'DISTRIBUTION_FAILED',
  DistributionSent = 'DISTRIBUTION_SENT',
  DistributionUpdated = 'DISTRIBUTION_UPDATED',
  IntegrationUpdated = 'INTEGRATION_UPDATED',
  LabelCreated = 'LABEL_CREATED',
  LabelDeleted = 'LABEL_DELETED',
  LabelEdited = 'LABEL_EDITED',
  MachineAetCreated = 'MACHINE_AET_CREATED',
  MachineAetDeleted = 'MACHINE_AET_DELETED',
  MachineAetUpdated = 'MACHINE_AET_UPDATED',
  OrganizationUpdated = 'ORGANIZATION_UPDATED',
  ReportsDeleted = 'REPORTS_DELETED',
  ReportsExported = 'REPORTS_EXPORTED',
  ReportAttachmentDeleted = 'REPORT_ATTACHMENT_DELETED',
  ReportAttachmentUploaded = 'REPORT_ATTACHMENT_UPLOADED',
  ReportBilled = 'REPORT_BILLED',
  ReportCreated = 'REPORT_CREATED',
  ReportDeleted = 'REPORT_DELETED',
  ReportDraftPdfGenerated = 'REPORT_DRAFT_PDF_GENERATED',
  ReportFastForwarded = 'REPORT_FAST_FORWARDED',
  ReportLabelAdded = 'REPORT_LABEL_ADDED',
  ReportLabelRemoved = 'REPORT_LABEL_REMOVED',
  ReportShared = 'REPORT_SHARED',
  ReportStatusUpdated = 'REPORT_STATUS_UPDATED',
  ReportTemplateCreated = 'REPORT_TEMPLATE_CREATED',
  ReportTemplateMerged = 'REPORT_TEMPLATE_MERGED',
  ReportTemplateRestored = 'REPORT_TEMPLATE_RESTORED',
  ReportTemplateUpdated = 'REPORT_TEMPLATE_UPDATED',
  ReportTypeUpdated = 'REPORT_TYPE_UPDATED',
  ReportUnshared = 'REPORT_UNSHARED',
  UserInvited = 'USER_INVITED',
  UserRemoved = 'USER_REMOVED',
  UserUpdated = 'USER_UPDATED',
  WorklistEntryCreated = 'WORKLIST_ENTRY_CREATED',
  WorklistEntryDeleted = 'WORKLIST_ENTRY_DELETED',
  WorklistEntryUpdated = 'WORKLIST_ENTRY_UPDATED',
}

export type AutoComplete = {
  __typename?: 'AutoComplete';
  emails: PaginatedAutoCompleteResponse;
  reportVariables: PaginatedAutoCompleteResponse;
};

export type AutoCompleteEmailsArgs = {
  request?: InputMaybe<PaginatedAutoCompleteEmailsRequest>;
};

export type AutoCompleteReportVariablesArgs = {
  request: PaginatedAutoCompleteReportVariablesRequest;
};

export enum AutoCompleteOrder {
  Count = 'COUNT',
  Similarity = 'SIMILARITY',
}

export type AutoCompleteResult = {
  __typename?: 'AutoCompleteResult';
  count: Scalars['Int'];
  isExcluded: Scalars['Boolean'];
  similarity: Scalars['Float'];
  value: Scalars['String'];
};

export enum AutoCompleteType {
  ReportShareEmail = 'REPORT_SHARE_EMAIL',
  ReportVariable = 'REPORT_VARIABLE',
}

export type Billing = {
  __typename?: 'Billing';
  actions: Array<BillingAction>;
  invoice?: Maybe<Invoice>;
  invoices: Array<Maybe<Invoice>>;
  subscriptionItems: Array<SubscriptionItem>;
};

export type BillingInvoiceArgs = {
  invoiceId: Scalars['ID'];
};

export type BillingAction = {
  __typename?: 'BillingAction';
  createdAt: Scalars['DateTime'];
  id: BillingActionType;
  stripeProductId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum BillingActionType {
  DistributionFax = 'DistributionFax',
  DistributionHealthlink = 'DistributionHealthlink',
  ReportFinalizeClassA = 'ReportFinalizeClassA',
  ReportFinalizeClassB = 'ReportFinalizeClassB',
}

export type CreateAutoCompleteExclusionRequest = {
  key: Scalars['String'];
  type: AutoCompleteType;
  value: Scalars['String'];
};

export type CreateLabelRequest = {
  color: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateMachineAetRequest = {
  aet: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateMeasurementMappingRequest = {
  alias?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  precision?: InputMaybe<Scalars['Int']>;
  properties?: InputMaybe<Array<UpdateMeasurementPropertyRequest>>;
  rangeGroupId?: InputMaybe<Scalars['ID']>;
  shortName?: InputMaybe<Scalars['String']>;
  site: Scalars['String'];
  units?: InputMaybe<Scalars['String']>;
};

export type CreateMeasurementRangeGroupRequest = {
  id?: InputMaybe<Scalars['ID']>;
  label: Scalars['String'];
  precision: Scalars['Int'];
  ranges: Array<CreateMeasurementRangeRequest>;
  sources: Array<MeasurementRangeGroupSourceInput>;
  units?: InputMaybe<Scalars['String']>;
};

export type CreateMeasurementRangeRequest = {
  age?: InputMaybe<NumericalRangeInput>;
  label: Scalars['String'];
  level: MeasurementRangeLevel;
  measurement: NumericalRangeInput;
  sex?: InputMaybe<Scalars['String']>;
};

export type CreateOrganizationRequest = {
  addressLineOne?: InputMaybe<Scalars['String']>;
  addressLineTwo?: InputMaybe<Scalars['String']>;
  contract?: InputMaybe<Scalars['String']>;
  copyTemplatesFrom?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  featureFlags?: InputMaybe<Array<FeatureFlag>>;
  logo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  pubsubTopic?: InputMaybe<Scalars['String']>;
  shortId?: InputMaybe<Scalars['String']>;
  status: OrganizationStatus;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionId?: InputMaybe<Scalars['String']>;
  supportEmail: Scalars['String'];
  timezone: Scalars['String'];
};

export type CreateReportRequest = {
  dob: Scalars['DateTime'];
  patientName: Scalars['String'];
  reportTemplateId: Scalars['ID'];
  sex: Scalars['String'];
  studyDate: Scalars['DateTime'];
};

export type CreateReportsExportJobRequest = {
  attachments?: InputMaybe<Scalars['Boolean']>;
  dicom?: InputMaybe<DicomExportOptions>;
  flattened?: InputMaybe<Scalars['Boolean']>;
  images?: InputMaybe<Scalars['Boolean']>;
  pdfs?: InputMaybe<Scalars['Boolean']>;
  reportIds: Array<Scalars['ID']>;
};

export type CreateWorklistEntryRequest = {
  machineAetId?: InputMaybe<Scalars['ID']>;
  patientDob: Scalars['DateTime'];
  patientFirstName: Scalars['String'];
  patientHeight?: InputMaybe<Scalars['Float']>;
  patientId?: InputMaybe<Scalars['String']>;
  patientLastName: Scalars['String'];
  patientSex: Scalars['String'];
  patientWeight?: InputMaybe<Scalars['Int']>;
  performingPhysicianId: Scalars['ID'];
  reportTemplateId: Scalars['ID'];
};

export type DefaultDistribution = {
  __typename?: 'DefaultDistribution';
  payload: DistributionPayload;
  trigger: DistributionTrigger;
};

export type DeleteAutoCompleteExclusionRequest = {
  key: Scalars['String'];
  type: AutoCompleteType;
  value: Scalars['String'];
};

export type DeleteReportsRequest = {
  reportIds: Array<Scalars['ID']>;
};

export type Dicom = {
  __typename?: 'Dicom';
  machines: Array<Machine>;
  measurementMappingsAndVariants: PaginatedMeasurementMappingsAndVariants;
  measurementRangeGroups: Array<MeasurementRangeGroup>;
  unusedMeasurementVariants: PaginatedUnusedMeasurementVariants;
};

export type DicomMeasurementMappingsAndVariantsArgs = {
  request?: InputMaybe<GetPaginatedMeasurementMappingsAndVariantsRequest>;
};

export type DicomUnusedMeasurementVariantsArgs = {
  request?: InputMaybe<GetPaginatedUnusedMeasurementVariantsRequest>;
};

export enum DicomExportOptions {
  All = 'ALL',
  Sr = 'SR',
  Us = 'US',
}

export type DicomSettings = {
  __typename?: 'DicomSettings';
  aet: Scalars['String'];
  ip: Scalars['String'];
  port: Scalars['Int'];
};

export type Distribution = {
  __typename?: 'Distribution';
  createdAt: Scalars['DateTime'];
  events: Array<DistributionEvent>;
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  organizationId: Scalars['ID'];
  payload: DistributionPayload;
  reportId: Scalars['ID'];
  status: DistributionStatus;
  trigger: DistributionTrigger;
  updatedAt: Scalars['DateTime'];
};

export type DistributionEmailPayload = {
  __typename?: 'DistributionEmailPayload';
  message?: Maybe<Scalars['String']>;
  shareeEmail: Scalars['String'];
  sharerEmail: Scalars['String'];
  sharerId: Scalars['ID'];
  sharerName: Scalars['String'];
};

export type DistributionEmailPayloadRequest = {
  __typename?: 'DistributionEmailPayloadRequest';
  message?: Maybe<Scalars['String']>;
  shareeEmail: Scalars['String'];
  sharerId: Scalars['ID'];
};

export type DistributionEvent = {
  __typename?: 'DistributionEvent';
  createdAt: Scalars['DateTime'];
  distributionId: Scalars['ID'];
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
  payload: Scalars['JSON'];
  reportId: Scalars['ID'];
  type: DistributionEventType;
  updatedAt: Scalars['DateTime'];
};

export enum DistributionEventType {
  Bounced = 'BOUNCED',
  Created = 'CREATED',
  Failed = 'FAILED',
  Processing = 'PROCESSING',
  Sent = 'SENT',
  Updated = 'UPDATED',
}

export type DistributionFaxFromInfo = {
  __typename?: 'DistributionFaxFromInfo';
  sharerEmail: Scalars['String'];
  sharerId: Scalars['ID'];
  sharerName: Scalars['String'];
};

export type DistributionFaxPayload = {
  __typename?: 'DistributionFaxPayload';
  from: DistributionFaxFromInfo;
  to: DistributionFaxToInfo;
};

export type DistributionFaxPayloadRequest = {
  __typename?: 'DistributionFaxPayloadRequest';
  from: DistributionFaxFromInfo;
  to: DistributionFaxToInfo;
};

export type DistributionFaxToInfo = {
  __typename?: 'DistributionFaxToInfo';
  faxNumber: Scalars['String'];
};

export type DistributionHealthLinkPayload = {
  __typename?: 'DistributionHealthLinkPayload';
  from: DistributionHealthLinkProviderFromInfo;
  to: DistributionHealthLinkProviderToInfo;
};

export type DistributionHealthLinkPayloadRequest = {
  __typename?: 'DistributionHealthLinkPayloadRequest';
  from: DistributionHealthLinkProviderFromInfo;
  to: DistributionHealthLinkProviderToInfo;
};

export type DistributionHealthLinkProviderFromInfo = {
  __typename?: 'DistributionHealthLinkProviderFromInfo';
  name: Scalars['String'];
  providerNumber: Scalars['String'];
};

export type DistributionHealthLinkProviderToInfo = {
  __typename?: 'DistributionHealthLinkProviderToInfo';
  endpointId: Scalars['ID'];
  endpointName: Scalars['String'];
  organizationAddress: HealthLinkAddress;
  organizationId: Scalars['ID'];
  organizationName: Scalars['String'];
  practitionerId: Scalars['ID'];
  practitionerName: HealthLinkName;
  providerNumber: Scalars['String'];
};

export type DistributionMedicalObjectsPayload = {
  __typename?: 'DistributionMedicalObjectsPayload';
  from: DistributionMedicalObjectsProviderInfo;
  to: DistributionMedicalObjectsProviderInfo;
};

export type DistributionMedicalObjectsPayloadRequest = {
  __typename?: 'DistributionMedicalObjectsPayloadRequest';
  from: DistributionMedicalObjectsProviderRequest;
  to: DistributionMedicalObjectsProviderRequest;
};

export type DistributionMedicalObjectsProviderInfo = {
  __typename?: 'DistributionMedicalObjectsProviderInfo';
  providerDetails: MedicalObjectsIntegrationProvider;
  providerNumber: Scalars['String'];
};

export type DistributionMedicalObjectsProviderRequest = {
  __typename?: 'DistributionMedicalObjectsProviderRequest';
  providerDetails?: Maybe<MedicalObjectsIntegrationProvider>;
  providerNumber: Scalars['String'];
};

export type DistributionPayload =
  | DistributionEmailPayload
  | DistributionFaxPayload
  | DistributionHealthLinkPayload
  | DistributionMedicalObjectsPayload;

export type DistributionPayloadRequest =
  | DistributionEmailPayloadRequest
  | DistributionFaxPayloadRequest
  | DistributionHealthLinkPayloadRequest
  | DistributionMedicalObjectsPayloadRequest;

export type DistributionRequest = {
  __typename?: 'DistributionRequest';
  payload: DistributionPayloadRequest;
  trigger: DistributionTrigger;
};

export type DistributionSettings = {
  __typename?: 'DistributionSettings';
  defaultDistribution?: Maybe<DefaultDistribution>;
  defaultDistributionType?: Maybe<DistributionType>;
};

export enum DistributionStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Sent = 'SENT',
}

export enum DistributionTrigger {
  Immediate = 'IMMEDIATE',
  Manual = 'MANUAL',
  OnFinalize = 'ON_FINALIZE',
}

export enum DistributionType {
  Email = 'EMAIL',
  Fax = 'FAX',
  HealthLink = 'HEALTH_LINK',
  MedicalObjects = 'MEDICAL_OBJECTS',
}

export type EditLabelRequest = {
  color?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type FaxIntegration = {
  __typename?: 'FaxIntegration';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
  settings: FaxIntegrationSettings;
  type: IntegrationType;
  updatedAt: Scalars['DateTime'];
};

export type FaxIntegrationSettings = {
  __typename?: 'FaxIntegrationSettings';
  enabled: Scalars['Boolean'];
};

export enum FeatureFlag {
  InAppMeasurements = 'IN_APP_MEASUREMENTS',
  NormalRanges = 'NORMAL_RANGES',
  Worklist = 'WORKLIST',
}

export enum FinalizeBehavior {
  NextReport = 'NEXT_REPORT',
  Noop = 'NOOP',
  Reports = 'REPORTS',
}

export type GetInsightsRequest = {
  cardiologists: Array<Scalars['String']>;
  end: Scalars['DateTime'];
  referringPhysicians: Array<Scalars['String']>;
  sonographers: Array<Scalars['String']>;
  start: Scalars['DateTime'];
  types: Array<Scalars['String']>;
};

export type GetPaginatedAuditTrailEntriesRequest = {
  end?: InputMaybe<Scalars['DateTime']>;
  events?: InputMaybe<Array<AuditTrailEvent>>;
  message?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<TimestampPaginationRequest>;
  payload?: InputMaybe<Scalars['JSON']>;
  start?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<Array<Scalars['String']>>;
};

export type GetPaginatedMeasurementMappingsAndVariantsRequest = {
  machine?: InputMaybe<Array<MachineFilter>>;
  name?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<PaginationRequest>;
  site?: InputMaybe<Scalars['String']>;
};

export type GetPaginatedReportSharesRequest = {
  assignedTo?: InputMaybe<Array<Scalars['String']>>;
  dob?: InputMaybe<Scalars['DateTime']>;
  end?: InputMaybe<Scalars['DateTime']>;
  isExpired?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<PaginationRequest>;
  start?: InputMaybe<Scalars['DateTime']>;
  statuses?: InputMaybe<Array<ReportStatusFilter>>;
  types?: InputMaybe<Array<ReportTypeFilter>>;
};

export type GetPaginatedReportTemplatesRequest = {
  end?: InputMaybe<Scalars['DateTime']>;
  master?: InputMaybe<Scalars['Boolean']>;
  pagination?: InputMaybe<PaginationRequest>;
  start?: InputMaybe<Scalars['DateTime']>;
  statuses?: InputMaybe<Array<ReportTemplateStatus>>;
};

export type GetPaginatedReportsRequest = {
  assignedTo?: InputMaybe<Array<Scalars['String']>>;
  dob?: InputMaybe<Scalars['DateTime']>;
  end?: InputMaybe<Scalars['DateTime']>;
  labels?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<PaginationRequest>;
  patientId?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
  statuses?: InputMaybe<Array<ReportStatusFilter>>;
  types?: InputMaybe<Array<ReportTypeFilter>>;
};

export type GetPaginatedUnusedMeasurementVariantsRequest = {
  machine?: InputMaybe<Array<MachineFilter>>;
  name?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<PaginationRequest>;
  site?: InputMaybe<Scalars['String']>;
};

export type GetPaginatedWorklistEntriesRequest = {
  end?: InputMaybe<Scalars['DateTime']>;
  machineAetIds?: InputMaybe<Array<Scalars['ID']>>;
  pagination?: InputMaybe<PaginationRequest>;
  start?: InputMaybe<Scalars['DateTime']>;
  statuses?: InputMaybe<Array<WorklistEntryStatus>>;
};

export type Hl7OverrideRequest = {
  clinicalArea?: InputMaybe<Scalars['String']>;
};

export type Hl7Overrides = {
  __typename?: 'HL7Overrides';
  clinicalArea?: Maybe<Scalars['String']>;
};

export type HealthLinkAddress = {
  __typename?: 'HealthLinkAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  line?: Maybe<Array<Scalars['String']>>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  use?: Maybe<Scalars['String']>;
};

export type HealthLinkEndpoint = {
  __typename?: 'HealthLinkEndpoint';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type HealthLinkIdentifier = {
  __typename?: 'HealthLinkIdentifier';
  system: Scalars['String'];
  type: HealthlinkIdentifierType;
  use: Scalars['String'];
  value: Scalars['String'];
};

export type HealthLinkIntegration = {
  __typename?: 'HealthLinkIntegration';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
  practitioners: PaginatedHealthLinkPractitioners;
  settings: HealthLinkIntegrationSettings;
  type: IntegrationType;
  updatedAt: Scalars['DateTime'];
};

export type HealthLinkIntegrationPractitionersArgs = {
  request?: InputMaybe<PaginatedHealthLinkPractitionersRequest>;
};

export type HealthLinkIntegrationSettings = {
  __typename?: 'HealthLinkIntegrationSettings';
  enabled: Scalars['Boolean'];
  hl7Overrides?: Maybe<Hl7Overrides>;
  providerMappings: Array<ProviderMapping>;
};

export type HealthLinkName = {
  __typename?: 'HealthLinkName';
  family: Scalars['String'];
  given: Array<Scalars['String']>;
  prefix: Array<Scalars['String']>;
  suffix: Array<Scalars['String']>;
  text: Scalars['String'];
};

export type HealthLinkOrganization = {
  __typename?: 'HealthLinkOrganization';
  address: HealthLinkAddress;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type HealthLinkPractitioner = {
  __typename?: 'HealthLinkPractitioner';
  id: Scalars['ID'];
  name: HealthLinkName;
  providers: Array<HealthLinkProvider>;
};

export type HealthLinkPractitionersFilter = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type HealthLinkProvider = {
  __typename?: 'HealthLinkProvider';
  endpoint: HealthLinkEndpoint;
  organization: HealthLinkOrganization;
  providerNumber: Scalars['ID'];
};

export type HealthlinkIdentifierType = {
  __typename?: 'HealthlinkIdentifierType';
  text: Scalars['String'];
};

export type Insights = {
  __typename?: 'Insights';
  cardiologists: Array<InsightsValue>;
  referringPhysicians: Array<InsightsValue>;
  reportsFinalized: Scalars['Int'];
  reportsShared: Scalars['Int'];
  reportsUploaded: Scalars['Int'];
  reportsUploadedByType: Array<InsightsValue>;
  reportsUploadedOverTime: Array<ReportUploadedOverTimeInsights>;
  sonographers: Array<InsightsValue>;
  uniquePatients: Scalars['Int'];
};

export type InsightsValue = {
  __typename?: 'InsightsValue';
  color?: Maybe<Scalars['String']>;
  count: Scalars['Int'];
  name: Scalars['String'];
};

export type Instance = {
  __typename?: 'Instance';
  dicom: InstanceDicom;
  fileUrl?: Maybe<Scalars['String']>;
  frameUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mp4Url?: Maybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  seriesId: Scalars['ID'];
  size?: Maybe<Scalars['Int']>;
  studyId: Scalars['ID'];
};

export type InstanceDicom = {
  __typename?: 'InstanceDicom';
  columns?: Maybe<Scalars['Int']>;
  contentDateTime?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Float']>;
  frameRate?: Maybe<Scalars['Int']>;
  heartRate?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  imageType?: Maybe<Scalars['String']>;
  instanceNumber?: Maybe<Scalars['Int']>;
  manufacturer?: Maybe<Scalars['String']>;
  modality: Scalars['String'];
  model?: Maybe<Scalars['String']>;
  numberOfFrames?: Maybe<Scalars['Int']>;
  numberOfStages?: Maybe<Scalars['Int']>;
  rows?: Maybe<Scalars['Int']>;
  sequenceOfUltrasoundRegions: Array<SequenceOfUltrasoundRegion>;
  seriesId: Scalars['ID'];
  stage: Stage;
  studyId: Scalars['ID'];
  tags: Scalars['JSON'];
  transferSyntaxUID?: Maybe<Scalars['String']>;
  view: View;
};

export type InstanceDicomTagsArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>;
};

export enum IntegrationType {
  Fax = 'FAX',
  HealthLink = 'HEALTH_LINK',
  MedicalObjects = 'MEDICAL_OBJECTS',
}

export type Integrations = {
  __typename?: 'Integrations';
  fax: FaxIntegration;
  healthLink: HealthLinkIntegration;
  medicalObjects: MedicalObjectsIntegration;
};

export type InviteUserRequest = {
  email: Scalars['String'];
  name: Scalars['String'];
  roleIds: Array<Scalars['String']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  amountDue: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  due?: Maybe<Scalars['DateTime']>;
  hostedPaymentUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  number?: Maybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  pdfDownloadUrl?: Maybe<Scalars['String']>;
  periodEnd: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  quantity: Scalars['Int'];
  status?: Maybe<Scalars['String']>;
  subscriptionItems: Array<InvoiceSubscriptionItem>;
};

export type InvoiceSubscriptionItem = {
  __typename?: 'InvoiceSubscriptionItem';
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
  periodEnd: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  product: Product;
  usageQuantity: Scalars['Int'];
  usageRecords: Array<Maybe<InvoiceUsageRecord>>;
};

export type InvoiceUsageRecord = {
  __typename?: 'InvoiceUsageRecord';
  organizationId: Scalars['ID'];
  patientName: Scalars['String'];
  report?: Maybe<Report>;
  reportId: Scalars['ID'];
  reportTemplateVersionId: Scalars['ID'];
  timestamp: Scalars['DateTime'];
};

export type Job = {
  __typename?: 'Job';
  finishedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  payload?: Maybe<Scalars['JSON']>;
  progress: Scalars['Float'];
  queuedAt: Scalars['DateTime'];
  startedAt?: Maybe<Scalars['DateTime']>;
  status: JobStatus;
  type: JobType;
};

export enum JobStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Queued = 'QUEUED',
}

export enum JobType {
  ReportsExport = 'REPORTS_EXPORT',
}

export type Label = {
  __typename?: 'Label';
  color: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type Machine = {
  __typename?: 'Machine';
  manufacturer: Scalars['String'];
  model: Scalars['String'];
};

export type MachineAet = {
  __typename?: 'MachineAet';
  aet: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
};

export type MachineFilter = {
  manufacturer: Scalars['String'];
  model: Scalars['String'];
};

export type Measurement = {
  __typename?: 'Measurement';
  hash: Scalars['ID'];
  mapping?: Maybe<MeasurementMapping>;
  name: Scalars['String'];
  properties: Array<MeasurementProperty>;
  site?: Maybe<Scalars['String']>;
  units?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['JSON']>;
};

export type MeasurementMapping = {
  __typename?: 'MeasurementMapping';
  alias?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  precision?: Maybe<Scalars['Int']>;
  properties: Array<MeasurementProperty>;
  rangeGroup?: Maybe<MeasurementRangeGroup>;
  shortName?: Maybe<Scalars['String']>;
  site?: Maybe<Scalars['String']>;
  units?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type MeasurementMappingAndVariants = {
  __typename?: 'MeasurementMappingAndVariants';
  mapping: MeasurementMapping;
  variants: Array<MeasurementVariant>;
};

export type MeasurementProperty = {
  __typename?: 'MeasurementProperty';
  name: Scalars['String'];
  value?: Maybe<Scalars['JSON']>;
};

export type MeasurementRange = {
  __typename?: 'MeasurementRange';
  age?: Maybe<NumericalRange>;
  label: Scalars['String'];
  level: MeasurementRangeLevel;
  measurement: NumericalRange;
  sex?: Maybe<Scalars['String']>;
};

export type MeasurementRangeCriteria = {
  __typename?: 'MeasurementRangeCriteria';
  age?: Maybe<Scalars['Int']>;
  measurement?: Maybe<Scalars['Float']>;
  sex?: Maybe<Scalars['String']>;
};

export type MeasurementRangeGroup = {
  __typename?: 'MeasurementRangeGroup';
  id: Scalars['ID'];
  label: Scalars['String'];
  precision: Scalars['Int'];
  ranges: Array<MeasurementRange>;
  sources: Array<MeasurementRangeGroupSource>;
  units?: Maybe<Scalars['String']>;
};

export type MeasurementRangeGroupSource = {
  __typename?: 'MeasurementRangeGroupSource';
  name: Scalars['String'];
  url: Scalars['String'];
};

export type MeasurementRangeGroupSourceInput = {
  name: Scalars['String'];
  url: Scalars['String'];
};

export enum MeasurementRangeLevel {
  Level1 = 'Level1',
  Level2 = 'Level2',
  Level3 = 'Level3',
  Level4 = 'Level4',
}

export type MeasurementVariant = {
  __typename?: 'MeasurementVariant';
  createdAt: Scalars['DateTime'];
  hash: Scalars['String'];
  id: Scalars['ID'];
  machine: Machine;
  mappingId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  organizationStats: Array<OrganizationMeasurementVariantStats>;
  originalMappingId?: Maybe<Scalars['ID']>;
  properties: Array<MeasurementProperty>;
  site?: Maybe<Scalars['String']>;
  units?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type MedicalObjectsIntegration = {
  __typename?: 'MedicalObjectsIntegration';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
  practiceProviders: Array<MedicalObjectsIntegrationProvider>;
  providers: PaginatedMedicalObjectsProviders;
  settings: MedicalObjectsIntegrationSettings;
  type: IntegrationType;
  updatedAt: Scalars['DateTime'];
};

export type MedicalObjectsIntegrationProvidersArgs = {
  request?: InputMaybe<PaginatedMedicalObjectsProvidersRequest>;
};

export type MedicalObjectsIntegrationProvider = {
  __typename?: 'MedicalObjectsIntegrationProvider';
  address: Array<MedicalObjectsProviderAddress>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  providerNumbers: Array<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
};

export type MedicalObjectsIntegrationSettings = {
  __typename?: 'MedicalObjectsIntegrationSettings';
  capricornUrl?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  hl7Overrides?: Maybe<Hl7Overrides>;
  practiceId?: Maybe<Scalars['String']>;
  practiceName?: Maybe<Scalars['String']>;
  providerMappings: Array<ProviderMapping>;
};

export type MedicalObjectsProviderAddress = {
  __typename?: 'MedicalObjectsProviderAddress';
  addressType?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  otherDesignation?: Maybe<Scalars['String']>;
  otherGeographicDesignation?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
};

export type MedicalObjectsProviderFilter = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addReportLabel: Label;
  addReportTemplate: ReportTemplate;
  addReportTemplateVariable: ReportTemplate;
  addReportTemplateVariables: ReportTemplate;
  createAutoCompleteExclusion: Scalars['Boolean'];
  createDistribution: Distribution;
  createLabel: Label;
  createMachineAet: MachineAet;
  createMeasurementMapping: MeasurementMapping;
  createMeasurementRangeGroup: MeasurementRangeGroup;
  createOrganization: Organization;
  createPasswordChangeTicketUrl: Scalars['String'];
  createReport: Report;
  createReportsExportJob: Job;
  createWorklistEntry: WorklistEntry;
  deleteAutoCompleteExclusion: Scalars['Boolean'];
  deleteDistribution: Scalars['Boolean'];
  deleteLabel: Scalars['Boolean'];
  deleteMachineAet: Scalars['Boolean'];
  deleteMeasurementMapping: Scalars['Boolean'];
  deleteMeasurementRangeGroup: Scalars['Boolean'];
  deleteOrganization: Scalars['Boolean'];
  deleteReport: Scalars['Boolean'];
  deleteReportAttachment: Scalars['Boolean'];
  deleteReportTemplateVariable: ReportTemplate;
  deleteReportTemplateVariables: ReportTemplate;
  deleteReports: Scalars['Boolean'];
  deleteWorklistEntry: Scalars['Boolean'];
  editLabel: Label;
  fastForwardReport?: Maybe<Report>;
  generateDraftReportPdf: ReportPdf;
  inviteUser: User;
  mergeReportTemplate: ReportTemplate;
  removeReportLabel: Scalars['Boolean'];
  removeUser: Scalars['Boolean'];
  restoreReportTemplate: ReportTemplate;
  sendDistribution: Distribution;
  shareReport: ReportShare;
  unshareReport: Scalars['Boolean'];
  updateDistribution: Distribution;
  updateIntegrations: Integrations;
  updateMachineAet: MachineAet;
  updateMeasurementMapping: MeasurementMapping;
  updateMeasurementRangeGroup: MeasurementRangeGroup;
  updateMeasurementVariants: Array<MeasurementVariant>;
  updateOrganization: Organization;
  updateProfile: User;
  updateReport: Report;
  updateReportStatus?: Maybe<Report>;
  updateReportTemplate: ReportTemplate;
  updateReportTemplateStatementSite: ReportTemplate;
  updateReportTemplateStatementSites: ReportTemplate;
  updateReportTemplateVariable: ReportTemplate;
  updateReportType: Report;
  updateUser: User;
  updateWorklistEntry: WorklistEntry;
};

export type MutationAddReportLabelArgs = {
  labelId: Scalars['ID'];
  organizationId: Scalars['ID'];
  reportId: Scalars['ID'];
};

export type MutationAddReportTemplateArgs = {
  organizationId: Scalars['ID'];
  request: AddReportTemplateRequest;
};

export type MutationAddReportTemplateVariableArgs = {
  organizationId: Scalars['ID'];
  reportTemplateId: Scalars['ID'];
  request: Scalars['JSON'];
};

export type MutationAddReportTemplateVariablesArgs = {
  organizationId: Scalars['ID'];
  reportTemplateId: Scalars['ID'];
  request: Scalars['JSON'];
};

export type MutationCreateAutoCompleteExclusionArgs = {
  organizationId: Scalars['ID'];
  request: CreateAutoCompleteExclusionRequest;
};

export type MutationCreateDistributionArgs = {
  organizationId: Scalars['ID'];
  reportId: Scalars['ID'];
  request: Scalars['JSON'];
};

export type MutationCreateLabelArgs = {
  organizationId: Scalars['ID'];
  request: CreateLabelRequest;
};

export type MutationCreateMachineAetArgs = {
  organizationId: Scalars['ID'];
  request: CreateMachineAetRequest;
};

export type MutationCreateMeasurementMappingArgs = {
  request: CreateMeasurementMappingRequest;
};

export type MutationCreateMeasurementRangeGroupArgs = {
  request: CreateMeasurementRangeGroupRequest;
};

export type MutationCreateOrganizationArgs = {
  request: CreateOrganizationRequest;
};

export type MutationCreateReportArgs = {
  createReportRequest: CreateReportRequest;
  organizationId: Scalars['ID'];
};

export type MutationCreateReportsExportJobArgs = {
  organizationId: Scalars['ID'];
  request: CreateReportsExportJobRequest;
};

export type MutationCreateWorklistEntryArgs = {
  organizationId: Scalars['ID'];
  request: CreateWorklistEntryRequest;
};

export type MutationDeleteAutoCompleteExclusionArgs = {
  organizationId: Scalars['ID'];
  request: DeleteAutoCompleteExclusionRequest;
};

export type MutationDeleteDistributionArgs = {
  distributionId: Scalars['ID'];
  organizationId: Scalars['ID'];
  reportId: Scalars['ID'];
  request: Scalars['JSON'];
};

export type MutationDeleteLabelArgs = {
  labelId: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export type MutationDeleteMachineAetArgs = {
  aet: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export type MutationDeleteMeasurementMappingArgs = {
  mappingId: Scalars['ID'];
};

export type MutationDeleteMeasurementRangeGroupArgs = {
  rangeGroupId: Scalars['ID'];
};

export type MutationDeleteOrganizationArgs = {
  organizationId: Scalars['ID'];
};

export type MutationDeleteReportArgs = {
  organizationId: Scalars['ID'];
  reportId: Scalars['ID'];
};

export type MutationDeleteReportAttachmentArgs = {
  organizationId: Scalars['ID'];
  reportAttachmentId: Scalars['ID'];
  reportId: Scalars['ID'];
};

export type MutationDeleteReportTemplateVariableArgs = {
  organizationId: Scalars['ID'];
  reportTemplateId: Scalars['ID'];
  reportTemplateVariableId: Scalars['ID'];
};

export type MutationDeleteReportTemplateVariablesArgs = {
  organizationId: Scalars['ID'];
  reportTemplateId: Scalars['ID'];
  reportTemplateVariableIds: Array<Scalars['ID']>;
};

export type MutationDeleteReportsArgs = {
  organizationId: Scalars['ID'];
  request: DeleteReportsRequest;
};

export type MutationDeleteWorklistEntryArgs = {
  organizationId: Scalars['ID'];
  worklistEntryId: Scalars['ID'];
};

export type MutationEditLabelArgs = {
  labelId: Scalars['ID'];
  organizationId: Scalars['ID'];
  request: EditLabelRequest;
};

export type MutationFastForwardReportArgs = {
  organizationId: Scalars['ID'];
  reportId: Scalars['ID'];
};

export type MutationGenerateDraftReportPdfArgs = {
  organizationId: Scalars['ID'];
  reportId: Scalars['ID'];
};

export type MutationInviteUserArgs = {
  organizationId: Scalars['ID'];
  request: InviteUserRequest;
};

export type MutationMergeReportTemplateArgs = {
  newReportTemplateId: Scalars['ID'];
  oldReportTemplateId: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export type MutationRemoveReportLabelArgs = {
  labelId: Scalars['ID'];
  organizationId: Scalars['ID'];
  reportId: Scalars['ID'];
};

export type MutationRemoveUserArgs = {
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationRestoreReportTemplateArgs = {
  organizationId: Scalars['ID'];
  reportTemplateVersionId: Scalars['ID'];
};

export type MutationSendDistributionArgs = {
  distributionId: Scalars['ID'];
  organizationId: Scalars['ID'];
  reportId: Scalars['ID'];
};

export type MutationShareReportArgs = {
  organizationId: Scalars['ID'];
  reportId: Scalars['ID'];
  request: ShareReportRequest;
};

export type MutationUnshareReportArgs = {
  organizationId: Scalars['ID'];
  reportId: Scalars['ID'];
  reportShareId: Scalars['ID'];
};

export type MutationUpdateDistributionArgs = {
  distributionId: Scalars['ID'];
  organizationId: Scalars['ID'];
  reportId: Scalars['ID'];
  request: Scalars['JSON'];
};

export type MutationUpdateIntegrationsArgs = {
  organizationId: Scalars['ID'];
  request: UpdateIntegrationsRequest;
};

export type MutationUpdateMachineAetArgs = {
  aet: Scalars['ID'];
  organizationId: Scalars['ID'];
  request: UpdateMachineAetRequest;
};

export type MutationUpdateMeasurementMappingArgs = {
  mappingId: Scalars['ID'];
  request: UpdateMeasurementMappingRequest;
};

export type MutationUpdateMeasurementRangeGroupArgs = {
  rangeGroupId: Scalars['ID'];
  request: UpdateMeasurementRangeGroupRequest;
};

export type MutationUpdateMeasurementVariantsArgs = {
  hash: Scalars['String'];
  request: UpdateMeasurementVariantsRequest;
};

export type MutationUpdateOrganizationArgs = {
  organizationId: Scalars['ID'];
  request: UpdateOrganizationRequest;
};

export type MutationUpdateProfileArgs = {
  request: UpdateProfileRequest;
};

export type MutationUpdateReportArgs = {
  organizationId: Scalars['ID'];
  reportId: Scalars['ID'];
  updateReportRequest: UpdateReportRequest;
};

export type MutationUpdateReportStatusArgs = {
  organizationId: Scalars['ID'];
  reportId: Scalars['ID'];
  request: UpdateReportStatusRequest;
};

export type MutationUpdateReportTemplateArgs = {
  organizationId: Scalars['ID'];
  reportTemplateId: Scalars['ID'];
  request: UpdateReportTemplateRequest;
};

export type MutationUpdateReportTemplateStatementSiteArgs = {
  organizationId: Scalars['ID'];
  reportTemplateId: Scalars['ID'];
  reportTemplateStatementSiteId: Scalars['ID'];
  request: UpdateReportTemplateStatementSiteRequest;
};

export type MutationUpdateReportTemplateStatementSitesArgs = {
  organizationId: Scalars['ID'];
  reportTemplateId: Scalars['ID'];
  request: UpdateReportTemplateStatementSitesRequest;
};

export type MutationUpdateReportTemplateVariableArgs = {
  organizationId: Scalars['ID'];
  reportTemplateId: Scalars['ID'];
  reportTemplateVariableId: Scalars['ID'];
  request: Scalars['JSON'];
};

export type MutationUpdateReportTypeArgs = {
  organizationId: Scalars['ID'];
  reportId: Scalars['ID'];
  request: UpdateReportTypeRequest;
};

export type MutationUpdateUserArgs = {
  organizationId: Scalars['ID'];
  request: UpdateUserRequest;
  userId: Scalars['ID'];
};

export type MutationUpdateWorklistEntryArgs = {
  organizationId: Scalars['ID'];
  request: UpdateWorklistEntryRequest;
  worklistEntryId: Scalars['ID'];
};

export type NumericalRange = {
  __typename?: 'NumericalRange';
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
};

export type NumericalRangeInput = {
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
};

export type Organization = {
  __typename?: 'Organization';
  addressLineOne?: Maybe<Scalars['String']>;
  addressLineTwo?: Maybe<Scalars['String']>;
  auditTrail: PaginatedAuditTrailEntries;
  autoComplete: AutoComplete;
  billing: Billing;
  contract?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dicomSettings: DicomSettings;
  distributionSettings: DistributionSettings;
  email?: Maybe<Scalars['String']>;
  featureFlags: Array<FeatureFlag>;
  id: Scalars['ID'];
  insights: Insights;
  integrations: Integrations;
  job?: Maybe<Job>;
  jobs: Array<Job>;
  label?: Maybe<Label>;
  labels: Array<Label>;
  logo?: Maybe<Scalars['String']>;
  machineStats: Array<OrganizationMachineStats>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  report?: Maybe<Report>;
  reportShare?: Maybe<ReportShare>;
  reportShares: PaginatedReportShares;
  reportTemplate?: Maybe<ReportTemplate>;
  reportTemplateDryRun: ReportTemplateDryRun;
  reportTemplates: PaginatedReportTemplates;
  reports: PaginatedReports;
  roles: Array<Role>;
  shortId: Scalars['String'];
  status: OrganizationStatus;
  stripeCustomerId?: Maybe<Scalars['ID']>;
  stripeSubscriptionId?: Maybe<Scalars['ID']>;
  timezone: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  users: Array<User>;
  worklist: Worklist;
};

export type OrganizationAuditTrailArgs = {
  request?: InputMaybe<GetPaginatedAuditTrailEntriesRequest>;
};

export type OrganizationInsightsArgs = {
  request: GetInsightsRequest;
};

export type OrganizationJobArgs = {
  jobId: Scalars['ID'];
};

export type OrganizationLabelArgs = {
  labelId: Scalars['ID'];
};

export type OrganizationReportArgs = {
  reportId: Scalars['ID'];
};

export type OrganizationReportShareArgs = {
  reportId: Scalars['ID'];
};

export type OrganizationReportSharesArgs = {
  request?: InputMaybe<GetPaginatedReportSharesRequest>;
};

export type OrganizationReportTemplateArgs = {
  reportTemplateId: Scalars['ID'];
};

export type OrganizationReportTemplateDryRunArgs = {
  reportId: Scalars['ID'];
  reportTemplateId: Scalars['ID'];
};

export type OrganizationReportTemplatesArgs = {
  request?: InputMaybe<GetPaginatedReportTemplatesRequest>;
};

export type OrganizationReportsArgs = {
  request?: InputMaybe<GetPaginatedReportsRequest>;
};

export type OrganizationMachineStats = {
  __typename?: 'OrganizationMachineStats';
  count: Scalars['Int'];
  machine: Machine;
  percentage: Scalars['Float'];
};

export type OrganizationMeasurementVariantStats = {
  __typename?: 'OrganizationMeasurementVariantStats';
  count: Scalars['Int'];
  id: Scalars['ID'];
  logo?: Maybe<Scalars['String']>;
  machine: Machine;
  name: Scalars['String'];
};

export type OrganizationMembership = {
  __typename?: 'OrganizationMembership';
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  permissions: Array<Scalars['String']>;
  roles: Array<Role>;
};

export enum OrganizationStatus {
  Active = 'ACTIVE',
  Trial = 'TRIAL',
}

export type PaginatedAuditTrailEntries = {
  __typename?: 'PaginatedAuditTrailEntries';
  pagination?: Maybe<TimestampPagination>;
  results: Array<AuditTrailEntry>;
};

export type PaginatedAutoCompleteEmailsRequest = {
  filter?: InputMaybe<Scalars['String']>;
  filterExclusions?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<AutoCompleteOrder>;
  pagination?: InputMaybe<PaginationRequest>;
};

export type PaginatedAutoCompleteReportVariablesRequest = {
  alias: Scalars['String'];
  filter?: InputMaybe<Scalars['String']>;
  filterExclusions?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<AutoCompleteOrder>;
  pagination?: InputMaybe<PaginationRequest>;
};

export type PaginatedAutoCompleteResponse = {
  __typename?: 'PaginatedAutoCompleteResponse';
  pagination: Pagination;
  results: Array<AutoCompleteResult>;
};

export type PaginatedHealthLinkPractitioners = {
  __typename?: 'PaginatedHealthLinkPractitioners';
  pagination?: Maybe<Pagination>;
  results: Array<HealthLinkPractitioner>;
};

export type PaginatedHealthLinkPractitionersRequest = {
  filter?: InputMaybe<HealthLinkPractitionersFilter>;
  pagination?: InputMaybe<PaginationRequest>;
};

export type PaginatedMeasurementMappingsAndVariants = {
  __typename?: 'PaginatedMeasurementMappingsAndVariants';
  mappingsAndVariants: Array<MeasurementMappingAndVariants>;
  pagination?: Maybe<Pagination>;
};

export type PaginatedMedicalObjectsProviders = {
  __typename?: 'PaginatedMedicalObjectsProviders';
  pagination?: Maybe<Pagination>;
  results: Array<MedicalObjectsIntegrationProvider>;
};

export type PaginatedMedicalObjectsProvidersRequest = {
  filter?: InputMaybe<MedicalObjectsProviderFilter>;
  pagination?: InputMaybe<PaginationRequest>;
};

export type PaginatedReportShares = {
  __typename?: 'PaginatedReportShares';
  pagination?: Maybe<Pagination>;
  shares: Array<ReportShare>;
};

export type PaginatedReportTemplates = {
  __typename?: 'PaginatedReportTemplates';
  pagination?: Maybe<Pagination>;
  reportTemplates: Array<ReportTemplate>;
};

export type PaginatedReports = {
  __typename?: 'PaginatedReports';
  pagination?: Maybe<Pagination>;
  reports: Array<Report>;
};

export type PaginatedUnusedMeasurementVariants = {
  __typename?: 'PaginatedUnusedMeasurementVariants';
  pagination?: Maybe<Pagination>;
  variants: Array<MeasurementVariant>;
};

export type PaginatedWorklistEntries = {
  __typename?: 'PaginatedWorklistEntries';
  pagination?: Maybe<Pagination>;
  results: Array<WorklistEntry>;
};

export type Pagination = {
  __typename?: 'Pagination';
  currentPage: Scalars['Int'];
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  remaining: Scalars['Int'];
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type PaginationRequest = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type PerformingPhysician = {
  __typename?: 'PerformingPhysician';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ProviderMapping = {
  __typename?: 'ProviderMapping';
  providerNumber: Scalars['String'];
  userId: Scalars['ID'];
};

export type ProviderMappingRequest = {
  providerNumber: Scalars['String'];
  userId: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  dicom: Dicom;
  organization?: Maybe<Organization>;
  organizations: Array<Organization>;
  user?: Maybe<User>;
};

export type QueryOrganizationArgs = {
  organizationId: Scalars['ID'];
};

export type Report = {
  __typename?: 'Report';
  attachments: Array<ReportAttachment>;
  changesetId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  distribution?: Maybe<Distribution>;
  distributions: Array<Distribution>;
  id: Scalars['ID'];
  labels: Array<Label>;
  organizationId: Scalars['ID'];
  pdfs: Array<ReportPdf>;
  related: Array<Report>;
  reportTemplate: ReportTemplate;
  shares: Array<ReportShare>;
  statementSites: Array<ReportStatementSite>;
  status: ReportStatus;
  study?: Maybe<Study>;
  updatedAt: Scalars['DateTime'];
  variables: Array<ReportVariable>;
};

export type ReportDistributionArgs = {
  distributionId: Scalars['ID'];
};

export type ReportVariablesArgs = {
  aliases?: InputMaybe<Array<Scalars['String']>>;
};

export type ReportAttachment = {
  __typename?: 'ReportAttachment';
  createdAt: Scalars['DateTime'];
  downloadUrl: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uploader?: Maybe<User>;
  viewUrl: Scalars['String'];
};

export type ReportChoiceVariable = {
  __typename?: 'ReportChoiceVariable';
  alias?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isTouched: Scalars['Boolean'];
  reportTemplateVersionId: Scalars['ID'];
  value?: Maybe<Scalars['JSON']>;
};

export type ReportMediaAttachmentVariable = {
  __typename?: 'ReportMediaAttachmentVariable';
  alias?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isTouched: Scalars['Boolean'];
  reportTemplateVersionId: Scalars['ID'];
  value?: Maybe<Scalars['JSON']>;
};

export type ReportPdf = {
  __typename?: 'ReportPdf';
  downloadUrl: Scalars['String'];
  version: Scalars['Int'];
  viewUrl: Scalars['String'];
};

export type ReportShare = {
  __typename?: 'ReportShare';
  createdAt: Scalars['DateTime'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isExpired: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  report?: Maybe<Report>;
  reportId: Scalars['ID'];
  shareeEmail: Scalars['String'];
  shareeId: Scalars['ID'];
  sharerEmail: Scalars['String'];
  sharerId: Scalars['ID'];
  status: ReportShareStatus;
  updatedAt: Scalars['DateTime'];
};

export enum ReportShareStatus {
  Failed = 'FAILED',
  Processing = 'PROCESSING',
  Sent = 'SENT',
}

export type ReportStatement = {
  __typename?: 'ReportStatement';
  id: Scalars['ID'];
  value: Scalars['String'];
};

export type ReportStatementSite = {
  __typename?: 'ReportStatementSite';
  id: Scalars['ID'];
  statements: Array<ReportStatement>;
};

export type ReportStaticVariable = {
  __typename?: 'ReportStaticVariable';
  alias?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isTouched: Scalars['Boolean'];
  reportTemplateVersionId: Scalars['ID'];
  units?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['JSON']>;
};

export enum ReportStatus {
  AwaitingReview = 'AWAITING_REVIEW',
  Finalized = 'FINALIZED',
  Unreported = 'UNREPORTED',
}

export enum ReportStatusFilter {
  AwaitingReview = 'AWAITING_REVIEW',
  Finalized = 'FINALIZED',
  Unreported = 'UNREPORTED',
}

export type ReportTemplate = {
  __typename?: 'ReportTemplate';
  billingActionId?: Maybe<BillingActionType>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isLatest: Scalars['Boolean'];
  isMaster: Scalars['Boolean'];
  labelColor: Scalars['String'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  reportNodes: Scalars['JSON'];
  statementSites: Array<ReportTemplateStatementSite>;
  status: ReportTemplateStatus;
  updatedAt: Scalars['DateTime'];
  variables: Array<ReportTemplateVariable>;
  versionId: Scalars['ID'];
  versions: Array<ReportTemplate>;
};

export type ReportTemplateChoiceVariable = {
  __typename?: 'ReportTemplateChoiceVariable';
  alias?: Maybe<Scalars['String']>;
  category: Scalars['String'];
  choices: Array<ReportTemplateVariableChoice>;
  controlType: VariableControlType;
  defaultValue?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  isEditable: Scalars['Boolean'];
  label: Scalars['String'];
  mappings: Array<MeasurementMapping>;
  site: Scalars['String'];
  source: ReportTemplateVariableSource;
};

export type ReportTemplateDryRun = {
  __typename?: 'ReportTemplateDryRun';
  measurements: Array<Measurement>;
  reportId: Scalars['ID'];
  variables: Array<ReportVariable>;
};

export type ReportTemplateMediaAttachmentVariable = {
  __typename?: 'ReportTemplateMediaAttachmentVariable';
  alias?: Maybe<Scalars['String']>;
  category: Scalars['String'];
  defaultValue?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  isEditable: Scalars['Boolean'];
  label: Scalars['String'];
  mappings: Array<MeasurementMapping>;
  source: ReportTemplateVariableSource;
};

export enum ReportTemplateMediaAttachmentVariableType {
  Chart = 'CHART',
  ImageUpload = 'IMAGE_UPLOAD',
  StudyImage = 'STUDY_IMAGE',
}

export type ReportTemplateStatement = {
  __typename?: 'ReportTemplateStatement';
  default: Scalars['Boolean'];
  id: Scalars['ID'];
  value: Scalars['String'];
};

export type ReportTemplateStatementSite = {
  __typename?: 'ReportTemplateStatementSite';
  id: Scalars['ID'];
  name: Scalars['String'];
  statements: Array<ReportTemplateStatement>;
};

export type ReportTemplateStaticVariable = {
  __typename?: 'ReportTemplateStaticVariable';
  alias?: Maybe<Scalars['String']>;
  category: Scalars['String'];
  controlType: VariableControlType;
  defaultValue?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  isEditable: Scalars['Boolean'];
  label: Scalars['String'];
  mappings: Array<MeasurementMapping>;
  precision?: Maybe<Scalars['Int']>;
  site: Scalars['String'];
  source: ReportTemplateVariableSource;
  units?: Maybe<Scalars['String']>;
};

export enum ReportTemplateStatus {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
}

export type ReportTemplateVariable =
  | ReportTemplateChoiceVariable
  | ReportTemplateMediaAttachmentVariable
  | ReportTemplateStaticVariable
  | ReportTemplateWallMotionVariable;

export type ReportTemplateVariableChoice = {
  __typename?: 'ReportTemplateVariableChoice';
  default: Scalars['Boolean'];
  id: Scalars['ID'];
  label: Scalars['String'];
  value: Scalars['String'];
};

export enum ReportTemplateVariableSource {
  Dynamic = 'DYNAMIC',
  Static = 'STATIC',
}

export type ReportTemplateWallMotionVariable = {
  __typename?: 'ReportTemplateWallMotionVariable';
  alias?: Maybe<Scalars['String']>;
  category: Scalars['String'];
  defaultValue?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  isEditable: Scalars['Boolean'];
  label: Scalars['String'];
  mappings: Array<MeasurementMapping>;
  site: Scalars['String'];
  source: ReportTemplateVariableSource;
  variant: WallMotionVariant;
};

export type ReportTypeFilter = {
  reportTemplateId?: InputMaybe<Scalars['String']>;
};

export type ReportUploadedOverTimeInsights = {
  __typename?: 'ReportUploadedOverTimeInsights';
  reportCount: Scalars['Int'];
  reportCreatedAtDay: Scalars['DateTime'];
  reportTemplateId: Scalars['ID'];
  reportTemplateLabelColor: Scalars['String'];
  reportTemplateName: Scalars['String'];
};

export type ReportVariable =
  | ReportChoiceVariable
  | ReportMediaAttachmentVariable
  | ReportStaticVariable
  | ReportWallMotionVariable;

export type ReportWallMotionVariable = {
  __typename?: 'ReportWallMotionVariable';
  alias?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isTouched: Scalars['Boolean'];
  reportTemplateVersionId: Scalars['ID'];
  value?: Maybe<Scalars['JSON']>;
};

export type Role = {
  __typename?: 'Role';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions: Array<Scalars['String']>;
};

export type SequenceOfUltrasoundRegion = {
  __typename?: 'SequenceOfUltrasoundRegion';
  physicalDeltaX: Scalars['Float'];
  physicalDeltaY: Scalars['Float'];
  physicalUnitsXDirection: Scalars['Int'];
  physicalUnitsYDirection: Scalars['Int'];
  regionFlags: Scalars['Int'];
  regionLocationMaxX1: Scalars['Float'];
  regionLocationMaxY1: Scalars['Float'];
  regionLocationMinX0: Scalars['Float'];
  regionLocationMinY0: Scalars['Float'];
};

export type ShareReportRequest = {
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  message?: InputMaybe<Scalars['String']>;
  toEmail: Scalars['String'];
};

export enum Stage {
  Post = 'POST',
  Rest = 'REST',
  Unknown = 'UNKNOWN',
}

export type Study = {
  __typename?: 'Study';
  createdAt: Scalars['DateTime'];
  dicom: StudyDicom;
  healthcareDatasetId: Scalars['String'];
  healthcareRegion: Scalars['String'];
  id: Scalars['ID'];
  instance?: Maybe<Instance>;
  instances: Array<Instance>;
  measurements: Array<Measurement>;
  organizationId: Scalars['ID'];
  reportId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type StudyInstanceArgs = {
  id: Scalars['ID'];
  seriesId: Scalars['ID'];
};

export type StudyDicom = {
  __typename?: 'StudyDicom';
  dob?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
  patientName?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  studyDate?: Maybe<Scalars['DateTime']>;
  tags?: Maybe<Scalars['JSON']>;
};

export type StudyDicomTagsArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>;
};

export type SubscriptionItem = {
  __typename?: 'SubscriptionItem';
  id: Scalars['ID'];
  product: Product;
};

export type TimestampPagination = {
  __typename?: 'TimestampPagination';
  firstSeen?: Maybe<Scalars['DateTime']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  lastSeen?: Maybe<Scalars['DateTime']>;
  limit: Scalars['Int'];
  total?: Maybe<Scalars['Int']>;
};

export type TimestampPaginationRequest = {
  firstSeen?: InputMaybe<Scalars['DateTime']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type UpdateDefaultDistribution = {
  payload: Scalars['JSON'];
  trigger: DistributionTrigger;
};

export type UpdateDistributionSettingsRequest = {
  defaultDistribution?: InputMaybe<UpdateDefaultDistribution>;
  defaultDistributionType?: InputMaybe<DistributionType>;
};

export type UpdateFaxIntegrationRequest = {
  enabled?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateHealthLinkIntegrationRequest = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  hl7Overrides?: InputMaybe<Hl7OverrideRequest>;
  providerMappings: Array<ProviderMappingRequest>;
};

export type UpdateIntegrationsRequest = {
  fax?: InputMaybe<UpdateFaxIntegrationRequest>;
  healthLink?: InputMaybe<UpdateHealthLinkIntegrationRequest>;
  medicalObjects?: InputMaybe<UpdateMedicalObjectsIntegrationRequest>;
};

export type UpdateMachineAetRequest = {
  aet?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateMeasurementMappingRequest = {
  alias?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  precision?: InputMaybe<Scalars['Int']>;
  properties?: InputMaybe<Array<UpdateMeasurementPropertyRequest>>;
  rangeGroupId?: InputMaybe<Scalars['ID']>;
  shortName?: InputMaybe<Scalars['String']>;
  site?: InputMaybe<Scalars['String']>;
  units?: InputMaybe<Scalars['String']>;
};

export type UpdateMeasurementPropertyRequest = {
  name: Scalars['String'];
  value?: InputMaybe<Scalars['JSON']>;
};

export type UpdateMeasurementRangeGroupRequest = {
  label?: InputMaybe<Scalars['String']>;
  precision?: InputMaybe<Scalars['Int']>;
  ranges: Array<UpdateMeasurementRangeRequest>;
  sources?: InputMaybe<Array<MeasurementRangeGroupSourceInput>>;
  units?: InputMaybe<Scalars['String']>;
};

export type UpdateMeasurementRangeRequest = {
  age?: InputMaybe<NumericalRangeInput>;
  label?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<MeasurementRangeLevel>;
  measurement?: InputMaybe<NumericalRangeInput>;
  sex?: InputMaybe<Scalars['String']>;
};

export type UpdateMeasurementVariantsRequest = {
  mappingId?: InputMaybe<Scalars['ID']>;
};

export type UpdateMedicalObjectsIntegrationRequest = {
  capricornUrl?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  hl7Overrides?: InputMaybe<Hl7OverrideRequest>;
  practiceId?: InputMaybe<Scalars['String']>;
  practiceName?: InputMaybe<Scalars['String']>;
  providerMappings: Array<ProviderMappingRequest>;
};

export type UpdateOrganizationRequest = {
  addressLineOne?: InputMaybe<Scalars['String']>;
  addressLineTwo?: InputMaybe<Scalars['String']>;
  contract?: InputMaybe<Scalars['String']>;
  distributionSettings?: InputMaybe<UpdateDistributionSettingsRequest>;
  email?: InputMaybe<Scalars['String']>;
  featureFlags?: InputMaybe<Array<FeatureFlag>>;
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<OrganizationStatus>;
  stripeCustomerId?: InputMaybe<Scalars['ID']>;
  stripeSubscriptionId?: InputMaybe<Scalars['ID']>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type UpdateProfileRequest = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<UpdateUserSettingsRequest>;
};

export type UpdateReportRequest = {
  changesetId: Scalars['String'];
  existingChangesetId?: InputMaybe<Scalars['String']>;
  reportTemplateVersionId: Scalars['ID'];
  statementSites?: InputMaybe<Array<UpdateStatementSiteRequest>>;
  takeOwnership: Scalars['Boolean'];
  variables?: InputMaybe<Array<UpdateVariableRequest>>;
};

export type UpdateReportStatusMedicalObjectsRequest = {
  providerNumber: Scalars['String'];
};

export type UpdateReportStatusRequest = {
  status: ReportStatus;
};

export type UpdateReportTemplateRequest = {
  billingActionId?: InputMaybe<BillingActionType>;
  labelColor?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  reportNodes?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<ReportTemplateStatus>;
};

export type UpdateReportTemplateStatementRequest = {
  default: Scalars['Boolean'];
  id: Scalars['ID'];
  value: Scalars['String'];
};

export type UpdateReportTemplateStatementSiteRequest = {
  id: Scalars['String'];
  name: Scalars['String'];
  statements: Array<UpdateReportTemplateStatementRequest>;
};

export type UpdateReportTemplateStatementSitesRequest = {
  reportTemplateStatementSites: Array<UpdateReportTemplateStatementSiteRequest>;
};

export type UpdateReportTemplateVariableChoice = {
  default: Scalars['Boolean'];
  id: Scalars['ID'];
  label: Scalars['String'];
  value: Scalars['String'];
};

export type UpdateReportTypeRequest = {
  reportTemplateId: Scalars['ID'];
};

export type UpdateStatementRequest = {
  id: Scalars['ID'];
  value: Scalars['String'];
};

export type UpdateStatementSiteRequest = {
  id: Scalars['ID'];
  statements: Array<UpdateStatementRequest>;
};

export type UpdateUserRequest = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  roleIds?: InputMaybe<Array<Scalars['String']>>;
  settings?: InputMaybe<UpdateUserSettingsRequest>;
};

export type UpdateUserSettingsRequest = {
  defaultOrganization?: InputMaybe<Scalars['String']>;
  finalizeBehavior: FinalizeBehavior;
};

export type UpdateVariableRequest = {
  id: Scalars['ID'];
  value?: InputMaybe<Scalars['JSON']>;
};

export type UpdateWorklistEntryRequest = {
  machineAetId?: InputMaybe<Scalars['ID']>;
  patientDob?: InputMaybe<Scalars['DateTime']>;
  patientFirstName?: InputMaybe<Scalars['String']>;
  patientHeight?: InputMaybe<Scalars['Float']>;
  patientId?: InputMaybe<Scalars['String']>;
  patientLastName?: InputMaybe<Scalars['String']>;
  patientSex?: InputMaybe<Scalars['String']>;
  patientWeight?: InputMaybe<Scalars['Int']>;
  performingPhysicianId?: InputMaybe<Scalars['ID']>;
  reportTemplateId?: InputMaybe<Scalars['ID']>;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  extraPermissions: Array<Scalars['String']>;
  firebaseId: Scalars['ID'];
  id: Scalars['ID'];
  intercomHash: Scalars['String'];
  name: Scalars['String'];
  organizationMemberships: Array<OrganizationMembership>;
  picture?: Maybe<Scalars['String']>;
  settings: UserSettings;
};

export type UserSettings = {
  __typename?: 'UserSettings';
  defaultOrganization?: Maybe<Scalars['ID']>;
  finalizeBehavior: FinalizeBehavior;
};

export enum VariableControlType {
  Checkbox = 'CHECKBOX',
  Datepicker = 'DATEPICKER',
  Input = 'INPUT',
  Multiselect = 'MULTISELECT',
  Radio = 'RADIO',
  Select = 'SELECT',
}

export enum View {
  Ap2 = 'AP2',
  Ap3 = 'AP3',
  Ap4 = 'AP4',
  Plax = 'PLAX',
  PsaxApical = 'PSAX_APICAL',
  PsaxBasal = 'PSAX_BASAL',
  PsaxMid = 'PSAX_MID',
  Unknown = 'UNKNOWN',
}

export enum WallMotionVariant {
  Ase_4Choice = 'ASE_4_CHOICE',
  Legacy_7Choice = 'LEGACY_7_CHOICE',
}

export type Worklist = {
  __typename?: 'Worklist';
  entries: PaginatedWorklistEntries;
  entry?: Maybe<WorklistEntry>;
  machineAets: Array<MachineAet>;
};

export type WorklistEntriesArgs = {
  request?: InputMaybe<GetPaginatedWorklistEntriesRequest>;
};

export type WorklistEntryArgs = {
  id: Scalars['ID'];
};

export type WorklistEntry = {
  __typename?: 'WorklistEntry';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  machineAet?: Maybe<MachineAet>;
  modality: Scalars['String'];
  organizationId: Scalars['ID'];
  patientDob: Scalars['DateTime'];
  patientFirstName: Scalars['String'];
  patientHeight?: Maybe<Scalars['Float']>;
  patientId: Scalars['String'];
  patientLastName: Scalars['String'];
  patientSex: Scalars['String'];
  patientWeight?: Maybe<Scalars['Int']>;
  performingPhysician: PerformingPhysician;
  reportId?: Maybe<Scalars['String']>;
  reportTemplate: ReportTemplate;
  status: WorklistEntryStatus;
  studyInstanceUID: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export enum WorklistEntryStatus {
  Completed = 'COMPLETED',
  Scheduled = 'SCHEDULED',
}

export type AddReportLabelMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportId: Types.Scalars['ID'];
  labelId: Types.Scalars['ID'];
}>;

export type AddReportLabelMutation = {
  __typename?: 'Mutation';
  addReportLabel: {
    __typename?: 'Label';
    id: string;
    name: string;
    description?: string | null;
    color: string;
    createdAt: DateTime;
    updatedAt: DateTime;
  };
};

export type AddReportTemplateMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  request: Types.AddReportTemplateRequest;
}>;

export type AddReportTemplateMutation = {
  __typename?: 'Mutation';
  addReportTemplate: {
    __typename?: 'ReportTemplate';
    id: string;
    name: string;
    reportNodes: any;
    isLatest: boolean;
    status: Types.ReportTemplateStatus;
    labelColor: string;
    createdAt: DateTime;
    updatedAt: DateTime;
    statementSites: Array<{
      __typename?: 'ReportTemplateStatementSite';
      id: string;
      name: string;
      statements: Array<{
        __typename?: 'ReportTemplateStatement';
        id: string;
        value: string;
        default: boolean;
      }>;
    }>;
    variables: Array<
      | {
          __typename: 'ReportTemplateChoiceVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          controlType: Types.VariableControlType;
          defaultValue?: any | null;
          choices: Array<{
            __typename?: 'ReportTemplateVariableChoice';
            id: string;
            label: string;
            value: string;
            default: boolean;
          }>;
        }
      | {
          __typename: 'ReportTemplateMediaAttachmentVariable';
          id: string;
          alias?: string | null;
          source: Types.ReportTemplateVariableSource;
          label: string;
          category: string;
        }
      | {
          __typename: 'ReportTemplateStaticVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          units?: string | null;
          precision?: number | null;
          controlType: Types.VariableControlType;
          defaultValue?: any | null;
        }
      | {
          __typename: 'ReportTemplateWallMotionVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          variant: Types.WallMotionVariant;
        }
    >;
  };
};

export type AddReportTemplateVariableMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportTemplateId: Types.Scalars['ID'];
  request: Types.Scalars['JSON'];
}>;

export type AddReportTemplateVariableMutation = {
  __typename?: 'Mutation';
  addReportTemplateVariable: {
    __typename?: 'ReportTemplate';
    id: string;
    versionId: string;
    name: string;
    reportNodes: any;
    isLatest: boolean;
    status: Types.ReportTemplateStatus;
    labelColor: string;
    createdAt: DateTime;
    updatedAt: DateTime;
    statementSites: Array<{
      __typename?: 'ReportTemplateStatementSite';
      id: string;
      name: string;
      statements: Array<{
        __typename?: 'ReportTemplateStatement';
        id: string;
        value: string;
        default: boolean;
      }>;
    }>;
    variables: Array<
      | {
          __typename: 'ReportTemplateChoiceVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          controlType: Types.VariableControlType;
          defaultValue?: any | null;
          choices: Array<{
            __typename?: 'ReportTemplateVariableChoice';
            id: string;
            label: string;
            value: string;
            default: boolean;
          }>;
        }
      | {
          __typename: 'ReportTemplateMediaAttachmentVariable';
          id: string;
          alias?: string | null;
          source: Types.ReportTemplateVariableSource;
          label: string;
          category: string;
        }
      | {
          __typename: 'ReportTemplateStaticVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          units?: string | null;
          precision?: number | null;
          controlType: Types.VariableControlType;
          defaultValue?: any | null;
        }
      | {
          __typename: 'ReportTemplateWallMotionVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          variant: Types.WallMotionVariant;
        }
    >;
  };
};

export type AddReportTemplateVariablesMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportTemplateId: Types.Scalars['ID'];
  request: Types.Scalars['JSON'];
}>;

export type AddReportTemplateVariablesMutation = {
  __typename?: 'Mutation';
  addReportTemplateVariables: {
    __typename?: 'ReportTemplate';
    id: string;
    versionId: string;
    name: string;
    reportNodes: any;
    isLatest: boolean;
    status: Types.ReportTemplateStatus;
    labelColor: string;
    createdAt: DateTime;
    updatedAt: DateTime;
    statementSites: Array<{
      __typename?: 'ReportTemplateStatementSite';
      id: string;
      name: string;
      statements: Array<{
        __typename?: 'ReportTemplateStatement';
        id: string;
        value: string;
        default: boolean;
      }>;
    }>;
    variables: Array<
      | {
          __typename: 'ReportTemplateChoiceVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          controlType: Types.VariableControlType;
          defaultValue?: any | null;
          choices: Array<{
            __typename?: 'ReportTemplateVariableChoice';
            id: string;
            label: string;
            value: string;
            default: boolean;
          }>;
        }
      | {
          __typename: 'ReportTemplateMediaAttachmentVariable';
          id: string;
          alias?: string | null;
          source: Types.ReportTemplateVariableSource;
          label: string;
          category: string;
        }
      | {
          __typename: 'ReportTemplateStaticVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          units?: string | null;
          precision?: number | null;
          controlType: Types.VariableControlType;
          defaultValue?: any | null;
        }
      | {
          __typename: 'ReportTemplateWallMotionVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          variant: Types.WallMotionVariant;
        }
    >;
  };
};

export type CreateAutoCompleteExclusionMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  request: Types.CreateAutoCompleteExclusionRequest;
}>;

export type CreateAutoCompleteExclusionMutation = {
  __typename?: 'Mutation';
  createAutoCompleteExclusion: boolean;
};

export type CreateDistributionMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportId: Types.Scalars['ID'];
  request: Types.Scalars['JSON'];
}>;

export type CreateDistributionMutation = {
  __typename?: 'Mutation';
  createDistribution: {
    __typename?: 'Distribution';
    id: string;
    organizationId: string;
    reportId: string;
  };
};

export type CreateLabelMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  request: Types.CreateLabelRequest;
}>;

export type CreateLabelMutation = {
  __typename?: 'Mutation';
  createLabel: {
    __typename?: 'Label';
    id: string;
    organizationId: string;
    name: string;
    description?: string | null;
    color: string;
    createdAt: DateTime;
    updatedAt: DateTime;
  };
};

export type CreateMeasurementMappingMutationVariables = Types.Exact<{
  request: Types.CreateMeasurementMappingRequest;
}>;

export type CreateMeasurementMappingMutation = {
  __typename?: 'Mutation';
  createMeasurementMapping: {
    __typename?: 'MeasurementMapping';
    id: string;
    name: string;
    site?: string | null;
    units?: string | null;
    precision?: number | null;
    properties: Array<{ __typename?: 'MeasurementProperty'; name: string; value?: any | null }>;
  };
};

export type CreateMeasurementRangeGroupMutationVariables = Types.Exact<{
  request: Types.CreateMeasurementRangeGroupRequest;
}>;

export type CreateMeasurementRangeGroupMutation = {
  __typename?: 'Mutation';
  createMeasurementRangeGroup: {
    __typename?: 'MeasurementRangeGroup';
    id: string;
    label: string;
    ranges: Array<{
      __typename?: 'MeasurementRange';
      label: string;
      sex?: string | null;
      age?: {
        __typename?: 'NumericalRange';
        lt?: number | null;
        lte?: number | null;
        gt?: number | null;
        gte?: number | null;
      } | null;
      measurement: {
        __typename?: 'NumericalRange';
        lt?: number | null;
        lte?: number | null;
        gt?: number | null;
        gte?: number | null;
      };
    }>;
  };
};

export type CreateOrganizationMutationVariables = Types.Exact<{
  createOrganizationRequest: Types.CreateOrganizationRequest;
}>;

export type CreateOrganizationMutation = {
  __typename?: 'Mutation';
  createOrganization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    addressLineOne?: string | null;
    addressLineTwo?: string | null;
    email?: string | null;
    phone?: string | null;
    logo?: string | null;
    timezone: string;
    featureFlags: Array<Types.FeatureFlag>;
    roles: Array<{
      __typename?: 'Role';
      id: string;
      name: string;
      description?: string | null;
      permissions: Array<string>;
    }>;
  };
};

export type CreatePasswordChangeTicketUrlMutationVariables = Types.Exact<{ [key: string]: never }>;

export type CreatePasswordChangeTicketUrlMutation = {
  __typename?: 'Mutation';
  createPasswordChangeTicketUrl: string;
};

export type CreateReportMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  createReportRequest: Types.CreateReportRequest;
}>;

export type CreateReportMutation = {
  __typename?: 'Mutation';
  createReport: { __typename?: 'Report'; id: string; organizationId: string };
};

export type CreateReportsExportJobMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  request: Types.CreateReportsExportJobRequest;
}>;

export type CreateReportsExportJobMutation = {
  __typename?: 'Mutation';
  createReportsExportJob: {
    __typename?: 'Job';
    id: string;
    type: Types.JobType;
    status: Types.JobStatus;
    progress: number;
    queuedAt: DateTime;
    startedAt?: DateTime | null;
    finishedAt?: DateTime | null;
    payload?: any | null;
  };
};

export type CreateWorklistEntryMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  request: Types.CreateWorklistEntryRequest;
}>;

export type CreateWorklistEntryMutation = {
  __typename?: 'Mutation';
  createWorklistEntry: {
    __typename?: 'WorklistEntry';
    id: string;
    organizationId: string;
    studyInstanceUID: string;
    patientId: string;
    patientFirstName: string;
    patientLastName: string;
    patientDob: DateTime;
    patientSex: string;
    patientHeight?: number | null;
    patientWeight?: number | null;
    modality: string;
    createdAt: DateTime;
    updatedAt: DateTime;
    machineAet?: { __typename?: 'MachineAet'; id: string; aet: string; name: string } | null;
    reportTemplate: { __typename?: 'ReportTemplate'; id: string; name: string };
    performingPhysician: { __typename?: 'PerformingPhysician'; id: string; name: string };
  };
};

export type DeleteAutoCompleteExclusionMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  request: Types.DeleteAutoCompleteExclusionRequest;
}>;

export type DeleteAutoCompleteExclusionMutation = {
  __typename?: 'Mutation';
  deleteAutoCompleteExclusion: boolean;
};

export type DeleteDistributionMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportId: Types.Scalars['ID'];
  distributionId: Types.Scalars['ID'];
  request: Types.Scalars['JSON'];
}>;

export type DeleteDistributionMutation = { __typename?: 'Mutation'; deleteDistribution: boolean };

export type DeleteLabelMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  labelId: Types.Scalars['ID'];
}>;

export type DeleteLabelMutation = { __typename?: 'Mutation'; deleteLabel: boolean };

export type DeleteMeasurementMappingMutationVariables = Types.Exact<{
  mappingId: Types.Scalars['ID'];
}>;

export type DeleteMeasurementMappingMutation = {
  __typename?: 'Mutation';
  deleteMeasurementMapping: boolean;
};

export type DeleteMeasurementRangeGroupMutationVariables = Types.Exact<{
  rangeGroupId: Types.Scalars['ID'];
}>;

export type DeleteMeasurementRangeGroupMutation = {
  __typename?: 'Mutation';
  deleteMeasurementRangeGroup: boolean;
};

export type DeleteOrganizationMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
}>;

export type DeleteOrganizationMutation = { __typename?: 'Mutation'; deleteOrganization: boolean };

export type DeleteReportMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportId: Types.Scalars['ID'];
}>;

export type DeleteReportMutation = { __typename?: 'Mutation'; deleteReport: boolean };

export type DeleteReportAttachmentMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportId: Types.Scalars['ID'];
  reportAttachmentId: Types.Scalars['ID'];
}>;

export type DeleteReportAttachmentMutation = {
  __typename?: 'Mutation';
  deleteReportAttachment: boolean;
};

export type DeleteReportTemplateVariableMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportTemplateId: Types.Scalars['ID'];
  reportTemplateVariableId: Types.Scalars['ID'];
}>;

export type DeleteReportTemplateVariableMutation = {
  __typename?: 'Mutation';
  deleteReportTemplateVariable: {
    __typename?: 'ReportTemplate';
    id: string;
    versionId: string;
    name: string;
    reportNodes: any;
    isLatest: boolean;
    status: Types.ReportTemplateStatus;
    labelColor: string;
    createdAt: DateTime;
    updatedAt: DateTime;
    statementSites: Array<{
      __typename?: 'ReportTemplateStatementSite';
      id: string;
      name: string;
      statements: Array<{
        __typename?: 'ReportTemplateStatement';
        id: string;
        value: string;
        default: boolean;
      }>;
    }>;
    variables: Array<
      | {
          __typename: 'ReportTemplateChoiceVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          controlType: Types.VariableControlType;
          defaultValue?: any | null;
          choices: Array<{
            __typename?: 'ReportTemplateVariableChoice';
            id: string;
            label: string;
            value: string;
            default: boolean;
          }>;
        }
      | {
          __typename: 'ReportTemplateMediaAttachmentVariable';
          id: string;
          alias?: string | null;
          source: Types.ReportTemplateVariableSource;
          label: string;
          category: string;
        }
      | {
          __typename: 'ReportTemplateStaticVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          units?: string | null;
          precision?: number | null;
          controlType: Types.VariableControlType;
          defaultValue?: any | null;
        }
      | {
          __typename: 'ReportTemplateWallMotionVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          variant: Types.WallMotionVariant;
        }
    >;
  };
};

export type DeleteReportTemplateVariablesMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportTemplateId: Types.Scalars['ID'];
  reportTemplateVariableIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;

export type DeleteReportTemplateVariablesMutation = {
  __typename?: 'Mutation';
  deleteReportTemplateVariables: {
    __typename?: 'ReportTemplate';
    id: string;
    versionId: string;
    name: string;
    reportNodes: any;
    isLatest: boolean;
    status: Types.ReportTemplateStatus;
    labelColor: string;
    createdAt: DateTime;
    updatedAt: DateTime;
    statementSites: Array<{
      __typename?: 'ReportTemplateStatementSite';
      id: string;
      name: string;
      statements: Array<{
        __typename?: 'ReportTemplateStatement';
        id: string;
        value: string;
        default: boolean;
      }>;
    }>;
    variables: Array<
      | {
          __typename: 'ReportTemplateChoiceVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          controlType: Types.VariableControlType;
          defaultValue?: any | null;
          choices: Array<{
            __typename?: 'ReportTemplateVariableChoice';
            id: string;
            label: string;
            value: string;
            default: boolean;
          }>;
        }
      | {
          __typename: 'ReportTemplateMediaAttachmentVariable';
          id: string;
          alias?: string | null;
          source: Types.ReportTemplateVariableSource;
          label: string;
          category: string;
        }
      | {
          __typename: 'ReportTemplateStaticVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          units?: string | null;
          precision?: number | null;
          controlType: Types.VariableControlType;
          defaultValue?: any | null;
        }
      | {
          __typename: 'ReportTemplateWallMotionVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          variant: Types.WallMotionVariant;
        }
    >;
  };
};

export type DeleteReportsMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  request: Types.DeleteReportsRequest;
}>;

export type DeleteReportsMutation = { __typename?: 'Mutation'; deleteReports: boolean };

export type DeleteWorklistEntryMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  worklistEntryId: Types.Scalars['ID'];
}>;

export type DeleteWorklistEntryMutation = { __typename?: 'Mutation'; deleteWorklistEntry: boolean };

export type EditLabelMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  labelId: Types.Scalars['ID'];
  request: Types.EditLabelRequest;
}>;

export type EditLabelMutation = {
  __typename?: 'Mutation';
  editLabel: {
    __typename?: 'Label';
    id: string;
    name: string;
    description?: string | null;
    color: string;
    createdAt: DateTime;
    updatedAt: DateTime;
  };
};

export type FastForwardReportMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportId: Types.Scalars['ID'];
}>;

export type FastForwardReportMutation = {
  __typename?: 'Mutation';
  fastForwardReport?: { __typename?: 'Report'; id: string; organizationId: string } | null;
};

export type GenerateDraftReportPdfMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportId: Types.Scalars['ID'];
}>;

export type GenerateDraftReportPdfMutation = {
  __typename?: 'Mutation';
  generateDraftReportPdf: { __typename?: 'ReportPdf'; viewUrl: string; downloadUrl: string };
};

export type InviteUserMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  inviteUserRequest: Types.InviteUserRequest;
}>;

export type InviteUserMutation = {
  __typename?: 'Mutation';
  inviteUser: {
    __typename?: 'User';
    id: string;
    name: string;
    email: string;
    picture?: string | null;
    organizationMemberships: Array<{
      __typename?: 'OrganizationMembership';
      roles: Array<{ __typename?: 'Role'; id: string; name: string }>;
    }>;
  };
};

export type MergeReportTemplateMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  oldReportTemplateId: Types.Scalars['ID'];
  newReportTemplateId: Types.Scalars['ID'];
}>;

export type MergeReportTemplateMutation = {
  __typename?: 'Mutation';
  mergeReportTemplate: {
    __typename?: 'ReportTemplate';
    id: string;
    versionId: string;
    name: string;
    reportNodes: any;
    isLatest: boolean;
    status: Types.ReportTemplateStatus;
    labelColor: string;
    createdAt: DateTime;
    updatedAt: DateTime;
    statementSites: Array<{
      __typename?: 'ReportTemplateStatementSite';
      id: string;
      name: string;
      statements: Array<{
        __typename?: 'ReportTemplateStatement';
        id: string;
        value: string;
        default: boolean;
      }>;
    }>;
    variables: Array<
      | {
          __typename: 'ReportTemplateChoiceVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          controlType: Types.VariableControlType;
          defaultValue?: any | null;
          choices: Array<{
            __typename?: 'ReportTemplateVariableChoice';
            id: string;
            label: string;
            value: string;
            default: boolean;
          }>;
        }
      | {
          __typename: 'ReportTemplateMediaAttachmentVariable';
          id: string;
          alias?: string | null;
          source: Types.ReportTemplateVariableSource;
          label: string;
          category: string;
        }
      | {
          __typename: 'ReportTemplateStaticVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          units?: string | null;
          precision?: number | null;
          controlType: Types.VariableControlType;
          defaultValue?: any | null;
        }
      | {
          __typename: 'ReportTemplateWallMotionVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          variant: Types.WallMotionVariant;
        }
    >;
  };
};

export type RemoveReportLabelMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportId: Types.Scalars['ID'];
  labelId: Types.Scalars['ID'];
}>;

export type RemoveReportLabelMutation = { __typename?: 'Mutation'; removeReportLabel: boolean };

export type RemoveUserMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  userId: Types.Scalars['ID'];
}>;

export type RemoveUserMutation = { __typename?: 'Mutation'; removeUser: boolean };

export type RestoreReportTemplateMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportTemplateVersionId: Types.Scalars['ID'];
}>;

export type RestoreReportTemplateMutation = {
  __typename?: 'Mutation';
  restoreReportTemplate: {
    __typename?: 'ReportTemplate';
    id: string;
    versionId: string;
    name: string;
    reportNodes: any;
    isLatest: boolean;
    status: Types.ReportTemplateStatus;
    labelColor: string;
    createdAt: DateTime;
    updatedAt: DateTime;
    statementSites: Array<{
      __typename?: 'ReportTemplateStatementSite';
      id: string;
      name: string;
      statements: Array<{
        __typename?: 'ReportTemplateStatement';
        id: string;
        value: string;
        default: boolean;
      }>;
    }>;
    variables: Array<
      | {
          __typename: 'ReportTemplateChoiceVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          controlType: Types.VariableControlType;
          defaultValue?: any | null;
          choices: Array<{
            __typename?: 'ReportTemplateVariableChoice';
            id: string;
            label: string;
            value: string;
            default: boolean;
          }>;
        }
      | {
          __typename: 'ReportTemplateMediaAttachmentVariable';
          id: string;
          alias?: string | null;
          source: Types.ReportTemplateVariableSource;
          label: string;
          category: string;
        }
      | {
          __typename: 'ReportTemplateStaticVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          units?: string | null;
          precision?: number | null;
          controlType: Types.VariableControlType;
          defaultValue?: any | null;
        }
      | {
          __typename: 'ReportTemplateWallMotionVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          variant: Types.WallMotionVariant;
        }
    >;
  };
};

export type SendDistributionMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportId: Types.Scalars['ID'];
  distributionId: Types.Scalars['ID'];
}>;

export type SendDistributionMutation = {
  __typename?: 'Mutation';
  sendDistribution: {
    __typename?: 'Distribution';
    id: string;
    organizationId: string;
    reportId: string;
    status: Types.DistributionStatus;
    events: Array<{
      __typename?: 'DistributionEvent';
      type: Types.DistributionEventType;
      payload: any;
      createdAt: DateTime;
    }>;
  };
};

export type ShareReportMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportId: Types.Scalars['ID'];
  shareReportRequest: Types.ShareReportRequest;
}>;

export type ShareReportMutation = {
  __typename?: 'Mutation';
  shareReport: {
    __typename?: 'ReportShare';
    id: string;
    organizationId: string;
    shareeId: string;
    shareeEmail: string;
    sharerId: string;
    sharerEmail: string;
    message?: string | null;
    createdAt: DateTime;
    updatedAt: DateTime;
    isExpired: boolean;
    expiresAt?: DateTime | null;
  };
};

export type UnshareReportMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportShareId: Types.Scalars['ID'];
  reportId: Types.Scalars['ID'];
}>;

export type UnshareReportMutation = { __typename?: 'Mutation'; unshareReport: boolean };

export type UpdateDistributionMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportId: Types.Scalars['ID'];
  distributionId: Types.Scalars['ID'];
  request: Types.Scalars['JSON'];
}>;

export type UpdateDistributionMutation = {
  __typename?: 'Mutation';
  updateDistribution: {
    __typename?: 'Distribution';
    id: string;
    organizationId: string;
    reportId: string;
  };
};

export type UpdateIntegrationsMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  updateIntegrationsRequest: Types.UpdateIntegrationsRequest;
}>;

export type UpdateIntegrationsMutation = {
  __typename?: 'Mutation';
  updateIntegrations: {
    __typename?: 'Integrations';
    medicalObjects: {
      __typename?: 'MedicalObjectsIntegration';
      id: string;
      organizationId: string;
      type: Types.IntegrationType;
      createdAt: DateTime;
      updatedAt: DateTime;
      settings: {
        __typename?: 'MedicalObjectsIntegrationSettings';
        enabled: boolean;
        capricornUrl?: string | null;
        practiceId?: string | null;
        practiceName?: string | null;
        providerMappings: Array<{
          __typename?: 'ProviderMapping';
          providerNumber: string;
          userId: string;
        }>;
      };
    };
    healthLink: {
      __typename?: 'HealthLinkIntegration';
      id: string;
      organizationId: string;
      type: Types.IntegrationType;
      createdAt: DateTime;
      updatedAt: DateTime;
      settings: {
        __typename?: 'HealthLinkIntegrationSettings';
        enabled: boolean;
        providerMappings: Array<{
          __typename?: 'ProviderMapping';
          providerNumber: string;
          userId: string;
        }>;
      };
    };
    fax: {
      __typename?: 'FaxIntegration';
      id: string;
      organizationId: string;
      type: Types.IntegrationType;
      createdAt: DateTime;
      updatedAt: DateTime;
      settings: { __typename?: 'FaxIntegrationSettings'; enabled: boolean };
    };
  };
};

export type UpdateMeasurementMappingMutationVariables = Types.Exact<{
  mappingId: Types.Scalars['ID'];
  request: Types.UpdateMeasurementMappingRequest;
}>;

export type UpdateMeasurementMappingMutation = {
  __typename?: 'Mutation';
  updateMeasurementMapping: {
    __typename?: 'MeasurementMapping';
    id: string;
    name: string;
    site?: string | null;
    units?: string | null;
    precision?: number | null;
    properties: Array<{ __typename?: 'MeasurementProperty'; name: string; value?: any | null }>;
  };
};

export type UpdateMeasurementRangeGroupMutationVariables = Types.Exact<{
  rangeGroupId: Types.Scalars['ID'];
  request: Types.UpdateMeasurementRangeGroupRequest;
}>;

export type UpdateMeasurementRangeGroupMutation = {
  __typename?: 'Mutation';
  updateMeasurementRangeGroup: {
    __typename?: 'MeasurementRangeGroup';
    id: string;
    label: string;
    ranges: Array<{
      __typename?: 'MeasurementRange';
      label: string;
      sex?: string | null;
      age?: {
        __typename?: 'NumericalRange';
        lt?: number | null;
        lte?: number | null;
        gt?: number | null;
        gte?: number | null;
      } | null;
      measurement: {
        __typename?: 'NumericalRange';
        lt?: number | null;
        lte?: number | null;
        gt?: number | null;
        gte?: number | null;
      };
    }>;
  };
};

export type UpdateMeasurementVariantsMutationVariables = Types.Exact<{
  hash: Types.Scalars['String'];
  request: Types.UpdateMeasurementVariantsRequest;
}>;

export type UpdateMeasurementVariantsMutation = {
  __typename?: 'Mutation';
  updateMeasurementVariants: Array<{
    __typename?: 'MeasurementVariant';
    id: string;
    name: string;
    site?: string | null;
    units?: string | null;
    createdAt: DateTime;
    updatedAt: DateTime;
    properties: Array<{ __typename?: 'MeasurementProperty'; name: string; value?: any | null }>;
    machine: { __typename?: 'Machine'; manufacturer: string; model: string };
  }>;
};

export type UpdateOrganizationMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  updateOrganizationRequest: Types.UpdateOrganizationRequest;
}>;

export type UpdateOrganizationMutation = {
  __typename?: 'Mutation';
  updateOrganization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    addressLineOne?: string | null;
    addressLineTwo?: string | null;
    email?: string | null;
    phone?: string | null;
    logo?: string | null;
    timezone: string;
    featureFlags: Array<Types.FeatureFlag>;
    distributionSettings: {
      __typename?: 'DistributionSettings';
      defaultDistribution?: {
        __typename?: 'DefaultDistribution';
        trigger: Types.DistributionTrigger;
        payload:
          | {
              __typename: 'DistributionEmailPayload';
              sharerId: string;
              sharerEmail: string;
              sharerName: string;
              shareeEmail: string;
              message?: string | null;
            }
          | { __typename: 'DistributionFaxPayload' }
          | {
              __typename: 'DistributionHealthLinkPayload';
              to: {
                __typename?: 'DistributionHealthLinkProviderToInfo';
                practitionerId: string;
                endpointId: string;
                endpointName: string;
                providerNumber: string;
                practitionerName: {
                  __typename?: 'HealthLinkName';
                  text: string;
                  family: string;
                  given: Array<string>;
                  prefix: Array<string>;
                  suffix: Array<string>;
                };
              };
            }
          | {
              __typename: 'DistributionMedicalObjectsPayload';
              to: {
                __typename?: 'DistributionMedicalObjectsProviderInfo';
                providerNumber: string;
                providerDetails: {
                  __typename?: 'MedicalObjectsIntegrationProvider';
                  id: string;
                  firstName?: string | null;
                  middleName?: string | null;
                  lastName?: string | null;
                  prefix?: string | null;
                  suffix?: string | null;
                  address: Array<{
                    __typename?: 'MedicalObjectsProviderAddress';
                    class?: string | null;
                    streetAddress?: string | null;
                    otherDesignation?: string | null;
                    city?: string | null;
                    state?: string | null;
                    postCode?: string | null;
                    country?: string | null;
                    addressType?: string | null;
                    otherGeographicDesignation?: string | null;
                  }>;
                };
              };
            };
      } | null;
    };
    roles: Array<{
      __typename?: 'Role';
      id: string;
      name: string;
      description?: string | null;
      permissions: Array<string>;
    }>;
  };
};

export type UpdateProfileMutationVariables = Types.Exact<{
  updateProfileRequest: Types.UpdateProfileRequest;
}>;

export type UpdateProfileMutation = {
  __typename?: 'Mutation';
  updateProfile: {
    __typename?: 'User';
    id: string;
    name: string;
    email: string;
    picture?: string | null;
    organizationMemberships: Array<{
      __typename?: 'OrganizationMembership';
      roles: Array<{ __typename?: 'Role'; id: string; name: string }>;
    }>;
    settings: {
      __typename?: 'UserSettings';
      defaultOrganization?: string | null;
      finalizeBehavior: Types.FinalizeBehavior;
    };
  };
};

export type UpdateReportMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportId: Types.Scalars['ID'];
  updateReportRequest: Types.UpdateReportRequest;
}>;

export type UpdateReportMutation = {
  __typename?: 'Mutation';
  updateReport: {
    __typename?: 'Report';
    id: string;
    organizationId: string;
    changesetId?: string | null;
    updatedAt: DateTime;
    reportTemplate: { __typename?: 'ReportTemplate'; versionId: string };
    variables: Array<
      | {
          __typename: 'ReportChoiceVariable';
          alias?: string | null;
          id: string;
          value?: any | null;
          isTouched: boolean;
        }
      | {
          __typename: 'ReportMediaAttachmentVariable';
          alias?: string | null;
          id: string;
          value?: any | null;
          isTouched: boolean;
        }
      | {
          __typename: 'ReportStaticVariable';
          id: string;
          alias?: string | null;
          value?: any | null;
          units?: string | null;
          isTouched: boolean;
        }
      | {
          __typename: 'ReportWallMotionVariable';
          alias?: string | null;
          id: string;
          value?: any | null;
          isTouched: boolean;
        }
    >;
    statementSites: Array<{
      __typename?: 'ReportStatementSite';
      id: string;
      statements: Array<{ __typename?: 'ReportStatement'; id: string; value: string }>;
    }>;
  };
};

export type UpdateReportStatusMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportId: Types.Scalars['ID'];
  updateReportStatusRequest: Types.UpdateReportStatusRequest;
}>;

export type UpdateReportStatusMutation = {
  __typename?: 'Mutation';
  updateReportStatus?: { __typename?: 'Report'; id: string; status: Types.ReportStatus } | null;
};

export type UpdateReportTemplateMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportTemplateId: Types.Scalars['ID'];
  request: Types.UpdateReportTemplateRequest;
}>;

export type UpdateReportTemplateMutation = {
  __typename?: 'Mutation';
  updateReportTemplate: {
    __typename?: 'ReportTemplate';
    id: string;
    versionId: string;
    name: string;
    reportNodes: any;
    isLatest: boolean;
    status: Types.ReportTemplateStatus;
    labelColor: string;
    createdAt: DateTime;
    updatedAt: DateTime;
    statementSites: Array<{
      __typename?: 'ReportTemplateStatementSite';
      id: string;
      name: string;
      statements: Array<{
        __typename?: 'ReportTemplateStatement';
        id: string;
        value: string;
        default: boolean;
      }>;
    }>;
    variables: Array<
      | {
          __typename: 'ReportTemplateChoiceVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          controlType: Types.VariableControlType;
          defaultValue?: any | null;
          choices: Array<{
            __typename?: 'ReportTemplateVariableChoice';
            id: string;
            label: string;
            value: string;
            default: boolean;
          }>;
        }
      | {
          __typename: 'ReportTemplateMediaAttachmentVariable';
          id: string;
          alias?: string | null;
          source: Types.ReportTemplateVariableSource;
          label: string;
          category: string;
        }
      | {
          __typename: 'ReportTemplateStaticVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          units?: string | null;
          precision?: number | null;
          controlType: Types.VariableControlType;
          defaultValue?: any | null;
        }
      | {
          __typename: 'ReportTemplateWallMotionVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          variant: Types.WallMotionVariant;
        }
    >;
  };
};

export type UpdateReportTemplateStatementSiteMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportTemplateId: Types.Scalars['ID'];
  reportTemplateStatementSiteId: Types.Scalars['ID'];
  request: Types.UpdateReportTemplateStatementSiteRequest;
}>;

export type UpdateReportTemplateStatementSiteMutation = {
  __typename?: 'Mutation';
  updateReportTemplateStatementSite: {
    __typename?: 'ReportTemplate';
    id: string;
    versionId: string;
    name: string;
    reportNodes: any;
    isLatest: boolean;
    status: Types.ReportTemplateStatus;
    labelColor: string;
    createdAt: DateTime;
    updatedAt: DateTime;
    statementSites: Array<{
      __typename?: 'ReportTemplateStatementSite';
      id: string;
      name: string;
      statements: Array<{
        __typename?: 'ReportTemplateStatement';
        id: string;
        value: string;
        default: boolean;
      }>;
    }>;
    variables: Array<
      | {
          __typename: 'ReportTemplateChoiceVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          controlType: Types.VariableControlType;
          defaultValue?: any | null;
          choices: Array<{
            __typename?: 'ReportTemplateVariableChoice';
            id: string;
            label: string;
            value: string;
            default: boolean;
          }>;
        }
      | {
          __typename: 'ReportTemplateMediaAttachmentVariable';
          id: string;
          alias?: string | null;
          source: Types.ReportTemplateVariableSource;
          label: string;
          category: string;
        }
      | {
          __typename: 'ReportTemplateStaticVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          units?: string | null;
          precision?: number | null;
          controlType: Types.VariableControlType;
          defaultValue?: any | null;
        }
      | {
          __typename: 'ReportTemplateWallMotionVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          variant: Types.WallMotionVariant;
        }
    >;
  };
};

export type UpdateReportTemplateStatementSitesMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportTemplateId: Types.Scalars['ID'];
  request: Types.UpdateReportTemplateStatementSitesRequest;
}>;

export type UpdateReportTemplateStatementSitesMutation = {
  __typename?: 'Mutation';
  updateReportTemplateStatementSites: {
    __typename?: 'ReportTemplate';
    id: string;
    versionId: string;
    name: string;
    reportNodes: any;
    isLatest: boolean;
    status: Types.ReportTemplateStatus;
    labelColor: string;
    createdAt: DateTime;
    updatedAt: DateTime;
    statementSites: Array<{
      __typename?: 'ReportTemplateStatementSite';
      id: string;
      name: string;
      statements: Array<{
        __typename?: 'ReportTemplateStatement';
        id: string;
        value: string;
        default: boolean;
      }>;
    }>;
    variables: Array<
      | {
          __typename: 'ReportTemplateChoiceVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          controlType: Types.VariableControlType;
          defaultValue?: any | null;
          choices: Array<{
            __typename?: 'ReportTemplateVariableChoice';
            id: string;
            label: string;
            value: string;
            default: boolean;
          }>;
        }
      | {
          __typename: 'ReportTemplateMediaAttachmentVariable';
          id: string;
          alias?: string | null;
          source: Types.ReportTemplateVariableSource;
          label: string;
          category: string;
        }
      | {
          __typename: 'ReportTemplateStaticVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          units?: string | null;
          precision?: number | null;
          controlType: Types.VariableControlType;
          defaultValue?: any | null;
        }
      | {
          __typename: 'ReportTemplateWallMotionVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          variant: Types.WallMotionVariant;
        }
    >;
  };
};

export type UpdateReportTemplateVariableMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportTemplateId: Types.Scalars['ID'];
  reportTemplateVariableId: Types.Scalars['ID'];
  request: Types.Scalars['JSON'];
}>;

export type UpdateReportTemplateVariableMutation = {
  __typename?: 'Mutation';
  updateReportTemplateVariable: {
    __typename?: 'ReportTemplate';
    id: string;
    versionId: string;
    name: string;
    reportNodes: any;
    isLatest: boolean;
    status: Types.ReportTemplateStatus;
    labelColor: string;
    createdAt: DateTime;
    updatedAt: DateTime;
    statementSites: Array<{
      __typename?: 'ReportTemplateStatementSite';
      id: string;
      name: string;
      statements: Array<{
        __typename?: 'ReportTemplateStatement';
        id: string;
        value: string;
        default: boolean;
      }>;
    }>;
    variables: Array<
      | {
          __typename: 'ReportTemplateChoiceVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          controlType: Types.VariableControlType;
          defaultValue?: any | null;
          choices: Array<{
            __typename?: 'ReportTemplateVariableChoice';
            id: string;
            label: string;
            value: string;
            default: boolean;
          }>;
        }
      | {
          __typename: 'ReportTemplateMediaAttachmentVariable';
          id: string;
          alias?: string | null;
          source: Types.ReportTemplateVariableSource;
          label: string;
          category: string;
        }
      | {
          __typename: 'ReportTemplateStaticVariable';
          id: string;
          alias?: string | null;
          label: string;
          site: string;
          category: string;
          units?: string | null;
          precision?: number | null;
          controlType: Types.VariableControlType;
          defaultValue?: any | null;
        }
      | {
          __typename: 'ReportTemplateWallMotionVariable';
          id: string;
          alias?: string | null;
          variant: Types.WallMotionVariant;
          label: string;
          site: string;
          category: string;
        }
    >;
  };
};

export type UpdateReportTypeMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportId: Types.Scalars['ID'];
  updateReportTypeRequest: Types.UpdateReportTypeRequest;
}>;

export type UpdateReportTypeMutation = {
  __typename?: 'Mutation';
  updateReportType: {
    __typename?: 'Report';
    id: string;
    organizationId: string;
    changesetId?: string | null;
    status: Types.ReportStatus;
    createdAt: DateTime;
    updatedAt: DateTime;
    variables: Array<
      | {
          __typename: 'ReportChoiceVariable';
          alias?: string | null;
          id: string;
          value?: any | null;
        }
      | {
          __typename: 'ReportMediaAttachmentVariable';
          id: string;
          alias?: string | null;
          value?: any | null;
          isTouched: boolean;
        }
      | {
          __typename: 'ReportStaticVariable';
          id: string;
          alias?: string | null;
          value?: any | null;
          units?: string | null;
        }
      | {
          __typename: 'ReportWallMotionVariable';
          alias?: string | null;
          id: string;
          value?: any | null;
        }
    >;
    statementSites: Array<{
      __typename?: 'ReportStatementSite';
      id: string;
      statements: Array<{ __typename?: 'ReportStatement'; id: string; value: string }>;
    }>;
    reportTemplate: {
      __typename?: 'ReportTemplate';
      id: string;
      versionId: string;
      name: string;
      reportNodes: any;
      isLatest: boolean;
      status: Types.ReportTemplateStatus;
      labelColor: string;
      createdAt: DateTime;
      updatedAt: DateTime;
    };
  };
};

export type UpdateUserMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  userId: Types.Scalars['ID'];
  updateUserRequest: Types.UpdateUserRequest;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'User';
    id: string;
    name: string;
    email: string;
    picture?: string | null;
    organizationMemberships: Array<{
      __typename?: 'OrganizationMembership';
      roles: Array<{ __typename?: 'Role'; id: string; name: string }>;
    }>;
    settings: {
      __typename?: 'UserSettings';
      defaultOrganization?: string | null;
      finalizeBehavior: Types.FinalizeBehavior;
    };
  };
};

export type UpdateWorklistEntryMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  worklistEntryId: Types.Scalars['ID'];
  request: Types.UpdateWorklistEntryRequest;
}>;

export type UpdateWorklistEntryMutation = {
  __typename?: 'Mutation';
  updateWorklistEntry: {
    __typename?: 'WorklistEntry';
    id: string;
    organizationId: string;
    studyInstanceUID: string;
    patientId: string;
    patientFirstName: string;
    patientLastName: string;
    patientDob: DateTime;
    patientSex: string;
    patientHeight?: number | null;
    patientWeight?: number | null;
    modality: string;
    createdAt: DateTime;
    updatedAt: DateTime;
    machineAet?: { __typename?: 'MachineAet'; id: string; aet: string; name: string } | null;
    reportTemplate: { __typename?: 'ReportTemplate'; id: string; name: string };
    performingPhysician: { __typename?: 'PerformingPhysician'; id: string; name: string };
  };
};

export type AuditTrailQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  getAuditTrailRequest?: Types.InputMaybe<Types.GetPaginatedAuditTrailEntriesRequest>;
}>;

export type AuditTrailQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    auditTrail: {
      __typename?: 'PaginatedAuditTrailEntries';
      results: Array<{
        __typename?: 'AuditTrailEntry';
        id: string;
        organizationId: string;
        event: Types.AuditTrailEvent;
        message: string;
        payload: any;
        createdAt: DateTime;
        user: {
          __typename?: 'User';
          id: string;
          email: string;
          name: string;
          picture?: string | null;
        };
      }>;
      pagination?: {
        __typename?: 'TimestampPagination';
        total?: number | null;
        limit: number;
        lastSeen?: DateTime | null;
        firstSeen?: DateTime | null;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
      } | null;
    };
  } | null;
};

export type AutoCompleteEmailsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  request?: Types.InputMaybe<Types.PaginatedAutoCompleteEmailsRequest>;
}>;

export type AutoCompleteEmailsQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    autoComplete: {
      __typename?: 'AutoComplete';
      emails: {
        __typename?: 'PaginatedAutoCompleteResponse';
        results: Array<{
          __typename?: 'AutoCompleteResult';
          count: number;
          similarity: number;
          value: string;
          isExcluded: boolean;
        }>;
        pagination: {
          __typename?: 'Pagination';
          total: number;
          remaining: number;
          offset: number;
          limit: number;
          currentPage: number;
          totalPages: number;
          hasNextPage: boolean;
          hasPreviousPage: boolean;
        };
      };
    };
  } | null;
};

export type AutoCompleteReportVariablesQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  request: Types.PaginatedAutoCompleteReportVariablesRequest;
}>;

export type AutoCompleteReportVariablesQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    autoComplete: {
      __typename?: 'AutoComplete';
      reportVariables: {
        __typename?: 'PaginatedAutoCompleteResponse';
        results: Array<{
          __typename?: 'AutoCompleteResult';
          count: number;
          similarity: number;
          value: string;
          isExcluded: boolean;
        }>;
        pagination: {
          __typename?: 'Pagination';
          total: number;
          remaining: number;
          offset: number;
          limit: number;
          currentPage: number;
          totalPages: number;
          hasNextPage: boolean;
          hasPreviousPage: boolean;
        };
      };
    };
  } | null;
};

export type SubscriptionItemsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
}>;

export type SubscriptionItemsQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    billing: {
      __typename?: 'Billing';
      subscriptionItems: Array<{
        __typename?: 'SubscriptionItem';
        id: string;
        product: { __typename?: 'Product'; id: string; name: string };
      }>;
    };
  } | null;
};

export type BillingActionsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
}>;

export type BillingActionsQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    billing: {
      __typename?: 'Billing';
      actions: Array<{
        __typename?: 'BillingAction';
        id: Types.BillingActionType;
        stripeProductId: string;
        createdAt: DateTime;
        updatedAt: DateTime;
      }>;
    };
  } | null;
};

export type InvoicesQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
}>;

export type InvoicesQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    billing: {
      __typename?: 'Billing';
      invoices: Array<{
        __typename?: 'Invoice';
        id: string;
        organizationId: string;
        periodStart: DateTime;
        periodEnd: DateTime;
        createdAt: DateTime;
        due?: DateTime | null;
        status?: string | null;
        number?: string | null;
        amountDue: number;
        quantity: number;
        pdfDownloadUrl?: string | null;
        hostedPaymentUrl?: string | null;
      } | null>;
    };
  } | null;
};

export type InvoiceQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  invoiceId: Types.Scalars['ID'];
}>;

export type InvoiceQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    billing: {
      __typename?: 'Billing';
      invoice?: {
        __typename?: 'Invoice';
        id: string;
        organizationId: string;
        periodStart: DateTime;
        periodEnd: DateTime;
        createdAt: DateTime;
        due?: DateTime | null;
        status?: string | null;
        number?: string | null;
        amountDue: number;
        quantity: number;
        subscriptionItems: Array<{
          __typename?: 'InvoiceSubscriptionItem';
          id: string;
          usageQuantity: number;
          product: { __typename?: 'Product'; id: string; name: string };
          usageRecords: Array<{
            __typename?: 'InvoiceUsageRecord';
            timestamp: DateTime;
            reportId: string;
            patientName: string;
            report?: {
              __typename?: 'Report';
              createdAt: DateTime;
              status: Types.ReportStatus;
              reportTemplate: { __typename?: 'ReportTemplate'; name: string; labelColor: string };
            } | null;
          } | null>;
        }>;
      } | null;
    };
  } | null;
};

export type MeasurementMappingsAndVariantsQueryVariables = Types.Exact<{
  request?: Types.InputMaybe<Types.GetPaginatedMeasurementMappingsAndVariantsRequest>;
}>;

export type MeasurementMappingsAndVariantsQuery = {
  __typename?: 'Query';
  dicom: {
    __typename?: 'Dicom';
    measurementMappingsAndVariants: {
      __typename?: 'PaginatedMeasurementMappingsAndVariants';
      pagination?: {
        __typename?: 'Pagination';
        total: number;
        remaining: number;
        offset: number;
        limit: number;
        currentPage: number;
        totalPages: number;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
      } | null;
      mappingsAndVariants: Array<{
        __typename?: 'MeasurementMappingAndVariants';
        mapping: {
          __typename?: 'MeasurementMapping';
          id: string;
          alias?: string | null;
          name: string;
          shortName?: string | null;
          site?: string | null;
          units?: string | null;
          precision?: number | null;
          createdAt: DateTime;
          updatedAt: DateTime;
          properties: Array<{
            __typename?: 'MeasurementProperty';
            name: string;
            value?: any | null;
          }>;
          rangeGroup?: {
            __typename?: 'MeasurementRangeGroup';
            id: string;
            label: string;
            units?: string | null;
            precision: number;
            ranges: Array<{
              __typename?: 'MeasurementRange';
              level: Types.MeasurementRangeLevel;
              label: string;
              sex?: string | null;
              age?: {
                __typename?: 'NumericalRange';
                lt?: number | null;
                lte?: number | null;
                gt?: number | null;
                gte?: number | null;
              } | null;
              measurement: {
                __typename?: 'NumericalRange';
                lt?: number | null;
                lte?: number | null;
                gt?: number | null;
                gte?: number | null;
              };
            }>;
            sources: Array<{
              __typename?: 'MeasurementRangeGroupSource';
              name: string;
              url: string;
            }>;
          } | null;
        };
        variants: Array<{
          __typename?: 'MeasurementVariant';
          id: string;
          mappingId?: string | null;
          originalMappingId?: string | null;
          hash: string;
          name: string;
          site?: string | null;
          units?: string | null;
          createdAt: DateTime;
          updatedAt: DateTime;
          properties: Array<{
            __typename?: 'MeasurementProperty';
            name: string;
            value?: any | null;
          }>;
          machine: { __typename?: 'Machine'; manufacturer: string; model: string };
          organizationStats: Array<{
            __typename?: 'OrganizationMeasurementVariantStats';
            id: string;
            name: string;
            logo?: string | null;
            count: number;
            machine: { __typename?: 'Machine'; manufacturer: string; model: string };
          }>;
        }>;
      }>;
    };
  };
};

export type UnusedMeasurementVariantsQueryVariables = Types.Exact<{
  request?: Types.InputMaybe<Types.GetPaginatedUnusedMeasurementVariantsRequest>;
}>;

export type UnusedMeasurementVariantsQuery = {
  __typename?: 'Query';
  dicom: {
    __typename?: 'Dicom';
    unusedMeasurementVariants: {
      __typename?: 'PaginatedUnusedMeasurementVariants';
      pagination?: {
        __typename?: 'Pagination';
        total: number;
        remaining: number;
        offset: number;
        limit: number;
        currentPage: number;
        totalPages: number;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
      } | null;
      variants: Array<{
        __typename?: 'MeasurementVariant';
        id: string;
        mappingId?: string | null;
        originalMappingId?: string | null;
        hash: string;
        name: string;
        site?: string | null;
        units?: string | null;
        createdAt: DateTime;
        updatedAt: DateTime;
        properties: Array<{ __typename?: 'MeasurementProperty'; name: string; value?: any | null }>;
        machine: { __typename?: 'Machine'; manufacturer: string; model: string };
        organizationStats: Array<{
          __typename?: 'OrganizationMeasurementVariantStats';
          id: string;
          name: string;
          logo?: string | null;
          count: number;
          machine: { __typename?: 'Machine'; manufacturer: string; model: string };
        }>;
      }>;
    };
  };
};

export type MachinesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MachinesQuery = {
  __typename?: 'Query';
  dicom: {
    __typename?: 'Dicom';
    machines: Array<{ __typename?: 'Machine'; manufacturer: string; model: string }>;
  };
};

export type MeasurementRangeGroupsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MeasurementRangeGroupsQuery = {
  __typename?: 'Query';
  dicom: {
    __typename?: 'Dicom';
    measurementRangeGroups: Array<{
      __typename?: 'MeasurementRangeGroup';
      id: string;
      label: string;
      units?: string | null;
      precision: number;
      ranges: Array<{
        __typename?: 'MeasurementRange';
        label: string;
        level: Types.MeasurementRangeLevel;
        sex?: string | null;
        age?: {
          __typename?: 'NumericalRange';
          lt?: number | null;
          lte?: number | null;
          gt?: number | null;
          gte?: number | null;
        } | null;
        measurement: {
          __typename?: 'NumericalRange';
          lt?: number | null;
          lte?: number | null;
          gt?: number | null;
          gte?: number | null;
        };
      }>;
      sources: Array<{ __typename?: 'MeasurementRangeGroupSource'; name: string; url: string }>;
    }>;
  };
};

export type InsightsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  request: Types.GetInsightsRequest;
}>;

export type InsightsQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    insights: {
      __typename?: 'Insights';
      reportsUploaded: number;
      reportsFinalized: number;
      reportsShared: number;
      uniquePatients: number;
      sonographers: Array<{ __typename?: 'InsightsValue'; name: string; count: number }>;
      cardiologists: Array<{ __typename?: 'InsightsValue'; name: string; count: number }>;
      referringPhysicians: Array<{ __typename?: 'InsightsValue'; name: string; count: number }>;
      reportsUploadedOverTime: Array<{
        __typename?: 'ReportUploadedOverTimeInsights';
        reportTemplateId: string;
        reportTemplateName: string;
        reportTemplateLabelColor: string;
        reportCreatedAtDay: DateTime;
        reportCount: number;
      }>;
      reportsUploadedByType: Array<{
        __typename?: 'InsightsValue';
        name: string;
        count: number;
        color?: string | null;
      }>;
    };
  } | null;
};

export type MedicalObjectsIntegrationSettingsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
}>;

export type MedicalObjectsIntegrationSettingsQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    users: Array<{ __typename?: 'User'; id: string; name: string }>;
    integrations: {
      __typename?: 'Integrations';
      medicalObjects: {
        __typename?: 'MedicalObjectsIntegration';
        id: string;
        organizationId: string;
        settings: {
          __typename?: 'MedicalObjectsIntegrationSettings';
          enabled: boolean;
          capricornUrl?: string | null;
          practiceId?: string | null;
          practiceName?: string | null;
          providerMappings: Array<{
            __typename?: 'ProviderMapping';
            userId: string;
            providerNumber: string;
          }>;
          hl7Overrides?: { __typename?: 'HL7Overrides'; clinicalArea?: string | null } | null;
        };
      };
    };
  } | null;
};

export type MedicalObjectsIntegrationPracticeProvidersQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
}>;

export type MedicalObjectsIntegrationPracticeProvidersQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    users: Array<{ __typename?: 'User'; id: string; name: string }>;
    integrations: {
      __typename?: 'Integrations';
      medicalObjects: {
        __typename?: 'MedicalObjectsIntegration';
        id: string;
        organizationId: string;
        practiceProviders: Array<{
          __typename?: 'MedicalObjectsIntegrationProvider';
          id: string;
          firstName?: string | null;
          middleName?: string | null;
          lastName?: string | null;
          prefix?: string | null;
          suffix?: string | null;
          providerNumbers: Array<string>;
          address: Array<{
            __typename?: 'MedicalObjectsProviderAddress';
            city?: string | null;
            state?: string | null;
          }>;
        }>;
      };
    };
  } | null;
};

export type MedicalObjectsIntegrationProvidersQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  request?: Types.InputMaybe<Types.PaginatedMedicalObjectsProvidersRequest>;
}>;

export type MedicalObjectsIntegrationProvidersQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    integrations: {
      __typename?: 'Integrations';
      medicalObjects: {
        __typename?: 'MedicalObjectsIntegration';
        id: string;
        organizationId: string;
        providers: {
          __typename?: 'PaginatedMedicalObjectsProviders';
          pagination?: {
            __typename?: 'Pagination';
            total: number;
            remaining: number;
            offset: number;
            limit: number;
            currentPage: number;
            totalPages: number;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
          } | null;
          results: Array<{
            __typename?: 'MedicalObjectsIntegrationProvider';
            id: string;
            firstName?: string | null;
            middleName?: string | null;
            lastName?: string | null;
            prefix?: string | null;
            suffix?: string | null;
            providerNumbers: Array<string>;
            address: Array<{
              __typename?: 'MedicalObjectsProviderAddress';
              city?: string | null;
              state?: string | null;
            }>;
          }>;
        };
      };
    };
  } | null;
};

export type HealthLinkIntegrationSettingsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
}>;

export type HealthLinkIntegrationSettingsQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    users: Array<{ __typename?: 'User'; id: string; name: string }>;
    integrations: {
      __typename?: 'Integrations';
      healthLink: {
        __typename?: 'HealthLinkIntegration';
        id: string;
        organizationId: string;
        settings: {
          __typename?: 'HealthLinkIntegrationSettings';
          enabled: boolean;
          providerMappings: Array<{
            __typename?: 'ProviderMapping';
            userId: string;
            providerNumber: string;
          }>;
          hl7Overrides?: { __typename?: 'HL7Overrides'; clinicalArea?: string | null } | null;
        };
      };
    };
  } | null;
};

export type HealthLinkIntegrationPractitionersQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  request?: Types.InputMaybe<Types.PaginatedHealthLinkPractitionersRequest>;
}>;

export type HealthLinkIntegrationPractitionersQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    integrations: {
      __typename?: 'Integrations';
      healthLink: {
        __typename?: 'HealthLinkIntegration';
        id: string;
        organizationId: string;
        practitioners: {
          __typename?: 'PaginatedHealthLinkPractitioners';
          pagination?: {
            __typename?: 'Pagination';
            total: number;
            remaining: number;
            offset: number;
            limit: number;
            currentPage: number;
            totalPages: number;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
          } | null;
          results: Array<{
            __typename?: 'HealthLinkPractitioner';
            id: string;
            name: {
              __typename?: 'HealthLinkName';
              text: string;
              family: string;
              given: Array<string>;
              prefix: Array<string>;
              suffix: Array<string>;
            };
            providers: Array<{
              __typename?: 'HealthLinkProvider';
              providerNumber: string;
              organization: {
                __typename?: 'HealthLinkOrganization';
                id: string;
                name: string;
                address: {
                  __typename?: 'HealthLinkAddress';
                  city?: string | null;
                  country?: string | null;
                  line?: Array<string> | null;
                  postalCode?: string | null;
                  state?: string | null;
                  text?: string | null;
                  type?: string | null;
                  use?: string | null;
                };
              };
              endpoint: { __typename?: 'HealthLinkEndpoint'; id: string; name: string };
            }>;
          }>;
        };
      };
    };
  } | null;
};

export type FaxIntegrationSettingsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
}>;

export type FaxIntegrationSettingsQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    users: Array<{ __typename?: 'User'; id: string; name: string }>;
    integrations: {
      __typename?: 'Integrations';
      fax: {
        __typename?: 'FaxIntegration';
        id: string;
        organizationId: string;
        settings: { __typename?: 'FaxIntegrationSettings'; enabled: boolean };
      };
    };
  } | null;
};

export type JobQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  jobId: Types.Scalars['ID'];
}>;

export type JobQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    job?: {
      __typename?: 'Job';
      id: string;
      type: Types.JobType;
      status: Types.JobStatus;
      progress: number;
      queuedAt: DateTime;
      startedAt?: DateTime | null;
      finishedAt?: DateTime | null;
      payload?: any | null;
    } | null;
  } | null;
};

export type LabelsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
}>;

export type LabelsQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    labels: Array<{
      __typename?: 'Label';
      id: string;
      name: string;
      description?: string | null;
      color: string;
      createdAt: DateTime;
      updatedAt: DateTime;
    }>;
  } | null;
};

export type OrganizationQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
}>;

export type OrganizationQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    shortId: string;
    name: string;
    stripeCustomerId?: string | null;
    stripeSubscriptionId?: string | null;
    contract?: string | null;
    status: Types.OrganizationStatus;
    addressLineOne?: string | null;
    addressLineTwo?: string | null;
    email?: string | null;
    phone?: string | null;
    logo?: string | null;
    timezone: string;
    featureFlags: Array<Types.FeatureFlag>;
    dicomSettings: { __typename?: 'DicomSettings'; ip: string; port: number; aet: string };
    distributionSettings: {
      __typename?: 'DistributionSettings';
      defaultDistributionType?: Types.DistributionType | null;
      defaultDistribution?: {
        __typename?: 'DefaultDistribution';
        trigger: Types.DistributionTrigger;
        payload:
          | {
              __typename: 'DistributionEmailPayload';
              sharerId: string;
              sharerEmail: string;
              sharerName: string;
              shareeEmail: string;
              message?: string | null;
            }
          | {
              __typename: 'DistributionFaxPayload';
              to: { __typename?: 'DistributionFaxToInfo'; faxNumber: string };
              from: {
                __typename?: 'DistributionFaxFromInfo';
                sharerId: string;
                sharerEmail: string;
                sharerName: string;
              };
            }
          | {
              __typename: 'DistributionHealthLinkPayload';
              to: {
                __typename?: 'DistributionHealthLinkProviderToInfo';
                practitionerId: string;
                organizationId: string;
                organizationName: string;
                endpointId: string;
                endpointName: string;
                providerNumber: string;
                practitionerName: {
                  __typename?: 'HealthLinkName';
                  text: string;
                  family: string;
                  given: Array<string>;
                  prefix: Array<string>;
                  suffix: Array<string>;
                };
                organizationAddress: {
                  __typename?: 'HealthLinkAddress';
                  city?: string | null;
                  country?: string | null;
                  line?: Array<string> | null;
                  postalCode?: string | null;
                  state?: string | null;
                  text?: string | null;
                  type?: string | null;
                  use?: string | null;
                };
              };
              from: {
                __typename?: 'DistributionHealthLinkProviderFromInfo';
                providerNumber: string;
                name: string;
              };
            }
          | {
              __typename: 'DistributionMedicalObjectsPayload';
              to: {
                __typename?: 'DistributionMedicalObjectsProviderInfo';
                providerNumber: string;
                providerDetails: {
                  __typename?: 'MedicalObjectsIntegrationProvider';
                  id: string;
                  firstName?: string | null;
                  middleName?: string | null;
                  lastName?: string | null;
                  prefix?: string | null;
                  suffix?: string | null;
                  address: Array<{
                    __typename?: 'MedicalObjectsProviderAddress';
                    class?: string | null;
                    streetAddress?: string | null;
                    otherDesignation?: string | null;
                    city?: string | null;
                    state?: string | null;
                    postCode?: string | null;
                    country?: string | null;
                    addressType?: string | null;
                    otherGeographicDesignation?: string | null;
                  }>;
                };
              };
              from: {
                __typename?: 'DistributionMedicalObjectsProviderInfo';
                providerNumber: string;
                providerDetails: {
                  __typename?: 'MedicalObjectsIntegrationProvider';
                  id: string;
                  firstName?: string | null;
                  middleName?: string | null;
                  lastName?: string | null;
                  prefix?: string | null;
                  suffix?: string | null;
                  address: Array<{
                    __typename?: 'MedicalObjectsProviderAddress';
                    class?: string | null;
                    streetAddress?: string | null;
                    otherDesignation?: string | null;
                    city?: string | null;
                    state?: string | null;
                    postCode?: string | null;
                    country?: string | null;
                    addressType?: string | null;
                    otherGeographicDesignation?: string | null;
                  }>;
                };
              };
            };
      } | null;
    };
    integrations: {
      __typename?: 'Integrations';
      medicalObjects: {
        __typename?: 'MedicalObjectsIntegration';
        id: string;
        organizationId: string;
        type: Types.IntegrationType;
        createdAt: DateTime;
        updatedAt: DateTime;
        settings: {
          __typename?: 'MedicalObjectsIntegrationSettings';
          enabled: boolean;
          capricornUrl?: string | null;
        };
      };
      healthLink: {
        __typename?: 'HealthLinkIntegration';
        id: string;
        organizationId: string;
        type: Types.IntegrationType;
        createdAt: DateTime;
        updatedAt: DateTime;
        settings: { __typename?: 'HealthLinkIntegrationSettings'; enabled: boolean };
      };
      fax: {
        __typename?: 'FaxIntegration';
        id: string;
        organizationId: string;
        type: Types.IntegrationType;
        createdAt: DateTime;
        updatedAt: DateTime;
        settings: { __typename?: 'FaxIntegrationSettings'; enabled: boolean };
      };
    };
    roles: Array<{
      __typename?: 'Role';
      id: string;
      name: string;
      description?: string | null;
      permissions: Array<string>;
    }>;
  } | null;
};

export type OrganizationMachineStatsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
}>;

export type OrganizationMachineStatsQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    machineStats: Array<{
      __typename?: 'OrganizationMachineStats';
      count: number;
      percentage: number;
      machine: { __typename?: 'Machine'; manufacturer: string; model: string };
    }>;
  } | null;
};

export type OrganizationsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type OrganizationsQuery = {
  __typename?: 'Query';
  organizations: Array<{
    __typename?: 'Organization';
    id: string;
    shortId: string;
    name: string;
    status: Types.OrganizationStatus;
    logo?: string | null;
  }>;
};

export type ReportMinimalQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportId: Types.Scalars['ID'];
}>;

export type ReportMinimalQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    report?: {
      __typename?: 'Report';
      id: string;
      organizationId: string;
      status: Types.ReportStatus;
      createdAt: DateTime;
      updatedAt: DateTime;
      variables: Array<
        | {
            __typename: 'ReportChoiceVariable';
            id: string;
            alias?: string | null;
            value?: any | null;
          }
        | {
            __typename: 'ReportMediaAttachmentVariable';
            id: string;
            alias?: string | null;
            value?: any | null;
          }
        | {
            __typename: 'ReportStaticVariable';
            id: string;
            alias?: string | null;
            value?: any | null;
            units?: string | null;
          }
        | {
            __typename: 'ReportWallMotionVariable';
            id: string;
            alias?: string | null;
            value?: any | null;
          }
      >;
      reportTemplate: {
        __typename?: 'ReportTemplate';
        id: string;
        versionId: string;
        name: string;
        labelColor: string;
      };
    } | null;
  } | null;
};

export type ReportTypeQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportId: Types.Scalars['ID'];
}>;

export type ReportTypeQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    report?: {
      __typename?: 'Report';
      id: string;
      status: Types.ReportStatus;
      reportTemplate: {
        __typename?: 'ReportTemplate';
        id: string;
        name: string;
        isLatest: boolean;
      };
    } | null;
  } | null;
};

export type ReportAttachmentsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportId: Types.Scalars['ID'];
}>;

export type ReportAttachmentsQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    report?: {
      __typename?: 'Report';
      id: string;
      organizationId: string;
      attachments: Array<{
        __typename?: 'ReportAttachment';
        id: string;
        name: string;
        viewUrl: string;
        downloadUrl: string;
        createdAt: DateTime;
        uploader?: { __typename?: 'User'; id: string; name: string } | null;
      }>;
    } | null;
  } | null;
};

export type ReportAttachmentsMinimalQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportId: Types.Scalars['ID'];
}>;

export type ReportAttachmentsMinimalQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    report?: {
      __typename?: 'Report';
      id: string;
      attachments: Array<{ __typename?: 'ReportAttachment'; id: string }>;
    } | null;
  } | null;
};

export type ReportQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportId: Types.Scalars['ID'];
}>;

export type ReportQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    report?: {
      __typename?: 'Report';
      id: string;
      organizationId: string;
      changesetId?: string | null;
      status: Types.ReportStatus;
      createdAt: DateTime;
      updatedAt: DateTime;
      variables: Array<
        | {
            __typename: 'ReportChoiceVariable';
            id: string;
            alias?: string | null;
            value?: any | null;
          }
        | {
            __typename: 'ReportMediaAttachmentVariable';
            id: string;
            alias?: string | null;
            value?: any | null;
          }
        | {
            __typename: 'ReportStaticVariable';
            id: string;
            alias?: string | null;
            value?: any | null;
            units?: string | null;
          }
        | {
            __typename: 'ReportWallMotionVariable';
            id: string;
            alias?: string | null;
            value?: any | null;
          }
      >;
      statementSites: Array<{
        __typename?: 'ReportStatementSite';
        id: string;
        statements: Array<{ __typename?: 'ReportStatement'; id: string; value: string }>;
      }>;
      reportTemplate: {
        __typename?: 'ReportTemplate';
        id: string;
        versionId: string;
        billingActionId?: Types.BillingActionType | null;
        name: string;
        reportNodes: any;
        isLatest: boolean;
        status: Types.ReportTemplateStatus;
        labelColor: string;
        createdAt: DateTime;
        updatedAt: DateTime;
        statementSites: Array<{
          __typename?: 'ReportTemplateStatementSite';
          id: string;
          name: string;
          statements: Array<{
            __typename?: 'ReportTemplateStatement';
            id: string;
            value: string;
            default: boolean;
          }>;
        }>;
        variables: Array<
          | {
              __typename: 'ReportTemplateChoiceVariable';
              id: string;
              alias?: string | null;
              source: Types.ReportTemplateVariableSource;
              label: string;
              site: string;
              category: string;
              controlType: Types.VariableControlType;
              defaultValue?: any | null;
              isEditable: boolean;
              choices: Array<{
                __typename?: 'ReportTemplateVariableChoice';
                id: string;
                label: string;
                value: string;
                default: boolean;
              }>;
              mappings: Array<{
                __typename?: 'MeasurementMapping';
                id: string;
                name: string;
                shortName?: string | null;
              }>;
            }
          | {
              __typename: 'ReportTemplateMediaAttachmentVariable';
              id: string;
              alias?: string | null;
              source: Types.ReportTemplateVariableSource;
              label: string;
              category: string;
              isEditable: boolean;
              defaultValue?: any | null;
            }
          | {
              __typename: 'ReportTemplateStaticVariable';
              id: string;
              alias?: string | null;
              source: Types.ReportTemplateVariableSource;
              label: string;
              site: string;
              category: string;
              units?: string | null;
              precision?: number | null;
              controlType: Types.VariableControlType;
              defaultValue?: any | null;
              isEditable: boolean;
              mappings: Array<{
                __typename?: 'MeasurementMapping';
                id: string;
                name: string;
                shortName?: string | null;
                alias?: string | null;
                site?: string | null;
                units?: string | null;
                precision?: number | null;
                rangeGroup?: {
                  __typename?: 'MeasurementRangeGroup';
                  id: string;
                  label: string;
                  units?: string | null;
                  precision: number;
                  ranges: Array<{
                    __typename?: 'MeasurementRange';
                    level: Types.MeasurementRangeLevel;
                    label: string;
                    sex?: string | null;
                    age?: {
                      __typename?: 'NumericalRange';
                      lt?: number | null;
                      lte?: number | null;
                      gt?: number | null;
                      gte?: number | null;
                    } | null;
                    measurement: {
                      __typename?: 'NumericalRange';
                      lt?: number | null;
                      lte?: number | null;
                      gt?: number | null;
                      gte?: number | null;
                    };
                  }>;
                  sources: Array<{
                    __typename?: 'MeasurementRangeGroupSource';
                    name: string;
                    url: string;
                  }>;
                } | null;
                properties: Array<{
                  __typename?: 'MeasurementProperty';
                  name: string;
                  value?: any | null;
                }>;
              }>;
            }
          | {
              __typename: 'ReportTemplateWallMotionVariable';
              id: string;
              alias?: string | null;
              source: Types.ReportTemplateVariableSource;
              label: string;
              site: string;
              category: string;
              variant: Types.WallMotionVariant;
              isEditable: boolean;
              defaultValue?: any | null;
            }
        >;
      };
      labels: Array<{
        __typename?: 'Label';
        id: string;
        name: string;
        color: string;
        description?: string | null;
        createdAt: DateTime;
        updatedAt: DateTime;
      }>;
    } | null;
  } | null;
};

export type ReportRelatedVariablesQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportId: Types.Scalars['ID'];
}>;

export type ReportRelatedVariablesQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    report?: {
      __typename?: 'Report';
      id: string;
      organizationId: string;
      changesetId?: string | null;
      createdAt: DateTime;
      updatedAt: DateTime;
      variables: Array<
        | {
            __typename: 'ReportChoiceVariable';
            id: string;
            alias?: string | null;
            value?: any | null;
          }
        | {
            __typename: 'ReportMediaAttachmentVariable';
            id: string;
            alias?: string | null;
            value?: any | null;
          }
        | {
            __typename: 'ReportStaticVariable';
            id: string;
            alias?: string | null;
            value?: any | null;
            units?: string | null;
          }
        | {
            __typename: 'ReportWallMotionVariable';
            id: string;
            alias?: string | null;
            value?: any | null;
          }
      >;
      related: Array<{
        __typename?: 'Report';
        id: string;
        createdAt: DateTime;
        updatedAt: DateTime;
        variables: Array<
          | {
              __typename: 'ReportChoiceVariable';
              id: string;
              alias?: string | null;
              value?: any | null;
            }
          | {
              __typename: 'ReportMediaAttachmentVariable';
              id: string;
              alias?: string | null;
              value?: any | null;
            }
          | {
              __typename: 'ReportStaticVariable';
              id: string;
              alias?: string | null;
              value?: any | null;
              units?: string | null;
            }
          | {
              __typename: 'ReportWallMotionVariable';
              id: string;
              alias?: string | null;
              value?: any | null;
            }
        >;
      }>;
    } | null;
  } | null;
};

export type ReportStudyInstancesMinimalQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportId: Types.Scalars['ID'];
}>;

export type ReportStudyInstancesMinimalQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    report?: {
      __typename?: 'Report';
      id: string;
      study?: {
        __typename?: 'Study';
        instances: Array<{
          __typename?: 'Instance';
          id: string;
          dicom: {
            __typename?: 'InstanceDicom';
            modality: string;
            transferSyntaxUID?: string | null;
            imageType?: string | null;
          };
        }>;
      } | null;
    } | null;
  } | null;
};

export type ReportStudyInstancesQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportId: Types.Scalars['ID'];
}>;

export type ReportStudyInstancesQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    report?: {
      __typename?: 'Report';
      id: string;
      organizationId: string;
      createdAt: DateTime;
      updatedAt: DateTime;
      status: Types.ReportStatus;
      reportTemplate: { __typename?: 'ReportTemplate'; id: string };
      study?: {
        __typename?: 'Study';
        id: string;
        organizationId: string;
        healthcareRegion: string;
        healthcareDatasetId: string;
        createdAt: DateTime;
        updatedAt: DateTime;
        instances: Array<{
          __typename?: 'Instance';
          id: string;
          studyId: string;
          seriesId: string;
          fileUrl?: string | null;
          frameUrl?: string | null;
          mp4Url?: string | null;
          size?: number | null;
          dicom: {
            __typename?: 'InstanceDicom';
            manufacturer?: string | null;
            model?: string | null;
            modality: string;
            instanceNumber?: number | null;
            contentDateTime?: DateTime | null;
            numberOfFrames?: number | null;
            numberOfStages?: number | null;
            stage: Types.Stage;
            view: Types.View;
            heartRate?: number | null;
            frameRate?: number | null;
            duration?: number | null;
            imageType?: string | null;
            transferSyntaxUID?: string | null;
            rows?: number | null;
            columns?: number | null;
            sequenceOfUltrasoundRegions: Array<{
              __typename?: 'SequenceOfUltrasoundRegion';
              regionFlags: number;
              regionLocationMinX0: number;
              regionLocationMinY0: number;
              regionLocationMaxX1: number;
              regionLocationMaxY1: number;
              physicalUnitsXDirection: number;
              physicalUnitsYDirection: number;
              physicalDeltaX: number;
              physicalDeltaY: number;
            }>;
          };
        }>;
      } | null;
    } | null;
  } | null;
};

export type ReportPdfsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportId: Types.Scalars['ID'];
}>;

export type ReportPdfsQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    report?: {
      __typename?: 'Report';
      id: string;
      organizationId: string;
      pdfs: Array<{
        __typename?: 'ReportPdf';
        version: number;
        viewUrl: string;
        downloadUrl: string;
      }>;
    } | null;
  } | null;
};

export type ReportSharesMinimalQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportId: Types.Scalars['ID'];
}>;

export type ReportSharesMinimalQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    report?: {
      __typename?: 'Report';
      id: string;
      shares: Array<{
        __typename?: 'ReportShare';
        id: string;
        shareeEmail: string;
        status: Types.ReportShareStatus;
        isExpired: boolean;
        expiresAt?: DateTime | null;
      }>;
    } | null;
  } | null;
};

export type ReportRelatedReportsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportId: Types.Scalars['ID'];
}>;

export type ReportRelatedReportsQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    report?: {
      __typename?: 'Report';
      id: string;
      organizationId: string;
      related: Array<{
        __typename?: 'Report';
        id: string;
        organizationId: string;
        status: Types.ReportStatus;
        createdAt: DateTime;
        updatedAt: DateTime;
        reportTemplate: {
          __typename?: 'ReportTemplate';
          id: string;
          name: string;
          labelColor: string;
        };
        variables: Array<
          | {
              __typename: 'ReportChoiceVariable';
              id: string;
              alias?: string | null;
              value?: any | null;
            }
          | {
              __typename: 'ReportMediaAttachmentVariable';
              id: string;
              alias?: string | null;
              value?: any | null;
            }
          | {
              __typename: 'ReportStaticVariable';
              id: string;
              alias?: string | null;
              value?: any | null;
              units?: string | null;
            }
          | {
              __typename: 'ReportWallMotionVariable';
              id: string;
              alias?: string | null;
              value?: any | null;
            }
        >;
        labels: Array<{
          __typename?: 'Label';
          id: string;
          name: string;
          description?: string | null;
          color: string;
          createdAt: DateTime;
          updatedAt: DateTime;
        }>;
        distributions: Array<{
          __typename?: 'Distribution';
          id: string;
          organizationId: string;
          reportId: string;
          status: Types.DistributionStatus;
          trigger: Types.DistributionTrigger;
          payload:
            | {
                __typename: 'DistributionEmailPayload';
                sharerId: string;
                sharerEmail: string;
                sharerName: string;
                shareeEmail: string;
              }
            | {
                __typename: 'DistributionFaxPayload';
                to: { __typename?: 'DistributionFaxToInfo'; faxNumber: string };
                from: {
                  __typename?: 'DistributionFaxFromInfo';
                  sharerId: string;
                  sharerEmail: string;
                  sharerName: string;
                };
              }
            | {
                __typename: 'DistributionHealthLinkPayload';
                to: {
                  __typename?: 'DistributionHealthLinkProviderToInfo';
                  practitionerId: string;
                  organizationId: string;
                  organizationName: string;
                  endpointId: string;
                  endpointName: string;
                  providerNumber: string;
                  practitionerName: {
                    __typename?: 'HealthLinkName';
                    text: string;
                    family: string;
                    given: Array<string>;
                    prefix: Array<string>;
                    suffix: Array<string>;
                  };
                  organizationAddress: {
                    __typename?: 'HealthLinkAddress';
                    city?: string | null;
                    country?: string | null;
                    line?: Array<string> | null;
                    postalCode?: string | null;
                    state?: string | null;
                    text?: string | null;
                    type?: string | null;
                    use?: string | null;
                  };
                };
                from: {
                  __typename?: 'DistributionHealthLinkProviderFromInfo';
                  providerNumber: string;
                  name: string;
                };
              }
            | {
                __typename: 'DistributionMedicalObjectsPayload';
                to: {
                  __typename?: 'DistributionMedicalObjectsProviderInfo';
                  providerNumber: string;
                  providerDetails: {
                    __typename?: 'MedicalObjectsIntegrationProvider';
                    id: string;
                    firstName?: string | null;
                    middleName?: string | null;
                    lastName?: string | null;
                    prefix?: string | null;
                    suffix?: string | null;
                  };
                };
                from: {
                  __typename?: 'DistributionMedicalObjectsProviderInfo';
                  providerNumber: string;
                  providerDetails: {
                    __typename?: 'MedicalObjectsIntegrationProvider';
                    id: string;
                    firstName?: string | null;
                    middleName?: string | null;
                    lastName?: string | null;
                    prefix?: string | null;
                    suffix?: string | null;
                  };
                };
              };
        }>;
      }>;
    } | null;
  } | null;
};

export type ReportRelatedReportsMinimalQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportId: Types.Scalars['ID'];
}>;

export type ReportRelatedReportsMinimalQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    report?: {
      __typename?: 'Report';
      id: string;
      related: Array<{ __typename?: 'Report'; id: string }>;
    } | null;
  } | null;
};

export type ReportDistributionsMinimalQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportId: Types.Scalars['ID'];
}>;

export type ReportDistributionsMinimalQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    report?: {
      __typename?: 'Report';
      id: string;
      status: Types.ReportStatus;
      distributions: Array<{
        __typename?: 'Distribution';
        id: string;
        organizationId: string;
        reportId: string;
        status: Types.DistributionStatus;
        trigger: Types.DistributionTrigger;
        createdAt: DateTime;
        updatedAt: DateTime;
      }>;
    } | null;
  } | null;
};

export type ReportDistributionsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportId: Types.Scalars['ID'];
}>;

export type ReportDistributionsQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    report?: {
      __typename?: 'Report';
      id: string;
      status: Types.ReportStatus;
      distributions: Array<{
        __typename?: 'Distribution';
        id: string;
        organizationId: string;
        reportId: string;
        status: Types.DistributionStatus;
        trigger: Types.DistributionTrigger;
        createdAt: DateTime;
        updatedAt: DateTime;
        events: Array<{
          __typename?: 'DistributionEvent';
          id: string;
          type: Types.DistributionEventType;
          payload: any;
          createdAt: DateTime;
        }>;
        payload:
          | {
              __typename: 'DistributionEmailPayload';
              sharerId: string;
              sharerEmail: string;
              sharerName: string;
              shareeEmail: string;
              message?: string | null;
            }
          | {
              __typename: 'DistributionFaxPayload';
              to: { __typename?: 'DistributionFaxToInfo'; faxNumber: string };
              from: {
                __typename?: 'DistributionFaxFromInfo';
                sharerId: string;
                sharerEmail: string;
                sharerName: string;
              };
            }
          | {
              __typename: 'DistributionHealthLinkPayload';
              to: {
                __typename?: 'DistributionHealthLinkProviderToInfo';
                practitionerId: string;
                organizationId: string;
                organizationName: string;
                endpointId: string;
                endpointName: string;
                providerNumber: string;
                practitionerName: {
                  __typename?: 'HealthLinkName';
                  text: string;
                  family: string;
                  given: Array<string>;
                  prefix: Array<string>;
                  suffix: Array<string>;
                };
                organizationAddress: {
                  __typename?: 'HealthLinkAddress';
                  city?: string | null;
                  country?: string | null;
                  line?: Array<string> | null;
                  postalCode?: string | null;
                  state?: string | null;
                  text?: string | null;
                  type?: string | null;
                  use?: string | null;
                };
              };
              from: {
                __typename?: 'DistributionHealthLinkProviderFromInfo';
                providerNumber: string;
                name: string;
              };
            }
          | {
              __typename: 'DistributionMedicalObjectsPayload';
              to: {
                __typename?: 'DistributionMedicalObjectsProviderInfo';
                providerNumber: string;
                providerDetails: {
                  __typename?: 'MedicalObjectsIntegrationProvider';
                  id: string;
                  firstName?: string | null;
                  middleName?: string | null;
                  lastName?: string | null;
                  prefix?: string | null;
                  suffix?: string | null;
                  address: Array<{
                    __typename?: 'MedicalObjectsProviderAddress';
                    class?: string | null;
                    streetAddress?: string | null;
                    otherDesignation?: string | null;
                    city?: string | null;
                    state?: string | null;
                    postCode?: string | null;
                    country?: string | null;
                    addressType?: string | null;
                    otherGeographicDesignation?: string | null;
                  }>;
                };
              };
              from: {
                __typename?: 'DistributionMedicalObjectsProviderInfo';
                providerNumber: string;
                providerDetails: {
                  __typename?: 'MedicalObjectsIntegrationProvider';
                  id: string;
                  firstName?: string | null;
                  middleName?: string | null;
                  lastName?: string | null;
                  prefix?: string | null;
                  suffix?: string | null;
                  address: Array<{
                    __typename?: 'MedicalObjectsProviderAddress';
                    class?: string | null;
                    streetAddress?: string | null;
                    otherDesignation?: string | null;
                    city?: string | null;
                    state?: string | null;
                    postCode?: string | null;
                    country?: string | null;
                    addressType?: string | null;
                    otherGeographicDesignation?: string | null;
                  }>;
                };
              };
            };
      }>;
    } | null;
  } | null;
};

export type ReportShareQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportId: Types.Scalars['ID'];
}>;

export type ReportShareQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    reportShare?: {
      __typename?: 'ReportShare';
      id: string;
      reportId: string;
      sharerEmail: string;
      shareeEmail: string;
      isExpired: boolean;
      expiresAt?: DateTime | null;
      report?: {
        __typename?: 'Report';
        id: string;
        organizationId: string;
        status: Types.ReportStatus;
        createdAt: DateTime;
        updatedAt: DateTime;
        variables: Array<
          | {
              __typename: 'ReportChoiceVariable';
              id: string;
              alias?: string | null;
              value?: any | null;
            }
          | {
              __typename: 'ReportMediaAttachmentVariable';
              id: string;
              alias?: string | null;
              value?: any | null;
            }
          | {
              __typename: 'ReportStaticVariable';
              id: string;
              alias?: string | null;
              value?: any | null;
              units?: string | null;
            }
          | {
              __typename: 'ReportWallMotionVariable';
              id: string;
              alias?: string | null;
              value?: any | null;
            }
        >;
        statementSites: Array<{
          __typename?: 'ReportStatementSite';
          id: string;
          statements: Array<{ __typename?: 'ReportStatement'; id: string; value: string }>;
        }>;
        reportTemplate: {
          __typename?: 'ReportTemplate';
          id: string;
          versionId: string;
          name: string;
          reportNodes: any;
          isLatest: boolean;
          status: Types.ReportTemplateStatus;
          labelColor: string;
          createdAt: DateTime;
          updatedAt: DateTime;
          statementSites: Array<{
            __typename?: 'ReportTemplateStatementSite';
            id: string;
            name: string;
            statements: Array<{
              __typename?: 'ReportTemplateStatement';
              id: string;
              value: string;
              default: boolean;
            }>;
          }>;
          variables: Array<
            | {
                __typename: 'ReportTemplateChoiceVariable';
                id: string;
                alias?: string | null;
                source: Types.ReportTemplateVariableSource;
                label: string;
                site: string;
                category: string;
                controlType: Types.VariableControlType;
                defaultValue?: any | null;
                isEditable: boolean;
                choices: Array<{
                  __typename?: 'ReportTemplateVariableChoice';
                  id: string;
                  label: string;
                  value: string;
                  default: boolean;
                }>;
              }
            | {
                __typename: 'ReportTemplateMediaAttachmentVariable';
                id: string;
                alias?: string | null;
                source: Types.ReportTemplateVariableSource;
                label: string;
                category: string;
                isEditable: boolean;
              }
            | {
                __typename: 'ReportTemplateStaticVariable';
                id: string;
                alias?: string | null;
                source: Types.ReportTemplateVariableSource;
                label: string;
                site: string;
                category: string;
                units?: string | null;
                precision?: number | null;
                controlType: Types.VariableControlType;
                defaultValue?: any | null;
                isEditable: boolean;
              }
            | {
                __typename: 'ReportTemplateWallMotionVariable';
                id: string;
                alias?: string | null;
                source: Types.ReportTemplateVariableSource;
                label: string;
                category: string;
                variant: Types.WallMotionVariant;
                isEditable: boolean;
              }
          >;
        };
        study?: {
          __typename?: 'Study';
          id: string;
          organizationId: string;
          healthcareRegion: string;
          healthcareDatasetId: string;
          instances: Array<{
            __typename?: 'Instance';
            id: string;
            studyId: string;
            seriesId: string;
            fileUrl?: string | null;
            frameUrl?: string | null;
            mp4Url?: string | null;
            size?: number | null;
            dicom: {
              __typename?: 'InstanceDicom';
              modality: string;
              instanceNumber?: number | null;
              numberOfFrames?: number | null;
              numberOfStages?: number | null;
              transferSyntaxUID?: string | null;
              imageType?: string | null;
              stage: Types.Stage;
              view: Types.View;
              heartRate?: number | null;
              frameRate?: number | null;
              duration?: number | null;
            };
          }>;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ReportSharesQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  getReportSharesRequest?: Types.InputMaybe<Types.GetPaginatedReportSharesRequest>;
}>;

export type ReportSharesQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    reportShares: {
      __typename?: 'PaginatedReportShares';
      shares: Array<{
        __typename?: 'ReportShare';
        id: string;
        sharerEmail: string;
        sharerId: string;
        createdAt: DateTime;
        updatedAt: DateTime;
        isExpired: boolean;
        expiresAt?: DateTime | null;
        report?: {
          __typename?: 'Report';
          id: string;
          organizationId: string;
          status: Types.ReportStatus;
          createdAt: DateTime;
          updatedAt: DateTime;
          variables: Array<
            | { __typename: 'ReportChoiceVariable' }
            | { __typename: 'ReportMediaAttachmentVariable' }
            | {
                __typename: 'ReportStaticVariable';
                id: string;
                alias?: string | null;
                value?: any | null;
                units?: string | null;
              }
            | { __typename: 'ReportWallMotionVariable' }
          >;
          reportTemplate: {
            __typename?: 'ReportTemplate';
            id: string;
            versionId: string;
            name: string;
            labelColor: string;
          };
        } | null;
      }>;
      pagination?: {
        __typename?: 'Pagination';
        total: number;
        remaining: number;
        offset: number;
        limit: number;
        currentPage: number;
        totalPages: number;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
      } | null;
    };
  } | null;
};

export type ReportTemplateQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportTemplateId: Types.Scalars['ID'];
}>;

export type ReportTemplateQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    reportTemplate?: {
      __typename?: 'ReportTemplate';
      id: string;
      organizationId: string;
      versionId: string;
      name: string;
      reportNodes: any;
      isLatest: boolean;
      status: Types.ReportTemplateStatus;
      labelColor: string;
      billingActionId?: Types.BillingActionType | null;
      createdAt: DateTime;
      updatedAt: DateTime;
      statementSites: Array<{
        __typename?: 'ReportTemplateStatementSite';
        id: string;
        name: string;
        statements: Array<{
          __typename?: 'ReportTemplateStatement';
          id: string;
          value: string;
          default: boolean;
        }>;
      }>;
      variables: Array<
        | {
            __typename: 'ReportTemplateChoiceVariable';
            id: string;
            alias?: string | null;
            source: Types.ReportTemplateVariableSource;
            label: string;
            site: string;
            category: string;
            controlType: Types.VariableControlType;
            defaultValue?: any | null;
            isEditable: boolean;
            choices: Array<{
              __typename?: 'ReportTemplateVariableChoice';
              id: string;
              label: string;
              value: string;
              default: boolean;
            }>;
            mappings: Array<{
              __typename?: 'MeasurementMapping';
              id: string;
              alias?: string | null;
              name: string;
              shortName?: string | null;
              site?: string | null;
              units?: string | null;
              precision?: number | null;
              properties: Array<{
                __typename?: 'MeasurementProperty';
                name: string;
                value?: any | null;
              }>;
            }>;
          }
        | {
            __typename: 'ReportTemplateMediaAttachmentVariable';
            id: string;
            alias?: string | null;
            source: Types.ReportTemplateVariableSource;
            label: string;
            category: string;
            isEditable: boolean;
            mappings: Array<{
              __typename?: 'MeasurementMapping';
              id: string;
              alias?: string | null;
              name: string;
              shortName?: string | null;
              site?: string | null;
              units?: string | null;
              precision?: number | null;
              properties: Array<{
                __typename?: 'MeasurementProperty';
                name: string;
                value?: any | null;
              }>;
            }>;
          }
        | {
            __typename: 'ReportTemplateStaticVariable';
            id: string;
            alias?: string | null;
            source: Types.ReportTemplateVariableSource;
            label: string;
            site: string;
            category: string;
            units?: string | null;
            precision?: number | null;
            controlType: Types.VariableControlType;
            defaultValue?: any | null;
            isEditable: boolean;
            mappings: Array<{
              __typename?: 'MeasurementMapping';
              id: string;
              alias?: string | null;
              name: string;
              shortName?: string | null;
              site?: string | null;
              units?: string | null;
              precision?: number | null;
              properties: Array<{
                __typename?: 'MeasurementProperty';
                name: string;
                value?: any | null;
              }>;
              rangeGroup?: {
                __typename?: 'MeasurementRangeGroup';
                id: string;
                label: string;
                units?: string | null;
                precision: number;
                ranges: Array<{
                  __typename?: 'MeasurementRange';
                  label: string;
                  level: Types.MeasurementRangeLevel;
                  sex?: string | null;
                  age?: {
                    __typename?: 'NumericalRange';
                    lt?: number | null;
                    lte?: number | null;
                    gt?: number | null;
                    gte?: number | null;
                  } | null;
                  measurement: {
                    __typename?: 'NumericalRange';
                    lt?: number | null;
                    lte?: number | null;
                    gt?: number | null;
                    gte?: number | null;
                  };
                }>;
                sources: Array<{
                  __typename?: 'MeasurementRangeGroupSource';
                  name: string;
                  url: string;
                }>;
              } | null;
            }>;
          }
        | {
            __typename: 'ReportTemplateWallMotionVariable';
            id: string;
            alias?: string | null;
            source: Types.ReportTemplateVariableSource;
            label: string;
            site: string;
            category: string;
            variant: Types.WallMotionVariant;
            defaultValue?: any | null;
            isEditable: boolean;
            mappings: Array<{
              __typename?: 'MeasurementMapping';
              id: string;
              alias?: string | null;
              name: string;
              shortName?: string | null;
              site?: string | null;
              units?: string | null;
              precision?: number | null;
              properties: Array<{
                __typename?: 'MeasurementProperty';
                name: string;
                value?: any | null;
              }>;
            }>;
          }
      >;
    } | null;
  } | null;
};

export type ReportTemplateMinimalQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportTemplateId: Types.Scalars['ID'];
}>;

export type ReportTemplateMinimalQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    reportTemplate?: { __typename?: 'ReportTemplate'; id: string; name: string } | null;
  } | null;
};

export type ReportTemplateVersionsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportTemplateId: Types.Scalars['ID'];
}>;

export type ReportTemplateVersionsQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    reportTemplate?: {
      __typename?: 'ReportTemplate';
      versions: Array<{
        __typename?: 'ReportTemplate';
        id: string;
        versionId: string;
        name: string;
        createdAt: DateTime;
        updatedAt: DateTime;
      }>;
    } | null;
  } | null;
};

export type ReportTemplateDryRunQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  reportTemplateId: Types.Scalars['ID'];
  reportId: Types.Scalars['ID'];
}>;

export type ReportTemplateDryRunQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    reportTemplateDryRun: {
      __typename?: 'ReportTemplateDryRun';
      reportId: string;
      variables: Array<
        | {
            __typename: 'ReportChoiceVariable';
            id: string;
            alias?: string | null;
            value?: any | null;
          }
        | {
            __typename: 'ReportMediaAttachmentVariable';
            id: string;
            alias?: string | null;
            value?: any | null;
          }
        | {
            __typename: 'ReportStaticVariable';
            id: string;
            alias?: string | null;
            value?: any | null;
            units?: string | null;
          }
        | {
            __typename: 'ReportWallMotionVariable';
            id: string;
            alias?: string | null;
            value?: any | null;
          }
      >;
      measurements: Array<{
        __typename?: 'Measurement';
        name: string;
        value?: any | null;
        site?: string | null;
        units?: string | null;
        hash: string;
        properties: Array<{ __typename?: 'MeasurementProperty'; name: string; value?: any | null }>;
        mapping?: {
          __typename?: 'MeasurementMapping';
          id: string;
          name: string;
          shortName?: string | null;
        } | null;
      }>;
    };
  } | null;
};

export type ReportTemplatesQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  getReportTemplatesRequest?: Types.InputMaybe<Types.GetPaginatedReportTemplatesRequest>;
}>;

export type ReportTemplatesQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    reportTemplates: {
      __typename?: 'PaginatedReportTemplates';
      reportTemplates: Array<{
        __typename?: 'ReportTemplate';
        id: string;
        versionId: string;
        name: string;
        isMaster: boolean;
        isLatest: boolean;
        status: Types.ReportTemplateStatus;
        billingActionId?: Types.BillingActionType | null;
        labelColor: string;
        createdAt: DateTime;
        updatedAt: DateTime;
      }>;
    };
  } | null;
};

export type ReportsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  getReportsRequest?: Types.InputMaybe<Types.GetPaginatedReportsRequest>;
}>;

export type ReportsQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    reports: {
      __typename?: 'PaginatedReports';
      reports: Array<{
        __typename?: 'Report';
        id: string;
        organizationId: string;
        status: Types.ReportStatus;
        createdAt: DateTime;
        updatedAt: DateTime;
        variables: Array<
          | {
              __typename: 'ReportChoiceVariable';
              id: string;
              alias?: string | null;
              value?: any | null;
            }
          | { __typename: 'ReportMediaAttachmentVariable' }
          | {
              __typename: 'ReportStaticVariable';
              id: string;
              alias?: string | null;
              value?: any | null;
              units?: string | null;
            }
          | { __typename: 'ReportWallMotionVariable' }
        >;
        reportTemplate: {
          __typename?: 'ReportTemplate';
          id: string;
          versionId: string;
          name: string;
          labelColor: string;
        };
        distributions: Array<{
          __typename?: 'Distribution';
          id: string;
          organizationId: string;
          reportId: string;
          status: Types.DistributionStatus;
          trigger: Types.DistributionTrigger;
          payload:
            | {
                __typename: 'DistributionEmailPayload';
                sharerId: string;
                sharerEmail: string;
                sharerName: string;
                shareeEmail: string;
              }
            | {
                __typename: 'DistributionFaxPayload';
                to: { __typename?: 'DistributionFaxToInfo'; faxNumber: string };
                from: {
                  __typename?: 'DistributionFaxFromInfo';
                  sharerId: string;
                  sharerEmail: string;
                  sharerName: string;
                };
              }
            | {
                __typename: 'DistributionHealthLinkPayload';
                to: {
                  __typename?: 'DistributionHealthLinkProviderToInfo';
                  practitionerId: string;
                  organizationId: string;
                  organizationName: string;
                  endpointId: string;
                  endpointName: string;
                  providerNumber: string;
                  practitionerName: {
                    __typename?: 'HealthLinkName';
                    text: string;
                    family: string;
                    given: Array<string>;
                    prefix: Array<string>;
                    suffix: Array<string>;
                  };
                  organizationAddress: {
                    __typename?: 'HealthLinkAddress';
                    city?: string | null;
                    country?: string | null;
                    line?: Array<string> | null;
                    postalCode?: string | null;
                    state?: string | null;
                    text?: string | null;
                    type?: string | null;
                    use?: string | null;
                  };
                };
                from: {
                  __typename?: 'DistributionHealthLinkProviderFromInfo';
                  providerNumber: string;
                  name: string;
                };
              }
            | {
                __typename: 'DistributionMedicalObjectsPayload';
                to: {
                  __typename?: 'DistributionMedicalObjectsProviderInfo';
                  providerNumber: string;
                  providerDetails: {
                    __typename?: 'MedicalObjectsIntegrationProvider';
                    id: string;
                    firstName?: string | null;
                    middleName?: string | null;
                    lastName?: string | null;
                    prefix?: string | null;
                    suffix?: string | null;
                  };
                };
                from: {
                  __typename?: 'DistributionMedicalObjectsProviderInfo';
                  providerNumber: string;
                  providerDetails: {
                    __typename?: 'MedicalObjectsIntegrationProvider';
                    id: string;
                    firstName?: string | null;
                    middleName?: string | null;
                    lastName?: string | null;
                    prefix?: string | null;
                    suffix?: string | null;
                  };
                };
              };
        }>;
        labels: Array<{
          __typename?: 'Label';
          id: string;
          name: string;
          description?: string | null;
          color: string;
          createdAt: DateTime;
          updatedAt: DateTime;
        }>;
      }>;
      pagination?: {
        __typename?: 'Pagination';
        total: number;
        remaining: number;
        offset: number;
        limit: number;
        currentPage: number;
        totalPages: number;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
      } | null;
    };
  } | null;
};

export type UserQueryVariables = Types.Exact<{ [key: string]: never }>;

export type UserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    name: string;
    email: string;
    picture?: string | null;
    extraPermissions: Array<string>;
    intercomHash: string;
    organizationMemberships: Array<{
      __typename?: 'OrganizationMembership';
      organizationId: string;
      name: string;
      permissions: Array<string>;
      roles: Array<{ __typename?: 'Role'; id: string; name: string }>;
    }>;
    settings: {
      __typename?: 'UserSettings';
      defaultOrganization?: string | null;
      finalizeBehavior: Types.FinalizeBehavior;
    };
  } | null;
};

export type UsersMinimalQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
}>;

export type UsersMinimalQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    users: Array<{ __typename?: 'User'; id: string; name: string }>;
  } | null;
};

export type UsersQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
}>;

export type UsersQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    users: Array<{
      __typename?: 'User';
      id: string;
      name: string;
      email: string;
      picture?: string | null;
      organizationMemberships: Array<{
        __typename?: 'OrganizationMembership';
        organizationId: string;
        roles: Array<{ __typename?: 'Role'; id: string; name: string }>;
      }>;
    }>;
  } | null;
};

export type WorklistEntriesQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
  request?: Types.InputMaybe<Types.GetPaginatedWorklistEntriesRequest>;
}>;

export type WorklistEntriesQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    worklist: {
      __typename?: 'Worklist';
      entries: {
        __typename?: 'PaginatedWorklistEntries';
        results: Array<{
          __typename?: 'WorklistEntry';
          id: string;
          organizationId: string;
          studyInstanceUID: string;
          patientId: string;
          patientFirstName: string;
          patientLastName: string;
          patientDob: DateTime;
          patientSex: string;
          patientHeight?: number | null;
          patientWeight?: number | null;
          modality: string;
          status: Types.WorklistEntryStatus;
          reportId?: string | null;
          createdAt: DateTime;
          updatedAt: DateTime;
          machineAet?: { __typename?: 'MachineAet'; id: string; aet: string; name: string } | null;
          reportTemplate: {
            __typename?: 'ReportTemplate';
            id: string;
            name: string;
            labelColor: string;
          };
          performingPhysician: { __typename?: 'PerformingPhysician'; id: string; name: string };
        }>;
        pagination?: {
          __typename?: 'Pagination';
          total: number;
          remaining: number;
          offset: number;
          limit: number;
          currentPage: number;
          totalPages: number;
          hasNextPage: boolean;
          hasPreviousPage: boolean;
        } | null;
      };
    };
  } | null;
};
