import { Text } from '@chakra-ui/react';
import type { AutoCompleteResult } from '@piccolohealth/echo-common';
import {
  MultiSelect,
  type MultiSelectOption,
  type MultiSelectProps,
  type OnChangeRequest,
  PaginationSelectFooter,
} from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import debouncePromise from 'debounce-promise';
import React from 'react';
import { useAutoCompleteReportVariablesInfiniteQuery } from '../../graphql/hooks/useAutoComplete';
import { useAppContext } from '../../hooks/useAppContext';

const DEBOUNCE_MS = 750;

export interface MultiSelectReportVariableProps
  extends Omit<MultiSelectProps<AutoCompleteResult>, 'value' | 'onChange' | 'options'> {
  alias: string;
  selectedValues: MultiSelectOption<AutoCompleteResult>[];
  isDisabled?: boolean;
  onChange: (value: MultiSelectOption<AutoCompleteResult>[]) => void;
}

export const MultiSelectReportVariable = (props: MultiSelectReportVariableProps) => {
  const { alias, onChange, isDisabled, components, ...rest } = props;
  const { organization } = useAppContext();

  const [inputValue, setInputValue] = React.useState('');

  const req = {
    organizationId: organization.id,
    request: {
      alias,
      filter: inputValue,
    },
  };

  const query = useAutoCompleteReportVariablesInfiniteQuery(req, {
    enabled: false,
    keepPreviousData: true,
    cacheTime: 0,
  });

  const isLoading = query.isFetching;
  const hasMore = query.hasNextPage ?? false;

  const pages = query.data?.pages ?? [];
  const pagination = P.first(pages)?.organization?.autoComplete?.reportVariables.pagination;

  const options: MultiSelectOption<AutoCompleteResult>[] = P.uniqBy(
    pages
      .flatMap((page) => page.organization?.autoComplete.reportVariables.results ?? [])
      .map((variable) => ({
        id: variable.value,
        value: variable.value,
        label: P.upperFirst(variable.value),
        raw: variable,
      })),
    (o) => o.label,
  );

  const selectedValues = React.useMemo(() => {
    return props.selectedValues.map((selectedValue) => {
      return {
        ...selectedValue,
        label: P.upperFirst(selectedValue.value),
      };
    });
  }, [props.selectedValues]);

  const debouncedRefetch = React.useMemo(
    () => debouncePromise(query.refetch, DEBOUNCE_MS),
    [query.refetch],
  );

  const onSearch = React.useCallback(
    (value: string) => {
      setInputValue(value);
      debouncedRefetch();
    },
    [debouncedRefetch],
  );

  const onChangePrime = React.useCallback(
    (req: OnChangeRequest<AutoCompleteResult>) => {
      onChange(req.values);
      debouncedRefetch();
    },
    [onChange, debouncedRefetch],
  );

  const onOpen = React.useCallback(() => {
    if (!query.isFetched) {
      query.refetch();
    }
  }, [query]);

  return (
    <MultiSelect
      {...rest}
      options={options}
      selectedValues={selectedValues}
      inputValue={inputValue}
      onOpen={onOpen}
      onChange={onChangePrime}
      onInputChange={onSearch}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isCreatable
      components={{
        Value: (props) => (
          <Text mr={1}>
            {P.upperFirst(props.option.label)}
            {props.index + 1 !== selectedValues?.length && ', '}
          </Text>
        ),
        Footer: () =>
          pagination ? (
            <PaginationSelectFooter
              items='result'
              total={pagination.total}
              fetchNextPage={query.fetchNextPage}
              isLoading={isLoading}
              hasMore={hasMore}
            />
          ) : null,
        ...components,
      }}
    />
  );
};
