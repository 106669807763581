export const readPdfAsImage = async (file: File): Promise<File> => {
  const reader = new FileReader();

  const blob: Blob = await new Promise((resolve, reject) => {
    reader.onload = async () => {
      const pdfjsLib = await import('pdfjs-dist');
      const pdfjsWorker = await import('pdfjs-dist/build/pdf.worker.mjs?worker');
      pdfjsLib.GlobalWorkerOptions.workerPort = new pdfjsWorker.default();

      const buffer = await file.arrayBuffer();

      // Load the PDF document
      const pdf = await pdfjsLib.getDocument(buffer).promise;

      // Get the first page of the PDF
      const page = await pdf.getPage(1);

      // Set the canvas size to match the PDF page size
      const canvas = document.createElement('canvas');
      const canvasContext = canvas.getContext('2d') as CanvasRenderingContext2D;
      const viewport = page.getViewport({ scale: 2 });
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      // Render the PDF page on the canvas
      const renderContext = {
        canvasContext,
        viewport,
      };
      await page.render(renderContext).promise;

      canvas.toBlob(
        (blob) => (blob ? resolve(blob) : reject('Could not convert pdf to image')),
        'image/png',
        1,
      );
    };

    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });

  return new File([blob], 'image.png');
};
