import { Button, Icon, Stack, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { COMPANY_NAME, renderPiccoloError } from '@piccolohealth/echo-common';
import { stringParam, useQueryParams } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FaRegCheckCircle } from 'react-icons/fa';
import { Navigate, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { HookedFormItem } from '../../components/forms/hookform/HookedFormItem';
import { HookedInput } from '../../components/forms/hookform/HookedInput';
import { HookedSubmitButton } from '../../components/forms/hookform/HookedSubmitButton';
import { useAuth } from '../../context/AuthContext';
import { asPiccoloError } from '../../utils/errors';
import { AuthCard } from './components/AuthCard';
import { AuthLayout } from './components/AuthLayout';

type ResendStatus = 'idle' | 'loading' | 'success' | 'error';

type FormValues = {
  password: string;
  confirmPassword: string;
};

const schema: Yup.SchemaOf<FormValues> = Yup.object({
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters'),
  confirmPassword: Yup.string()
    .required('Please confirm your password')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
});

export const VerifyInvite = () => {
  const [params] = useQueryParams({
    email: stringParam,
    oobCode: stringParam,
  });
  const { email, oobCode } = params;

  const [resendStatus, setResendStatus] = React.useState<ResendStatus>('idle');

  const { verifyInvite, requestVerifyInviteEmail, logout } = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    logout();
  }, [logout]);

  const methods = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const onResendVerifyInvite = async () => {
    if (!email) {
      return;
    }

    setResendStatus('loading');
    await requestVerifyInviteEmail(email)
      .then(() => setResendStatus('success'))
      .catch(() => {
        setResendStatus('error');
        methods.setError('password', {
          message: 'Failed to resend verification email. Please contact support.',
        });
      });
  };

  const onSubmit = async (data: FormValues) => {
    if (!oobCode || !email) {
      return;
    }

    try {
      await verifyInvite(oobCode, email, data.password);
    } catch (error) {
      const piccoloError = asPiccoloError(error);
      const renderedError = renderPiccoloError(piccoloError);
      methods.reset({ password: '', confirmPassword: '' });
      methods.setError('password', { message: renderedError.message });
    }
  };

  const onClickLogin = () => {
    navigate('/login');
  };

  if (!oobCode || !email) {
    return <Navigate to='/login' />;
  }

  const content = P.run(() => {
    if (resendStatus === 'success') {
      return (
        <AuthCard>
          <Stack spacing={4} align='center'>
            <Icon as={FaRegCheckCircle} color='success' boxSize={16} />
            <Text fontSize='sm' textAlign='center'>
              Verification email sent successfully. Please check your email to continue.
            </Text>
          </Stack>
        </AuthCard>
      );
    }

    if (methods.formState.isSubmitSuccessful) {
      return (
        <AuthCard>
          <Stack spacing={4} align='center'>
            <Icon as={FaRegCheckCircle} color='success' boxSize={16} />
            <Text fontSize='sm' textAlign='center'>
              Your password has been set successfully. Please login to continue.
            </Text>
            <Button w='full' size='md' colorScheme='purple' onClick={onClickLogin}>
              Login
            </Button>
          </Stack>
        </AuthCard>
      );
    }

    return (
      <AuthCard>
        <Stack spacing={0}>
          <Text fontWeight='semibold' fontSize='2xl'>
            Welcome to {COMPANY_NAME}
          </Text>
          <Text fontSize='sm' color='secondary'>
            Please set a password to complete your account setup
          </Text>
        </Stack>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack spacing={4}>
              <HookedFormItem name='password' label='Password'>
                <HookedInput
                  name='password'
                  type='password'
                  size='md'
                  placeholder='Enter new password'
                />
              </HookedFormItem>
              <HookedFormItem name='confirmPassword' label='Confirm Password'>
                <HookedInput
                  name='confirmPassword'
                  type='password'
                  size='md'
                  placeholder='Confirm new password'
                />
              </HookedFormItem>
              <HookedSubmitButton
                isDisabled={false}
                size='md'
                mt={4}
                loadingText='Setting password...'
              >
                Set Password
              </HookedSubmitButton>
            </Stack>
          </form>
        </FormProvider>
        <Button
          variant='link'
          w='fit-content'
          cursor='pointer'
          onClick={onResendVerifyInvite}
          isLoading={resendStatus === 'loading'}
          loadingText='Resending verification email...'
        >
          Code expired? Resend verification email
        </Button>
      </AuthCard>
    );
  });

  return <AuthLayout>{content}</AuthLayout>;
};
