import React from 'react';
import { useController } from 'react-hook-form';
import { AutoCompleteEmail, type AutoCompleteEmailProps } from '../AutoCompleteEmail';

interface Props extends Omit<AutoCompleteEmailProps, 'value' | 'onChange'> {
  name: string;
}

export const HookedAutoCompleteEmail = (props: Props) => {
  const { name, ...rest } = props;
  const { field } = useController({ name });

  return <AutoCompleteEmail {...rest} onChange={field.onChange} value={field.value} />;
};
