import type * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { CreateDistribution } from '../mutations/createDistribution.mutation';

export const useCreateDistributionMutation = (
  options?: MutationOptions<
    Types.CreateDistributionMutation,
    Types.CreateDistributionMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(CreateDistribution), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.includes('distributions') || query.queryKey.includes('reports'),
      });
    },
  });
};
