import { Icon } from '@chakra-ui/react';
import type { Command } from '@piccolohealth/ui';
import React from 'react';
import { FaBook, FaFlask } from 'react-icons/fa';
import type { TiptapCommandMenuState } from '../contextMenuRenderer';
import { measurementsPages } from './measurements';
import { statementsPages } from './statements';
import { SCREENSHOTS } from '../../../../../utils/images';

export const reportEditingPages = (
  state: TiptapCommandMenuState,
): Command.Page<TiptapCommandMenuState>[] => {
  return [
    {
      kind: 'actions',
      id: 'ROOT',
      title: 'What would you like to do?',
      searchable: false,
      actions: [
        {
          kind: 'goto',
          id: 'measurements',
          title: 'Measurements',
          raw: 'Measurements',
          description: 'Add a measurement',
          leftIcon: () => <Icon as={FaFlask} />,
          tooltip: () => ({
            kind: 'image',
            src: SCREENSHOTS.measurements,
            description: 'Add a measurement from a list of known measurements',
          }),
        },
        {
          kind: 'goto',
          id: 'statements',
          title: 'Statements',
          raw: 'Statements',
          description: 'Add a statement',
          leftIcon: () => <Icon as={FaBook} />,
          tooltip: () => ({
            kind: 'image',
            src: SCREENSHOTS.statements,
            description: 'Add a statement from your list of saved statements',
          }),
        },
      ],
    },
    ...statementsPages(state),
    ...measurementsPages(state),
  ];
};
