import type * as Types from '@piccolohealth/echo-common';
import { createGqlQuery } from '../fetcher';
import {
  Report,
  ReportAttachments,
  ReportAttachmentsMinimal,
  ReportDistributions,
  ReportDistributionsMinimal,
  ReportMinimal,
  ReportPdfs,
  ReportRelatedReports,
  ReportRelatedReportsMinimal,
  ReportRelatedVariables,
  ReportSharesMinimal,
  ReportStudyInstances,
  ReportStudyInstancesMinimal,
  ReportType,
} from '../queries/report.query';

export const getReportBaseKey = (variables: Types.ReportMinimalQueryVariables) => [
  'report',
  variables,
];

export const useReportQuery = createGqlQuery<Types.ReportQueryVariables, Types.ReportQuery>(
  (variables) => ['report', variables],
  Report,
);

export const useReportTypeQuery = createGqlQuery<
  Types.ReportTypeQueryVariables,
  Types.ReportTypeQuery
>((variables) => ['report', variables, 'type'], ReportType);

export const useReportMinimalQuery = createGqlQuery<
  Types.ReportMinimalQueryVariables,
  Types.ReportMinimalQuery
>((variables) => ['report', variables, 'minimal'], ReportMinimal);

export const useReportRelatedVariablesQuery = createGqlQuery<
  Types.ReportRelatedVariablesQueryVariables,
  Types.ReportRelatedVariablesQuery
>((variables) => ['report', variables, 'related', 'variables'], ReportRelatedVariables);

export const useReportRelatedReportsQuery = createGqlQuery<
  Types.ReportRelatedReportsQueryVariables,
  Types.ReportRelatedReportsQuery
>((variables) => ['report', variables, 'related'], ReportRelatedReports);

export const useReportRelatedReportsMinimalQuery = createGqlQuery<
  Types.ReportRelatedReportsMinimalQueryVariables,
  Types.ReportRelatedReportsMinimalQuery
>((variables) => ['report', variables, 'related', 'minimal'], ReportRelatedReportsMinimal);

export const useReportStudyInstancesMinimalQuery = createGqlQuery<
  Types.ReportStudyInstancesMinimalQueryVariables,
  Types.ReportStudyInstancesMinimalQuery
>(
  (variables) => ['report', variables, 'study', 'instances', 'minimal'],
  ReportStudyInstancesMinimal,
);
export const useReportStudyInstancesQuery = createGqlQuery<
  Types.ReportStudyInstancesQueryVariables,
  Types.ReportStudyInstancesQuery
>((variables) => ['report', variables, 'study', 'instances'], ReportStudyInstances);

export const useReportAttachmentsQuery = createGqlQuery<
  Types.ReportAttachmentsQueryVariables,
  Types.ReportAttachmentsQuery
>((variables) => ['report', variables, 'attachments'], ReportAttachments);

export const useReportAttachmentsMinimalQuery = createGqlQuery<
  Types.ReportAttachmentsMinimalQueryVariables,
  Types.ReportAttachmentsMinimalQuery
>((variables) => ['report', variables, 'attachments', 'minimal'], ReportAttachmentsMinimal);

export const useReportPdfsQuery = createGqlQuery<
  Types.ReportPdfsQueryVariables,
  Types.ReportPdfsQuery
>((variables) => ['report', variables, 'pdfs'], ReportPdfs);

export const useReportSharesMinimalQuery = createGqlQuery<
  Types.ReportSharesMinimalQueryVariables,
  Types.ReportSharesMinimalQuery
>((variables) => ['report', variables, 'reportShares'], ReportSharesMinimal);

export const useReportDistributionsMinimalQuery = createGqlQuery<
  Types.ReportDistributionsMinimalQueryVariables,
  Types.ReportDistributionsMinimalQuery
>((variables) => ['report', variables, 'distributions', 'minimal'], ReportDistributionsMinimal);

export const useReportDistributionsQuery = createGqlQuery<
  Types.ReportDistributionsQueryVariables,
  Types.ReportDistributionsQuery
>((variables) => ['report', variables, 'distributions'], ReportDistributions);
