import type * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { UpdateReport } from '../mutations/updateReport.mutation';
import { useReportMinimalQuery, useReportQuery } from './useReportQuery';
import { P } from '@piccolohealth/util';

const updater = (resp: Types.UpdateReportMutation, old: any) => {
  const newCache = P.cloneDeep(old);

  newCache.organization.report.reportTemplate.versionId =
    resp?.updateReport?.reportTemplate.versionId;
  newCache.organization.report.changesetId = resp?.updateReport?.changesetId;
  newCache.organization.report.updatedAt = resp?.updateReport?.updatedAt;
  newCache.organization.report.updatedAt = resp?.updateReport?.updatedAt;
  newCache.organization.report.variables = resp?.updateReport?.variables;
  newCache.organization.report.statementSites = resp?.updateReport?.statementSites;

  return newCache;
};

export const useUpdateReportMutation = (
  options?: MutationOptions<Types.UpdateReportMutation, Types.UpdateReportMutationVariables>,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(UpdateReport), {
    ...options,
    retry: 2,
    onSuccess: async (resp, variables, ctx) => {
      await options?.onSuccess?.(resp, variables, ctx);

      queryClient.setQueriesData(
        {
          queryKey: useReportQuery.getKey({
            organizationId: variables.organizationId,
            reportId: variables.reportId,
          }),
          exact: true,
        },
        (old) => updater(resp, old),
      );

      queryClient.setQueriesData(
        useReportMinimalQuery.getKey({
          organizationId: variables.organizationId,
          reportId: variables.reportId,
        }),
        (old) => updater(resp, old),
      );
    },
  });
};
