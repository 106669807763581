import {
  type Instance,
  isPlayableDicom,
  Stage,
  type Study,
  View,
} from '@piccolohealth/echo-common';
import { useHotkey } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useFullscreen, useLocalStorage, useMethods } from 'react-use';
import { ScrollDirection, useScroll } from '../../../hooks/useScroll';
import { insideBound } from '../../../utils/overlap';
import { Layout, layoutToCount } from '../controls/Layout';
import { type ImageViewerMode, ImageViewerModeType } from '../controls/ModeControl';
import { Sync } from '../controls/SyncControl';
import { useMeasurementControl } from '../measurements/tool/hooks/useMeasurementControl';
import { STAGE_ORDER, VIEW_ORDER } from '../utils/stress';
import { Synchronizer } from '../utils/synchronizer';
import { usePlayerRefs } from './usePlayerRefs';

const DEFAULT_MODE = {
  type: ImageViewerModeType.Normal,
} as ImageViewerMode;
const DEFAULT_IS_PLAYING = true;
const DEFAULT_IS_FULLSCREEN = false;
const DEFAULT_LAYOUT = Layout.layout1x1;
const DEFAULT_SYNC = Sync.None;
const DEFAULT_CURRENT_INDEX = 0;
const DEFAULT_BRIGHTNESS = 1.0;
const DEFAULT_CONTRAST = 1.0;
const DEFAULT_SPEED = 1.0;
const DEFAULT_TOOLBAR_POSITION = 'top' as const;
const DEFAULT_IS_REPORT_SHOWING = false;
const DEFAULT_IS_MEASUREMENTS_SHOWING = false;

interface ImageViewerSettings {
  layout: Layout;
  toolbarPosition: 'top' | 'bottom';
  isMeasurementsShowing: boolean;
  isReportShowing: boolean;
}

export type InstanceStack = {
  instances: Instance[];
  index: number | null;
  view: View;
  stage: string;
};

export type Viewport = {
  stack: InstanceStack;
  index: number;
  total: number;
};

interface ImageViewerState {
  currentIndex: number;
  mode: ImageViewerMode;
  isPlaying: boolean;
  isFullscreen: boolean;
  layout: Layout;
  sync: Sync;
  brightness: number;
  contrast: number;
  speed: number;
  toolbarPosition: 'top' | 'bottom';
  isReportShowing: boolean;
  isMeasurementsShowing: boolean;
  hasStressInstances: boolean;
  synchronizer: Synchronizer;

  instances: InstanceStack[];
  viewports: Viewport[];
}

function imageViewerMethods(state: ImageViewerState) {
  return {
    pause() {
      return { ...state, isPlaying: false };
    },
    play() {
      return { ...state, isPlaying: true };
    },
    playPause() {
      return { ...state, isPlaying: !state.isPlaying };
    },
    changeBrightness(brightness: number) {
      return { ...state, brightness };
    },
    changeContrast(contrast: number) {
      return { ...state, contrast };
    },
    changeSpeed(speed: number) {
      return { ...state, speed };
    },
    changeToolbarPosition(toolbarPosition: 'top' | 'bottom') {
      return { ...state, toolbarPosition };
    },
    changeFullscreen(value: boolean) {
      return { ...state, isFullscreen: value };
    },
    changeLayout(layout: Layout) {
      const viewports = getViewports(state.instances, state.currentIndex, layout, state.mode);

      return {
        ...state,
        viewports,
        layout,
      };
    },
    changeMode(mode: ImageViewerMode) {
      const layout = mode.type === ImageViewerModeType.Stress ? Layout.layout1x2 : Layout.layout2x2;
      const sync = mode.type === ImageViewerModeType.Stress ? Sync.Rate : Sync.None;

      const instances: InstanceStack[] = getInstancesStack(
        state.instances.flatMap((v) => v.instances),
        mode,
      );

      const currentIndex =
        mode.type === ImageViewerModeType.Stress
          ? instances.findIndex((stack) => stack.view === mode.view)
          : DEFAULT_CURRENT_INDEX;

      const viewports: Viewport[] = getViewports(instances, currentIndex, layout, mode);

      state.synchronizer.setEnabled(sync === Sync.Rate);

      return {
        ...state,
        currentIndex,
        mode,
        layout,
        sync,
        instances,
        viewports,
      };
    },

    seek(count: number) {
      switch (state.mode.type) {
        case ImageViewerModeType.Stress: {
          const proposedIndex = state.currentIndex + count;
          const nextCurrentIndex: number = P.run(() => {
            if (proposedIndex < 0) {
              return proposedIndex + state.instances.length;
            } else if (proposedIndex >= state.instances.length) {
              return 0;
            } else {
              return proposedIndex;
            }
          });

          const view = state.instances[nextCurrentIndex].view;
          const nextMode = {
            type: ImageViewerModeType.Stress,
            view,
          };

          const viewports = getViewports(state.instances, nextCurrentIndex, state.layout, nextMode);

          return { ...state, currentIndex: nextCurrentIndex, mode: nextMode, viewports };
        }
        case ImageViewerModeType.Normal: {
          const proposedIndex = state.currentIndex + count;
          const nextCurrentIndex: number = P.run(() => {
            if (proposedIndex < 0) {
              return proposedIndex + state.instances.length;
            } else if (proposedIndex >= state.instances.length) {
              return 0;
            } else {
              return proposedIndex;
            }
          });

          const viewports = getViewports(
            state.instances,
            nextCurrentIndex,
            state.layout,
            state.mode,
          );

          return { ...state, currentIndex: nextCurrentIndex, viewports };
        }
      }
    },
    scroll(direction: ScrollDirection) {
      state.synchronizer.scroll(direction);
      return this.pause();
    },
    nextClip() {
      const count = layoutToCount(state.layout);
      return this.seek(count);
    },
    previousClip() {
      return this.seek(-layoutToCount(state.layout));
    },
    nextFrame() {
      return this.scroll(ScrollDirection.Down);
    },
    previousFrame() {
      return this.scroll(ScrollDirection.Up);
    },
    clickInstanceStack(instanceStack: InstanceStack) {
      const mode = { ...state.mode, view: instanceStack.view };
      const currentIndex =
        mode.type === ImageViewerModeType.Stress
          ? state.instances.findIndex((stack) => stack.view === instanceStack.view)
          : state.instances.indexOf(instanceStack);

      const viewports = getViewports(state.instances, currentIndex, state.layout, mode);

      return { ...state, currentIndex, mode, viewports };
    },
    clickViewportInstance(instance: Instance) {
      const viewports = state.viewports.map((viewport) => {
        if (viewport.stack.instances.includes(instance)) {
          return {
            ...viewport,
            stack: { ...viewport.stack, index: viewport.stack.instances.indexOf(instance) ?? 0 },
          };
        }

        return viewport;
      });

      return { ...state, viewports };
    },
    toggleShowReport() {
      return {
        ...state,
        isReportShowing: !state.isReportShowing,
      };
    },
    toggleShowMeasurements() {
      return {
        ...state,
        isMeasurementsShowing: !state.isMeasurementsShowing,
      };
    },
  };
}

const getInstancesStack = (instances: Instance[], mode: ImageViewerMode): InstanceStack[] => {
  switch (mode.type) {
    case ImageViewerModeType.Stress: {
      const grouped = Object.values(
        P.groupBy(
          instances.filter(
            (instance) =>
              instance.dicom.stage !== Stage.Unknown &&
              instance.dicom.view !== View.Unknown &&
              !!instance.mp4Url,
          ),
          (instance) => `${instance.dicom.stage}-${instance.dicom.view}`,
        ),
      ).map((groupedInstances) => {
        const instances = groupedInstances ?? [];
        const stage = instances[0]?.dicom.stage || Stage.Unknown;
        const view = instances[0]?.dicom.view || View.Unknown;
        // Take the latest instance for a view.
        const sortedByLatest = P.orderBy(
          instances,
          (instance) => instance.dicom.instanceNumber,
        ).reverse();

        return {
          instances: sortedByLatest,
          stage,
          view,
          index: 0,
        };
      });

      const sortedByStage = P.orderBy(grouped, (instance) => STAGE_ORDER.indexOf(instance.stage));
      const sorted = P.orderBy(sortedByStage, (instance) => VIEW_ORDER.indexOf(instance.view));

      return sorted;
    }

    case ImageViewerModeType.Normal: {
      return instances.map((instance) => {
        return {
          instances: [instance],
          stage: instance.dicom.stage ?? Stage.Unknown,
          view: instance.dicom.view ?? View.Unknown,
          index: null,
        };
      });
    }
  }
};

const getViewports = (
  instances: InstanceStack[],
  index: number,
  layout: Layout,
  mode: ImageViewerMode,
) => {
  const viewports: Viewport[] = instances.map((stack, index) => {
    const total = stack.index === null ? instances.length : stack.instances.length;

    return {
      index,
      stack,
      total,
    };
  });

  switch (mode.type) {
    case ImageViewerModeType.Normal:
      return viewports.slice(index, index + layoutToCount(layout));
    case ImageViewerModeType.Stress: {
      return viewports
        .filter((viewport) => viewport.stack.view === mode.view)
        .slice(0, 0 + layoutToCount(layout));
    }
  }
};

export interface ImageViewerActionsOptions {
  isPlaying?: boolean;
  layout?: Layout;
  speed?: number;
  toolbarPosition?: 'top' | 'bottom';
  brightness?: number;
  contrast?: number;
  minSpeed?: number;
  maxSpeed?: number;
  mode?: ImageViewerMode;
  sync?: Sync;
}

const useImageViewerActionsInternal = (study: Study, options: ImageViewerActionsOptions) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const gridRef = React.useRef<HTMLDivElement>(null);
  const playerRefs = usePlayerRefs();

  const [settings, setImageViewerSettings] = useLocalStorage<ImageViewerSettings>(
    'image-viewer-settings',
    {
      layout: DEFAULT_LAYOUT,
      toolbarPosition: DEFAULT_TOOLBAR_POSITION,
      isMeasurementsShowing: DEFAULT_IS_MEASUREMENTS_SHOWING,
      isReportShowing: DEFAULT_IS_REPORT_SHOWING,
    },
  );

  const isPlaying = options.isPlaying ?? DEFAULT_IS_PLAYING;
  const isFullscreen = DEFAULT_IS_FULLSCREEN;
  const layout = options.layout ?? settings?.layout ?? DEFAULT_LAYOUT;
  const mode = options.mode ?? DEFAULT_MODE;
  const brightness = options.brightness ?? DEFAULT_BRIGHTNESS;
  const contrast = options.contrast ?? DEFAULT_CONTRAST;
  const speed = options.speed ?? DEFAULT_SPEED;
  const toolbarPosition =
    options.toolbarPosition ?? settings?.toolbarPosition ?? DEFAULT_TOOLBAR_POSITION;
  const sync = options.sync ?? DEFAULT_SYNC;
  const currentIndex = DEFAULT_CURRENT_INDEX;
  const isReportShowing = settings?.isReportShowing ?? DEFAULT_IS_REPORT_SHOWING;
  const isMeasurementsShowing = settings?.isMeasurementsShowing ?? DEFAULT_IS_MEASUREMENTS_SHOWING;

  const { instances, viewports, hasStressInstances } = React.useMemo(() => {
    const imageInstances = study.instances.filter((instance) =>
      isPlayableDicom({
        modality: instance.dicom.modality,
        transferSyntaxUID: instance.dicom.transferSyntaxUID ?? undefined,
        imageType: instance.dicom.imageType ?? undefined,
      }),
    );

    const currentIndex = DEFAULT_CURRENT_INDEX;

    const instances: InstanceStack[] = getInstancesStack(
      P.orderBy(imageInstances, (instance) => instance.dicom.instanceNumber),
      mode,
    );

    const viewports: Viewport[] = getViewports(instances, currentIndex, layout, mode);

    const hasStressInstances = study.instances.some(
      (instance) => instance.dicom.stage !== Stage.Unknown,
    );

    return {
      instances,
      hasStressInstances,
      viewports,
      currentIndex,
    };
  }, [study.instances, mode, layout]);

  const initialState = React.useMemo(() => {
    return {
      currentIndex,
      isPlaying,
      isFullscreen,
      layout,
      brightness,
      contrast,
      speed,
      toolbarPosition,
      mode,
      sync,
      isReportShowing,
      isMeasurementsShowing,
      hasStressInstances,
      synchronizer: new Synchronizer(),
      instances,
      viewports,
    };
  }, [
    currentIndex,
    isPlaying,
    isFullscreen,
    layout,
    brightness,
    contrast,
    speed,
    toolbarPosition,
    mode,
    sync,
    isReportShowing,
    isMeasurementsShowing,
    hasStressInstances,
    instances,
    viewports,
  ]);

  const [state, methods] = useMethods(imageViewerMethods, initialState);

  // Save settings to local storage
  React.useEffect(() => {
    setImageViewerSettings({
      layout: state.layout,
      toolbarPosition: state.toolbarPosition,
      isMeasurementsShowing: state.isMeasurementsShowing,
      isReportShowing: state.isReportShowing,
    });
  }, [
    setImageViewerSettings,
    state.layout,
    state.toolbarPosition,
    state.isMeasurementsShowing,
    state.isReportShowing,
  ]);

  useHotkey(' ', methods.playPause, { filterForms: true }, []); // Spacebar
  useHotkey('ArrowRight', methods.nextClip, { filterForms: true }, [state.layout]);
  useHotkey('ArrowLeft', methods.previousClip, { filterForms: true }, [state.layout]);
  useHotkey('ArrowDown', methods.nextFrame, { filterForms: true }, [state.layout]);
  useHotkey('ArrowUp', methods.previousFrame, { filterForms: true }, [state.layout]);
  useScroll((direction, coords) => {
    if (gridRef.current && insideBound(gridRef.current.getBoundingClientRect(), coords)) {
      methods.scroll(direction);
    }
  });

  useFullscreen(containerRef, state.isFullscreen, {
    onClose: () => methods.changeFullscreen(false),
  });

  const firstViewport = P.first(state.viewports) ?? null;
  const firstInstance = P.first(firstViewport?.stack?.instances ?? []) ?? null;
  const playerRef = playerRefs[0];

  const measurementControl = useMeasurementControl({
    instance: firstInstance,
    playerRef,
    changeLayout: methods.changeLayout,
  });

  return {
    ...state,
    ...methods,
    measurementControl,
    containerRef,
    gridRef,
    playerRefs,
  };
};

export type UseImageViewerActionsReturn = ReturnType<typeof useImageViewerActionsInternal>;

export const useImageViewerActions = (
  study: Study,
  options: ImageViewerActionsOptions,
): UseImageViewerActionsReturn => useImageViewerActionsInternal(study, options);
