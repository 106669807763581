import type * as Types from '@piccolohealth/echo-common';
import { createGqlQuery } from '../fetcher';
import { MeasurementRangeGroups } from '../queries/dicom.query';

export const getMeasurementRangeGroupsBaseKey = (
  variables: Types.MeasurementRangeGroupsQueryVariables,
) => ['dicom', 'measurementRangeGroups', variables];

export const useMeasurementRangeGroupsQuery = createGqlQuery<
  Types.MeasurementRangeGroupsQueryVariables,
  Types.MeasurementRangeGroupsQuery
>(getMeasurementRangeGroupsBaseKey, MeasurementRangeGroups);
