import { HStack } from '@chakra-ui/react';
import {
  type AutoCompleteResult,
  type ReportTemplate,
  ReportTemplateStatus,
  type User,
} from '@piccolohealth/echo-common';
import {
  MultiSelect,
  type MultiSelectOption,
  type OnChangeRequest,
  RangeDatepicker,
} from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FormItem } from '../../../components/forms/FormItem';
import { MultiSelectReportVariable } from '../../../components/forms/MultiSelectReportVariable';
import { useReportTemplatesQuery } from '../../../graphql/hooks/useReportTemplatesQuery';
import { useUsersMinimalQuery } from '../../../graphql/hooks/useUsersQuery';
import { useAppContext } from '../../../hooks/useAppContext';
import type { InsightsFilter } from '../hooks/useInsightsFilter';

const userToOption = (user: User): MultiSelectOption<User> => {
  return {
    label: user.name,
    value: user.name,
    raw: user,
  };
};

interface Props {
  filter: InsightsFilter;
}

const ReportDateFilterControl = (props: Props) => {
  const { filter } = props;

  return (
    <FormItem label='Report date' w='2xs'>
      <RangeDatepicker
        startDate={filter.startDate}
        endDate={filter.endDate}
        onStartDateChange={(v) => v && filter.onStartDateFilter(v)}
        onEndDateChange={(v) => v && filter.onEndDateFilter(v)}
      />
    </FormItem>
  );
};

const ReportTemplateTypeFilterControl = (props: Props) => {
  const { filter } = props;
  const { organization } = useAppContext();

  const { data } = useReportTemplatesQuery({
    organizationId: organization.id,
    getReportTemplatesRequest: {
      statuses: [ReportTemplateStatus.Archived, ReportTemplateStatus.Published],
      master: true,
      pagination: {
        limit: 100,
      },
    },
  });

  const templates =
    (data?.organization?.reportTemplates?.reportTemplates as ReportTemplate[]) || [];

  const options: MultiSelectOption<ReportTemplate>[] = templates.map((reportTemplate) => ({
    label: reportTemplate.name,
    value: reportTemplate.id,
    color: reportTemplate.labelColor,
    raw: reportTemplate,
  }));

  const onChange = React.useCallback(
    (req: OnChangeRequest<ReportTemplate>) => {
      const reportTemplateIds = req.values.map((item) => item.value);
      filter.onReportTemplateTypeFilter(reportTemplateIds);
    },
    [filter],
  );

  const selectedValues = React.useMemo(() => {
    return P.compact(
      filter.reportTemplateType.map((reportTemplateType) =>
        options.find((o) => o.value === reportTemplateType),
      ),
    );
  }, [filter.reportTemplateType, options]);

  return (
    <FormItem label='Report type' w='2xs'>
      <MultiSelect
        options={options}
        selectedValues={selectedValues}
        onChange={onChange}
        placeholder='All report types'
        optionVariant='tag'
      />
    </FormItem>
  );
};

const CardiologistFilterControl = (props: Props) => {
  const { filter } = props;

  const { organization } = useAppContext();
  const { data } = useUsersMinimalQuery({ organizationId: organization.id });

  const users = (data?.organization?.users as User[]) || [];

  const options = users.map(userToOption);

  const onChange = React.useCallback(
    (req: OnChangeRequest<User>) => {
      filter.onCardiologistFilter(req.values.map((item) => item.value));
    },
    [filter],
  );

  const selectedValues = React.useMemo(() => {
    return P.compact(filter.cardiologist.map((v) => options.find((o) => P.isEqual(o.value, v))));
  }, [filter.cardiologist, options]);

  return (
    <FormItem label='Cardiologist' w='2xs'>
      <MultiSelect
        options={options}
        selectedValues={selectedValues}
        onChange={onChange}
        placeholder='All cardiologists'
      />
    </FormItem>
  );
};

const SonographerFilterControl = (props: Props) => {
  const { filter } = props;

  const { organization } = useAppContext();
  const { data } = useUsersMinimalQuery({ organizationId: organization.id });

  const users = (data?.organization?.users as User[]) || [];

  const options = users.map(userToOption);

  const onChange = React.useCallback(
    (req: OnChangeRequest<User>) => {
      filter.onSonographerFilter(req.values.map((item) => item.value));
    },
    [filter],
  );

  const selectedValues = React.useMemo(() => {
    return P.compact(filter.sonographer.map((v) => options.find((o) => P.isEqual(o.value, v))));
  }, [filter.sonographer, options]);

  return (
    <FormItem label='Sonographer' w='2xs'>
      <MultiSelect
        options={options}
        selectedValues={selectedValues}
        onChange={onChange}
        placeholder='All sonographers'
      />
    </FormItem>
  );
};

const ReferringPhysicianFilterControl = (props: Props) => {
  const { filter } = props;

  const selectedValues: MultiSelectOption<AutoCompleteResult>[] = React.useMemo(() => {
    return filter.referringPhysician.map((item) => {
      return {
        label: item,
        value: item,
        raw: {
          value: item,
          count: 1,
          isExcluded: false,
          similarity: 1,
        },
      };
    });
  }, [filter.referringPhysician]);

  return (
    <FormItem label='Referring physician' w='2xs'>
      <MultiSelectReportVariable
        alias='referringPhysician'
        selectedValues={selectedValues}
        onChange={(value) => filter.onReferringPhysicianFilter(value.map((item) => item.value))}
        placeholder='All referring physicians'
      />
    </FormItem>
  );
};

export const InsightsFilters = (props: Props) => {
  const { filter } = props;

  return (
    <HStack>
      <ReportDateFilterControl filter={filter} />
      <ReportTemplateTypeFilterControl filter={filter} />
      <CardiologistFilterControl filter={filter} />
      <SonographerFilterControl filter={filter} />
      <ReferringPhysicianFilterControl filter={filter} />
    </HStack>
  );
};
