import { Input, type InputProps, forwardRef, useMergeRefs } from '@chakra-ui/react';
import React, { type ChangeEvent } from 'react';
import { useController } from 'react-hook-form';

interface Props extends InputProps {
  name: string;
}

export const HookedInput = forwardRef<Props, 'input'>((props, forwardedRef) => {
  const { name, ...rest } = props;

  const {
    field: { ref, ...inputProps },
  } = useController({ name });

  const mergedRef = useMergeRefs(ref, forwardedRef);

  const onChange = React.useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.target.value === ''
        ? inputProps.onChange(null)
        : inputProps.onChange(event.target.value);
    },
    [inputProps.onChange],
  );

  return (
    <Input
      {...inputProps}
      onChange={onChange}
      value={inputProps.value}
      ref={mergedRef}
      size='sm'
      bg='white'
      {...rest}
    />
  );
});
