import { Center, HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import { type Instance, Stage, View, renderDicomView } from '@piccolohealth/echo-common';
import { Spin } from '@piccolohealth/ui';
import { P, inflection } from '@piccolohealth/util';
import React from 'react';
import { useAppContext } from '../../../hooks/useAppContext';
import { useOnScreen } from '../../../hooks/useOnScreen';
import { AuthedImage } from '../../generic/AuthedImage';

interface Props {
  instance: Instance;
  width?: number;
  index?: number;
  count?: number;
  isVisible: boolean;
  onClick: () => void;
}

export const InstanceThumbnail = (props: Props) => {
  const { instance, width = 130, count, index, isVisible, onClick } = props;

  const { config } = useAppContext();
  const ref = React.useRef<HTMLDivElement>(null);
  const { onScreenCount } = useOnScreen(ref, '10px');

  const thumbUrl = `${config.api.url}${instance.frameUrl}/1`;
  const borderColor = isVisible ? '#9187DA' : 'gray.400';
  const stage =
    instance.dicom.stage !== Stage.Unknown ? inflection.titleize(instance.dicom.stage) : null;
  const view = instance.dicom.view !== View.Unknown ? renderDicomView(instance.dicom.view) : null;
  const progress = P.isNil(index) || P.isNil(count) ? null : `${index + 1}/${count}`;
  const height = width * 0.89;

  return (
    <Stack
      p='3px'
      rounded='lg'
      alignItems='inherit'
      userSelect='none'
      ref={ref}
      bg={borderColor}
      onClick={onClick}
      spacing={0}
      _hover={{
        filter: 'brightness(1.4)',
        cursor: 'pointer',
      }}
      w={`${width}px`}
    >
      <Center
        pos='relative'
        h={`${height}px`}
        bg='black'
        roundedBottom='md'
        overflow='hidden'
        rounded='lg'
      >
        <Text pos='absolute' top={0} left={0} fontSize='xs' fontWeight='bold' color='white' p={1}>
          {progress}
        </Text>

        <HStack
          pos='absolute'
          bottom={0}
          left={0}
          p={1}
          fontSize='xs'
          fontWeight='bold'
          color='white'
          w='full'
          align='end'
        >
          {stage && <Text>{stage}</Text>}
          <Spacer />
          {view && <Text textAlign='right'>{view}</Text>}
        </HStack>

        {onScreenCount > 0 ? (
          <AuthedImage
            cacheKey={`thumbnail-${instance.id}`}
            url={thumbUrl}
            w='full'
            roundedBottom='md'
          />
        ) : (
          <Spin />
        )}
      </Center>
    </Stack>
  );
};
