import type { StyleFunctionProps } from '@chakra-ui/react';
import { Badge } from './badge';

const baseStyle = {
  label: {
    whiteSpace: 'nowrap',
    textAlign: 'initial',
  },
};

const variants = {
  subtle: (props: StyleFunctionProps) => ({
    container: {
      ...Badge.variants?.subtle(props),
      rounded: 'md',
    },
    label: {},
  }),
};

export const Tag = {
  baseStyle,
  variants,
} as any;
