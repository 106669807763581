import { MenuItem, Text } from '@chakra-ui/react';
import { PERMISSIONS, type ReportTemplate } from '@piccolohealth/echo-common';
import React from 'react';
import { FaRegFileArchive } from 'react-icons/fa';
import { usePermission } from '../../../hooks/usePermission';
import { showModal } from '../../generic/Modal';
import { ReportTemplateMergeModal } from '../../modals/templates/ReportTemplateMergeModal';

interface Props {
  reportTemplate: ReportTemplate;
  reportTemplates: ReportTemplate[];
  options: {
    onDelete: () => Promise<void>;
  };
}

export const ReportTemplateMergeMenuItem = (props: Props) => {
  const { reportTemplate, reportTemplates, options, ...rest } = props;
  const isDisabled = !usePermission(PERMISSIONS.reportTemplateUpdate).value;

  return (
    <MenuItem
      {...rest}
      isDisabled={isDisabled}
      onClick={() =>
        showModal(ReportTemplateMergeModal, {
          reportTemplate,
          reportTemplates,
          onDelete: options.onDelete,
        })
      }
      icon={<FaRegFileArchive />}
    >
      <Text>Merge</Text>
    </MenuItem>
  );
};
