import {
  Box,
  Button,
  Flex,
  type FlexProps,
  HStack,
  Image,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  getRelevantRangesForCriteria,
  type MeasurementRange,
  Stage,
  View,
} from '@piccolohealth/echo-common';
import { Dot } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { getMeasurementRangeColor } from '../../../../../../utils/ranges';
import { showModal } from '../../../../../generic/Modal';
import { AttachedMediaModal } from './AttachedMediaModal';
import { ChartAttachmentContent } from './chart/ChartAttachmentContent';
import type { CreateMediaAttachmentRequest } from './MediaAttachmentZone';

interface Props extends Omit<FlexProps, 'title'> {
  ssr: boolean;
  title?: string | React.ReactElement;
  footer?: React.ReactElement | null;
  id: string;
  description?: string | null;
  metadata: JSX.Element | null;
  height: string;
  width: string;
  isDisabled: boolean;
  onClickRemove: (id: string) => void;
  onClickOpen: () => void;
}

const AttachedItem = (props: React.PropsWithChildren<Props>) => {
  const {
    children,
    ssr,
    title,
    footer,
    id,
    description,
    metadata,
    width,
    height,
    isDisabled,
    onClickRemove,
    onClickOpen,
    ...rest
  } = props;

  return (
    <Flex
      flexDir='column'
      alignItems='stretch'
      minW={width}
      h='full'
      rounded='md'
      overflow='hidden'
      {...rest}
    >
      {title && (
        <Text fontWeight='semibold' fontSize='2xs' pb={1}>
          {title}
        </Text>
      )}
      <Flex
        flexBasis='auto'
        flexGrow={0}
        justifyContent='center'
        alignItems='center'
        h='full'
        minHeight={0}
        cursor='pointer'
        onClick={onClickOpen}
      >
        {children}
      </Flex>
      <Box flexGrow={0} flexShrink={0} flexBasis='auto'>
        <Box position='absolute' top={4} right={4} zIndex={1}>
          {metadata}
        </Box>
        <HStack align='baseline' minH={8} py={1} borderTopWidth='1px' borderColor='gray.200'>
          <Stack align='start' spacing={1}>
            {description && (
              <Text
                w='340px'
                color='gray.700'
                fontWeight='normal'
                fontSize='xs'
                wordBreak='break-all'
                noOfLines={1}
              >
                <strong>Description:</strong> {description}
              </Text>
            )}
            {footer && <Box maxW='340px'>{footer}</Box>}
          </Stack>
          <Spacer />
          {!ssr ? (
            <Button
              isDisabled={isDisabled}
              color='secondary'
              variant='link'
              onClick={() => onClickRemove(id)}
              fontSize='xs'
            >
              Remove
            </Button>
          ) : null}
        </HStack>
      </Box>
    </Flex>
  );
};

export const AttachedMedia = (props: {
  ssr: boolean;
  value: CreateMediaAttachmentRequest;
  isDisabled: boolean;
  onClickRemove: (id: string) => void;
}) => {
  const { ssr, value, isDisabled, onClickRemove } = props;

  switch (value.type) {
    case 'CHART': {
      const height = 315;
      const width = 420;

      const footer = P.run(() => {
        const ranges = value.data?.[0]?.rangeInfo?.result?.rangeGroup.ranges ?? [];
        const criteria = value.data?.[0]?.rangeInfo?.criteria;

        if (!criteria) {
          return null;
        }

        const applicableRangesForSexAndAge: MeasurementRange[] = getRelevantRangesForCriteria(
          ranges,
          criteria,
        );

        return (
          <HStack spacing={2} wrap='wrap' rowGap={0} lineHeight='normal'>
            {applicableRangesForSexAndAge.map((v) => (
              <HStack key={v.level}>
                <Dot size='2' bg={getMeasurementRangeColor(v.level).dark} />
                <Text fontWeight='normal' fontSize='xs'>
                  {v.label}
                </Text>
              </HStack>
            ))}
          </HStack>
        );
      });

      const title = (
        <Stack spacing={0}>
          {value.data.map((v) => (
            <HStack key={v.series[0].id}>
              <Dot size='2' bg={v.color} />
              <Text fontSize='xs'>{v.series[0].id}</Text>
            </HStack>
          ))}
        </Stack>
      );

      return (
        <AttachedItem
          ssr={ssr}
          id={value.id}
          metadata={null}
          title={title}
          footer={footer}
          description={value?.description}
          overflow='revert'
          width={`${width}px`}
          height={`${height}px`}
          isDisabled={isDisabled}
          onClickRemove={onClickRemove}
          onClickOpen={() =>
            showModal(AttachedMediaModal, {
              children: (
                <Stack h='720px' spacing={2}>
                  <ChartAttachmentContent ssr={false} data={value.data} />
                  {footer}
                  <Text fontSize='sm' fontWeight='semibold'>
                    {title}
                  </Text>
                </Stack>
              ),
            })
          }
        >
          <ChartAttachmentContent ssr={ssr} data={value.data} width={width} height={height} />
        </AttachedItem>
      );
    }

    case 'STUDY_IMAGE': {
      const stage =
        value.metadata?.stage && value.metadata.stage !== Stage.Unknown
          ? value.metadata.stage
          : null;
      const view =
        value.metadata?.view && value.metadata.view !== View.Unknown ? value.metadata.view : null;

      const stageView =
        stage || view ? (
          <Text fontSize='sm' fontWeight='bold' color='white'>
            {stage} {view}
          </Text>
        ) : null;

      return (
        <AttachedItem
          ssr={ssr}
          id={value.id}
          description={value.description}
          metadata={stageView}
          height='auto'
          width='auto'
          isDisabled={isDisabled}
          onClickRemove={onClickRemove}
          onClickOpen={() =>
            showModal(AttachedMediaModal, {
              children: (
                <Stack w='full' h='full'>
                  <Image src={value.data} />
                  <Text fontWeight='semibold' fontSize='sm'>
                    {value.description}
                  </Text>
                </Stack>
              ),
            })
          }
        >
          <Image
            w='full'
            maxH='full'
            src={value.data}
            fallbackSrc={value.data}
            borderTopRadius='lg'
          />
        </AttachedItem>
      );
    }

    case 'IMAGE_UPLOAD': {
      return (
        <AttachedItem
          ssr={ssr}
          id={value.id}
          description={value.description}
          metadata={null}
          height='100%'
          width='auto'
          isDisabled={isDisabled}
          onClickRemove={onClickRemove}
          onClickOpen={() =>
            showModal(AttachedMediaModal, {
              children: (
                <Stack w='full' h='full'>
                  <Image src={value.data} />
                  <Text fontWeight='semibold' fontSize='sm'>
                    {value.description}
                  </Text>
                </Stack>
              ),
            })
          }
        >
          <Image w='full' maxH='full' src={value.data} />
        </AttachedItem>
      );
    }
  }
};
