import { P } from '@piccolohealth/util';
import type {
  MeasurementRange,
  MeasurementRangeCriteria,
  MeasurementRangeGroup,
  MeasurementRangeLevel,
  NumericalRange,
  ReportTemplateStaticVariable,
  ReportVariable,
} from '../graphql';
import { convertMeasurementValue, roundMeasurementValue } from './dicom';
import { getReportVariableValue, isReportStaticVariable } from './reporting';

export interface MeasurementRangeResult {
  range?: MeasurementRange | null;
  rangeGroup: MeasurementRangeGroup;
}

export const sortNumericalRanges = (ranges: NumericalRange[]): NumericalRange[] => {
  return ranges.sort((a, b) => {
    // Compare based on the smallest lower bound
    const aMin = a.gte ?? a.gt ?? Number.NEGATIVE_INFINITY;
    const bMin = b.gte ?? b.gt ?? Number.NEGATIVE_INFINITY;

    if (aMin !== bMin) {
      return aMin - bMin;
    }

    // If lower bounds are equal, compare based on the smallest upper bound
    const aMax = a.lte ?? a.lt ?? Number.POSITIVE_INFINITY;
    const bMax = b.lte ?? b.lt ?? Number.POSITIVE_INFINITY;

    return aMax - bMax;
  });
};

export const validateNumericalRange = (range: NumericalRange): NumericalRange => {
  // Allow ranges with everything as null
  if (P.isNil(range.gt) && P.isNil(range.gte) && P.isNil(range.lt) && P.isNil(range.lte)) {
    return range;
  }

  // Ensure if we have gte, then we don’t have gt, and if we have lte, then we don’t have lt
  if (!P.isNil(range.gte) && !P.isNil(range.gt)) {
    throw new Error('Cannot have both gte and gt at the same time');
  }

  if (!P.isNil(range.lte) && !P.isNil(range.lt)) {
    throw new Error('Cannot have both lte and lt at the same time');
  }

  // Ensure gte and lt are logically consistent
  if (!P.isNil(range.gte) && !P.isNil(range.lt) && range.gte > range.lt) {
    throw new Error('Invalid range: gte cannot be greater than lt');
  }

  // Ensure gte and lte are logically consistent
  if (!P.isNil(range.gte) && !P.isNil(range.lte) && range.gte > range.lte) {
    throw new Error('Invalid range: gte cannot be greater than lte');
  }

  // Ensure gt and lte are logically consistent
  if (!P.isNil(range.gt) && !P.isNil(range.lte) && range.gt >= range.lte) {
    throw new Error('Invalid range: gt cannot be greater than or equal to lte');
  }

  // Ensure gt and lt are logically consistent
  if (!P.isNil(range.gt) && !P.isNil(range.lt) && range.gt >= range.lt) {
    throw new Error('Invalid range: gt cannot be greater than or equal to lt');
  }

  return range;
};

export const validateMeasurementRangeGroup = (
  rangeGroup: MeasurementRangeGroup,
): MeasurementRangeGroup => {
  const ranges = rangeGroup.ranges;

  if (ranges.length === 0) {
    throw new Error('Measurement range group must have at least one range');
  }

  const validateNumericalRanges = (ranges: NumericalRange[], precision: number) => {
    if (ranges.length === 0) {
      return true;
    }

    if (ranges.length === 1) {
      return true;
    }

    for (let i = 1; i < ranges.length; i++) {
      const prev = ranges[i - 1];
      const curr = ranges[i];

      if (!isRangesValid(prev, curr, precision)) {
        return false;
      }
    }

    return true;
  };

  const groupedBySex = P.groupBy(ranges, (range) => range.sex ?? 'no sex');

  for (const [sex, value] of Object.entries(groupedBySex)) {
    const uniqueAges = P.uniqBy(
      P.compact((value ?? []).map((range) => range.age)),
      (age) => renderNumericalRange(age) ?? 'no age',
    );
    const sortedAges = sortNumericalRanges(uniqueAges);

    const agesValid = validateNumericalRanges(sortedAges, 0);

    if (!agesValid) {
      throw new Error(
        `Overlapping or badly spaced age ranges for ${sex}: ${JSON.stringify(sortedAges)}`,
      );
    }

    const groupedByAge = P.groupBy(value ?? [], (range) =>
      range.age ? (renderNumericalRange(range.age) ?? 'no-age') : 'no age',
    );

    for (const [age, ranges] of Object.entries(groupedByAge)) {
      const measurementRanges = (ranges ?? []).map((range) => range.measurement);
      const sortedMeasurementRanges = sortNumericalRanges(measurementRanges);

      const measurementsValid = validateNumericalRanges(
        sortedMeasurementRanges,
        rangeGroup.precision,
      );

      if (!measurementsValid) {
        throw new Error(
          `Overlapping or badly spaced measurement ranges for ${age}: ${JSON.stringify(
            sortedMeasurementRanges,
          )}`,
        );
      }
    }
  }

  return rangeGroup;
};

export const isRangesValid = (
  range1?: NumericalRange | null,
  range2?: NumericalRange | null,
  gap?: number,
): boolean => {
  if (P.isNil(range1) && P.isNil(range2)) {
    return false;
  }

  if (P.isNil(range1) && !P.isNil(range2)) {
    validateNumericalRange(range2);
    return true;
  }

  if (!P.isNil(range1) && P.isNil(range2)) {
    validateNumericalRange(range1);
    return true;
  }

  if (!P.isNil(range1) && !P.isNil(range2)) {
    validateNumericalRange(range1);
    validateNumericalRange(range2);
  }

  // Both ranges must exist
  const allowedGap = 10 ** -(gap ?? 0);
  const range1Upper = range1?.lte ?? range1?.lt ?? Number.POSITIVE_INFINITY;
  const range2Lower = range2?.gte ?? range2?.gt ?? Number.NEGATIVE_INFINITY;
  const range1Inclusive = !P.isNil(range1?.lte);
  const range2Inclusive = !P.isNil(range2?.gte);

  const parseNumber = (value: number) => {
    return Number.parseFloat(value.toFixed(gap));
  };

  // Adjusted gap checking to handle inclusivity correctly
  if (range1Inclusive && range2Inclusive) {
    return parseNumber(range2Lower - range1Upper) === allowedGap;
  } else if (range1Inclusive && !range2Inclusive) {
    return parseNumber(range2Lower - range1Upper) === 0;
  } else if (!range1Inclusive && range2Inclusive) {
    return parseNumber(range2Lower - range1Upper) === 0;
  } else if (!range1Inclusive && !range2Inclusive) {
    return false;
  } else {
    throw new Error('Invalid range inclusivity.');
  }
};

export const convertRangeGroupUnits = (
  group: MeasurementRangeGroup,
  toUnits: string,
  precision: number,
): MeasurementRangeGroup => {
  return {
    ...group,
    units: toUnits,
    ranges: group.ranges.map((range) => {
      const gt = P.run(() => {
        if (P.isNil(range.measurement.gt)) {
          return null;
        }

        if (P.isNil(group.units)) {
          return range.measurement.gt;
        }

        return roundMeasurementValue(
          convertMeasurementValue(range.measurement.gt, group.units, toUnits),
          precision,
        );
      });

      const gte = P.run(() => {
        if (P.isNil(range.measurement.gte)) {
          return null;
        }

        if (P.isNil(group.units)) {
          return range.measurement.gte;
        }

        return roundMeasurementValue(
          convertMeasurementValue(range.measurement.gte, group.units, toUnits),
          precision,
        );
      });

      const lt = P.run(() => {
        if (P.isNil(range.measurement.lt)) {
          return null;
        }

        if (P.isNil(group.units)) {
          return range.measurement.lt;
        }

        return roundMeasurementValue(
          convertMeasurementValue(range.measurement.lt, group.units, toUnits),
          precision,
        );
      });

      const lte = P.run(() => {
        if (P.isNil(range.measurement.lte)) {
          return null;
        }

        if (P.isNil(group.units)) {
          return range.measurement.lte;
        }

        return roundMeasurementValue(
          convertMeasurementValue(range.measurement.lte, group.units, toUnits),
          precision,
        );
      });

      return {
        ...range,
        measurement: { gt, gte, lt, lte },
      };
    }),
  };
};

export const isMeasurementCriteriaInRange = (
  range: MeasurementRange,
  criteria: MeasurementRangeCriteria,
): boolean => {
  const sexInRange = P.isNil(range.sex) || range.sex.toLowerCase() === criteria.sex?.toLowerCase();

  const ageInRange = P.run(() => {
    if (
      P.isNil(range.age?.gt) &&
      P.isNil(range.age?.gte) &&
      P.isNil(range.age?.lt) &&
      P.isNil(range.age?.lte)
    ) {
      return true;
    }

    if (P.isNil(criteria.age)) {
      return false;
    }

    return (
      (P.isNil(range.age.gt) || criteria.age > range.age.gt) &&
      (P.isNil(range.age.gte) || criteria.age >= range.age.gte) &&
      (P.isNil(range.age.lt) || criteria.age < range.age.lt) &&
      (P.isNil(range.age.lte) || criteria.age <= range.age.lte)
    );
  });

  const measurementInRange = P.run(() => {
    if (
      P.isNil(range.measurement.gt) &&
      P.isNil(range.measurement.gte) &&
      P.isNil(range.measurement.lt) &&
      P.isNil(range.measurement.lte)
    ) {
      return true;
    }

    if (P.isNil(criteria.measurement)) {
      return false;
    }

    const one = P.isNil(range.measurement.gt) || criteria.measurement > range.measurement.gt;
    const two = P.isNil(range.measurement.gte) || criteria.measurement >= range.measurement.gte;
    const three = P.isNil(range.measurement.lt) || criteria.measurement < range.measurement.lt;
    const four = P.isNil(range.measurement.lte) || criteria.measurement <= range.measurement.lte;

    return one && two && three && four;
  });

  return sexInRange && ageInRange && measurementInRange;
};

/**
 * Very similar to isMeasurementCriteriaInRange, but this function returns the ranges that match
 */
export const getRelevantRangesForCriteria = (
  ranges: MeasurementRange[],
  criteria: MeasurementRangeCriteria,
): MeasurementRange[] => {
  return ranges.filter((range) => {
    const sexInRange = P.isNil(range.sex) || range.sex === criteria.sex;

    const ageInRange = P.run(() => {
      if (
        P.isNil(criteria.age) ||
        (P.isNil(range.age?.gte) &&
          P.isNil(range.age?.lte) &&
          P.isNil(range.age?.lt) &&
          P.isNil(range.age?.gt))
      ) {
        return true;
      }

      const gt = range.age.gt ?? Number.NEGATIVE_INFINITY;
      const gte = range.age.gte ?? Number.NEGATIVE_INFINITY;
      const lt = range.age.lt ?? Number.POSITIVE_INFINITY;
      const lte = range.age.lte ?? Number.POSITIVE_INFINITY;

      return (
        (P.isNil(range.age.gt) || criteria.age > gt) &&
        (P.isNil(range.age.gte) || criteria.age >= gte) &&
        (P.isNil(range.age.lt) || criteria.age < lt) &&
        (P.isNil(range.age.lte) || criteria.age <= lte)
      );
    });

    return sexInRange && ageInRange;
  });
};

export const getRangeResult = (
  id: string,
  reportTemplateVariable: ReportTemplateStaticVariable,
  reportVariables: ReportVariable[],
): MeasurementRangeResult | null => {
  const reportVariable = reportVariables.find((variable) => variable.id === id);

  if (!reportVariable || !isReportStaticVariable(reportVariable)) {
    return null;
  }

  const rangeResults = reportTemplateVariable.mappings.map((mapping) => {
    const rangeGroup = mapping.rangeGroup;

    if (!rangeGroup) {
      return null;
    }

    const convertedRangeGroup = reportVariable.units
      ? // Set a high enough precision so that for all realistic use cases we don't lose precision
        // E.g. converting 1256mm to meters, we ideally would like to preserve 1.256m
        convertRangeGroupUnits(rangeGroup, reportVariable.units, 4)
      : rangeGroup;

    const sex = getReportVariableValue<string>(reportVariables, 'sex');
    const age = getReportVariableValue<number>(reportVariables, 'age');
    const measurement = P.run(() => {
      // If neither the report variable nor the range group have units, we can just return
      // the value safely, as no conversion needs to happen
      if (!reportVariable.units && !convertedRangeGroup.units) {
        return reportVariable.value;
      }

      // If one has units but not the other, there is data inconsistency, so we should
      // not try to compare as it is unsafe to do so
      if (
        (reportVariable.units && !convertedRangeGroup.units) ||
        (!reportVariable.units && convertedRangeGroup.units)
      ) {
        return null;
      }

      // Otherwise, normalize the value to the units of the range group
      return convertMeasurementValue(
        reportVariable.value,
        reportVariable.units!,
        convertedRangeGroup.units!,
      );
    });

    const bridgedRanges = bridgeMeasurementRanges(convertedRangeGroup.ranges);

    const matchingRange = convertedRangeGroup.ranges.find((_range, index) => {
      const bridgedRange = bridgedRanges[index];
      return isMeasurementCriteriaInRange(bridgedRange, {
        sex: sex ?? null,
        age: age ?? null,
        measurement: measurement ?? null,
      });
    });

    return {
      range: matchingRange ?? null,
      rangeGroup: convertedRangeGroup,
    };
  });

  return P.first(P.compact(rangeResults)) ?? null;
};

export const renderNumericalRange = (range: NumericalRange, suffix?: string): string | null => {
  // Unicode maths symbols: https://en.wikipedia.org/wiki/Mathematical_Operators_(Unicode_block)
  const CHARS = {
    GT: '>',
    GTE: '≥',
    LT: '<',
    LTE: '≤',
  } as const;

  if (P.isNil(range.gt) && P.isNil(range.gte) && P.isNil(range.lt) && P.isNil(range.lte)) {
    return null;
  }

  // e.g. >= 10
  if (P.isNil(range.gt) && !P.isNil(range.gte) && P.isNil(range.lt) && P.isNil(range.lte)) {
    return P.compact([`${CHARS.GTE} ${range.gte}`, suffix]).join(' ');
  }

  // e.g. <= 10
  if (P.isNil(range.gt) && P.isNil(range.gte) && P.isNil(range.lt) && !P.isNil(range.lte)) {
    return P.compact([`${CHARS.LTE} ${range.lte}`, suffix]).join(' ');
  }

  // e.g. > 10
  if (!P.isNil(range.gt) && P.isNil(range.gte) && P.isNil(range.lt) && P.isNil(range.lte)) {
    return P.compact([`${CHARS.GT} ${range.gt}`, suffix]).join(' ');
  }

  // e.g. < 10
  if (P.isNil(range.gt) && P.isNil(range.gte) && !P.isNil(range.lt) && P.isNil(range.lte)) {
    return P.compact([`${CHARS.LT} ${range.lt}`, suffix]).join(' ');
  }

  // e.g. 10 - 20
  if (P.isNil(range.gt) && !P.isNil(range.gte) && P.isNil(range.lt) && !P.isNil(range.lte)) {
    return P.compact([`${range.gte} - ${range.lte}`, suffix]).join(' ');
  }

  // e.g. >= 10 and < 20
  if (P.isNil(range.gt) && !P.isNil(range.gte) && !P.isNil(range.lt) && P.isNil(range.lte)) {
    return P.compact([`${CHARS.GTE} ${range.gte} and ${CHARS.LT} ${range.lt}`, suffix]).join(' ');
  }

  // e.g. > 10 and <= 20
  if (!P.isNil(range.gt) && P.isNil(range.gte) && P.isNil(range.lt) && !P.isNil(range.lte)) {
    return P.compact([`${CHARS.GT} ${range.gt} and ${CHARS.LTE} ${range.lte}`, suffix]).join(' ');
  }

  // e.g. > 10 and < 20
  if (!P.isNil(range.gt) && P.isNil(range.gte) && !P.isNil(range.lt) && P.isNil(range.lte)) {
    return P.compact([`${CHARS.GT} ${range.gt} and ${CHARS.LT} ${range.lt}`, suffix]).join(' ');
  }

  return null;
};

export const transformRangesForGraphing = (
  ranges: MeasurementRange[],
): {
  min: number | undefined;
  max: number | undefined;
  label: string;
  level: MeasurementRangeLevel;
}[] => {
  const getMin = (measurement: NumericalRange): number => {
    return !P.isNil(measurement.gte)
      ? measurement.gte
      : !P.isNil(measurement.gt)
        ? measurement.gt
        : Number.NEGATIVE_INFINITY;
  };

  const getMax = (measurement: NumericalRange): number => {
    return !P.isNil(measurement.lte)
      ? measurement.lte
      : !P.isNil(measurement.lt)
        ? measurement.lt
        : Number.POSITIVE_INFINITY;
  };

  const minMaxes = ranges.map((range) => {
    return {
      label: range.label,
      level: range.level,
      min: getMin(range.measurement),
      max: getMax(range.measurement),
    };
  });

  const sortedMinMaxes = minMaxes.sort((a, b) => a.min - b.min);

  for (let i = 0; i < sortedMinMaxes.length - 1; i++) {
    const currentMax = sortedMinMaxes[i].max;
    const nextMin = sortedMinMaxes[i + 1].min;

    if (currentMax !== null) {
      const midpoint = (currentMax + nextMin) / 2;
      sortedMinMaxes[i].max = midpoint;
      sortedMinMaxes[i + 1].min = midpoint;
    }
  }

  const res = sortedMinMaxes.map((range) => {
    return {
      ...range,
      min: range.min === Number.NEGATIVE_INFINITY ? 0 : range.min,
      max: range.max === Number.POSITIVE_INFINITY ? undefined : range.max,
    };
  });

  return res;
};

export const bridgeMeasurementRanges = (ranges: MeasurementRange[]): MeasurementRange[] => {
  if (ranges.length <= 1) {
    return ranges;
  }

  const copiedRanges = JSON.parse(JSON.stringify(ranges)) as MeasurementRange[];

  // Segregate ranges by sex, as we don't want to bridge ranges between sexes
  const rangesBySex = Object.values(P.groupBy(copiedRanges, (range) => range.sex ?? ''));

  return rangesBySex.flatMap((ranges) => {
    const result = ranges ?? [];

    for (let i = 0; i < result.length - 1; i++) {
      const current = result[i];
      const next = result[i + 1];

      const start = current.measurement.gte ?? current.measurement.gt ?? Number.NEGATIVE_INFINITY;
      const nextStart = next.measurement.gte ?? next.measurement.gt ?? Number.NEGATIVE_INFINITY;

      const isAscending = start <= nextStart;

      if (isAscending) {
        const currentEnd =
          current.measurement.lte ?? current.measurement.lt ?? Number.POSITIVE_INFINITY;
        const nextStart = next.measurement.gte ?? next.measurement.gt ?? Number.NEGATIVE_INFINITY;

        const isGap = nextStart - currentEnd > 0;

        // If there is no gap between the two ranges, no need to bridge them
        if (!isGap) {
          continue;
        }

        const midpoint = (currentEnd + nextStart) / 2;

        // Adjust current range to end with lt = midpoint and remove the inclusive boundary if it existed
        current.measurement.lt = midpoint;
        current.measurement.lte = null;

        // Adjust next range to begin with gte = midpoint and remove the inclusive boundary if it existed
        next.measurement.gte = midpoint;
        next.measurement.gt = null;
      } else {
        const currentEnd =
          current.measurement.gte ?? current.measurement.gt ?? Number.NEGATIVE_INFINITY;
        const nextStart = next.measurement.lte ?? next.measurement.lt ?? Number.POSITIVE_INFINITY;

        // Otherwise, we are descending order, and we must reverse the comparisons
        const isGap = currentEnd - nextStart > 0;

        if (!isGap) {
          continue;
        }

        const midpoint = (currentEnd + nextStart) / 2;

        current.measurement.gte = midpoint;
        current.measurement.gt = null;

        next.measurement.lt = midpoint;
        next.measurement.lte = null;
      }
    }

    return result;
  });
};
