import { Button, HStack, Icon, InputGroup, InputRightElement, Stack } from '@chakra-ui/react';
import { type ReportTemplateVariableChoice, uuid } from '@piccolohealth/echo-common';
import { DndKitCore, DndKitSortable, DragHandle, Sortable } from '@piccolohealth/ui';
import React from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { ToggleTagControl } from '../../../forms/ToggleTagControl';
import { HookedInput } from '../../../forms/hookform/HookedInput';
import { IconButton } from '../../../generic/IconButton';

interface HookedSortableChoiceControlProps {
  id: string;
  name: string;
  onRemove: () => void;
  onDefault: () => void;
}

const HookedSortableChoiceControl = (props: HookedSortableChoiceControlProps) => {
  const { id, name, onRemove, onDefault } = props;

  const isDefault: boolean = useWatch({ name: `${name}.default` }) ?? false;

  const { attributes, setNodeRef, listeners, transform, transition } = DndKitSortable.useSortable({
    id,
  });

  return (
    <Sortable ref={setNodeRef} transform={transform} transition={transition}>
      <HStack spacing={4}>
        <DragHandle listeners={listeners} attributes={attributes} />
        <InputGroup size='sm' w='full' variant='flushed'>
          <HookedInput
            name={`${name}.value`}
            autoFocus
            tabIndex={0}
            placeholder='Eg: mildly dilated'
          />
          <InputRightElement w='fit-content' mr={2}>
            <HStack>
              <ToggleTagControl value={isDefault} onToggle={onDefault} />
              <IconButton icon={FaTrash} aria-label='Remove' onClick={onRemove} />
            </HStack>
          </InputRightElement>
        </InputGroup>
      </HStack>
    </Sortable>
  );
};

interface Props {
  name: string;
}

export const HookedSortableChoicesControl = (props: Props) => {
  const { name } = props;

  const { fields, append, remove, move, update } = useFieldArray({
    name,
    keyName: 'sortableId',
  });

  const choices = fields as unknown as ReportTemplateVariableChoice[];

  const sensors = DndKitCore.useSensors(
    DndKitCore.useSensor(DndKitCore.PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  const onDragEnd = React.useCallback(
    (event: DndKitCore.DragEndEvent) => {
      const { active, over } = event;
      if (over && active.id !== over.id) {
        const oldIndex = choices.findIndex((item) => item.id === active.id);
        const newIndex = choices.findIndex((item) => item.id === over.id);
        move(oldIndex, newIndex);
      }
    },
    [move, choices],
  );

  const onRemove = React.useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  const onDefault = React.useCallback(
    (index: number) => {
      const choice = choices[index];

      if (!choice) {
        return;
      }

      // Remove default from all choices.
      choices.forEach((value, index) => update(index, { ...value, default: false }));
      // If choice was already default, remove default, otherwise set it
      update(index, { ...choice, default: !choice?.default });
    },
    [update, choices],
  );

  const onAdd = React.useCallback(() => {
    append({
      id: uuid(),
      label: '',
      value: '',
      // First choice always is always default=true
      default: choices.length === 0,
    });
  }, [append, choices]);

  return (
    <DndKitCore.DndContext
      sensors={sensors}
      collisionDetection={DndKitCore.closestCenter}
      onDragEnd={onDragEnd}
    >
      <DndKitSortable.SortableContext
        strategy={DndKitSortable.verticalListSortingStrategy}
        items={choices.map(({ id }) => id)}
      >
        <Stack spacing={4}>
          {choices.map((choice, index) => (
            <HookedSortableChoiceControl
              key={choice.id}
              id={choice.id}
              name={`${name}[${index}]`}
              onRemove={() => onRemove(index)}
              onDefault={() => onDefault(index)}
            />
          ))}
        </Stack>
        <Button onClick={() => onAdd()} leftIcon={<Icon as={FaPlus} />} w='full'>
          New option
        </Button>
      </DndKitSortable.SortableContext>
    </DndKitCore.DndContext>
  );
};
