import type { DistributionType } from '@piccolohealth/echo-common';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import {
  DistributionTypeChooser,
  type DistributionTypeChooserProps,
} from '../DistributionTypeChooser';

interface Props extends Omit<DistributionTypeChooserProps, 'value' | 'onChange'> {
  name: string;
}

export const HookedDistributionTypeChooser = (props: Props) => {
  const { name, ...rest } = props;

  const { resetField, setValue } = useFormContext();

  const {
    field: { ref, ...inputProps },
  } = useController({ name });

  const onChange = React.useCallback(
    (value: DistributionType) => {
      if (inputProps.value === value) {
        return;
      }

      setValue(name, value, { shouldDirty: true });
      resetField('payload');
    },
    [inputProps.value, name, resetField, setValue],
  );

  return <DistributionTypeChooser value={inputProps.value} onChange={onChange} {...rest} />;
};
