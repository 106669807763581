import { Box, type ResponsiveValue } from '@chakra-ui/react';
import type { ReportTemplateVariable } from '@piccolohealth/echo-common';
import React from 'react';
import { ChoiceVariableControl } from './ChoiceVariableControl';
import { StaticVariableControl } from './StaticVariableControl';
import { WallMotionVariableControl } from './WallMotionVariableControl';

interface Props {
  name: string;
  reportTemplateVariable: ReportTemplateVariable;
  isDisabled?: boolean;
  size?: ResponsiveValue<string>;
  shouldCapitalize: () => boolean;
}

export const VariableControl = (props: Props) => {
  const { name, reportTemplateVariable, isDisabled, size, shouldCapitalize } = props;

  switch (reportTemplateVariable.__typename) {
    case 'ReportTemplateStaticVariable':
      return (
        <StaticVariableControl
          name={name}
          reportTemplateVariable={reportTemplateVariable}
          isDisabled={isDisabled}
        />
      );
    case 'ReportTemplateChoiceVariable':
      return (
        <ChoiceVariableControl
          name={name}
          reportTemplateVariable={reportTemplateVariable}
          isDisabled={isDisabled}
          size={size}
          shouldCapitalize={shouldCapitalize}
        />
      );
    case 'ReportTemplateWallMotionVariable':
      return (
        <WallMotionVariableControl
          name={name}
          reportTemplateVariable={reportTemplateVariable}
          isDisabled={isDisabled}
        />
      );
    default:
      return <Box>Not implemented</Box>;
  }
};
