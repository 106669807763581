import React from 'react';
import { useController } from 'react-hook-form';
import { UserChooser, type UserChooserProps } from '../UserChooser';

interface Props extends Omit<UserChooserProps, 'onChange' | 'value' | 'selectedValue'> {
  name: string;
}

export const HookedUserChooser = (props: Props) => {
  const { name, ...rest } = props;
  const { field } = useController({ name });
  const { onChange, value } = field;

  return <UserChooser {...rest} onChange={onChange} value={value ?? null} />;
};
