import {
  Box,
  HStack,
  Heading,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Spacer,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { BulletSteps } from '../../../../../../generic/BulletSteps';
import type { CreateMediaAttachmentRequest } from '../MediaAttachmentZone';
import { StudyImageAttachmentForm } from './StudyImageAttachmentForm';

interface Props {
  reportId: string;
  onAttachMedia: (value: CreateMediaAttachmentRequest) => void;
  onBack: () => void;
  onClose: () => void;
}

export const StudyImageAttachmentActionStep = (props: Props) => {
  const { reportId, onAttachMedia, onBack, onClose } = props;

  return (
    <>
      <ModalHeader>
        <HStack align='start'>
          <Box>
            <Heading size='md'>Attach an image from the study</Heading>
            <Text fontSize='md' fontWeight='normal' color='secondary' mt={2}>
              Choose an image from the performed study and attach it to your report
            </Text>
          </Box>
          <Spacer />
          <HStack spacing={3}>
            <BulletSteps currentStep={2} totalSteps={2} />
            <ModalCloseButton position='unset' top='unset' right='unset' />
          </HStack>
        </HStack>
      </ModalHeader>
      <ModalBody>
        <Box h='70vh' minW='80vw'>
          <StudyImageAttachmentForm
            reportId={reportId}
            onBack={onBack}
            onClose={onClose}
            onAttachStudyImage={onAttachMedia}
          />
        </Box>
      </ModalBody>
      <ModalFooter />
    </>
  );
};
