import { Box, HStack } from '@chakra-ui/react';
import React from 'react';
import type { AuditTrailFilter } from '../../hooks/useAuditTrailFilter';
import { AuditTrailDateFilterControl } from './controls/AuditTrailDateFilterControl';
import { AuditTrailEventFilterControl } from './controls/AuditTrailEventFilterControl';
import { AuditTrailMessageFilterControl } from './controls/AuditTrailMessageFilterControl';
import { AuditTrailUserFilterControl } from './controls/AuditTrailUserFilterControl';

interface Props {
  filter: AuditTrailFilter;
}

export const AuditTrailFilterHeader = (props: Props) => {
  const { filter } = props;

  return (
    <HStack align='normal' spacing={4}>
      <Box width='xs'>
        <AuditTrailMessageFilterControl filter={filter} />
      </Box>
      <Box>
        <AuditTrailDateFilterControl filter={filter} />
      </Box>
      <Box width='xs'>
        <AuditTrailEventFilterControl filter={filter} />
      </Box>
      <Box width='xs'>
        <AuditTrailUserFilterControl filter={filter} />
      </Box>
    </HStack>
  );
};
