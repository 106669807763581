import type { ResponsiveValue } from '@chakra-ui/react';
import UnorderedList from '@tiptap/extension-bullet-list';
import History from '@tiptap/extension-history';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import { type Extensions, useEditor } from '@tiptap/react';
import React from 'react';
import { useGetCss } from '../../../hooks/useGetCss';
import { ContextMenu } from '../extensions/contextmenu/ContextMenu';
import { getContextMenuRenderer } from '../extensions/contextmenu/contextMenuRenderer';
import { reportEditingPages } from '../extensions/contextmenu/pages/reportEditing';
import { OneLineDocument } from '../nodes';
import { StatementListItem } from '../nodes/StatementListItem';
import { Statements } from '../nodes/Statements';
import { VariableControl } from '../nodes/variable/VariableControl';
import { VariableNode, type VariableNodeProps } from '../nodes/variable/VariableNode';
import type { TiptapContext } from '../utils/TiptapContext';
import { TiptapEditorContent } from '../utils/TiptapEditor';

interface Props {
  value: string;
  limit: number;
  context: TiptapContext;
  size: ResponsiveValue<string>;
  spacing: ResponsiveValue<string>;
  listType: 'ordered' | 'unordered';
  onChange: (value: string) => void;
}

export const TiptapStatementSiteList = (props: Props) => {
  const { value, limit, size, spacing, context, listType, onChange } = props;

  const variableControl = React.useCallback(
    (props: VariableNodeProps) => {
      return <VariableControl {...props} size={size} />;
    },
    [size],
  );

  const commandMenuRenderer = React.useCallback(
    () => getContextMenuRenderer(reportEditingPages),
    [],
  );

  const getCss = useGetCss();
  const listExtension = listType === 'ordered' ? OrderedList : UnorderedList;

  const extensions: Extensions = [
    OneLineDocument,
    Statements,
    Text,
    Paragraph,
    listExtension.configure({
      HTMLAttributes: {
        style: getCss({ listStylePosition: 'inside' }).styles,
      },
    }),
    StatementListItem.configure({
      HTMLAttributes: {
        style: getCss({ py: spacing }).styles,
      },
      limit,
    }),
    VariableNode.configure({
      component: variableControl,
      context: context,
    }),
    ContextMenu.configure({
      renderer: commandMenuRenderer,
    }),
    History,
    Placeholder.configure({
      includeChildren: true,
      placeholder: "Type '/' or right-click for commands",
    }),
  ];

  const editor = useEditor(
    {
      extensions,
      content: value,
      onUpdate: ({ editor }) => {
        onChange(editor.getHTML());
      },
      editable: !context.isDisabled,
      editorProps: {
        attributes: {
          // Prevents the Prosemirror DOM element from being tabbable
          tabindex: '-1',
        },
      },
    },
    [context.isDisabled, spacing, listType, onChange],
  );

  React.useEffect(() => {
    if (editor && !editor.isDestroyed && value !== editor.getHTML()) {
      editor.commands.setContent(value);
    }
  }, [editor, value]);

  return (
    <TiptapEditorContent context={context} editor={editor} value={value} extensions={extensions} />
  );
};
