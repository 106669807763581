import type { ButtonProps } from '@chakra-ui/react';
import { FileInput } from '@piccolohealth/ui';
import React from 'react';
import { useController } from 'react-hook-form';

interface Props extends ButtonProps {
  name: string;
  multiple: boolean;
  renderFilesSelected?: (files: File[]) => JSX.Element;
}

export const HookedFileInput = (props: Props) => {
  const { name, multiple, renderFilesSelected, ...rest } = props;

  const {
    field: { ref, ...inputProps },
  } = useController({ name });

  return (
    <FileInput
      {...rest}
      files={inputProps.value}
      onFilesChange={inputProps.onChange}
      multiple={multiple}
      renderFilesSelected={renderFilesSelected}
    />
  );
};
