import {
  Box,
  Input as ChakraInput,
  Input,
  type InputProps,
  useStyleConfig,
} from '@chakra-ui/react';
import { useNode } from '@craftjs/core';
import {
  getReportTemplateVariableById,
  isReportTemplateStaticVariable,
} from '@piccolohealth/echo-common';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useController } from 'react-hook-form';
import { useReport } from '../../../../context/ReportContext';
import { useCompiledTemplate } from '../../../../hooks/useCompiledTemplate';
import { useTextWidth } from '../../../../hooks/useTextWidth';
import { theme } from '../../../../theme/theme';
import type { BaseNodeProps } from '../../../../utils/craftjs';
import { FormItem } from '../../../forms/FormItem';
import { HookedInput } from '../../../forms/hookform/HookedInput';
import { NodeSizeSettings } from '../../common/nodes/NodeSize';
import { type NodeSpacingProps, NodeSpacingSettings } from '../../common/nodes/NodeSpacing';
import {
  type NodeTypographyProps,
  NodeTypographySettings,
} from '../../common/nodes/NodeTypography';
import { ReportVariableRenderingError } from '../../common/ReportVariableRenderingError';
import { SettingsAccordion } from '../../common/settings/SettingsAccordion';
import { SettingsItem } from '../../common/settings/SettingsItem';
import { VariableChooser } from '../../common/settings/VariableChooser';
import { useSSRNode } from '../../hooks/useSSRNode';

interface InputWithUnitsProps extends InputProps {
  variableId: string;
  name: string;
}

const InputWithUnits = (props: InputWithUnitsProps) => {
  const { variableId, name, ...rest } = props;
  const { isDisabled } = useReport();
  const { compiledTemplate } = useCompiledTemplate(`{{withPlaceholder ${variableId} "-"}}`);

  return <Input value={compiledTemplate} isDisabled={isDisabled} {...rest} />;
};

const HookedInputWithUnits = (props: InputWithUnitsProps) => {
  const { variableId, name, ...rest } = props;
  const { reportTemplate, isDisabled } = useReport();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { field } = useController({ name });

  const reportTemplateVariable = getReportTemplateVariableById(
    reportTemplate.variables,
    variableId,
  );

  const { recalculateWidth, width } = useTextWidth({
    ref: inputRef,
  });

  const baseInputStyles = useStyleConfig('Input', rest) as any;

  // biome-ignore lint/correctness/useExhaustiveDependencies: The recalculateWidth should be rewritten at some point
  React.useEffect(() => {
    recalculateWidth();
  }, [field.value, recalculateWidth]);

  if (!reportTemplateVariable) {
    return (
      <ReportVariableRenderingError
        variableId={variableId}
        message={`Unable to find variable ${variableId} in input component`}
      />
    );
  }

  if (!isReportTemplateStaticVariable(reportTemplateVariable)) {
    return (
      <ReportVariableRenderingError
        variableId={variableId}
        message={`Variable ${variableId} is not supported by input component`}
      />
    );
  }

  const afterNode =
    !P.isNil(reportTemplateVariable.units) && (inputRef?.current?.value?.length ?? 0) > 0
      ? {
          ...rest,
          px: P.get(theme, baseInputStyles.field['--input-padding']),
          fontSize: rest.fontSize ?? P.get(theme, baseInputStyles.field['--input-font-size']),
          pointerEvents: 'none',
          display: 'flex',
          position: 'absolute',
          height: '100%',
          left: width,
          alignItems: 'center',
          justifyItems: 'center',
          content: `"${reportTemplateVariable.units}"`,
          lineHeight: 'normal',
        }
      : {};

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.currentTarget.blur();
    }
  };

  return (
    <Box
      position='relative'
      w='full'
      display='flex'
      overflow='hidden'
      sx={{
        _after: afterNode,
      }}
    >
      <HookedInput
        ref={inputRef}
        name={name}
        isDisabled={isDisabled}
        onKeyDown={onKeyDown}
        data-pw={`inputVariableControl-${variableId}`}
        {...rest}
      />
    </Box>
  );
};

export interface Props extends BaseNodeProps, NodeSpacingProps, NodeTypographyProps {
  variableId: string;
  size: 'sm' | 'md' | 'lg';
}

export const InlineInput = (props: Props) => {
  const { variableId, padding, margin, ssr } = props;

  const {
    connectors: { connect, drag },
  } = useSSRNode(props);

  const name = `variables.${props.variableId}.value`;

  const nodeStyles = P.pickBy(
    {
      fontSize: props.fontSize,
      fontWeight: props.fontWeight,
      lineHeight: props.lineHeight,
    },
    (item) => !P.isUndefined(item),
  );

  const content = P.run(() => {
    if (ssr) {
      return (
        <InputWithUnits
          name={name}
          variableId={variableId}
          variant='unstyled'
          w='full'
          size={props.size}
          p={padding}
          m={margin}
          sx={nodeStyles}
        />
      );
    } else {
      return (
        <HookedInputWithUnits
          name={name}
          variableId={variableId}
          variant='unstyled'
          w='full'
          size={props.size}
          p={padding}
          m={margin}
          sx={nodeStyles}
        />
      );
    }
  });

  return (
    <Box ref={(ref) => connect(drag(ref))} w='full'>
      {content}
    </Box>
  );
};

const InlineInputSettings = () => {
  const {
    actions: { setProp },
    variableId,
    size,
  } = useNode((node) => ({
    variableId: node.data.props.variableId,
    size: node.data.props.size,
  }));

  return (
    <SettingsAccordion>
      <SettingsItem title='Inline input'>
        <FormItem id='variableId' label='Variable'>
          <VariableChooser
            variableId={variableId}
            type={['ReportTemplateStaticVariable']}
            onChange={(value) => setProp((props: Props) => (props.variableId = value as string))}
          />
        </FormItem>
        <FormItem id='size' label='Size'>
          <ChakraInput
            value={size}
            onChange={(e) =>
              setProp((props: Props) => (props.size = e.target.value as Props['size']))
            }
            size='sm'
          />
        </FormItem>
      </SettingsItem>
      <SettingsItem title='Spacing'>
        <NodeSpacingSettings />
      </SettingsItem>
      <SettingsItem title='Typography'>
        <NodeTypographySettings />
      </SettingsItem>
    </SettingsAccordion>
  );
};

InlineInput.defaultProps = {
  variableId: 'patientName',
  size: 'sm' as const,
  ...NodeSpacingSettings.defaultProps,
  ...NodeSizeSettings.defaultProps,
  ...NodeTypographySettings.defaultProps,
};

InlineInput.craft = {
  name: 'InlineInput',
  props: InlineInput.defaultProps,
  related: {
    settings: InlineInputSettings,
  },
};
