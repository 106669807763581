import type * as Types from '@piccolohealth/echo-common';
import { useMutation } from 'react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { CreateReportsExportJob } from '../mutations/createReportsExportJob.mutation';

export const useCreateReportsExportJobMutation = (
  options?: MutationOptions<
    Types.CreateReportsExportJobMutation,
    Types.CreateReportsExportJobMutationVariables
  >,
) => {
  return useMutation(gqlFetcher(CreateReportsExportJob), options);
};
