import type * as Types from '@piccolohealth/echo-common';
import { useInfiniteQuery } from 'react-query';
import { type InfiniteQueryOptions, createGqlQuery } from '../fetcher';
import { AutoCompleteEmails, AutoCompleteReportVariables } from '../queries/autoComplete.query';

export const useAutoCompleteEmailsQuery = createGqlQuery<
  Types.AutoCompleteEmailsQueryVariables,
  Types.AutoCompleteEmailsQuery
>((variables) => ['autocomplete', variables, 'emails'], AutoCompleteEmails);

export const useAutoCompleteEmailsInfiniteQuery = (
  variables: Types.AutoCompleteEmailsQueryVariables,
  options?: InfiniteQueryOptions<Types.AutoCompleteEmailsQuery>,
) => {
  const fetcher = useAutoCompleteEmailsQuery.getFetcher();

  return useInfiniteQuery(
    useAutoCompleteEmailsQuery.getKey(variables),
    async ({ pageParam }) => {
      return fetcher({
        ...variables,
        request: {
          ...variables.request,
          pagination: {
            offset: pageParam,
          },
        },
      });
    },
    {
      ...options,
      getNextPageParam: (lastPage: any) => {
        const pagination = lastPage?.organization?.autoComplete?.emails?.pagination;

        if (!pagination?.hasNextPage) {
          return undefined;
        }

        return pagination?.currentPage * pagination?.limit;
      },
    },
  );
};

export const useAutoCompleteReportVariablesQuery = createGqlQuery<
  Types.AutoCompleteReportVariablesQueryVariables,
  Types.AutoCompleteReportVariablesQuery
>((variables) => ['autocomplete', variables, 'reportVariables'], AutoCompleteReportVariables);

export const useAutoCompleteReportVariablesInfiniteQuery = (
  variables: Types.AutoCompleteReportVariablesQueryVariables,
  options?: InfiniteQueryOptions<Types.AutoCompleteReportVariablesQuery>,
) => {
  const fetcher = useAutoCompleteReportVariablesQuery.getFetcher();

  return useInfiniteQuery(
    useAutoCompleteReportVariablesQuery.getKey(variables),
    async ({ pageParam }) => {
      return fetcher({
        ...variables,
        request: {
          ...variables.request,
          pagination: {
            offset: pageParam,
          },
        },
      });
    },
    {
      ...options,
      getNextPageParam: (lastPage: any) => {
        const pagination = lastPage?.organization?.autoComplete?.reportVariables?.pagination;

        if (!pagination?.hasNextPage) {
          return undefined;
        }

        return pagination?.currentPage * pagination?.limit;
      },
    },
  );
};
