import { Tag, type TagProps } from '@chakra-ui/react';
import { DistributionStatus } from '@piccolohealth/echo-common';
import React from 'react';

interface Props extends TagProps {
  status: DistributionStatus;
}

const getTextAndColor = (status: DistributionStatus) => {
  switch (status) {
    case DistributionStatus.Pending:
      return { text: 'Pending', color: 'yellow' };
    case DistributionStatus.Processing:
      return { text: 'Processing', color: 'blue' };
    case DistributionStatus.Sent:
      return { text: 'Sent', color: 'green' };
    case DistributionStatus.Failed:
      return { text: 'Failed', color: 'red' };
  }
};

export const DistributionStatusTag = (props: Props) => {
  const { status, ...rest } = props;

  const { text, color } = getTextAndColor(status);

  return (
    <Tag colorScheme={color} {...rest}>
      {text}
    </Tag>
  );
};
