import { Box, Input, Select } from '@chakra-ui/react';
import { useNode } from '@craftjs/core';
import { type WallMotionValues, getWallMotionModule } from '@piccolohealth/echo-common';
import { RadioGroup } from '@piccolohealth/ui';
import React from 'react';
import { useController } from 'react-hook-form';
import { useReport } from '../../../../context/ReportContext';
import type { BaseNodeProps } from '../../../../utils/craftjs';
import { FormItem } from '../../../forms/FormItem';
import {
  Apical2ChamberControl,
  Apical3ChamberControl,
  Apical4ChamberControl,
  BullseyeControl,
  PsaxApicalControl,
  PsaxBasalControl,
  PsaxMidControl,
  type WallMotionDiagramProps,
  WallMotionDiagramType,
} from '../../../forms/wallmotion';
import {
  HorizontalWallMotionLegend,
  VerticalWallMotionLegend,
} from '../../../forms/wallmotion/Legend';
import { SettingsAccordion } from '../../common/settings/SettingsAccordion';
import { SettingsItem } from '../../common/settings/SettingsItem';
import { VariableChooser } from '../../common/settings/VariableChooser';
import { useSSRNode } from '../../hooks/useSSRNode';

export const DiagramComponent = (
  props: {
    diagramType: WallMotionDiagramType;
  } & WallMotionDiagramProps,
) => {
  switch (props.diagramType) {
    case WallMotionDiagramType.Bullseye:
      return <BullseyeControl {...props} />;
    case WallMotionDiagramType.Apical2Chamber:
      return <Apical2ChamberControl {...props} />;
    case WallMotionDiagramType.Apical4Chamber:
      return <Apical4ChamberControl {...props} />;
    case WallMotionDiagramType.Apical3Chamber:
    case WallMotionDiagramType.Plax:
      return <Apical3ChamberControl {...props} />;
    case WallMotionDiagramType.PsaxBasal:
      return <PsaxBasalControl {...props} />;
    case WallMotionDiagramType.PsaxMid:
      return <PsaxMidControl {...props} />;
    case WallMotionDiagramType.PsaxApical:
      return <PsaxApicalControl {...props} />;
    case WallMotionDiagramType.Legend:
      return <VerticalWallMotionLegend {...props} />;
    case WallMotionDiagramType.VerticalLegend:
      return <VerticalWallMotionLegend {...props} />;
    case WallMotionDiagramType.HorizontalLegend:
      return <HorizontalWallMotionLegend {...props} />;
  }
};

export interface Props extends BaseNodeProps {
  variableId: string | null;
  isDisabled: boolean;
  showScoreNumbers: boolean;
  width: string;
  height: string;
  diagramType: WallMotionDiagramType;
}

export const WallMotionDiagram = (props: Props) => {
  const { variableId, height, width, diagramType, showScoreNumbers } = props;

  const {
    connectors: { connect, drag },
  } = useSSRNode(props);

  const { isDisabled } = useReport();

  const { field } = useController({ name: `variables.${variableId}.value` });
  const { onChange, value } = field;

  const module = getWallMotionModule(value?.variant);

  const values =
    (value?.wmComplex as WallMotionValues | undefined) || module.defaultWmComplexValues;

  const setWmComplex = React.useCallback(
    (wallKey: string, scoreKey: string) => {
      const wmComplex: WallMotionValues = {
        ...values,
        [wallKey]: scoreKey,
      };
      const wmPresent = Object.values(wmComplex).some((v) => v !== 'normal');
      onChange({
        variant: module.variant,
        wmPresent,
        wmComplex,
      });
    },
    [onChange, values, module.variant],
  );

  return (
    <Box ref={(ref) => connect(drag(ref))}>
      <DiagramComponent
        diagramType={diagramType}
        width={width}
        height={height}
        isDisabled={isDisabled}
        showScoreNumbers={showScoreNumbers}
        module={module}
        values={values}
        onContextMenuClick={setWmComplex}
      />
    </Box>
  );
};

const WallMotionDiagramSettings = () => {
  const {
    actions: { setProp },
    variableId,
    isDisabled,
    showScoreNumbers,
    width,
    height,
    diagramType,
  } = useNode((node) => ({
    variableId: node.data.props.variableId,
    isDisabled: node.data.props.isDisabled,
    showScoreNumbers: node.data.props.showScoreNumbers,
    width: node.data.props.width,
    height: node.data.props.height,
    diagramType: node.data.props.diagramType,
  }));

  return (
    <SettingsAccordion>
      <SettingsItem title='Diagram'>
        <FormItem id='variableId' label='Variable'>
          <VariableChooser
            variableId={variableId}
            type={['ReportTemplateWallMotionVariable']}
            onChange={(value) => setProp((props: Props) => (props.variableId = value))}
          />
        </FormItem>
        <FormItem id='isDisabled' label='Disabled'>
          <RadioGroup
            value={isDisabled.toString()}
            onChange={(e) => setProp((props: Props) => (props.isDisabled = e === 'true'), 1000)}
            options={[
              { label: 'False', value: 'false' },
              { label: 'True', value: 'true' },
            ]}
            size='sm'
            variant='outline'
          />
        </FormItem>
        <FormItem id='showScoreNumbers' label='Show score numbers'>
          <RadioGroup
            value={showScoreNumbers.toString()}
            onChange={(e) =>
              setProp((props: Props) => (props.showScoreNumbers = e === 'true'), 1000)
            }
            options={[
              { label: 'False', value: 'false' },
              { label: 'True', value: 'true' },
            ]}
            size='sm'
            variant='outline'
          />
        </FormItem>
        <FormItem id='width' label='Width'>
          <Input
            defaultValue={width}
            onChange={(e) => setProp((props: Props) => (props.width = e.target.value))}
          />
        </FormItem>
        <FormItem id='height' label='Height'>
          <Input
            defaultValue={height}
            onChange={(e) => setProp((props: Props) => (props.height = e.target.value))}
          />
        </FormItem>
        <FormItem id='diagramType' label='Diagram type'>
          <Select
            defaultValue={diagramType}
            onChange={(e) =>
              setProp(
                (props: Props) => (props.diagramType = e.target.value as WallMotionDiagramType),
              )
            }
          >
            {Object.keys(WallMotionDiagramType).map((diagramType) => (
              <option key={diagramType} value={diagramType}>
                {diagramType}
              </option>
            ))}
          </Select>
        </FormItem>
      </SettingsItem>
    </SettingsAccordion>
  );
};

WallMotionDiagram.defaultProps = {
  variableId: 'lvWm',
  width: 200,
  height: 200,
  diagramType: WallMotionDiagramType.Bullseye,
  isDisabled: true,
  showScoreNumbers: false,
};

WallMotionDiagram.craft = {
  name: 'WallMotionDiagram',
  props: WallMotionDiagram.defaultProps,
  related: {
    settings: WallMotionDiagramSettings,
  },
};
