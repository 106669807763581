import React from 'react';
import * as Konva from 'react-konva';
import { type Line, type Polygon, getExtendedLine, getIntersectionWithPolygonEdge } from '../utils';

interface Props {
  line: Line;
  polygon: Polygon;
  color: string;
}

export const MeasurementAreaShading = (props: Props) => {
  const { line, polygon, color } = props;

  const mainLineStart = line.start;
  const mainLineEnd = line.end;
  const lineLength = line.length?.value || 0;

  // Set the number of perpendicular lines and calculate the increment step
  const numLines = 10;
  const stepLength = lineLength / (numLines + 1);
  const perpendicularLines = [];

  // Direction vector for the main line
  const dx = (mainLineEnd.x - mainLineStart.x) / lineLength;
  const dy = (mainLineEnd.y - mainLineStart.y) / lineLength;

  for (let i = 1; i <= numLines; i++) {
    // Calculate the point along the main line at this increment
    const pointOnLine = {
      x: mainLineStart.x + dx * stepLength * i,
      y: mainLineStart.y + dy * stepLength * i,
    };

    // Perpendicular direction (rotate the direction vector by 90 degrees)
    const leftDirection = { x: -dy, y: dx };
    const rightDirection = { x: dy, y: -dx };

    // Extend lines in both directions from the point on the main line
    const extendedLeftLine = getExtendedLine(
      {
        start: pointOnLine,
        end: { x: pointOnLine.x + leftDirection.x, y: pointOnLine.y + leftDirection.y },
      },
      10000,
    );
    const extendedRightLine = getExtendedLine(
      {
        start: pointOnLine,
        end: { x: pointOnLine.x + rightDirection.x, y: pointOnLine.y + rightDirection.y },
      },
      10000,
    );

    // Find intersections for each extended line within the polygon
    const leftIntersection = getIntersectionWithPolygonEdge(extendedLeftLine, polygon);
    const rightIntersection = getIntersectionWithPolygonEdge(extendedRightLine, polygon);

    if (leftIntersection && rightIntersection) {
      perpendicularLines.push([leftIntersection, rightIntersection]);
    }
  }

  return (
    <>
      {perpendicularLines.map(([start, end], index) => (
        <Konva.Line
          key={index}
          points={[start.x, start.y, end.x, end.y]}
          stroke={color}
          strokeWidth={1}
          dash={[3, 3]}
        />
      ))}
    </>
  );
};
