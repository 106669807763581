import { Icon } from '@chakra-ui/react';
import { Handlebars } from '@piccolohealth/echo-common';
import type { Command } from '@piccolohealth/ui';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import { type Extensions, useEditor } from '@tiptap/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FaBook } from 'react-icons/fa';
import { useReport } from '../../../../../context/ReportContext';
import { OneLineDocument, VariableNode } from '../../../nodes';
import { VariableControl } from '../../../nodes/variable/VariableControl';
import type { VariableNodeProps } from '../../../nodes/variable/VariableNode';
import type { TiptapContext } from '../../../utils/TiptapContext';
import { TiptapEditorContent } from '../../../utils/TiptapEditor';
import { getVariablePairs } from '../../../utils/getVariablePairs';
import type { TiptapCommandMenuState } from '../contextMenuRenderer';

interface StatementCommandItemProps {
  content: string;
}

const StatementCommandItem = (props: StatementCommandItemProps) => {
  const { reportTemplate, isDisabled, timezone } = useReport();
  const { getValues } = useFormContext();

  const variablePairs = React.useMemo(() => {
    const { variables } = getValues();
    return getVariablePairs(Object.values(variables), reportTemplate.variables);
  }, [getValues, reportTemplate]);

  const context: TiptapContext = React.useMemo(() => {
    return {
      isDisabled,
      timezone,
      getFormName: (id: string) => `variables.${id}.value`,
      reportTemplate,
      variablePairs,
      reportTemplateStatements: [],
    };
  }, [isDisabled, timezone, variablePairs, reportTemplate]);

  const variableControl = (props: VariableNodeProps) => {
    return <VariableControl {...props} />;
  };

  const extensions: Extensions = [
    OneLineDocument,
    Text,
    Paragraph,
    VariableNode.configure({
      component: variableControl,
      context,
    }),
  ];

  const editor = useEditor(
    {
      extensions,
      content: props.content,
      editable: false,
    },
    [],
  );

  return (
    <TiptapEditorContent
      context={context}
      editor={editor}
      value={props.content}
      extensions={extensions}
    />
  );
};

export const statementsPages = (
  state: TiptapCommandMenuState,
): Command.Page<TiptapCommandMenuState>[] => {
  return [
    {
      kind: 'actions',
      id: 'statements',
      title: 'Statements',
      searchable: true,
      actions: state.context.reportTemplateStatements.map((template) => {
        const compiledTemplateValue = Handlebars.convertHandlebarsTemplateToTiptap(template.value);

        return {
          kind: 'void',
          id: template.id,
          leftIcon: () => <Icon as={FaBook} />,
          title: <StatementCommandItem content={compiledTemplateValue} />,
          raw: compiledTemplateValue,
          action: (props) => {
            const nodeBefore = props.editor.view.state.selection.$from.nodeBefore;

            // If the previous character is /, then extend the range back 1
            if (nodeBefore?.text?.endsWith('/')) {
              props.range.from -= 1;
            }

            props.range.to -= 1;

            return props.editor.chain().insertContentAt(props.range, compiledTemplateValue).run();
          },
        };
      }),
    },
  ];
};
