import type * as Common from '@piccolohealth/echo-common';
import { PiccoloError } from '@piccolohealth/echo-common';
import { Spin, useRequiredParams } from '@piccolohealth/ui';
import React from 'react';
import { Error } from '../../../components/generic/Error';
import { ReportContent } from '../../../components/reports/custom/ReportContent';
import { useReportTypeQuery } from '../../../graphql/hooks/useReportQuery';
import { useAppContext } from '../../../hooks/useAppContext';

export const Report = () => {
  const { organization } = useAppContext();
  const { reportId } = useRequiredParams<{ reportId: string }>();

  const { isLoading, data, error } = useReportTypeQuery(
    {
      organizationId: organization.id,
      reportId,
    },
    {
      notifyOnChangeProps: ['data'],
    },
  );

  if (isLoading) {
    return <Spin />;
  }

  if (error) {
    return <Error error={error} />;
  }

  const report = data?.organization?.report as Common.Report | undefined;

  if (!report) {
    return (
      <Error
        error={
          new PiccoloError({
            type: 'ReportNotFound',
            message: 'Report not found',
          })
        }
      />
    );
  }

  return <ReportContent report={report} />;
};
