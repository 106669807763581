import { Box, Input } from '@chakra-ui/react';
import { useNode } from '@craftjs/core';
import React from 'react';
import { useReport } from '../../../../context/ReportContext';
import type { BaseNodeProps } from '../../../../utils/craftjs';
import { FormItem } from '../../../forms/FormItem';
import { HookedSingleDatepicker } from '../../../forms/hookform/HookedSingleDatepicker';
import { type NodeSpacingProps, NodeSpacingSettings } from '../../common/nodes/NodeSpacing';
import {
  type NodeTypographyProps,
  NodeTypographySettings,
} from '../../common/nodes/NodeTypography';
import { SettingsAccordion } from '../../common/settings/SettingsAccordion';
import { SettingsItem } from '../../common/settings/SettingsItem';
import { VariableChooser } from '../../common/settings/VariableChooser';
import { useSSRNode } from '../../hooks/useSSRNode';

export interface Props extends BaseNodeProps, NodeSpacingProps, NodeTypographyProps {
  variableId: string;
  size: 'sm' | 'md' | 'lg';
}

export const InlineDatepicker = (props: Props) => {
  const { variableId, size, padding, margin, gap, fontSize, fontWeight, lineHeight } = props;
  const {
    connectors: { connect, drag },
  } = useSSRNode(props);
  const { isDisabled } = useReport();

  const name = `variables.${variableId}.value`;

  return (
    <Box ref={(ref) => connect(drag(ref))} w='full' m={margin} gap={gap}>
      <HookedSingleDatepicker
        name={name}
        isDisabled={isDisabled}
        w='full'
        variant='unstyled'
        data-pw={`datepickerVariableControl-${variableId}`}
        placeholder=''
        showIcon={false}
        sx={{
          padding: padding,
          fontSize: fontSize,
          fontWeight: fontWeight,
          lineHeight: lineHeight,
        }}
        size={size}
      />
    </Box>
  );
};

const InlineDatepickerSettings = () => {
  const {
    actions: { setProp },
    variableId,
    size,
  } = useNode((node) => ({
    variableId: node.data.props.variableId,
    size: node.data.props.size,
  }));

  return (
    <SettingsAccordion>
      <SettingsItem title='Inline datepicker'>
        <FormItem id='variableId' label='Variable'>
          <VariableChooser
            variableId={variableId}
            type={['ReportTemplateStaticVariable']}
            onChange={(value) => setProp((props: Props) => (props.variableId = value as string))}
          />
        </FormItem>
        <FormItem id='size' label='Size'>
          <Input
            defaultValue={size}
            value={size}
            onChange={(e) =>
              setProp((props: Props) => (props.size = e.target.value as Props['size']))
            }
          />
        </FormItem>
      </SettingsItem>
      <SettingsItem title='Spacing'>
        <NodeSpacingSettings />
      </SettingsItem>
      <SettingsItem title='Typography'>
        <NodeTypographySettings />
      </SettingsItem>
    </SettingsAccordion>
  );
};

InlineDatepicker.defaultProps = {
  variableId: 'dob',
  size: 'sm' as const,
  ...NodeSpacingSettings.defaultProps,
  ...NodeTypographySettings.defaultProps,
};

InlineDatepicker.craft = {
  name: 'InlineDatepicker',
  props: InlineDatepicker.defaultProps,
  related: {
    settings: InlineDatepickerSettings,
  },
};
