import type { MeasurementRange } from '@piccolohealth/echo-common';
import { Dot } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { getMeasurementRangeColor } from '../../utils/ranges';

interface Props {
  range: MeasurementRange | null;
}

export const MeasurementRangeDot = (props: Props) => {
  const { range } = props;

  if (P.isNil(range)) {
    return <Dot flexShrink={0} bg='gray.400' size={2} />;
  }

  const color = getMeasurementRangeColor(range.level).dark;

  return <Dot flexShrink={0} bg={color} size={2} />;
};
