import { PERMISSIONS, orPermission } from '@piccolohealth/echo-common';
import { useRequiredParams } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { Navigate, type RouteObject } from 'react-router-dom';
import { OrganizationNotFound } from '../../components/generic/OrganizationNotFound';
import { PageError } from '../../components/generic/PageError';
import { PermissionRoute } from '../../components/generic/PermissionRoute';
import { ProtectedRoute } from '../../components/generic/ProtectedRoute';
import { ReportBreadcrumb } from '../../components/reports/ReportBreadcrumb';
import { TemplateBreadcrumb } from '../../components/templates/TemplateBreadcrumb';
import { useAppContext } from '../../hooks/useAppContext';
import { AuditTrail } from '../../pages/organization/AuditTrail';
import { Insights } from '../../pages/organization/Insights';
import { Profile } from '../../pages/organization/Profile';
import { Reports } from '../../pages/organization/Reports';
import { Settings } from '../../pages/organization/Settings';
import { Templates } from '../../pages/organization/Templates';
import { Report } from '../../pages/organization/report/Report';
import { ReportShare } from '../../pages/organization/report/ReportShare';
import { Template } from '../../pages/organization/template/Template';
import { Invoices } from '../billing/Invoices';
import { Worklist } from '../worklist/Worklist';
import { OrganizationLayout } from './components/OrganizationLayout';

const ReportsRedirect = () => {
  const { organizationId } = useRequiredParams<{ organizationId: string }>();
  return <Navigate to={`/organizations/${organizationId}/reports`} />;
};

export const OrganizationRedirect = () => {
  const { user } = useAppContext();

  const organizationMemberships = user?.organizationMemberships ?? [];

  const defaultOrganizationId = organizationMemberships.find(
    (membership) => membership.organizationId === user.settings.defaultOrganization,
  )?.organizationId;
  const fallbackOrganizationId = P.first(organizationMemberships)?.organizationId;
  const organizationId = defaultOrganizationId ?? fallbackOrganizationId;

  if (organizationId) {
    return <Navigate to={`/organizations/${organizationId}`} />;
  } else {
    return <OrganizationNotFound />;
  }
};

export const organizationRoutes: RouteObject[] = [
  {
    path: ':organizationId',
    element: (
      <ProtectedRoute>
        <OrganizationLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <ReportsRedirect />,
      },
      {
        path: 'worklist',
        handle: { crumb: () => 'Worklist' },
        children: [
          {
            index: true,
            element: (
              <PermissionRoute permission={PERMISSIONS.worklistRead}>
                <Worklist />
              </PermissionRoute>
            ),
          },
        ],
      },
      {
        path: 'reports',
        handle: { crumb: () => 'Reports' },
        children: [
          {
            index: true,
            element: (
              <PermissionRoute
                permission={orPermission(PERMISSIONS.reportsRead, PERMISSIONS.reportsReadShared)}
              >
                <Reports />
              </PermissionRoute>
            ),
          },
          {
            path: ':reportId/shared/*',
            handle: { crumb: ReportBreadcrumb },
            element: (
              <PermissionRoute permission={PERMISSIONS.reportsReadShared}>
                <ReportShare />
              </PermissionRoute>
            ),
          },
          {
            path: ':reportId/*',
            handle: { crumb: ReportBreadcrumb },
            element: (
              <PermissionRoute permission={PERMISSIONS.reportsRead}>
                <Report />
              </PermissionRoute>
            ),
          },
        ],
      },
      {
        path: 'templates',
        handle: { crumb: () => 'Templates' },
        children: [
          {
            index: true,
            element: (
              <PermissionRoute permission={PERMISSIONS.organizationSupport}>
                <Templates />
              </PermissionRoute>
            ),
          },
          {
            path: ':reportTemplateId/*',
            handle: { crumb: TemplateBreadcrumb },
            element: (
              <PermissionRoute permission={PERMISSIONS.organizationSupport}>
                <Template />
              </PermissionRoute>
            ),
          },
        ],
      },
      {
        path: 'billing',
        children: [
          {
            handle: { crumb: () => 'Invoices' },
            path: 'invoices',
            element: (
              <PermissionRoute permission={PERMISSIONS.organizationUpdate}>
                <Invoices />
              </PermissionRoute>
            ),
          },
        ],
      },
      {
        path: 'audit-trail',
        handle: { crumb: () => 'Audit Trail' },
        element: (
          <PermissionRoute permission={PERMISSIONS.organizationUpdate}>
            <AuditTrail />
          </PermissionRoute>
        ),
      },
      {
        path: 'insights',
        handle: { crumb: () => 'Insights' },
        element: (
          <PermissionRoute permission={PERMISSIONS.organizationUpdate}>
            <Insights />
          </PermissionRoute>
        ),
      },
      {
        path: 'settings',
        handle: { crumb: () => 'Settings' },
        element: (
          <PermissionRoute permission={PERMISSIONS.organizationUpdate}>
            <Settings />
          </PermissionRoute>
        ),
      },
      {
        path: 'profile',
        handle: { crumb: () => 'Profile' },
        element: <Profile />,
      },
      {
        path: '*',
        element: <PageError type='NotFound' />,
      },
    ],
  },
];
