import {
  Box,
  Icon,
  Stack,
  Step,
  StepDescription,
  StepIndicator,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
} from '@chakra-ui/react';
import React from 'react';

import type { DistributionEvent } from '@piccolohealth/echo-common';
import { FancyDate } from '@piccolohealth/ui';
import { DateTime, P } from '@piccolohealth/util';
import { FaCheck } from 'react-icons/fa';
import { DistributionEventTypeTag } from './DistributionEventType';

interface Props {
  events: DistributionEvent[];
}

export const DistributionEventsList = (props: Props) => {
  const { events } = props;

  const orderedEvents = React.useMemo(
    () => P.orderBy(events, (event) => DateTime.fromISO(event.createdAt.toString()), 'asc'),
    [events],
  );

  return (
    <Stepper
      index={orderedEvents.length - 1}
      size='xs'
      orientation='vertical'
      gap={0}
      colorScheme='gray'
      sx={{
        '--stepper-accent-color': '#E2E8F0',
      }}
    >
      {orderedEvents.map((event) => (
        <Step key={event.id}>
          <Box mt={0.5}>
            <StepIndicator
              sx={{
                '[data-status=complete] &': {
                  background: 'gray.100',
                  borderColor: 'gray.300',
                  borderWidth: '1px',
                },
                '[data-status=active] &': {
                  background: 'blue.500',
                  borderColor: 'blue.500',
                },
              }}
            >
              <StepStatus active={<Icon color='white' boxSize='8px' as={FaCheck} />} />
            </StepIndicator>
          </Box>
          <Stack flexShrink='0' spacing={1}>
            <StepTitle>
              <DistributionEventTypeTag size='sm' type={event.type} />
            </StepTitle>
            <StepDescription>
              <Box mb={4}>
                <FancyDate date={event.createdAt} direction='row' showHumanized={false} />
              </Box>
            </StepDescription>
          </Stack>

          <StepSeparator />
        </Step>
      ))}
    </Stepper>
  );
};
