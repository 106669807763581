import { Box, HStack, InputGroup, InputRightAddon, Text, useDisclosure } from '@chakra-ui/react';
import type { ReportTemplateStaticVariable } from '@piccolohealth/echo-common';
import { FloatingPopover } from '@piccolohealth/ui';
import React from 'react';
import { useController } from 'react-hook-form';
import { useCompiledTemplate } from '../../../../hooks/useCompiledTemplate';
import { HookedInput } from '../../../forms/hookform/HookedInput';
import { HighlightedVariableValue } from './HighlightedVariableValue';

interface Props {
  name: string;
  reportTemplateVariable: ReportTemplateStaticVariable;
  isDisabled?: boolean;
}

export const StaticVariableControl = (props: Props) => {
  const { name, reportTemplateVariable, isDisabled } = props;
  const { compiledTemplate } = useCompiledTemplate(`{{${reportTemplateVariable.id}}}`);

  const disclosure = useDisclosure();
  const { field } = useController({ name });

  return (
    <Box display='inline' layerStyle='inlineFocus'>
      <FloatingPopover
        open={disclosure.isOpen}
        setOpen={(value) => (value ? disclosure.onOpen() : disclosure.onClose())}
        placement='bottom-start'
        isRoot={false}
        trigger='focus'
        shouldInitialFocus
        render={({ context }) => (
          <Box
            bg='white'
            rounded='md'
            shadow='popover'
            data-pw={`staticVariableInput-${reportTemplateVariable.id}`}
          >
            <HStack
              py={1.5}
              px={3}
              borderBottomWidth='1px'
              borderBottomColor='gray.200'
              bg='white'
              w='full'
              roundedTop='md'
              userSelect='none'
              tabIndex={-1}
            >
              <Text fontWeight='semibold' color='gray.500' fontSize='xs'>
                {reportTemplateVariable.label}
              </Text>
            </HStack>
            <Box p={2}>
              <InputGroup size='sm'>
                <HookedInput
                  name={name}
                  borderRightRadius={reportTemplateVariable.units ? 0 : 'md'}
                  isDisabled={isDisabled}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      (context.refs.domReference.current as any).focus();
                      context.onOpenChange(false);
                    }
                  }}
                />
                {reportTemplateVariable.units && (
                  <InputRightAddon borderRightRadius='md'>
                    {reportTemplateVariable.units}
                  </InputRightAddon>
                )}
              </InputGroup>
            </Box>
          </Box>
        )}
      >
        <Box display='inline' tabIndex={0}>
          <HighlightedVariableValue
            value={field.value}
            reportTemplateVariable={reportTemplateVariable}
            template={compiledTemplate}
            capitalize={false}
            showChoices={false}
          />
        </Box>
      </FloatingPopover>
    </Box>
  );
};
