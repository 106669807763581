import { Box } from '@chakra-ui/react';
import type {
  DistributionEvent,
  DistributionPayload,
  DistributionTrigger,
} from '@piccolohealth/echo-common';
import type { DateTime } from '@piccolohealth/util';
import React from 'react';
import { DistributionSummaryList } from './DistributionSummaryList';

interface Props {
  payload: DistributionPayload;
  trigger: DistributionTrigger;
  events?: DistributionEvent[];
  createdAt?: DateTime;
}

export const DistributionSummary = (props: Props) => {
  return (
    <Box w='full' px={2} mt={2} fontSize='md' fontWeight='normal'>
      <DistributionSummaryList {...props} />
    </Box>
  );
};
