import { Box, HStack, Skeleton, Stack, Text } from '@chakra-ui/react';
import {
  type Report,
  getReportVariableValue,
  getReportVariableValueAsDateTime,
  getReportVariableValueAsString,
  renderNullable,
} from '@piccolohealth/echo-common';
import { DateTime, P } from '@piccolohealth/util';
import React from 'react';
import { useReportQuery } from '../../../graphql/hooks/useReportQuery';
import { useAppContext } from '../../../hooks/useAppContext';
import { AssignLabelsControl } from '../../controls/AssignLabelsControl';
import { ReportChangeTypeControl } from '../../controls/ReportChangeTypeControl';
import { ReportStatusTag } from '../../generic/ReportStatusTag';

interface Props {
  reportId: string;
}

export const ReportHeader = (props: Props) => {
  const { reportId } = props;
  const { organization } = useAppContext();

  const { data } = useReportQuery({
    organizationId: organization.id,
    reportId,
  });

  const report = data?.organization?.report as Report | undefined;

  if (!report) {
    return (
      <Stack h={10}>
        <Skeleton height='full' endColor='gray.50' />
      </Stack>
    );
  }

  const dobValue = getReportVariableValueAsDateTime(report.variables, 'dob');
  const dob = renderNullable(dobValue?.toLocaleString(DateTime.DATE_SHORT));
  const ageValue = getReportVariableValue<string>(report.variables, 'age');
  const age = renderNullable(ageValue && `${ageValue}`);
  const studyDateValue = getReportVariableValueAsDateTime(report.variables, 'studyDate');
  const studyDate = renderNullable(studyDateValue?.toLocaleString(DateTime.DATETIME_SHORT));
  const reportStatus = <ReportStatusTag status={report.status} />;
  const sexValue = getReportVariableValueAsString(report.variables, 'sex');
  const sex = renderNullable(sexValue && P.upperFirst(sexValue));

  return (
    <HStack
      spacing={4}
      minH={10}
      h='fit-content'
      fontSize='sm'
      overflowX='hidden'
      whiteSpace='nowrap'
    >
      <HStack>
        <Text>Type:</Text>
        <Box>
          <ReportChangeTypeControl report={report} />
        </Box>
      </HStack>
      <HStack flexShrink={0}>
        <Text>Status:</Text>
        <Box>{reportStatus}</Box>
      </HStack>
      <HStack flexShrink={0}>
        <Text>Date:</Text>
        <Box>{studyDate}</Box>
      </HStack>
      <HStack flexShrink={0}>
        <Text>DOB:</Text>
        <Box>{dob}</Box>
      </HStack>
      <HStack flexShrink={0}>
        <Text>Age:</Text>
        <Box>{age}</Box>
      </HStack>
      <HStack flexShrink={0}>
        <Text>Sex:</Text>
        <Box>{sex}</Box>
      </HStack>
      <HStack>
        <Text>Labels:</Text>
        <Box minW='xs'>
          <AssignLabelsControl reportId={report.id} reportLabels={report.labels} />
        </Box>
      </HStack>
    </HStack>
  );
};
