import {
  PiccoloError,
  type ReportTemplate,
  type ReportTemplateDryRun,
} from '@piccolohealth/echo-common';
import { Spin, stringParam, useQueryParams, useRequiredParams } from '@piccolohealth/ui';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReportTemplateActionsMenu } from '../../../components/controls/templates/ReportTemplateActionsControl';
import { Content } from '../../../components/generic/Content';
import { Error } from '../../../components/generic/Error';
import { ReportTemplateStatusTag } from '../../../components/generic/ReportTemplateStatusTag';
import { TemplateContent } from '../../../components/templates/TemplateContent';
import {
  useReportTemplateDryRunQuery,
  useReportTemplateQuery,
} from '../../../graphql/hooks/useReportTemplateQuery';
import { useReportTemplatesQuery } from '../../../graphql/hooks/useReportTemplatesQuery';
import { useAppContext } from '../../../hooks/useAppContext';

export const Template = () => {
  const { organization } = useAppContext();
  const { reportTemplateId } = useRequiredParams<{ reportTemplateId: string }>();
  const navigate = useNavigate();

  const [params] = useQueryParams({
    reportId: stringParam,
  });

  const reportTemplateQuery = useReportTemplateQuery({
    organizationId: organization.id,
    reportTemplateId,
  });

  const reportTemplatesQuery = useReportTemplatesQuery({
    organizationId: organization.id,
  });

  const reportTemplateDryRunQuery = useReportTemplateDryRunQuery(
    {
      organizationId: organization.id,
      reportTemplateId,
      reportId: params.reportId as string,
    },
    { enabled: !!params.reportId },
  );

  const onDelete = async () => {
    navigate(`/organizations/${organization.id}/templates`);
  };

  const isLoading =
    reportTemplateQuery.isLoading ||
    reportTemplatesQuery.isLoading ||
    (!!params.reportId && reportTemplateDryRunQuery.isLoading);
  const error =
    reportTemplateQuery.error || reportTemplatesQuery.error || reportTemplateDryRunQuery.error;

  const dryRun = reportTemplateDryRunQuery.data?.organization?.reportTemplateDryRun as
    | ReportTemplateDryRun
    | undefined;

  if (isLoading) {
    return <Spin />;
  }

  if (error) {
    return <Error error={error} />;
  }

  const reportTemplate = reportTemplateQuery.data?.organization?.reportTemplate as
    | ReportTemplate
    | undefined;
  const reportTemplates = reportTemplatesQuery.data?.organization?.reportTemplates
    .reportTemplates as ReportTemplate[];

  if (!reportTemplate) {
    return (
      <Error
        error={
          new PiccoloError({
            type: 'ReportTemplateNotFound',
            message: 'Report template not found',
          })
        }
      />
    );
  }

  return (
    <Content
      leftExtraContent={<ReportTemplateStatusTag status={reportTemplate.status} />}
      rightExtraContent={
        <ReportTemplateActionsMenu
          reportTemplate={reportTemplate}
          reportTemplates={reportTemplates}
          onDelete={onDelete}
        />
      }
    >
      <TemplateContent reportTemplate={reportTemplate} dryRun={dryRun} />
    </Content>
  );
};
