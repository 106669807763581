import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import {
  type MeasurementMapping,
  type ReportTemplateVariable,
  renderReportTemplateVariableType,
} from '@piccolohealth/echo-common';
import { FormProvider, useForm } from 'react-hook-form';
import { useUpdateReportTemplateVariableMutation } from '../../../graphql/hooks/useUpdateReportTemplateVariableMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { FormSection } from '../../forms/FormSection';
import { HookedFormItem } from '../../forms/hookform/HookedFormItem';
import { HookedInput } from '../../forms/hookform/HookedInput';
import { HookedSelect } from '../../forms/hookform/HookedSelect';
import { HookedSubmitButton } from '../../forms/hookform/HookedSubmitButton';
import { HookedSwitch } from '../../forms/hookform/HookedSwitch';
import { createModal } from '../../generic/Modal';
import { ReportTemplateVariableTypeControl } from './ReportTemplateVariableTypeControl';
import { P } from '@piccolohealth/util';
import React from 'react';

type FormValues = ReportTemplateVariable;

interface Props {
  variable: ReportTemplateVariable;
  reportTemplateId: string;
}

export const ReportTemplateVariableEditModal = createModal<Props>((props) => {
  const { variable, reportTemplateId, modal } = props;
  const { organization, successToast, errorToast } = useAppContext();

  const mutation = useUpdateReportTemplateVariableMutation();

  const onSubmit = async (values: Partial<FormValues>) => {
    if (values.__typename === 'ReportTemplateChoiceVariable') {
      values.defaultValue =
        (values.choices ?? []).find((choice) => choice.default === true)?.value ?? null;
    }

    values.mappings = values.mappings?.map((id) => ({ id })) as unknown as MeasurementMapping[];

    await mutation
      .mutateAsync({
        organizationId: organization.id,
        reportTemplateId,
        reportTemplateVariableId: variable.id,
        request: values,
      })
      .then(() => {
        successToast('Variable edited successfully');
        modal.hide();
      })
      .catch((err) => {
        errorToast(`Error editing variable: ${err.message}`);
      });
  };

  const initialValues = P.cloneDeep({
    ...variable,
    mappings: variable.mappings.map((mapping) => mapping.id),
  });

  const methods = useForm({
    defaultValues: initialValues as any,
  });

  const types: NonNullable<ReportTemplateVariable['__typename']>[] = [
    'ReportTemplateStaticVariable',
    'ReportTemplateChoiceVariable',
    'ReportTemplateWallMotionVariable',
    'ReportTemplateMediaAttachmentVariable',
  ];

  const typeOptions = types.map((type) => ({
    value: type,
    label: renderReportTemplateVariableType(type),
    raw: type,
  }));

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove} size='4xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit variable</ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalBody>
              <FormSection>
                <HookedFormItem label='ID' name='id'>
                  <HookedInput name='id' />
                </HookedFormItem>
                <HookedFormItem label='Label' name='label'>
                  <HookedInput name='label' />
                </HookedFormItem>
                <HookedFormItem label='Site' name='site'>
                  <HookedInput name='site' />
                </HookedFormItem>
                <HookedFormItem label='Category' name='category'>
                  <HookedInput name='category' />
                </HookedFormItem>
                <HookedFormItem label='Editable' name='isEditable'>
                  <HookedSwitch name='isEditable' />
                </HookedFormItem>
                <HookedFormItem label='Type' name='__typename'>
                  <HookedSelect name='__typename' options={typeOptions} />
                </HookedFormItem>
                <ReportTemplateVariableTypeControl />
              </FormSection>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup size='sm'>
                <Button key='close' onClick={modal.hide}>
                  Close
                </Button>
                <HookedSubmitButton>Save</HookedSubmitButton>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
