import type { AutoCompleteResult } from '@piccolohealth/echo-common';
import type { MultiSelectOption } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useController } from 'react-hook-form';
import {
  MultiSelectReportVariable,
  type MultiSelectReportVariableProps,
} from '../MultiSelectReportVariable';

export const inputValueToAutoCompleteResult = (
  inputValue: string | null,
): MultiSelectOption<AutoCompleteResult> | null => {
  if (P.isNull(inputValue) || P.isEmptyString(inputValue)) {
    return null;
  }

  return {
    label: inputValue,
    value: inputValue,
    raw: {
      count: 1,
      isExcluded: false,
      similarity: 1,
      value: inputValue,
    },
  };
};

export const valuesToAutoCompleteResult = (
  value: string | string[] | null,
): MultiSelectOption<AutoCompleteResult>[] => {
  return P.compact(
    [value].flat().map((i) => {
      return inputValueToAutoCompleteResult(i);
    }),
  );
};

interface Props
  extends Omit<MultiSelectReportVariableProps, 'onChange' | 'value' | 'selectedValues'> {
  name: string;
}

export const HookedMultiSelectReportVariable = (props: Props) => {
  const { name, ...rest } = props;
  const { field } = useController({ name });
  const { onChange } = field;

  const selectedValues: MultiSelectOption<AutoCompleteResult>[] = valuesToAutoCompleteResult(
    field.value,
  );

  const onChangePrime = React.useCallback(
    (value: MultiSelectOption<AutoCompleteResult>[]) => onChange(value.map((v) => v.value)),
    [onChange],
  );

  return (
    <MultiSelectReportVariable {...rest} onChange={onChangePrime} selectedValues={selectedValues} />
  );
};
