import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  type TagProps,
} from '@chakra-ui/react';
import { type ReportTemplate, ReportTemplateStatus } from '@piccolohealth/echo-common';
import { Spin } from '@piccolohealth/ui';
import React from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { useReportTemplatesQuery } from '../../graphql/hooks/useReportTemplatesQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { ChangeReportTypeTag, ReportTypeTag } from '../generic/ReportTypeTag';

export const ReportChangeTypeMenuItem = (
  props: React.PropsWithChildren<{
    reportTemplate: ReportTemplate;
    onClick: () => void;
  }>,
) => {
  const { children, reportTemplate, onClick } = props;

  return (
    <>
      <MenuItem
        data-pw={`reportChangeTypeMenuItem-${reportTemplate?.name ?? 'unknown'}`}
        onClick={onClick}
      >
        {children}
      </MenuItem>
    </>
  );
};

export const ReportTemplateMenuItems = (props: {
  onClick?: (reportTemplate: ReportTemplate) => void;
}) => {
  const { onClick } = props;
  const { organization } = useAppContext();

  const { isLoading, data } = useReportTemplatesQuery({
    organizationId: organization.id,
    getReportTemplatesRequest: {
      statuses: [ReportTemplateStatus.Published],
      master: true,
      pagination: {
        limit: 100,
      },
    },
  });

  const reportTemplates =
    (data?.organization?.reportTemplates?.reportTemplates as ReportTemplate[]) || [];

  const reportTemplateMenuItems = reportTemplates.map((reportTemplate) => {
    return (
      <ReportChangeTypeMenuItem
        key={`${reportTemplate.id}`}
        reportTemplate={reportTemplate}
        onClick={() => onClick?.(reportTemplate)}
      >
        <ReportTypeTag name={reportTemplate.name} labelColor={reportTemplate.labelColor} />
      </ReportChangeTypeMenuItem>
    );
  });

  if (isLoading)
    return (
      <MenuItem justifyContent='center'>
        <Spin size='sm' />
      </MenuItem>
    );

  return <>{reportTemplateMenuItems}</>;
};

interface Props extends Omit<TagProps, 'onClick'> {
  reportTemplate?: ReportTemplate;
  isDisabled?: boolean;
  onClick: (reportTemplate: ReportTemplate) => void;
}

export const ReportTypeChooser = (props: Props) => {
  const { reportTemplate, isDisabled, onClick, ...rest } = props;

  const disabledProps = isDisabled
    ? { isDisabled: true, disabled: true, cursor: 'not-allowed' }
    : {};

  return (
    <Menu isLazy lazyBehavior='keepMounted'>
      {({ isOpen }) => (
        <>
          <MenuList>
            <ReportTemplateMenuItems onClick={(reportTemplate) => onClick?.(reportTemplate)} />
          </MenuList>

          {reportTemplate ? (
            <MenuButton
              as={Button}
              bg='transparent'
              isActive={isOpen}
              disabled={true}
              data-pw='reportChangeTypeMenuButton'
              {...disabledProps}
              p={0}
              _disabled={{}}
              _hover={{}}
              _active={{}}
            >
              <ChangeReportTypeTag
                name={reportTemplate.name}
                labelColor={reportTemplate.labelColor}
                {...rest}
              />
            </MenuButton>
          ) : (
            <MenuButton
              as={Button}
              variant='ghost'
              rightIcon={<Icon as={FaChevronDown} />}
              data-pw='reportChangeTypeMenuButton'
              color='gray.500'
              {...disabledProps}
              p={0}
              _disabled={{}}
              _hover={{}}
              _active={{}}
            >
              Choose a report type
            </MenuButton>
          )}
        </>
      )}
    </Menu>
  );
};
