import { Button, HStack, Stack, Tag, Text } from '@chakra-ui/react';
import {
  type PiccoloError,
  type WorklistEntry,
  WorklistEntryStatus,
} from '@piccolohealth/echo-common';
import {
  DataTable,
  FancyDate,
  type PaginationOptions,
  createColumnHelper,
} from '@piccolohealth/ui';
import { DateTime, P } from '@piccolohealth/util';
import React from 'react';
import { showModal } from '../../../components/generic/Modal';
import { ReportTypeTag } from '../../../components/generic/ReportTypeTag';
import { TextLink } from '../../../components/generic/TextLink';
import { WorklistEntryDeleteModal } from './WorklistEntryDeleteModal';
import { WorklistEntryEditModal } from './WorklistEntryEditModal';
import { WorklistEntryStatusTag } from './WorklistEntryStatusTag';

interface Props {
  isLoading: boolean;
  error: PiccoloError | null;
  worklistEntries: WorklistEntry[];
  emptyText: string;
  pagination?: PaginationOptions;
}

export const WorklistEntryTable = (props: Props) => {
  const { isLoading, error, worklistEntries, emptyText, pagination } = props;

  const onRemove = React.useCallback((worklistEntry: WorklistEntry) => {
    showModal(WorklistEntryDeleteModal, {
      worklistEntryId: worklistEntry.id,
    });
  }, []);

  const onEdit = React.useCallback((worklistEntry: WorklistEntry) => {
    showModal(WorklistEntryEditModal, {
      worklistEntry: worklistEntry,
    });
  }, []);

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<WorklistEntry>();

    return [
      columnHelper.display({
        header: 'Patient',
        cell: (ps) => {
          return (
            <Stack spacing={1}>
              <Text fontSize='sm' fontWeight='bold'>
                {ps.row.original.patientFirstName} {ps.row.original.patientLastName}
              </Text>
              <Text fontSize='sm' fontWeight='normal'>
                {ps.row.original.patientId} -
                {DateTime.fromISO(ps.row.original.patientDob.toString())?.toLocaleString(
                  DateTime.DATE_SHORT,
                )}{' '}
                - {P.upperFirst(ps.row.original.patientSex)}
              </Text>
            </Stack>
          );
        },
        minSize: 140,
        maxSize: 340,
      }),
      columnHelper.display({
        header: 'Created',
        cell: (ps) => {
          return (
            <FancyDate
              date={DateTime.fromISO(ps.row.original.createdAt as unknown as string)}
              direction='column'
              fontSize='sm'
              spacing={0}
            />
          );
        },
        minSize: 200,
        maxSize: 300,
      }),
      columnHelper.display({
        header: 'Report type',
        cell: (ps) => {
          return (
            <ReportTypeTag
              name={ps.row.original.reportTemplate.name}
              labelColor={ps.row.original.reportTemplate.labelColor}
            />
          );
        },
        minSize: 200,
        maxSize: 300,
      }),
      columnHelper.display({
        header: 'Assigned physician',
        cell: (ps) => {
          return <Text fontSize='sm'>{ps.row.original.performingPhysician.name}</Text>;
        },
        minSize: 200,
        maxSize: 400,
      }),
      columnHelper.display({
        header: 'Machine',
        cell: (ps) => {
          return ps.row.original.machineAet?.name ? (
            <Text fontSize='sm'>{ps.row.original.machineAet?.name}</Text>
          ) : (
            <Tag colorScheme='green'>Any machine</Tag>
          );
        },
        minSize: 160,
        maxSize: 200,
      }),
      columnHelper.display({
        header: 'Status',
        cell: (ps) => {
          return <WorklistEntryStatusTag status={ps.row.original.status} />;
        },
        minSize: 100,
        maxSize: 200,
      }),
      columnHelper.display({
        header: 'Actions',
        cell: (ps) => {
          const isScheduled = ps.row.original.status === WorklistEntryStatus.Scheduled;

          return (
            <HStack>
              {isScheduled && (
                <>
                  <Button
                    variant='link'
                    colorScheme='red'
                    onClick={() => onRemove(ps.row.original)}
                    data-pw={`worklistEntryRemoveButton-${ps.row.original.id}`}
                  >
                    Remove
                  </Button>

                  <Button
                    variant='link'
                    colorScheme='purple'
                    onClick={() => onEdit(ps.row.original)}
                    data-pw={`worklistEntryEditButton-${ps.row.original.id}`}
                  >
                    Edit
                  </Button>
                </>
              )}
              {!isScheduled && ps.row.original.reportId && (
                <Button
                  size='sm'
                  variant='link'
                  colorScheme='purple'
                  as={TextLink}
                  to={`/organizations/${ps.row.original.organizationId}/reports/${ps.row.original.reportId}`}
                >
                  Go to report
                </Button>
              )}
            </HStack>
          );
        },
        minSize: 100,
      }),
    ];
  }, [onEdit, onRemove]);

  return (
    <DataTable
      columns={columns}
      data={worklistEntries}
      error={error}
      isLoading={isLoading}
      pagination={pagination}
      size='md'
      data-pw='worklistEntriesTable'
      renderEmpty={() => (
        <Text textAlign='left' fontSize='sm'>
          {emptyText}
        </Text>
      )}
    />
  );
};
