import { Box } from '@chakra-ui/react';
import type { ReportTemplate } from '@piccolohealth/echo-common';
import React from 'react';
import { ReportTemplateCreateControl } from '../../components/controls/templates/ReportTemplateCreateControl';
import { Content } from '../../components/generic/Content';
import { TemplatesTable } from '../../components/templates/TemplatesTable';
import { useReportTemplatesQuery } from '../../graphql/hooks/useReportTemplatesQuery';
import { useAppContext } from '../../hooks/useAppContext';

export const Templates = () => {
  const { organization } = useAppContext();

  const { isLoading, data, error, refetch } = useReportTemplatesQuery({
    organizationId: organization.id,
    getReportTemplatesRequest: {
      pagination: {
        limit: 100,
      },
    },
  });

  const reportTemplates =
    (data?.organization?.reportTemplates?.reportTemplates as ReportTemplate[]) || [];

  return (
    <Content rightExtraContent={<ReportTemplateCreateControl />}>
      <Box px={4}>
        <TemplatesTable
          reportTemplates={reportTemplates}
          isLoading={isLoading}
          error={error}
          refetch={async () => {
            await refetch();
          }}
        />
      </Box>
    </Content>
  );
};
