import { P } from '@piccolohealth/util';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormItem, type FormItemProps } from '../FormItem';

interface Props extends Omit<FormItemProps, 'id'> {
  name: string;
  label?: string;
  helperText?: string;
}

export const HookedFormItem = (props: React.PropsWithChildren<Props>) => {
  const { name, ...rest } = props;
  const { formState } = useFormContext();

  const error = P.get(formState.errors, `${name}.message`) ?? undefined;
  const message = P.isNil(error) ? undefined : String(error);

  return <FormItem {...rest} id={name} error={message} />;
};
