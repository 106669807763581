import { Box, type BoxProps, type ResponsiveValue, forwardRef } from '@chakra-ui/react';
import { mapResponsive } from '@chakra-ui/utils';
import React from 'react';

interface Props extends BoxProps {
  ratio: ResponsiveValue<number>;
}

export const AspectRatio = forwardRef<Props, 'div'>((props, ref) => {
  const { ratio, children, ...rest } = props;

  return (
    <Box
      ref={ref}
      position='relative'
      _before={{
        height: 0,
        content: `""`,
        display: 'block',
        paddingBottom: mapResponsive(ratio, (r) => `${(1 / r) * 100}%`),
      }}
      __css={{
        '& > *:not(style)': {
          position: 'absolute',
          top: '0',
          right: '0',
          bottom: '0',
          left: '0',
          width: '100%',
          height: '100%',
        },
      }}
      {...rest}
    >
      {children}
    </Box>
  );
});
