import { P } from '@piccolohealth/util';
import deepmerge from 'deepmerge';
import type { ReportFormValues, ReportStatement, ReportStatementSite, ReportVariable } from '..';

export const statementSiteDiff = (
  prev: Record<string, ReportStatementSite>,
  curr: Record<string, ReportStatementSite>,
): Record<string, ReportStatementSite> => {
  const diff: Record<string, ReportStatementSite> = {};
  for (const [id, currStatementSite] of Object.entries(curr)) {
    const withoutIds = (statements: ReportStatement[]) =>
      (statements ?? []).map(({ id, ...rest }) => rest);

    if (!P.isEqual(withoutIds(currStatementSite?.statements), withoutIds(prev[id]?.statements))) {
      diff[id] = currStatementSite;
    }
  }
  return diff;
};

export const variablesDiff = (
  prev: Record<string, ReportVariable>,
  curr: Record<string, ReportVariable>,
): Record<string, ReportVariable> => {
  const diff: Record<string, ReportVariable> = {};
  for (const [id, currVariable] of Object.entries(curr)) {
    if (!P.isEqual(currVariable?.value, prev[id]?.value)) {
      diff[id] = currVariable;
    }
  }
  return diff;
};

export interface DiffResponse<A> {
  diff: A;
  isDiff: boolean;
}

export const reportFormValuesDiff = (
  prev: ReportFormValues,
  curr: ReportFormValues,
): DiffResponse<ReportFormValues> => {
  const diff = {
    variables: variablesDiff(prev.variables, curr.variables),
    statementSites: statementSiteDiff(prev.statementSites, curr.statementSites),
  };

  return {
    diff,
    isDiff: !P.isEmptyObject(diff.variables) || !P.isEmptyObject(diff.statementSites),
  };
};

export const reportFormValuesCombine = (
  prev: ReportFormValues,
  curr: ReportFormValues,
): ReportFormValues => {
  return deepmerge(prev, curr, {
    arrayMerge: (_destinationArray, sourceArray) => sourceArray,
  });
};
