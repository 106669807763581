import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import React from 'react';
import { createModal } from '../../../../../generic/Modal';
import { ChooseActionStep } from './ChooseActionStep';
import type { CreateMediaAttachmentRequest } from './MediaAttachmentZone';
import { ChartAttachmentActionStep } from './chart/ChartAttachmentActionStep';
import { ImageUploadAttachmentActionStep } from './image/ImageUploadAttachmentActionStep';
import { StudyImageAttachmentActionStep } from './study/StudyImageAttachmentActionStep';

type Action = 'choose-action' | 'attach-chart' | 'attach-study-image' | 'attach-image-upload';

interface Props {
  reportId: string;
  onAttachMedia: (value: CreateMediaAttachmentRequest) => void;
}

export const MediaAttachmentModal = createModal<Props>((props) => {
  const { reportId, onAttachMedia, modal } = props;
  const { hide, visible, remove } = modal;

  const [currentStep, setCurrentStep] = React.useState<Action>('choose-action');

  const onClose = React.useCallback(() => {
    hide();
    setCurrentStep('choose-action');
  }, [hide]);

  const content = React.useMemo(() => {
    switch (currentStep) {
      case 'choose-action':
        return (
          <ChooseActionStep
            onClose={onClose}
            onChartAttachmentAction={() => setCurrentStep('attach-chart')}
            onStudyImageAttachmentAction={() => setCurrentStep('attach-study-image')}
            onImageUploadAttachmentAction={() => setCurrentStep('attach-image-upload')}
          />
        );

      case 'attach-chart':
        return (
          <ChartAttachmentActionStep
            reportId={reportId}
            onAttachMedia={onAttachMedia}
            onClose={onClose}
            onBack={() => setCurrentStep('choose-action')}
          />
        );

      case 'attach-study-image':
        return (
          <StudyImageAttachmentActionStep
            reportId={reportId}
            onAttachMedia={onAttachMedia}
            onClose={onClose}
            onBack={() => setCurrentStep('choose-action')}
          />
        );

      case 'attach-image-upload':
        return (
          <ImageUploadAttachmentActionStep
            onAttachMedia={onAttachMedia}
            onClose={onClose}
            onBack={() => setCurrentStep('choose-action')}
          />
        );
    }
  }, [currentStep, onAttachMedia, onClose, reportId]);

  return (
    <Modal isOpen={visible} onClose={onClose} onCloseComplete={remove}>
      <ModalOverlay />
      <ModalContent maxW='auto' width='auto'>
        {content}
      </ModalContent>
    </Modal>
  );
});

export default MediaAttachmentModal;
