import { Box, Button, HStack, Heading, Stack } from '@chakra-ui/react';
import type { LooseObject } from '@piccolohealth/echo-common';
import { uuid } from '@piccolohealth/util';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { HookedFormItem } from '../../../../../../forms/hookform/HookedFormItem';
import { HookedSubmitButton } from '../../../../../../forms/hookform/HookedSubmitButton';
import { HookedTextArea } from '../../../../../../forms/hookform/HookedTextArea';
import { Divider } from '../../../../../../generic/Divider';
import { ImageViewer } from '../../../../../../viewer/ImageViewer';
import { Layout } from '../../../../../../viewer/controls/Layout';
import type { CreateMediaAttachmentStudyImageRequest } from '../MediaAttachmentZone';

interface Props {
  reportId: string;
  onAttachStudyImage: (value: CreateMediaAttachmentStudyImageRequest) => void;
  onClose: () => void;
  onBack: () => void;
}

export const StudyImageAttachmentForm = (props: Props) => {
  const { reportId, onAttachStudyImage, onClose, onBack } = props;

  const methods = useForm<CreateMediaAttachmentStudyImageRequest>({
    defaultValues: {
      id: uuid(),
      type: 'STUDY_IMAGE',
      description: null,
      data: null,
      metadata: null,
    },
  });

  const { setValue } = methods;

  const setImageData = React.useCallback(
    (value: string, metadata: LooseObject) => {
      setValue('data', value, { shouldDirty: true });
      setValue('metadata', metadata, { shouldDirty: true });
    },
    [setValue],
  );

  const onSubmit = React.useCallback(
    (data: CreateMediaAttachmentStudyImageRequest) => {
      onAttachStudyImage(data);
      onClose();
    },
    [onAttachStudyImage, onClose],
  );

  return (
    <FormProvider {...methods}>
      <Box as='form' onSubmit={methods.handleSubmit(onSubmit)} h='full'>
        <HStack h='full' spacing={4}>
          <ImageViewer
            reportId={reportId}
            isPlaying={false}
            layout={Layout.layout1x1}
            allowChangeLayout={false}
            allowChangeModes={false}
            allowShowReport={false}
            onScreenshot={setImageData}
          />
          <Stack spacing={4} h='full' w='3xs'>
            <Heading size='sm'>Attach a study image</Heading>
            <HookedFormItem
              name='description'
              label='Description'
              helperText='A caption below your image'
            >
              <HookedTextArea name='description' minRows={3} />
            </HookedFormItem>
            <Divider />
            <HStack>
              <Button onClick={onBack}>Back</Button>
              <Button onClick={onClose}>Close</Button>
              <HookedSubmitButton data-pw='attachStudyImageSubmitButton'>Save</HookedSubmitButton>
            </HStack>
          </Stack>
        </HStack>
      </Box>
    </FormProvider>
  );
};
