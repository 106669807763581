import React from 'react';
import * as Konva from 'react-konva';
import { MeasurementAreaShading } from './MeasurementAreaShading';
import { MeasurementCrosshair } from './MeasurementCrosshair';
import { MeasurementText } from './MeasurementText';
import type { UseAreaLengthVolumeToolReturn } from './hooks/useAreaLengthVolumeTool';

interface Props {
  tool: UseAreaLengthVolumeToolReturn;
}

export const MeasurementVolume = (props: Props) => {
  const { tool } = props;
  const { polygon, line, text } = tool;

  if (!polygon) {
    return null;
  }

  const strokeColor = line ? 'yellow' : 'red';
  const fillColor = line ? 'rgba(255,0,0,0.05)' : 'rgba(255,255,0,0.05)';

  const [polygonStart, polygonEnd] = [polygon.points[0], polygon.points[polygon.points.length - 1]];

  const polygonMiddlePoints = polygon.points.slice(0, polygon.points.length - 1);

  return (
    <>
      <Konva.Line
        points={polygonMiddlePoints.flatMap((p) => [p.x, p.y])}
        stroke={strokeColor}
        strokeWidth={1}
        dash={[4, 2]}
        closed={false}
        fill={fillColor}
      />

      {line && (
        <>
          <Konva.Line
            points={[polygonEnd.x, polygonEnd.y, polygonStart.x, polygonStart.y]}
            stroke={strokeColor}
            strokeWidth={1}
          />
          <MeasurementCrosshair position={polygonStart} color={strokeColor} gap={3} size={5} />
          <MeasurementCrosshair position={polygonEnd} color={strokeColor} gap={3} size={5} />
          <Konva.Line
            points={[line.start.x, line.start.y, line.end.x, line.end.y]}
            stroke={strokeColor}
            strokeWidth={1}
            dash={[4, 2]}
          />

          <MeasurementCrosshair position={line.end} color={strokeColor} gap={3} size={5} />
          {text && (
            <>
              <MeasurementAreaShading line={line} polygon={polygon} color={strokeColor} />
              <MeasurementText value={text.value} position={text.position} color={strokeColor} />
            </>
          )}
        </>
      )}
    </>
  );
};
