import React from 'react';
import { useController } from 'react-hook-form';
import {
  MedicalObjectsProviderToChooser,
  type MedicalObjectsProviderToChooserProps,
} from '../MedicalObjectsProviderToChooser';

interface Props
  extends Omit<MedicalObjectsProviderToChooserProps, 'onChange' | 'value' | 'selectedValues'> {
  name: string;
}

export const HookedMedicalObjectsProviderYoChooser = (props: Props) => {
  const { name, ...rest } = props;
  const { field } = useController({ name });
  const { onChange, value } = field;

  return <MedicalObjectsProviderToChooser {...rest} onChange={onChange} value={value} />;
};
