import { Element as CraftJsElement } from '@craftjs/core';
import { P } from '@piccolohealth/util';
import React from 'react';
import { renderNode } from '../../../utils/craftjs';
import { useSSREditor } from '../hooks/useSSREditor';
import { RESOLVERS } from '../report/helpers';

export declare type ElementProps<T extends React.ElementType> = {
  id?: string;
  is?: T;
  custom?: Record<string, any>;
  children?: React.ReactNode;
  canvas?: boolean;
  linked?: Record<string, string>;
} & React.ComponentProps<T>;

export function Element<T extends React.ElementType>(props: ElementProps<T>) {
  const editor = useSSREditor(props);

  if (P.isEmptyObject(editor)) {
    const resolvedLinkedNodeId = P.get<string, string>(props.linked ?? {}, props.id);

    if (P.isNil(resolvedLinkedNodeId)) {
      return null;
    }

    return renderNode(props.nodes, RESOLVERS, resolvedLinkedNodeId, props.ssr);
  }

  return <CraftJsElement {...props} />;
}
