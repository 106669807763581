import { IntegrationType } from '@piccolohealth/echo-common';
import React from 'react';
import { useAppContext } from '../../../../hooks/useAppContext';
import { showModal } from '../../../generic/Modal';
import { IntegrationCard } from '../IntegrationCard';
import { FaxIntegrationModal } from './FaxIntegrationModal';
import { LOGOS } from '../../../../utils/images';

const title = 'Fax';
const description = 'Distribute reports via fax';
const logo = LOGOS.fax;

export const FaxIntegrationCard = () => {
  const { organization } = useAppContext();

  const isIntegrationEnabled = organization.hasIntegrationEnabled(IntegrationType.Fax);

  return (
    <IntegrationCard
      title={title}
      description={description}
      logo={logo}
      isIntegrationEnabled={isIntegrationEnabled}
      onEditIntegration={() =>
        showModal(FaxIntegrationModal, {
          title,
          description,
          logo,
          isIntegrationEnabled,
        })
      }
      h='4xs'
      w='sm'
    />
  );
};
