import type Konva from 'konva';
import React from 'react';
import {
  type Line,
  type MeasurementInfo,
  type MeasurementTool,
  type Polygon,
  type UltrasoundRegion,
  type Vector2D,
  calculateAreaLabelPosition,
  calculateAreaLengthVolume,
  calculateLength,
  calculateRoundedMeasurement,
  getExtendedLine,
  getIntersectionWithPolygonEdge,
  getMeasurementMetrics,
  getMidpoint,
  getScaledPoint,
} from '../../utils';
import { useAreaTool } from './useAreaTool';

export interface UseAreaLengthVolumeToolOptions {
  scale: Vector2D;
  ultrasoundRegions: UltrasoundRegion[];
  measurementInfo: MeasurementInfo | null;
}

export interface UseAreaLengthVolumeToolReturn extends MeasurementTool {
  step: Step;
  polygon: Polygon | null;
  line: Line | null;
  handleMouseDown: (event: Konva.KonvaEventObject<MouseEvent>) => void;
  handleMouseMove: (event: Konva.KonvaEventObject<MouseEvent>) => void;
  handleMouseUp: () => void;
}

export type Step = 'area' | 'line' | 'volume';

export const useAreaLengthVolumeTool = (
  options: UseAreaLengthVolumeToolOptions,
): UseAreaLengthVolumeToolReturn => {
  const areaTool = useAreaTool(options);

  const polygon = areaTool.polygon;

  const [step, setStep] = React.useState<Step>('area');
  const [line, setLine] = React.useState<Line | null>(null);

  const handleMouseDown = (event: Konva.KonvaEventObject<MouseEvent>) => {
    if (step === 'area') {
      return areaTool.handleMouseDown(event);
    }

    if (step === 'line') {
      setStep('volume');
    }
  };

  const handleMouseUp = () => {
    if (step === 'area') {
      areaTool.handleMouseUp();
      setStep('line');
    }
  };

  const handleMouseMove = (event: Konva.KonvaEventObject<MouseEvent>) => {
    if (step === 'area') {
      return areaTool.handleMouseMove(event);
    }

    if (step === 'line') {
      const point = event.target.getStage()?.getPointerPosition();

      if (!point || !areaTool.isPolygonValid || !polygon) {
        return;
      }

      const polygonStartPoint = polygon.points[0];
      const polygonEndPoint = polygon.points[polygon.points.length - 1];

      const cursorPosition = getScaledPoint(point, options.scale);
      const midpoint = getMidpoint(polygonStartPoint, polygonEndPoint);

      // Get a line that extends from the midpoint to the cursor position
      const extendedLineInDirection = getExtendedLine(
        { start: midpoint, end: cursorPosition },
        10000,
      );

      // Find the intersection between the extended line and the polygon edges
      const intersection = getIntersectionWithPolygonEdge(extendedLineInDirection, polygon);

      if (intersection) {
        const { rowPixelSpacing, colPixelSpacing, suffix } = getMeasurementMetrics(
          options.ultrasoundRegions,
          [midpoint, intersection],
        );

        const length = calculateLength(
          midpoint,
          intersection,
          rowPixelSpacing ?? 1,
          colPixelSpacing ?? 1,
        );

        setLine({
          start: midpoint,
          end: intersection,
          length: { value: length, units: suffix },
        });
      }
    }
  };

  const reset = () => {
    setStep('area');
    setLine(null);
    areaTool.reset();
  };

  const measurement = React.useMemo(() => {
    if (step !== 'volume' || !options.measurementInfo || !polygon || !line) {
      return null;
    }

    if (!polygon.area || !line.length) {
      return null;
    }

    if (polygon.area.units !== line.length.units) {
      return null;
    }

    const value = calculateAreaLengthVolume(polygon, line);

    if (!value) {
      return null;
    }

    return calculateRoundedMeasurement({
      fromUnit: polygon.area.units,
      toUnit: options.measurementInfo.units,
      value,
      precision: options.measurementInfo.precision,
    });
  }, [line, options.measurementInfo, polygon, step]);

  const text = React.useMemo(() => {
    if (!measurement || !polygon) {
      return null;
    }

    const position = calculateAreaLabelPosition(polygon);

    if (!position) {
      return null;
    }

    return { value: `${measurement.value} ${measurement.units}`, position };
  }, [measurement, polygon]);

  return {
    polygon: polygon,
    text,
    measurement,
    step,
    line,
    reset,
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
  };
};
