import { Select, type SelectOption } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useReport } from '../../../../context/ReportContext';

interface Props {
  statementSiteId: string;
  onChange: (value: string) => void;
}

export const StatementSiteChooser = (props: Props) => {
  const { statementSiteId, onChange } = props;
  const { reportTemplate } = useReport();

  const options: SelectOption<string>[] = P.compact(
    reportTemplate.statementSites.map((statementSite) => {
      return { value: statementSite.id, label: statementSite.name, raw: statementSite.id };
    }),
  );

  const onChangePrime = React.useCallback(
    (value: SelectOption<string>) => {
      return onChange(value.value);
    },
    [onChange],
  );

  const selectedValue: SelectOption<string> | null = React.useMemo(() => {
    return options.find((option) => option.value === statementSiteId) ?? null;
  }, [options, statementSiteId]);

  return (
    <Select
      onChange={onChangePrime}
      value={selectedValue}
      options={options}
      placeholder='Select an option'
    />
  );
};
