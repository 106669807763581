import { Text } from '@chakra-ui/react';
import type { Invoice } from '@piccolohealth/echo-common';
import { DescriptionList } from '@piccolohealth/ui';
import { P, inflection } from '@piccolohealth/util';
import React from 'react';

interface Props {
  invoice: Invoice;
}

export const InvoiceReportTypeSummary = (props: Props) => {
  const reports = props.invoice.subscriptionItems.flatMap((item) =>
    P.compact(
      item.usageRecords.map((ur) => {
        const reportId = ur?.reportId ?? 'Unknown';
        const reportTemplateName = ur?.report?.reportTemplate.name;

        if (!reportTemplateName) {
          return {
            reportId,
            reportTemplateName: 'Unknown (deleted)',
          };
        }

        return {
          reportId,
          reportTemplateName,
        };
      }),
    ),
  );
  const uniqueReports = P.uniqBy(reports, (r) => r.reportId);
  const countedReportTemplates = P.mapValues(
    P.groupBy(uniqueReports, (r) => r.reportTemplateName),
    (v) => v?.length ?? 0,
  );

  return (
    <DescriptionList
      rowGap={2}
      items={P.listify(countedReportTemplates, (count, reportTemplateName) => ({
        title: reportTemplateName,
        content: (
          <Text fontSize='sm'>
            {count} {inflection.inflect('report', count)}
          </Text>
        ),
      }))}
    />
  );
};
