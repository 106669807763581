import { HStack, Input, Kbd, Spacer, Text } from '@chakra-ui/react';
import {
  type HealthLinkAddress,
  type HealthLinkName,
  type HealthLinkPractitioner,
  renderHealthLinkName,
} from '@piccolohealth/echo-common';
import {
  MultiSelectComponents,
  Select,
  SelectComponents,
  type SelectGroup,
  type SelectOption,
} from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import debouncePromise from 'debounce-promise';
import React from 'react';
import { useHealthLinkIntegrationPractitionersQuery } from '../../graphql/hooks/useIntegrationsQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { FormItem } from './FormItem';
import { FormSection } from './FormSection';

const LIMIT = 40;

type HealthLinkProviderDetails = {
  practitionerId: string;
  practitionerName: HealthLinkName;
  endpointId: string;
  endpointName: string;
  organizationId: string;
  organizationAddress: HealthLinkAddress;
  organizationName: string;
  providerNumber: string;
};

export interface HealthLinkProviderToChooserProps {
  value: HealthLinkProviderDetails | null;
  onChange: (value: HealthLinkProviderDetails) => void;
  isDisabled?: boolean;
}

export const HealthLinkProviderToChooser = (props: HealthLinkProviderToChooserProps) => {
  const { value, onChange, isDisabled } = props;

  const { organization } = useAppContext();

  const [filter, setFilter] = React.useState({
    firstName: '',
    lastName: '',
  });

  const { data, isFetching, refetch } = useHealthLinkIntegrationPractitionersQuery(
    {
      organizationId: organization.id,
      request: {
        pagination: {
          limit: LIMIT,
        },
        filter: {
          firstName: P.isEmptyString(filter.firstName) ? undefined : filter.firstName,
          lastName: P.isEmptyString(filter.lastName) ? undefined : filter.lastName,
        },
      },
    },
    {
      enabled: false,
    },
  );

  const debouncedRefetch = React.useMemo(() => debouncePromise(refetch, 300), [refetch]);

  React.useEffect(() => {
    if (!P.isEmptyString(filter.firstName) || !P.isEmptyString(filter.lastName)) {
      debouncedRefetch();
    }
  }, [filter, debouncedRefetch]);

  const isLoading = isFetching;

  const practitioners = data?.organization?.integrations.healthLink.practitioners.results ?? [];

  const options: SelectGroup<HealthLinkProviderDetails, HealthLinkPractitioner>[] =
    practitioners.flatMap((practitioner) => {
      return {
        id: practitioner.id,
        label: renderHealthLinkName(practitioner.name),
        raw: practitioner,
        options: practitioner.providers.map((provider) => {
          return {
            value: provider.providerNumber,
            label: provider.providerNumber,
            raw: {
              practitionerId: practitioner.id,
              practitionerName: practitioner.name,
              endpointId: provider.endpoint.id,
              endpointName: provider.endpoint.name,
              organizationId: provider.organization.id,
              organizationAddress: provider.organization.address,
              organizationName: provider.organization.name,
              providerNumber: provider.providerNumber,
            },
          };
        }),
      };
    });

  const valuePrime: SelectOption<HealthLinkProviderDetails> | null = value
    ? {
        label: value.providerNumber,
        value: value.providerNumber,
        raw: value,
      }
    : null;

  const onChangePrime = React.useCallback(
    (value: SelectOption<HealthLinkProviderDetails>) => {
      return onChange(value.raw);
    },
    [onChange],
  );

  const components = React.useMemo<
    SelectComponents<HealthLinkProviderDetails, HealthLinkPractitioner>
  >(() => {
    return {
      OptionGroup: (props) => (
        <HStack fontWeight='semibold' color='gray.500' py={2} px={1} fontSize='xs'>
          <Text>{props.group.label}</Text>
          <Spacer />
          <Text>{props.group.raw.id}</Text>
        </HStack>
      ),
      Option: (props) => (
        <HStack w='full' fontSize='xs'>
          <Kbd fontSize='sm'>{props.option.value}</Kbd>
          <Spacer />
          <Text>{props.option.raw.organizationName}</Text>
        </HStack>
      ),
      Value: (props) => (
        <HStack w='full' mr={1}>
          <Text>{renderHealthLinkName(props.option.raw.practitionerName)}</Text>
          <Spacer />
          <Kbd fontSize='sm'>{props.option.raw.providerNumber}</Kbd>
        </HStack>
      ),
      Header: () => (
        <SelectComponents.Header color='revert'>
          <FormSection
            w='full'
            heading='Filter providers'
            description='Search providers using first name or last name'
          >
            <HStack w='full' pb={2}>
              <FormItem>
                <Input
                  value={filter.firstName}
                  onChange={(e) => setFilter((prev) => ({ ...prev, firstName: e.target.value }))}
                  size='sm'
                  placeholder='First name'
                  data-pw='distributionModalHealthLinkToFirstNameInput'
                />
              </FormItem>
              <FormItem>
                <Input
                  value={filter.lastName}
                  onChange={(e) => setFilter((prev) => ({ ...prev, lastName: e.target.value }))}
                  size='sm'
                  placeholder='Last name'
                  data-pw='distributionModalHealthLinkToLastNameInput'
                />
              </FormItem>
            </HStack>
          </FormSection>
        </SelectComponents.Header>
      ),
      Footer: () => (
        <MultiSelectComponents.Footer>
          <Text fontSize='xs' whiteSpace='nowrap'>
            Limited to {LIMIT} results
          </Text>
        </MultiSelectComponents.Footer>
      ),
    };
  }, [filter.firstName, filter.lastName]);

  return (
    <Select
      options={options}
      value={valuePrime}
      onChange={onChangePrime}
      isDisabled={isDisabled}
      isLoading={isLoading}
      placeholder='Choose a provider number'
      data-pw='distributionModalHealthLinkToProviderNumberChooser'
      components={components}
    />
  );
};
