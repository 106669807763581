import { SkeletonText, Stack } from '@chakra-ui/react';
import type { ReportTemplateVariable, ReportVariable } from '@piccolohealth/echo-common';
import { MotionSlideOut, ScrollArea, typedMemo } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useReport } from '../../../../context/ReportContext';
import { useAppContext } from '../../../../hooks/useAppContext';
import { useHistoricalVariables } from '../../../../hooks/useHistoricalVariables';
import type { UseMeasurementControlReturn } from '../tool/hooks/useMeasurementControl';
import { MeasurementListHeader } from './MeasurementListHeader';
import { MeasurementsList } from './MeasurementsList';

export type VariablesBySite = {
  site: string;
  measurements: {
    reportTemplateVariable: ReportTemplateVariable;
    variable: ReportVariable;
  }[];
}[];

interface Props {
  measurementControl: UseMeasurementControlReturn;
  isMeasurementsShowing: boolean;
}

export const MeasurementsSidebar = typedMemo((props: Props) => {
  const { measurementControl, isMeasurementsShowing } = props;

  const { organization } = useAppContext();
  const { reportTemplate, reportId } = useReport();

  const [searchValue, setSearchValue] = React.useState('');

  const onSearch = React.useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  const { currentVariables, isLoading } = useHistoricalVariables({
    organizationId: organization.id,
    reportId,
    reportTemplate,
  });

  const variablesBySite: VariablesBySite = React.useMemo(() => {
    const filteredByName = currentVariables.filter(({ alias, templateVariable }) => {
      if (searchValue === '' || !searchValue) {
        return true;
      }
      const labelMatch = templateVariable.label.toLowerCase().includes(searchValue.toLowerCase());
      const aliasMatch = alias.toLowerCase().includes(searchValue.toLowerCase());

      return labelMatch || aliasMatch;
    });

    const bySite = P.groupBy(filteredByName, ({ templateVariable }) => templateVariable.site);

    const orderedBySite = P.mapValues(bySite, (measurements, site) => ({
      site,
      measurements: P.orderBy(measurements ?? [], (v) => v.templateVariable.label).map((v) => {
        return {
          reportTemplateVariable: v.templateVariable,
          variable: v.variable,
        };
      }),
    }));

    const filteredBySite = P.omitBy(orderedBySite, (v, site) => ['Study', 'Misc'].includes(site));

    return Object.values(filteredBySite);
  }, [currentVariables, searchValue]);

  const content = P.run(() => {
    if (isLoading) {
      return (
        <SkeletonText
          noOfLines={12}
          spacing={4}
          skeletonHeight={2}
          startColor='gray.600'
          endColor='gray.700'
          pr={2}
        />
      );
    }

    return <MeasurementsList variables={variablesBySite} />;
  });

  return (
    <MotionSlideOut isOpen={isMeasurementsShowing}>
      <Stack w='320px' h='full' flexShrink={0} bg='gray.900' pl={1}>
        <MeasurementListHeader
          onChange={onSearch}
          value={searchValue}
          measurementControl={measurementControl}
          variablesBySite={variablesBySite}
        />

        <ScrollArea>{content}</ScrollArea>
      </Stack>
    </MotionSlideOut>
  );
});
