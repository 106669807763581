import type * as Types from '@piccolohealth/echo-common';
import type { AuditTrailEntry } from '@piccolohealth/echo-common';
import { useAppContext } from '../../hooks/useAppContext';
import type { AuditTrailFilter } from '../../hooks/useAuditTrailFilter';
import { createTimestampPaginatedGqlQuery } from '../fetcher';
import { AuditTrail } from '../queries/auditTrail.query';

export const useAuditTrailFilterQuery = (filter: AuditTrailFilter) => {
  const { organization } = useAppContext();

  const query = createTimestampPaginatedGqlQuery<
    Types.AuditTrailQueryVariables,
    Types.AuditTrailQuery
  >((variables) => ['auditTrail', variables], AuditTrail, {
    filter,
    getPaginationResponse: (response) => {
      return response?.organization?.auditTrail?.pagination;
    },
  });

  const resp = query({
    organizationId: organization.id,
    getAuditTrailRequest: {
      pagination: {
        lastSeen: filter.lastSeen,
        firstSeen: filter.firstSeen,
        limit: filter.pageSize,
      },
      start: filter.startDateFilter,
      end: filter.endDateFilter,
      message: filter.messageFilter,
      events: filter.eventsFilter,
      users: filter.usersFilter,
    },
  });

  const entries = (resp.data?.organization?.auditTrail?.results as AuditTrailEntry[]) ?? [];

  return {
    ...resp,
    entries,
  };
};
