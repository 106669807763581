import { Empty } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import type { BarSeriesOption, EChartsOption } from 'echarts';
import React from 'react';
import { CHART_COLORS } from './ChartsTheme';
import { ECharts } from './ECharts';

interface Props {
  chartData: BarChartData[];
}

export interface BarChartData {
  x: string;
  y: {
    [key: string]: {
      value: number;
      color: string;
    };
  };
}

export const BarChart = (props: Props) => {
  const { chartData } = props;

  const formattedData = React.useMemo(() => {
    const series: BarSeriesOption[] = [];
    const categories = chartData.map((item) => item.x);

    const keys = Object.keys(chartData[0]?.y || {});
    keys.forEach((key) => {
      const data = chartData.map((item) => {
        if (!item.y[key]?.value) {
          return null;
        }

        return {
          name: item.x,
          value: item.y[key]?.value || 0,

          itemStyle: {
            borderRadius: [6, 6, 6, 6],
            color: chartData[0]?.y[key]?.color || CHART_COLORS.white,
            barBorderWidth: 2,
            barBorderColor: CHART_COLORS.white,
          },
        };
      });

      series.push({
        name: key,
        type: 'bar',
        stack: 'total',
        label: { show: true, position: 'inside', color: CHART_COLORS.gray },
        data,
        labelLayout: {
          hideOverlap: true,
        },
        itemStyle: {
          color: chartData[0]?.y[key]?.color || CHART_COLORS.white, // Assign color to the legend
        },
      });
    });

    return { categories, series };
  }, [chartData]);

  const options: EChartsOption = React.useMemo(() => {
    return {
      grid: {
        top: 40,
        right: 40,
        bottom: 40,
        left: 40,
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: formattedData.categories,
        axisTick: { alignWithLabel: true },
        axisLine: { lineStyle: { color: CHART_COLORS.lightGray, width: 1 } },
        axisLabel: { color: CHART_COLORS.gray },
      },
      yAxis: {
        type: 'value',
      },
      series: formattedData.series,
      legend: {
        show: true,
      },
    };
  }, [formattedData.categories, formattedData.series]);

  if (P.isEmpty(chartData)) {
    return <Empty />;
  }

  return <ECharts options={options} />;
};
