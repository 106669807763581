import { Tag, TagCloseButton, TagLabel, type TagProps, TagRightIcon } from '@chakra-ui/react';
import React from 'react';
import { FaAngleDown } from 'react-icons/fa';

interface Props extends TagProps {
  name: string;
  labelColor?: string;
  onRemove?: () => void;
}

export const ReportTypeTag = (props: Props) => {
  const { name, labelColor, onRemove, ...rest } = props;

  return (
    <Tag colorScheme={labelColor} {...rest}>
      <TagLabel>{name}</TagLabel>
      {onRemove && (
        <TagCloseButton
          tabIndex={-1}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onRemove();
          }}
        />
      )}
    </Tag>
  );
};

export const ChangeReportTypeTag = (props: Props) => {
  const { name, labelColor, ...rest } = props;

  return (
    <Tag colorScheme={labelColor} {...rest}>
      <TagLabel>{name}</TagLabel>
      <TagRightIcon as={FaAngleDown} />
    </Tag>
  );
};
