import { Circle, type SquareProps } from '@chakra-ui/react';
import React from 'react';

interface Props extends SquareProps {
  isFilled: boolean;
  isOutlined: boolean;
}

export const Bullet = (props: Props) => {
  const { isOutlined, isFilled, ...rest } = props;
  const bg = isFilled ? 'purple.600' : 'gray.300';
  const outline = isOutlined ? '#E9D8FD solid 5px' : undefined;

  return <Circle size='10px' bg={bg} outline={outline} {...rest} />;
};
