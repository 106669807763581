import { P } from '@piccolohealth/util';
import React from 'react';
import type { DraggingPosition, TreeItem, TreeItemIndex, TreeViewState } from 'react-complex-tree';
import type { MeasurementMappingAndVariants, MeasurementVariant } from '../../../../../common/src';
import { useUpdateMeasurementVariantsMutation } from '../../../graphql/hooks/useUpdateMeasurementVariantsMutation';
import { useToast } from '../../../hooks/useToast';
import {
  type TreeData,
  generateMappingsAndVariantsTree,
  getParentOfItem,
} from '../components/mappings/utils';

export interface UseMappingsAndVariantsTreeStateOptions {
  variants: MeasurementVariant[];
  mappings: MeasurementMappingAndVariants[];
}

export const useMappingsAndVariantsTreeState = (
  options: UseMappingsAndVariantsTreeStateOptions,
) => {
  const [treeState, setTreeState] = React.useState<Record<TreeItemIndex, TreeItem<TreeData>>>({});
  const [viewState, setViewState] = React.useState<TreeViewState<string>>({});
  const memoizedGetParentOfItem = P.memoize(getParentOfItem);

  const updateMeasurementVariantsMutation = useUpdateMeasurementVariantsMutation({});

  const { successToast, errorToast } = useToast();

  const updateMeasurementVariants = React.useCallback(
    async (options: { hash: string; mappingId: string | null }) => {
      const { hash, mappingId } = options;

      await updateMeasurementVariantsMutation
        .mutateAsync({
          hash,
          request: {
            mappingId,
          },
        })
        .then(() => {
          successToast('Successfully updated variants');
        })
        .catch((e) => {
          errorToast(`Failed to update variants: ${e.message}`);
        });
    },
    [errorToast, successToast, updateMeasurementVariantsMutation],
  );

  const onFocusItem = (item: TreeItem<TreeData>, treeId: string) => {
    setViewState((prev) => {
      return {
        ...prev,
        [treeId]: {
          ...prev[treeId],
          focusedItem: item.index,
        },
      };
    });
  };

  const onExpandItem = (item: TreeItem<TreeData>, treeId: string) => {
    setViewState((prev) => {
      return {
        ...prev,
        [treeId]: {
          ...prev[treeId],
          expandedItems: [...(prev[treeId]?.expandedItems ?? []), item.index],
        },
      };
    });
  };

  const onCollapseItem = (item: TreeItem<TreeData>, treeId: string) => {
    setViewState((prev) => {
      return {
        ...prev,
        [treeId]: {
          ...prev[treeId],
          expandedItems: [...(prev[treeId]?.expandedItems ?? [])].filter((i) => i !== item.index),
        },
      };
    });
  };

  const onSelectItem = (items: TreeItemIndex[], treeId: string) => {
    setViewState((prev) => {
      return {
        ...prev,
        [treeId]: {
          ...prev[treeId],
          // only select 1 element at a time. If need multiple select, spread all items
          selectedItems: [items[0]],
        },
      };
    });
  };

  const onDrop = (items: TreeItem<TreeData>[], target: DraggingPosition) => {
    // // Assuming items is an array with a single dragged item
    const draggedItem = items[0];

    if (draggedItem.data.type !== 'hash') {
      return;
    }

    if (target.targetType !== 'item') {
      return;
    }

    const mappingId = target.targetItem.toString().replace('M-M-', '');
    updateMeasurementVariants({ hash: draggedItem.data.variant.hash, mappingId });
  };

  const canDrag = React.useCallback((items: TreeItem<TreeData>[]) => {
    // assume one item only (deal with multiple selection later)
    const item = items[0];

    if (!item) {
      return false;
    }

    const itemIndex = item.index.toString();

    // can drag hashes in the variants tree around
    if (itemIndex.startsWith('V-H-')) {
      return true;
    }

    if (itemIndex.startsWith('M-H-')) {
      return true;
    }

    return false;
  }, []);

  const canDropAt = React.useCallback(
    (items: TreeItem<TreeData>[], target: DraggingPosition) => {
      // assume one item only (deal with multiple selection later)
      const item = items[0];

      if (!item) {
        return false;
      }

      const itemParent = memoizedGetParentOfItem(treeState, item.index);
      const itemIndex = item.index.toString();

      // cannot drag mappings around
      if (itemIndex.startsWith('M-M-')) {
        return false;
      }

      // cannot drag into the variants tree
      if (target.treeId === 'V-TREE') {
        return false;
      }

      if (itemIndex.startsWith('M-H-') || itemIndex.startsWith('V-H-')) {
        // do not allow dragging anywhere but inside the mappings tree
        if (target.treeId !== 'M-TREE') {
          return false;
        }

        switch (target.targetType) {
          // Cannot drag within same node
          case 'between-items': {
            return false;
          }
          // Cannot drag to root node
          case 'root': {
            return false;
          }
          // Can drag into an item.
          case 'item': {
            const targetIndex = target.targetItem.toString();
            // But must not allow dragging into your parent
            if (target.targetItem === itemParent?.index) {
              return false;
            }

            // Can drag into a mapping node
            if (targetIndex.startsWith('M-M-')) {
              return true;
            }

            return false;
          }
        }
      }

      return false;
    },
    [memoizedGetParentOfItem, treeState],
  );

  const removeItem = React.useCallback(
    (item: TreeItem<TreeData>) => {
      if (item.data.type !== 'hash') {
        return;
      }

      updateMeasurementVariants({ hash: item.data.variant.hash, mappingId: null });
    },
    [updateMeasurementVariants],
  );

  React.useEffect(() => {
    setTreeState(generateMappingsAndVariantsTree(options.variants, options.mappings));
  }, [options.variants, options.mappings]);

  return {
    treeState,
    viewState,
    onFocusItem,
    onExpandItem,
    onCollapseItem,
    onSelectItem,
    onDrop,
    canDrag,
    canDropAt,
    removeItem,
  };
};
