import { HStack, Spacer, Tag, TagCloseButton, TagLabel, Text, Tooltip } from '@chakra-ui/react';
import type { Label } from '@piccolohealth/echo-common';
import {
  MultiSelect,
  MultiSelectComponents,
  type MultiSelectOption,
  type OnChangeRequest,
  SelectCheck,
} from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useAddReportLabelMutation } from '../../graphql/hooks/useAddReportLabelMutation';
import { useCreateLabelMutation } from '../../graphql/hooks/useCreateLabelMutation';
import { useLabelsQuery } from '../../graphql/hooks/useLabelsQuery';
import { useRemoveReportLabelMutation } from '../../graphql/hooks/useRemoveReportLabelMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { ManageLabelMenu } from './ManageLabelMenu';

const labelToOption = (label: Label): MultiSelectOption<Label> => {
  return {
    label: label.name,
    value: label.id,
    raw: label,
  };
};

interface Props {
  reportId: string;
  reportLabels: Label[];
}

export const AssignLabelsControl = (props: Props) => {
  const { reportId, reportLabels } = props;
  const { organization, errorToast } = useAppContext();

  const createLabelMutation = useCreateLabelMutation({ retry: false });
  const addReportLabelMutation = useAddReportLabelMutation({ retry: false });
  const removeReportLabelMutation = useRemoveReportLabelMutation({ retry: false });

  const labelsQuery = useLabelsQuery({
    organizationId: organization.id,
  });

  const options = React.useMemo(() => {
    const labels = (labelsQuery?.data?.organization?.labels as Label[]) || [];
    return labels.map(labelToOption);
  }, [labelsQuery]);

  const isMutationsLoading =
    createLabelMutation.isLoading ||
    addReportLabelMutation.isLoading ||
    removeReportLabelMutation.isLoading;

  const selectedValues: MultiSelectOption<Label>[] = React.useMemo(() => {
    return reportLabels.map(labelToOption);
  }, [reportLabels]);

  const onChange = React.useCallback(
    (request: OnChangeRequest<Label>) => {
      switch (request.action) {
        case 'CREATE': {
          return createLabelMutation
            .mutateAsync({
              organizationId: organization.id,
              request: {
                color: 'blue',
                name: request.value.value,
              },
            })
            .catch((err) => errorToast(`Error creating label: ${err.message}`));
        }
        case 'ADD': {
          return addReportLabelMutation
            .mutateAsync({
              organizationId: organization.id,
              reportId,
              labelId: request.value.value,
            })
            .catch((err) => errorToast(`Error adding label: ${err.message}`));
        }

        case 'REMOVE': {
          return removeReportLabelMutation
            .mutateAsync({
              organizationId: organization.id,
              reportId,
              labelId: request.value.value,
            })
            .catch((err) => errorToast(`Error removing label: ${err.message}`));
        }
      }
    },
    [
      organization,
      reportId,
      createLabelMutation,
      addReportLabelMutation,
      removeReportLabelMutation,
      errorToast,
    ],
  );

  return (
    <MultiSelect
      options={options}
      selectedValues={selectedValues}
      isLoading={isMutationsLoading}
      isCreatable
      onChange={onChange}
      variant='selectGhost'
      placeholder='Add label'
      data-pw={`assignLabelsControl-${reportId}`}
      components={{
        Value: (props) => (
          <Tag size='md' variant='subtle' colorScheme={props.option.raw.color}>
            <TagLabel>{props.option.label}</TagLabel>
            <TagCloseButton
              tabIndex={-1}
              onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onChange({
                  value: props.option,
                  values: selectedValues.filter((v) => v.value !== props.option.value),
                  action: 'REMOVE',
                });
              }}
            />
          </Tag>
        ),
        Option: (props) => (
          <HStack w='full'>
            <Tooltip label={props.option.raw.description} p={2}>
              <Tag size='sm' colorScheme={props.option.raw.color ?? 'blue'} maxW='100%'>
                <TagLabel>{props.option.label}</TagLabel>
              </Tag>
            </Tooltip>
            <Spacer />
            <SelectCheck />
            {P.isString(props.option.raw) && props.option.raw === 'CREATABLE' ? null : (
              <ManageLabelMenu label={props.option.raw} />
            )}
          </HStack>
        ),
        Footer: () => (
          <MultiSelectComponents.Footer>
            <Text color='gray.500' fontSize='xs' fontWeight='semibold'>
              Select a label or create one
            </Text>
          </MultiSelectComponents.Footer>
        ),
      }}
    />
  );
};
