import { forwardRef } from '@chakra-ui/react';
import type { LooseObject } from '@piccolohealth/echo-common';
import React from 'react';
import { AuthedImage } from '../../generic/AuthedImage';

interface Props {
  id: string;
  src: string;
  onScreenshot?: (data: string, metadata: LooseObject) => void;
}

export const JpegPlayer = forwardRef<Props, 'img'>((props, ref) => {
  const { id, src, onScreenshot } = props;

  return (
    <AuthedImage
      ref={ref}
      url={src}
      cacheKey={id}
      onLoaded={(blob) => onScreenshot?.(blob.base64, {})}
      h='full'
      w='full'
      objectFit='contain'
      className='imageviewer'
    />
  );
});
