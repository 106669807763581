import { Box, Button, Heading, Icon, Stack } from '@chakra-ui/react';
import {
  type ReportTemplateStatement,
  type ReportTemplateStatementSite,
  uuid,
} from '@piccolohealth/echo-common';
import { DndKitCore, DndKitSortable, Empty } from '@piccolohealth/ui';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FaPlus } from 'react-icons/fa';
import { useReport } from '../../../../context/ReportContext';
import { HookedFormItem } from '../../../forms/hookform/HookedFormItem';
import { Divider } from '../../../generic/Divider';
import type { TiptapContext } from '../../../tiptap/utils/TiptapContext';
import { SortableReportTemplateStatement } from './SortableReportTemplateStatement';

export const SortableReportTemplateStatements = () => {
  const { reportId, reportTemplate, isDisabled, timezone } = useReport();

  const { watch } = useFormContext();
  const currentReportTemplateStatementSiteId = watch('currentReportTemplateStatementSiteId');
  const reportTemplateStatementSites = watch('statementSites') as ReportTemplateStatementSite[];

  const currentReportTemplateStatementSiteIndex = reportTemplateStatementSites.findIndex(
    (s) => s.id === currentReportTemplateStatementSiteId,
  );

  const currentReportTemplateStatementSite = watch(
    `statementSites[${currentReportTemplateStatementSiteIndex}]`,
  ) as ReportTemplateStatementSite;

  const { fields, append, remove, move } = useFieldArray({
    name: `statementSites[${currentReportTemplateStatementSiteIndex}].statements`,
    keyName: 'sortableId',
  });

  const reportTemplateStatements = fields as unknown as ReportTemplateStatement[];

  const sensors = DndKitCore.useSensors(
    DndKitCore.useSensor(DndKitCore.PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  const variablePairs = React.useMemo(() => {
    return reportTemplate.variables.map((template) => ({
      template,
      variable: {
        id: template.id,
        reportTemplateVersionId: reportTemplate.versionId,
        alias: template.alias,
        reportId,
        value: template.defaultValue,
        isTouched: false,
        template,
      },
    }));
  }, [reportId, reportTemplate]);

  const getFormName = React.useCallback((id: string) => {
    return `reportTemplateVariables.${id}.defaultValue`;
  }, []);

  const context: TiptapContext = React.useMemo(
    () => ({
      isDisabled,
      timezone,
      getFormName,
      reportTemplate,
      variablePairs,
      reportTemplateStatements,
    }),
    [isDisabled, timezone, reportTemplate, variablePairs, reportTemplateStatements, getFormName],
  );

  const onDragEnd = React.useCallback(
    (event: DndKitCore.DragEndEvent) => {
      const { active, over } = event;
      if (over && active.id !== over.id) {
        const oldIndex = reportTemplateStatements.findIndex((item) => item.id === active.id);
        const newIndex = reportTemplateStatements.findIndex((item) => item.id === over.id);
        move(oldIndex, newIndex);
      }
    },
    [move, reportTemplateStatements],
  );

  const onRemove = React.useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  const onAdd = React.useCallback(() => {
    const newReportTemplateStatement: ReportTemplateStatement = {
      id: uuid(),
      value: '',
      default: true,
    };
    append(newReportTemplateStatement);
  }, [append]);

  if (!currentReportTemplateStatementSite) {
    return <Empty title='No site found' />;
  }

  return (
    <Box px={4} py={4}>
      <Heading size='md'>{currentReportTemplateStatementSite.name}</Heading>
      <Divider />
      <DndKitCore.DndContext
        sensors={sensors}
        collisionDetection={DndKitCore.closestCenter}
        onDragEnd={onDragEnd}
      >
        <DndKitSortable.SortableContext
          strategy={DndKitSortable.verticalListSortingStrategy}
          items={reportTemplateStatements.map(({ id }) => id)}
        >
          <Stack spacing={2}>
            {reportTemplateStatements.map((reportTemplateStatement, index) => (
              <HookedFormItem
                key={reportTemplateStatement.id}
                name={`statementSites[${currentReportTemplateStatementSiteIndex}].statements[${index}].value`}
              >
                <SortableReportTemplateStatement
                  key={reportTemplateStatement.id}
                  name={`statementSites[${currentReportTemplateStatementSiteIndex}].statements[${index}]`}
                  site={currentReportTemplateStatementSite.name}
                  reportTemplateStatement={reportTemplateStatement}
                  onRemove={() => onRemove(index)}
                  context={context}
                  isDisabled={isDisabled}
                />
              </HookedFormItem>
            ))}
            <Button rightIcon={<Icon as={FaPlus} />} onClick={onAdd}>
              Add statement
            </Button>
          </Stack>
        </DndKitSortable.SortableContext>
      </DndKitCore.DndContext>
    </Box>
  );
};
