import { Box, HStack, Spacer } from '@chakra-ui/react';
import type { ReportTemplateStatement } from '@piccolohealth/echo-common';
import { DndKitSortable, DragHandle, Sortable } from '@piccolohealth/ui';
import React from 'react';
import { useController } from 'react-hook-form';
import { FaTrash } from 'react-icons/fa';
import { ToggleTagControl } from '../../../forms/ToggleTagControl';
import { IconButton } from '../../../generic/IconButton';
import { TiptapReportTemplateStatement } from '../../../tiptap/editors/TiptapReportTemplateStatement';
import type { TiptapContext } from '../../../tiptap/utils/TiptapContext';

const Controls = (props: { isDefault: boolean; onRemove: () => void; onDefault: () => void }) => {
  const { isDefault, onRemove, onDefault } = props;

  return (
    <HStack>
      <ToggleTagControl value={isDefault} onToggle={onDefault} />
      <IconButton icon={FaTrash} aria-label='Remove' onClick={onRemove} />
    </HStack>
  );
};

interface Props {
  name: string;
  site: string;
  isDisabled: boolean;
  reportTemplateStatement: ReportTemplateStatement;
  onRemove: () => void;
  context: TiptapContext;
}

export const SortableReportTemplateStatement = (props: Props) => {
  const { name, isDisabled, site, reportTemplateStatement, onRemove, context } = props;
  const isDefaultControl = useController({ name: `${name}.default` });
  const valueControl = useController({ name: `${name}.value` });

  const onDefault = React.useCallback(() => {
    isDefaultControl.field.onChange(!isDefaultControl.field.value);
  }, [isDefaultControl]);

  const { attributes, setNodeRef, listeners, transform, transition } = DndKitSortable.useSortable({
    id: reportTemplateStatement.id,
  });

  return (
    <Sortable ref={setNodeRef} transform={transform} transition={transition}>
      <HStack w='full' spacing={1}>
        <DragHandle h='9' listeners={listeners} attributes={attributes} />

        <HStack
          h='full'
          w='full'
          spacing={1}
          px={3}
          py={1}
          rounded='lg'
          _hover={{ bg: 'gray.100' }}
        >
          <Box w='full'>
            <TiptapReportTemplateStatement
              isDisabled={isDisabled}
              site={site}
              value={valueControl.field.value}
              onChange={valueControl.field.onChange}
              context={context}
            />
          </Box>
          <Spacer />
          <Controls
            isDefault={isDefaultControl.field.value}
            onDefault={onDefault}
            onRemove={onRemove}
          />
        </HStack>
      </HStack>
    </Sortable>
  );
};
