import { Button, HStack, Spacer, Tooltip } from '@chakra-ui/react';
import {
  type Distribution,
  DistributionStatus,
  PERMISSIONS,
  ReportStatus,
} from '@piccolohealth/echo-common';
import React from 'react';
import { Card } from '../../../components/generic/Card';
import { Divider } from '../../../components/generic/Divider';
import { showModal } from '../../../components/generic/Modal';
import { VisibleWithPermission } from '../../../components/generic/VisibleWithPermission';
import { usePermission } from '../../../hooks/usePermission';
import { DistributionDeleteModal } from './DistributionDeleteModal';
import { DistributionEditModal } from './DistributionEditModal';
import { DistributionEventsModal } from './DistributionEventsModal';
import { DistributionHeader } from './DistributionHeader';
import { DistributionSendModal } from './DistributionSendModal';
import { DistributionSummary } from './DistributionSummary';

interface Props {
  distribution: Distribution;
  reportStatus: ReportStatus;
}

export const DistributionCard = (props: Props) => {
  const { distribution, reportStatus } = props;

  const hasSendPermission = usePermission(PERMISSIONS.distributionSend).value;

  const onViewEvents = React.useCallback(() => {
    showModal(DistributionEventsModal, {
      events: distribution.events,
    });
  }, [distribution]);

  const onRemove = React.useCallback(() => {
    showModal(DistributionDeleteModal, {
      reportId: distribution.reportId,
      distributionId: distribution.id,
    });
  }, [distribution]);

  const onEdit = React.useCallback(() => {
    showModal(DistributionEditModal, {
      distribution: distribution,
    });
  }, [distribution]);

  const onSend = React.useCallback(() => {
    showModal(DistributionSendModal, {
      distribution: distribution,
    });
  }, [distribution]);

  const canRemove = ![DistributionStatus.Sent, DistributionStatus.Processing].includes(
    distribution.status,
  );
  const canEdit = ![
    DistributionStatus.Sent,
    DistributionStatus.Failed,
    DistributionStatus.Processing,
  ].includes(distribution.status);
  const canSend = (reportStatus === ReportStatus.Finalized && canEdit) || !hasSendPermission;

  return (
    <Card p={4} h='full' w='lg'>
      <DistributionHeader payload={distribution.payload} status={distribution.status} />
      <Divider />
      <DistributionSummary
        payload={distribution.payload}
        trigger={distribution.trigger}
        events={distribution.events}
        createdAt={distribution.createdAt}
      />
      <Spacer />
      <Divider />
      <HStack>
        <Spacer />
        <VisibleWithPermission permission={PERMISSIONS.organizationSupport}>
          <Button variant='link' onClick={onViewEvents}>
            View events
          </Button>
        </VisibleWithPermission>
        <Tooltip
          isDisabled={canRemove}
          label='Distributions cannot be removed after the distribution has been sent'
        >
          <Button
            variant='link'
            colorScheme='red'
            onClick={onRemove}
            isDisabled={!canRemove}
            data-pw={`distributionRemoveButton-${distribution.id}`}
          >
            Remove
          </Button>
        </Tooltip>
        <Tooltip
          isDisabled={canEdit}
          label='Distributions cannot be edited after the distribution has been sent'
        >
          <Button
            variant='link'
            colorScheme='purple'
            onClick={onEdit}
            isDisabled={!canEdit}
            data-pw={`distributionEditButton-${distribution.id}`}
          >
            Edit
          </Button>
        </Tooltip>
        <Tooltip
          isDisabled={canSend}
          label='Distributions cannot be sent until report is finalized, and the distribution has not been sent already'
        >
          <Button
            variant='link'
            colorScheme='purple'
            onClick={onSend}
            isDisabled={!canSend}
            data-pw={`distributionSendButton-${distribution.id}`}
          >
            Send now
          </Button>
        </Tooltip>
      </HStack>
    </Card>
  );
};
