import { Box, HStack, Skeleton, Stack } from '@chakra-ui/react';
import {
  type Report,
  type ReportShare,
  getReportVariableValue,
  getReportVariableValueAsDateTime,
  getReportVariableValueAsString,
  renderNullable,
} from '@piccolohealth/echo-common';
import { DateTime, P } from '@piccolohealth/util';
import React from 'react';
import { useReportShareQuery } from '../../../graphql/hooks/useReportShareQuery';
import { useAppContext } from '../../../hooks/useAppContext';

interface Props {
  reportId: string;
}

export const SharedReportHeader = (props: Props) => {
  const { reportId } = props;
  const { organization } = useAppContext();

  const { data } = useReportShareQuery({
    organizationId: organization.id,
    reportId: reportId,
  });

  const reportShare = data?.organization?.reportShare as ReportShare | undefined;
  const report = reportShare?.report as Report | undefined;

  if (!reportShare || !report) {
    return (
      <Stack>
        <Skeleton height='21px' endColor='gray.50' />
      </Stack>
    );
  }

  const dobValue = getReportVariableValueAsDateTime(report.variables, 'dob');
  const dob = renderNullable(dobValue?.toLocaleString(DateTime.DATE_SHORT));
  const ageValue = getReportVariableValue<string>(report.variables, 'age');
  const age = renderNullable(ageValue && `${ageValue} `);
  const reportDateValue = getReportVariableValueAsDateTime(report.variables, 'reportDate');
  const reportDate = renderNullable(reportDateValue?.toLocaleString(DateTime.DATETIME_SHORT));
  const sexValue = getReportVariableValueAsString(report.variables, 'sex');
  const sex = renderNullable(sexValue && P.upperFirst(sexValue));

  return (
    <HStack spacing={4} minH={10} h='fit-content' overflowX='hidden' whiteSpace='nowrap'>
      <HStack>
        <Box>Shared by:</Box>
        <Box>{reportShare.sharerEmail}</Box>
      </HStack>
      <HStack>
        <Box>DOB:</Box>
        <Box>{dob}</Box>
      </HStack>
      <HStack>
        <Box>Age:</Box>
        <Box>{age}</Box>
      </HStack>
      <HStack>
        <Box>Sex:</Box>
        <Box>{sex}</Box>
      </HStack>
      <HStack>
        <Box>Report date:</Box>
        <Box>{reportDate}</Box>
      </HStack>
    </HStack>
  );
};
