import { HStack, Spacer, Tag, Text } from '@chakra-ui/react';
import {
  type ReportTemplateStaticVariable,
  renderFormattedValue,
} from '@piccolohealth/echo-common';
import {
  Select,
  SelectCheck,
  type SelectGroup,
  type SelectOption,
  type SelectProps,
  typedMemo,
} from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import type { VariablesBySite } from './sidebar/MeasurementsSidebar';
import { type MeasurementType, isUnitValidMeasurementType } from './utils';

type MeasurementOption = {
  reportTemplateVariable: ReportTemplateStaticVariable;
  renderedValue: string | null;
};

const getOptions = (
  variables: VariablesBySite,
  type: MeasurementType,
): SelectGroup<MeasurementOption, string>[] => {
  return variables.flatMap(({ site, measurements }) => {
    const options = P.compact(
      measurements.map(({ reportTemplateVariable, variable }) => {
        if (!reportTemplateVariable) {
          return null;
        }

        // Only allow measuring StaticVariables
        if (reportTemplateVariable.__typename !== 'ReportTemplateStaticVariable') {
          return null;
        }

        // // Only allow measuring variables that are editable. I.e. no indexed variables
        if (!reportTemplateVariable.isEditable) {
          return null;
        }

        if (!reportTemplateVariable.units) {
          return null;
        }

        if (!isUnitValidMeasurementType(reportTemplateVariable.units, type)) {
          return null;
        }

        const renderedValue = P.run(() => {
          if (!variable.value) {
            return reportTemplateVariable.units ?? '';
          }

          const formattedValue = renderFormattedValue(variable.value, reportTemplateVariable.units);

          if (!P.isString(formattedValue)) {
            return null;
          }

          return formattedValue;
        });

        return {
          value: reportTemplateVariable.id,
          label: reportTemplateVariable.label,
          raw: { reportTemplateVariable, renderedValue },
        };
      }),
    );

    if (P.isEmpty(options)) {
      return [];
    }

    return {
      id: site,
      label: site,
      raw: site,
      options: options,
    };
  });
};

interface Props
  extends Omit<SelectProps<MeasurementOption, string>, 'onChange' | 'value' | 'options'> {
  variables: VariablesBySite;
  type: MeasurementType;
  value: string | null;
  onChange: (value: string) => void;
}

export const MeasurementSelect = typedMemo((props: Props) => {
  const { variables, value, onChange, ...rest } = props;

  const options: SelectGroup<MeasurementOption, string>[] = React.useMemo(
    () => getOptions(variables, props.type),
    [props.type, variables],
  );

  const selectedValue = React.useMemo(
    () => options.flatMap((option) => option.options).find((o) => o.value === value) ?? null,
    [options, value],
  );

  const onChangePrime = React.useCallback(
    (option: SelectOption<MeasurementOption>) => {
      onChange(option.raw.reportTemplateVariable.id);
    },
    [onChange],
  );

  return (
    <Select
      options={options}
      value={selectedValue}
      onChange={onChangePrime}
      placeholder='No measurement selected'
      components={{
        Option: (props) => {
          return (
            <HStack fontSize='sm' w='full'>
              <Text fontSize='xs'>{props.option.label}</Text>
              <Spacer />
              <SelectCheck />
              {props.option.raw.renderedValue && (
                <Tag flexShrink={0} size='sm'>
                  {props.option.raw.renderedValue}
                </Tag>
              )}
            </HStack>
          );
        },
        Value: (props) => {
          return (
            <HStack fontSize='sm' w='full' mr={2}>
              <Text fontSize='xs'>{props.option.label}</Text>
              <Spacer />
              {props.option.raw.renderedValue && (
                <Tag flexShrink={0} size='sm'>
                  {props.option.raw.renderedValue}
                </Tag>
              )}
            </HStack>
          );
        },
      }}
      {...rest}
    />
  );
});
